import React from "react";
import {
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
  useUpdate,
  useNavigation,
  BaseRecord,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { CustomErrorComponent, FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { setFormInitialValues } from "utils";
import { ButtonProps, Descriptions, Divider } from "antd";
import { IResourceFormColumn } from "interfaces";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface TransactionEditProps extends IResourceComponentsProps {
  id?: string | number;
  saveButtonProps?: ButtonProps;
}

export const TransactionEdit: React.FC<TransactionEditProps> = ({
  id,
  saveButtonProps,
}) => {
  const { id: idFromParams } = useParsed();
  const { resource } = useResource();
  const { list } = useNavigation();
  const { mutate: mutateUpdate, isLoading: isUpdateLoading } = useUpdate();

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    queryResult,
    formLoading,
  } = useForm({
    id: id || idFromParams,
    resource: resource?.meta?.editResource,
    meta: { method: resource?.meta?.editMethod || "put" },
    warnWhenUnsavedChanges: false,
  });
  const record = queryResult?.data?.data;
  const customerType =
    record?.unitHolderId?.[4] === "I" ? "retail" : "institution";
  const transactionType = !!record?.switchingCode
    ? "switching"
    : record?.transactionType?.transactionTypeName?.toLowerCase();

  let formResourceName = `transactions/create/${customerType}/${transactionType}`;
  const { resource: formResource } = useResource(formResourceName);

  let descriptionItems = [
    {
      key: "fullName",
      label: "Customer",
      children: `${record?.fullName || ""} (${record?.unitHolderId || ""})`,
    },
  ];

  if (!!record?.switchingCode) {
    descriptionItems.push({
      key: "transactionType",
      label: "Transaction Type",
      children: `Switching (${record?.transactionType?.transactionTypeName})`,
    });
  } else {
    descriptionItems.push({
      key: "transactionType",
      label: "Transaction Type",
      children: record?.transactionType?.transactionTypeName,
    });
  }

  const customFormColumns = formResource?.meta?.formColumns?.reduce(
    (prev: IResourceFormColumn[], column: IResourceFormColumn) => {
      // filterout remaining unit
      if (column.key === "display-remaining") {
        return prev;
      }

      if (column.key === "productId") {
        return [
          ...prev,
          {
            key: "productId",
            label: "Product",
            type: "select",
            span: 2,
            hideOnCreate: true,
            isDisabled: true,
            ignoreOnSubmit: true,
            optionsMeta: {
              resourceName: "products/data",
              initialFilters: [
                { field: "approvalStatusId", operator: "eq", value: 3 },
              ],
            },
          },
        ];
      } else if (column.key === "productFromId") {
        return [
          ...prev,
          {
            key: "productFromId",
            label: "Product",
            type: "select",
            span: 2,
            hideOnCreate: true,
            isDisabled: true,
            ignoreOnSubmit: true,
            optionsMeta: {
              resourceName: "products/data",
              initialFilters: [
                { field: "approvalStatusId", operator: "eq", value: 3 },
              ],
            },
          },
        ];
      }
      return [...prev, column];
    },
    []
  );

  const onFinish = (values: BaseRecord) => {
    mutateUpdate(
      {
        resource: formResource?.name,
        id: id || idFromParams || "",
        values: values,
        meta: { method: "patch" },
      },
      { onSuccess: () => list(resource?.identifier || resource?.name || "") }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  // set the form's initial values
  setFormInitialValues({ formColumns: resource?.meta?.formColumns, formProps });

  formProps?.form?.setFieldsValue({
    amount: formProps?.initialValues?.nominal,
    settlementDate: formProps?.initialValues?.settledAt,
    productId: formProps?.initialValues?.product?.id,
    productFromId: formProps?.initialValues?.product?.id,
    valueType: formProps?.initialValues?.unit ? true : false,
    value: formProps?.initialValues?.unit
      ? formProps?.initialValues?.unit
      : formProps?.initialValues?.nominal,
  });

  return (
    <Edit
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined, extra: <CustomRefreshButton /> }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading || isUpdateLoading,
        ...saveButtonProps,
        disabled:
          record?.transactionType?.transactionTypeCode === "SUB" &&
          !!record?.switchingCode,
      }}
      isLoading={formLoading || isUpdateLoading}
    >
      <Descriptions column={1} bordered items={descriptionItems} />

      <Divider />

      <FormCard
        formProps={{ ...formProps, onFinish }}
        resource={{
          ...formResource,
          meta: { ...formResource?.meta, formColumns: customFormColumns },
        }}
      />
    </Edit>
  );
};
