import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import { Title } from "components/atoms";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export const SplashScreen: React.FC = () => {
  const colorMode = useSelector(
    (state: RootState) => state?.general?.colorMode
  );

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colorMode === "dark" ? "black" : "white",
      }}
    >
      <Space
        size="large"
        direction="vertical"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Title size="large" collapsed={false} mode={colorMode} />
        <Spin size="large" indicator={<LoadingOutlined />} />
      </Space>
    </div>
  );
};
