import { useTable, CreateButton, List, useTableProps } from "@refinedev/antd";
import {
  BaseRecord,
  useResource,
  CrudFilters,
  HttpError,
} from "@refinedev/core";
import { DownloadButton } from "components/atoms";
import {
  ListDynamicFilter,
  ListTable,
  UploadDragger,
} from "components/molecules";
import { IFilterVariables, IResourceFilter } from "interfaces";
import { Col, Row, Divider } from "antd";
import { startCase } from "lodash";
import React from "react";

export interface PermissionListContainerProps {
  title?: string;
  resourceName?: string;
  extraButton?: React.ReactNode;
  disableActions?: boolean;
  rowSelection?: any;
  onRow?: (record: BaseRecord) => any;
  useTableProps?: useTableProps<
    BaseRecord,
    HttpError,
    IFilterVariables,
    BaseRecord
  >;
}

export const PermissionListContainer: React.FC<
  PermissionListContainerProps
> = ({
  title,
  resourceName,
  extraButton,
  disableActions,
  rowSelection,
  onRow,
  useTableProps,
}) => {
  const { resource } = useResource(resourceName);

  const { tableProps, searchFormProps, sorters, filters } = useTable<
    BaseRecord,
    HttpError,
    IFilterVariables
  >({
    resource: resource?.meta?.listResource || resource?.name,
    pagination: resource?.meta?.pagination,
    sorters: { initial: resource?.meta?.initialSorters },
    filters: {
      defaultBehavior: "replace",
      initial: resource?.meta?.initialFilters,
      permanent: resource?.meta?.permanentFilters,
    },
    onSearch: (filterParams) => {
      const filters: CrudFilters = [];

      resource?.meta?.filters?.forEach((filter: IResourceFilter) => {
        const filterParam =
          filterParams[filter?.key as keyof IFilterVariables] !== ""
            ? filterParams[filter?.key as keyof IFilterVariables]
            : undefined;

        if (filter?.type === "daterange") {
          filters.push({
            field: filter?.key,
            operator: "gte",
            value: filterParam?.[0]?.format("YYYY-MM-DD") ?? undefined,
          });
          filters.push({
            field: filter?.key,
            operator: "lte",
            value: filterParam?.[1]?.format("YYYY-MM-DD") ?? undefined,
          });
        } else {
          filters.push({
            field: filter?.key,
            operator: filter?.operator,
            value: filterParam,
          });
        }
      });

      return filters;
    },
    ...useTableProps,
  });

  const mapHierarchy = (data?: readonly BaseRecord[]) => {
    let hierarchy: any = [];

    // push all children data
    data?.forEach((record) => {
      if (!record?.modules?.parentModule) {
        hierarchy.push({ ...record });
      } else {
        let parent = hierarchy?.find((item: BaseRecord) => {
          return item?.modules?.module === record?.modules?.parentModule;
        });
        if (!!parent) {
          if (!parent?.children) parent.children = [];
          parent.children.push({ ...record });
        } else {
          hierarchy.push({ ...record });
        }
      }
    });

    return hierarchy;
  };

  return (
    <List
      resource={resource?.meta?.listResource || resource?.name}
      title={
        title ||
        resource?.meta?.label ||
        startCase(resource?.identifier || resource?.name || "")
      }
      headerProps={{
        breadcrumb: undefined,
        extra: !disableActions && (
          <>
            {extraButton}

            {resource?.meta?.downloadButtons?.map((button: any) => (
              <DownloadButton
                key={button?.resource}
                resource={button?.resource}
                label={button?.label}
              />
            ))}

            {resource?.meta?.canCreate && <CreateButton />}
          </>
        ),
      }}
    >
      <div style={{ margin: "24px" }} />

      <Row gutter={[16, 16]}>
        {resource?.meta?.uploadResource && (
          <Col xs={24}>
            <UploadDragger
              resource={resource?.meta?.uploadResource}
              formats={resource?.meta?.uploadFormats}
            />
            <Divider />
          </Col>
        )}

        {resource?.meta?.filters && (
          <Col
            xs={24}
            // lg={24} xl={6}
          >
            {/* <Card title="Filters" bordered={false}> */}
            <ListDynamicFilter
              tableProps={tableProps}
              formProps={searchFormProps}
              filters={resource?.meta?.filters}
            />
            {/* </Card> */}
          </Col>
        )}

        <Col
          xs={24}
          // xl={resourceInfo?.filters ? 18 : 24}
        >
          <ListTable
            resourceName={resource?.identifier || resource?.name}
            tableProps={{
              ...tableProps,
              dataSource: mapHierarchy(tableProps?.dataSource),
              rowSelection: rowSelection
                ? { ...tableProps.rowSelection, ...rowSelection }
                : undefined,
              onRow,
            }}
            sorters={sorters}
            filters={filters}
            fixedColumns={resource?.meta?.fixedColumns}
            disableActions={disableActions}
          />
        </Col>
      </Row>
    </List>
  );
};
