import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  useResource,
  useList,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, SaveButton } from "@refinedev/antd";
import { FormCard } from "components/organisms";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Divider } from "antd";

export const PortfolioCreate: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const translate = useTranslate();

  const {
    formProps: latestFormProps,
    saveButtonProps: latestSaveButtonProps,
    formLoading: latestFormLoading,
  } = useForm({
    resource: "customers/user-portfolios",
    redirect: false,
  });

  const {
    formProps: historyFormProps,
    saveButtonProps: historySaveButtonProps,
    formLoading: historyFormLoading,
  } = useForm({
    resource: "customers/user-portfolio-histories",
    redirect: false,
  });

  const getInitialDate = useList({
    resource: "customers/user-portfolio-histories/default-date-ranges",
    pagination: { mode: "off" },
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });
  let initialDate = getInitialDate?.data?.data;

  useEffect(() => {
    if (initialDate) {
      const dateFrom = dayjs(initialDate?.[1]?.date);
      const dateTo = dayjs(initialDate?.[0]?.date);
      const initialDateValue = [dateFrom, dateTo];
      historyFormProps?.form?.setFieldsValue({
        date: initialDateValue,
      });
    }
  }, [initialDate, historyFormProps?.form]);

  return (
    <>
      <Create
        title="Run Portfolio History"
        goBack={false}
        headerProps={{ breadcrumb: undefined }}
        saveButtonProps={{
          ...historySaveButtonProps,
          loading: historyFormLoading,
        }}
        footerButtons={({ saveButtonProps }) => (
          <>
            <SaveButton
              {...saveButtonProps}
              type="primary"
              style={{ marginRight: 8 }}
              icon={historyFormLoading ? <LoadingOutlined /> : <SyncOutlined />}
            >
              {translate("buttons.run", "Run")}
            </SaveButton>
          </>
        )}
      >
        <FormCard formProps={historyFormProps} resource={resource} />
      </Create>

      <Divider />

      <Create
        title="Run Latest Portfolio"
        goBack={false}
        headerProps={{ breadcrumb: undefined }}
        saveButtonProps={{
          ...latestSaveButtonProps,
          loading: latestFormLoading,
        }}
        footerButtons={({ saveButtonProps }) => (
          <>
            <SaveButton
              {...saveButtonProps}
              type="primary"
              style={{ marginRight: 8 }}
              icon={latestFormLoading ? <LoadingOutlined /> : <SyncOutlined />}
            >
              {translate("buttons.run", "Run")}
            </SaveButton>
          </>
        )}
      >
        <FormCard
          formProps={latestFormProps}
          resource={{ name: "customers/user-portfolios" }}
        />
      </Create>
    </>
  );
};
