import React from "react";
import {
  BaseRecord,
  IResourceComponentsProps,
  useInvalidate,
  useResource,
} from "@refinedev/core";
import { Create, UseFormProps, useForm } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { FormCard } from "components/organisms";
import { CalculatorOutlined } from "@ant-design/icons";
import { ResultCard } from "./components";

export const FeeCalculatorCreate: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const invalidate = useInvalidate();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };
  const [feeHistories, setFeeHistories] = React.useState<BaseRecord>();

  if (resource?.meta?.defaultAction === "create") {
    useFormProps = {
      ...useFormProps,
      onMutationSuccess: (data) => {
        setFeeHistories(data?.data);
        invalidate({
          resource: resource?.identifier || resource?.name || "",
          invalidates: ["all"],
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
    };
  }

  const { formProps, saveButtonProps, formLoading } = useForm(useFormProps);
  const formValues = formProps?.form?.getFieldsValue() as any;

  if (feeHistories?.length > 0) {
    return (
      <ResultCard
        feeHistories={feeHistories as BaseRecord[]}
        setFeeHistories={setFeeHistories}
        fee={formValues?.fee}
        unit={formValues?.unit}
      />
    );
  }

  return (
    <Create
      key={resource?.identifier || resource?.name}
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={{
        ...saveButtonProps,
        loading: formLoading,
        icon: <CalculatorOutlined />,
        children: "Calculate",
      }}
    >
      <FormCard
        key={resource?.identifier || resource?.name}
        formProps={formProps}
        resource={resource}
      />
    </Create>
  );
};
