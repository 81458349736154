import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { SplashScreen } from "components";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_IO_URL}`,
  release: "backoffice-web@1.7.0",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <ReduxProvider store={store}>
    <React.Suspense fallback={<SplashScreen />}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </React.Suspense>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
