import React, { useState } from "react";
import {
  BaseRecord,
  IResourceComponentsProps,
  useInvalidate,
  useResource,
  useUpdateMany,
  BaseKey,
} from "@refinedev/core";
import { ApproveButtons } from "components/molecules";
import { PermissionListContainer } from "./components/permissionListContainer";
import { Space } from "antd";

interface PermissionListProps extends IResourceComponentsProps {
  title?: string;
}

export const PermissionList: React.FC<PermissionListProps> = ({ title }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { resource } = useResource();
  const { mutate: mutateMany, isLoading: isMutateManyLoading } =
    useUpdateMany();
  const invalidate = useInvalidate();

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record: BaseRecord) => {
      const status = (
        record?.approvalStatus?.approvalStatusName ||
        record?.kycStatus?.kycStatusName ||
        record?.transactionStatus?.transactionStatusName
      )?.toLowerCase();

      return {
        disabled: !(status === (resource?.meta?.statusToApprove || "pending")),
      };
    },
  };

  const handleApproval = ({ isApproved }: { isApproved: boolean }) => {
    mutateMany(
      {
        resource:
          resource?.meta?.approveResource || `${resource?.name}/approval`,
        ids: (selectedRowKeys || []) as BaseKey[],
        values: { isApproved },
        meta: { method: "patch" },
      },
      {
        onSettled: () => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
          setSelectedRowKeys([]);
        },
      }
    );
  };

  return (
    <PermissionListContainer
      key={resource?.identifier || resource?.name}
      title={title}
      resourceName={resource?.identifier || resource?.name}
      rowSelection={resource?.meta?.canBulkApprove ? rowSelection : undefined}
      extraButton={
        resource?.meta?.canBulkApprove && (
          <Space direction="horizontal" size="large">
            {selectedRowKeys?.length > 0 && (
              <div>{selectedRowKeys?.length} items selected</div>
            )}

            <ApproveButtons
              onClick={handleApproval}
              disableApprove={!(selectedRowKeys?.length > 0)}
              disableReject={!(selectedRowKeys?.length > 0)}
              isLoading={isMutateManyLoading}
            />
          </Space>
        )
      }
    />
  );
};
