import { Grid, Watermark } from "antd";
import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/id";
import { useGetLocale } from "@refinedev/core";

const ThemedLayoutV2 = lazy(() =>
  import("@refinedev/antd").then((module) => ({
    default: module.ThemedLayoutV2,
  }))
);
const MobileNavbar = lazy(() =>
  import("./mobileNavbar").then((module) => ({ default: module.MobileNavbar }))
);
const VerticalNavbar = lazy(() =>
  import("./verticalNavbar").then((module) => ({
    default: module.VerticalNavbar,
  }))
);
const Title = lazy(() =>
  import("components/atoms").then((module) => ({ default: module.Title }))
);
const Header = lazy(() =>
  import("./header").then((module) => ({ default: module.Header }))
);
const Footer = lazy(() =>
  import("./footer").then((module) => ({ default: module.Footer }))
);

interface CustomLayoutProps {
  children?: React.ReactNode;
}

export const CustomLayout: React.FC<CustomLayoutProps> = ({ children }) => {
  const user = useSelector((state: RootState) => state.user);
  const navbarMode = useSelector(
    (state: RootState) => state.general.navbarMode
  );
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.sm === "undefined" ? false : !breakpoint.sm;
  const locale = useGetLocale();
  const currentLocale = locale();
  dayjs.locale(currentLocale);

  if (!!isMobile) {
    return (
      <Watermark content={[user?.email || "", dayjs().format("D MMMM YYYY")]}>
        <ThemedLayoutV2
          Sider={() => <MobileNavbar Title={Title} />}
          Header={() => <Header showNavbar={false} />}
          Footer={() => <Footer />}
        >
          <div style={{ maxWidth: "1300px", marginInline: "auto" }}>
            {children}
          </div>
        </ThemedLayoutV2>
      </Watermark>
    );
  }

  if (navbarMode === "horizontal") {
    return (
      <Watermark content={[user?.email || "", dayjs().format("D MMMM YYYY")]}>
        <ThemedLayoutV2
          Sider={() => null}
          Header={() => <Header showNavbar={true} />}
          Footer={() => <Footer />}
        >
          <div style={{ maxWidth: "1300px", marginInline: "auto" }}>
            {children}
          </div>
        </ThemedLayoutV2>
      </Watermark>
    );
  }

  return (
    <Watermark content={[user?.email || "", dayjs().format("D MMMM YYYY")]}>
      <ThemedLayoutV2
        Sider={() => <VerticalNavbar Title={Title} />}
        Header={() => <Header showNavbar={false} />}
        Footer={() => <Footer />}
      >
        <div style={{ maxWidth: "1300px", marginInline: "auto" }}>
          {children}
        </div>
      </ThemedLayoutV2>
    </Watermark>
  );
};
