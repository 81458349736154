import { useApiUrl, useTranslate } from "@refinedev/core";
import { SummaryCard } from "./components";
import useAxiosQuery from "utils/useAxiosQuery";
import { Empty, Table, theme, Typography, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import {
  GetCISTotalTransactionProductResponse,
  GetGoodfundTransactionResponse,
  PostSInvestTransactionResponse,
} from "./models/api";
import { useUploader } from "../../../utils/useUploader";
import { goodfundTransactionResponseAdapter } from "./utils/goodfundTransactionResponseAdapter";
import { useMemo } from "react";
import useReconTableRows from "./hooks/useReconTableRows";
import { RECON_TABLE_COLUMNS } from "./models/constants/table";
import { RefinePageHeaderClassNames } from "@refinedev/ui-types";

export const ReconPage: React.FC = () => {
  const url = useApiUrl();
  const translate = useTranslate();
  const sinvestGoodfundTransactionsUrl = `${url}/recons/goodfund-transactions`;
  const cisGoodfundTransactionsUrl = `${url}/dashboard/total-transaction-per-product`;

  const { token } = theme.useToken();

  // SINVEST data
  const { data, loading, refetch } =
    useAxiosQuery<GetGoodfundTransactionResponse>(
      sinvestGoodfundTransactionsUrl
    );

  // CIS Data
  const { data: cisGoodfundRespData, loading: CISGoodfundRespDataLoading } =
    useAxiosQuery<GetCISTotalTransactionProductResponse>(
      cisGoodfundTransactionsUrl
    );

  // Upload SINVEST
  const {
    handleBeforeUpload,
    handleUpload,
    handleRemove,
    file,
    loading: SINvestLoading,
  } = useUploader<PostSInvestTransactionResponse>({
    onSuccess: refetch,
    accept: ["xls", "xlsx"],
    url: `${url}/recons/import`,
  });

  const cisGoodfundData = useMemo(
    () =>
      goodfundTransactionResponseAdapter(cisGoodfundRespData?.body?.rows || []),
    [cisGoodfundRespData]
  );

  const sinvestGoodfundData = useMemo(
    () => goodfundTransactionResponseAdapter(data?.body?.rows || []),
    [data]
  );

  const { data: tableData } = useReconTableRows(
    cisGoodfundData.rows,
    sinvestGoodfundData.rows
  );

  return (
    <div>
      <SummaryCard
        title={translate(
          "page.recon.summaryTitle",
          "Transaction Comparison Summary"
        )}
        dataGoodfund={cisGoodfundData.summary}
        dataSInvest={sinvestGoodfundData.summary}
        loading={loading || SINvestLoading || CISGoodfundRespDataLoading}
        isDataAvailable={!!cisGoodfundRespData && !!data}
      />
      <Typography.Title
        className={RefinePageHeaderClassNames.SubTitle}
        level={5}
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        {translate("page.recon.uploadTitle", "Upload SInvest Result")}
      </Typography.Title>
      <Upload.Dragger
        fileList={file ? [file] : []}
        onRemove={handleRemove}
        maxCount={1}
        beforeUpload={handleBeforeUpload}
        customRequest={handleUpload}
      >
        <div style={{ marginTop: "75px", marginBottom: "75px" }}>
          <div>
            <InboxOutlined
              style={{
                fontSize: "48px",
                color: token?.colorPrimary,
              }}
              size={100}
            />
          </div>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </div>
      </Upload.Dragger>
      <Typography.Title
        className={RefinePageHeaderClassNames.SubTitle}
        level={5}
        style={{ marginTop: 16, marginBottom: 16 }}
      >
        {translate("page.recon.discrepanciesTable", "Discrepancies")}
      </Typography.Title>
      <Table
        bordered
        rowKey="productId"
        dataSource={tableData?.differing || []}
        columns={RECON_TABLE_COLUMNS}
        loading={loading || SINvestLoading || CISGoodfundRespDataLoading}
        locale={{
          emptyText: (
            <Empty
              description={translate(
                "page.recon.discrepanciesEmptyMessage",
                "Differing transactions will be shown here"
              )}
            />
          ),
        }}
      />
      <Typography.Title
        className={RefinePageHeaderClassNames.SubTitle}
        level={5}
        style={{ marginBottom: 16, marginTop: 16 }}
      >
        {translate("page.recon.matchedTable", "Matched Transactions")}
      </Typography.Title>
      <Table
        bordered
        loading={loading || SINvestLoading || CISGoodfundRespDataLoading}
        locale={{
          emptyText: (
            <Empty
              description={translate(
                "page.recon.matchedTableEmptyMessage",
                "Matching transactions will be shown here"
              )}
            />
          ),
        }}
        rowKey="productId"
        dataSource={tableData?.matched || []}
        columns={RECON_TABLE_COLUMNS}
      />
    </div>
  );
};
