import { IResourceFormColumn } from "interfaces";
import { FormProps } from "antd";

const setFormInitialValues = ({
  formColumns,
  formProps,
}: {
  formColumns: IResourceFormColumn[] | undefined;
  formProps: FormProps<{}>;
}) => {
  const initialValues = formProps?.initialValues;

  // only for user form
  if (!!initialValues?.password) delete initialValues?.password;

  // only for dropdowns
  formColumns?.forEach((formColumn: IResourceFormColumn) => {
    const optionsObjectName =
      formColumn?.optionsMeta?.objectName ??
      formColumn?.key?.replace("Id", "") ??
      "";
    const optionsValueKey = formColumn?.optionsMeta?.valueKey ?? "id";
    const optionsValue = initialValues?.[optionsObjectName]?.[optionsValueKey];

    if (optionsValue) {
      Object.assign(initialValues, { [formColumn?.key ?? ""]: optionsValue });
    }
  });
};

export { setFormInitialValues };
