import React from "react";
import {
  IResourceComponentsProps,
  useInvalidate,
  useResource,
} from "@refinedev/core";
import { Create, UseFormProps, useForm } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { FormCard } from "components/organisms";
import { Button, Form, Input, Space } from "antd";
import { KeyOutlined } from "@ant-design/icons";

export const ExternalTokenCreate: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const invalidate = useInvalidate();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };
  const [token, setToken] = React.useState<string>();

  if (resource?.meta?.defaultAction === "create") {
    useFormProps = {
      ...useFormProps,
      onMutationSuccess: () => {
        formProps?.form?.resetFields();
        invalidate({
          resource: resource?.identifier || resource?.name || "",
          invalidates: ["all"],
        });
      },
    };
  }

  const { formProps, saveButtonProps, formLoading } = useForm(useFormProps);

  const generateToken = () => {
    const token = require("uuid").v4();
    formProps?.form?.setFieldsValue({ token });
    setToken(token);
  };

  return (
    <Create
      key={resource?.identifier || resource?.name}
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={{ ...saveButtonProps, loading: formLoading }}
    >
      <Form form={formProps?.form} layout="vertical">
        <Form.Item label={"Token"} name="token" required>
          <Space.Compact style={{ width: "100%" }}>
            <Input disabled value={token} />
            <Button
              type="primary"
              onClick={generateToken}
              icon={<KeyOutlined />}
            >
              {"Generate"}
            </Button>
          </Space.Compact>
        </Form.Item>
      </Form>

      <FormCard
        key={resource?.identifier || resource?.name}
        formProps={formProps}
        resource={resource}
      />
    </Create>
  );
};
