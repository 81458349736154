import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IAuthState {
  refreshToken?: string;
  email?: string;
  remember?: boolean;
}

const initialState: IAuthState = {
  refreshToken: "",
  email: "",
  remember: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<IAuthState>) => {
      Object.entries(action?.payload)?.forEach(([key, value]) => {
        if (value !== undefined) state[key as keyof IAuthState] = value;
      });
    },
    resetAuth: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, resetAuth } = authSlice.actions;

export default authSlice.reducer;
