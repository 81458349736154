import React from "react";
import {
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
  useCan,
  useUpdate,
  BaseRecord,
  useInvalidate,
  useTranslate,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { CustomErrorComponent, FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { setFormInitialValues } from "utils";
import { ButtonWithConfirmation } from "components/atoms";
import { PoweroffOutlined } from "@ant-design/icons";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface DefaultEditProps extends IResourceComponentsProps {
  id?: string | number;
}

export const UserEdit: React.FC<DefaultEditProps> = ({ id }) => {
  const { id: idFromParams } = useParsed();
  const { resource } = useResource();
  const translate = useTranslate();
  const invalidate = useInvalidate();
  const { mutate: mutateUpdate, isLoading: isDeactivating } =
    useUpdate<BaseRecord>();

  const { formProps, saveButtonProps, queryResult, formLoading } = useForm({
    id: id || idFromParams,
    resource: resource?.meta?.editResource,
    meta: { method: resource?.meta?.editMethod || "put" },
  });

  const record = queryResult?.data?.data;

  // set the form's initial values
  setFormInitialValues({ formColumns: resource?.meta?.formColumns, formProps });

  // handle the access control of the buttons
  const canDeactivate = useCan({
    resource: "users/deactivate",
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  const handleDeactivate = async () => {
    mutateUpdate(
      {
        resource: "users/deactivate",
        id: id || idFromParams || record?.id || "",
        values: { isActive: !record?.isActive },
        meta: { method: "patch" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Edit
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      isLoading={formLoading}
      headerProps={{
        breadcrumb: undefined,
        extra: (
          <>
            <ButtonWithConfirmation
              type="primary"
              danger={record?.isActive}
              onClick={handleDeactivate}
              loading={isDeactivating}
              disabled={!canDeactivate?.data?.can}
              icon={<PoweroffOutlined />}
            >
              {record?.isActive
                ? translate("buttons.deactivate")
                : translate("buttons.activate")}
            </ButtonWithConfirmation>

            <CustomRefreshButton />
          </>
        ),
      }}
      saveButtonProps={{ ...saveButtonProps, loading: formLoading }}
    >
      <FormCard formProps={formProps} resource={resource} />
    </Edit>
  );
};
