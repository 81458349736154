import { DownloadOutlined } from "@ant-design/icons";
import {
  useApiUrl,
  useCan,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import { Button, ButtonProps } from "antd";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { axiosInstance } from "utils";

interface DownloadButtonProps extends ButtonProps {
  resource?: string;
  id?: string;
  label?: string;
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  resource,
  id,
  label,
  ...rest
}) => {
  const apiUrl = useApiUrl();
  const { open } = useNotification();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  let url = `${apiUrl}/${resource}`;
  if (id) url += `/${id}`;

  const canDownload = useCan({
    resource: resource,
    action: !!id ? "show" : "list",
    queryOptions: {
      enabled: !!resource,
      staleTime: 5 * 60 * 1000,
    },
  });

  const onClick = () => {
    setIsLoading(true);
    axiosInstance
      .get(url, { responseType: "blob" })
      .then((res) => {
        let fileName = "download.csv";
        const contentDisposition = res.headers["content-disposition"];

        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename=(.+)/);
          if (filenameMatch?.length === 2) fileName = filenameMatch[1];
        }

        return fileDownload(res.data, fileName);
      })
      .catch((err) => {
        const errorBlob = err?.response?.data;

        // convert blob to json
        errorBlob.text().then((text: any) => {
          const error = JSON.parse(text);

          open?.({
            type: "error",
            message: translate(
              "notifications.error",
              { statusCode: error?.statusCode },
              `Error code: ${error?.statusCode}`
            ),
            description: error?.message || "Unknown error",
          });
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Button
      {...rest}
      onClick={onClick}
      loading={isLoading}
      icon={<DownloadOutlined />}
      disabled={rest?.disabled || !canDownload?.data?.can}
    >
      {label || "Download"}
    </Button>
  );
};
