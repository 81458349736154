import React, { useEffect, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useUpdate,
  useNavigation,
  useResource,
  useCan,
  useParsed,
  HttpError,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Space, TimelineItemProps } from "antd";
import {
  CustomErrorComponent,
  ShowItemRecursor,
  ShowLayout,
} from "components/organisms";
import { ApproveButtons } from "components/molecules";
import { mapStatus, useChildRecords } from "utils";
import { DownloadButton } from "components/atoms";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface TransactionDraftShowProps extends IResourceComponentsProps {
  id?: string | number;
}

export const TransactionDraftApprove: React.FC<TransactionDraftShowProps> = ({
  id,
}) => {
  const { mutate, isLoading: isMutateLoading } = useUpdate();
  const { list } = useNavigation();
  const { id: idFromParams, pathname } = useParsed();
  const { resource } = useResource(
    pathname?.split("/").filter(Boolean).slice(0, -2).join("/")
  );

  const { queryResult } = useShow({
    resource: resource?.meta?.showResource || resource?.name,
    id: id || idFromParams,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const customerType =
    record?.unitHolderId?.[4] === "I" ? "retail" : "institution";
  const transactionType = !!record?.switchingCode
    ? "switching"
    : record?.transactionType?.transactionTypeName?.toLowerCase();

  let formResourceName = `transactions/create/${customerType}/${transactionType}`;
  const { resource: formResource } = useResource(formResourceName);

  // handle the population of the data's status
  const statusData = useChildRecords({
    resourceName: resource?.meta?.statusResource,
    foreignKey: resource?.meta?.statusForeignKey,
    parentId: record?.id,
  });

  const [timelineItemProps, setTimelineItemProps] =
    useState<TimelineItemProps[]>();

  useEffect(() => {
    if (statusData && statusData.length > 0)
      setTimelineItemProps(mapStatus({ data: statusData }));
    else setTimelineItemProps(undefined);
  }, [resource, statusData, record]);

  const canApprove = useCan({
    resource: `${formResource?.name}/approval`,
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  // enable or disable the approval button
  const [needsApproval, setNeedsApproval] = React.useState(false);

  useEffect(() => {
    const status = (
      record?.approvalStatus?.approvalStatusName ||
      record?.kycStatus?.kycStatusName ||
      record?.transactionStatus?.transactionStatusName
    )?.toLowerCase();

    const needsApproval =
      status === (resource?.meta?.statusToApprove || "pending");
    setNeedsApproval(needsApproval);
  }, [record, resource]);

  // handle the approval button
  const [remark, setRemark] = useState<string>();
  const handleApproval = ({ isApproved }: { isApproved: boolean }) => {
    let values = { isApproved };
    if (!!resource?.meta?.rejectRemarkKey) {
      values = { ...values, [resource?.meta?.rejectRemarkKey]: remark };
    }

    mutate(
      {
        resource: `${formResource?.name}/approval`,
        id: id || idFromParams || record?.id || "",
        values: values,
        meta: { method: "patch" },
      },
      {
        onSuccess: () => {
          list(resource?.identifier || resource?.name || "");
        },
      }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          onBack: () => list(resource?.identifier || resource?.name || ""),
          breadcrumb: undefined,
          extra: (
            <>
              {resource?.meta?.downloadButtons?.map((button: any) => (
                <DownloadButton
                  key={`${button?.resource}/${
                    id || idFromParams || record?.id || ""
                  }`}
                  resource={button?.resource}
                  id={(id || idFromParams || record?.id || "") as string}
                  label={button?.label}
                />
              ))}

              <CustomRefreshButton />
            </>
          ),
        }}
      >
        <ShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
          timelineItemProps={timelineItemProps}
        />
      </Show>

      <ShowItemRecursor
        parentName={resource?.identifier || resource?.name}
        parentData={record}
      />

      <ApproveButtons
        approveLabel="Set as Pending"
        rejectLabel="Discard Draft"
        onClick={handleApproval}
        onRejectRemark={
          !!resource?.meta?.rejectRemarkKey ? setRemark : undefined
        }
        disableApprove={!needsApproval || !canApprove?.data?.can}
        disableReject={!needsApproval || !canApprove?.data?.can}
        isLoading={isMutateLoading}
      />
    </Space>
  );
};
