import React from "react";
import { RefreshButton } from "@refinedev/antd";
import { useInvalidate } from "@refinedev/core";

interface CustomRefreshButtonProps {
  type?: "primary" | "default" | "dashed" | "link" | "text";
  style?: any;
}

export const CustomRefreshButton: React.FC<CustomRefreshButtonProps> = ({
  type,
  style,
}) => {
  const invalidates = useInvalidate();

  return (
    <RefreshButton
      type={type || "default"}
      style={style}
      onClick={() => invalidates({ invalidates: ["all"] })}
    />
  );
};
