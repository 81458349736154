import React, { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { convertHexToRGBA } from "utils";

export const IconWrapper: React.FC<
  PropsWithChildren<{ color?: string; width?: number; height?: number }>
> = ({ color, width, height, children }) => {
  const colorModeFromStorage = useSelector(
    (state: RootState) => state?.general?.colorMode
  );

  const defaultColor = colorModeFromStorage === "light" ? "#000" : "#fff";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: width || "32px",
        height: height || "32px",
        borderRadius: "50%",
        backgroundColor: convertHexToRGBA(color || defaultColor, 0.1),
        color: color || defaultColor,
      }}
    >
      {children}
    </div>
  );
};
