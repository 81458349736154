import { useEffect, useMemo, useState } from "react";
import { ReconTransactionRecord } from "../models/data";
import { ReconTableCellValue, ReconTableRow } from "../models/table";

type ComparisonResult = {
  matched: ReconTableRow[];
  differing: ReconTableRow[];
};

export default function useReconTableRows(
  goodfundArray: ReconTransactionRecord[],
  sinvestArray: ReconTransactionRecord[]
) {
  const [reconTableData, setReconTableData] = useState<
    ComparisonResult | undefined
  >();
  const getValue = (
    val1: number,
    val2: number | undefined
  ): ReconTableCellValue => ({
    value: val1 ?? 0,
    isDifferent: (val1 || 0) !== (val2 || 0),
  });

  const tableData: ComparisonResult = useMemo(() => {
    const matchingRows: ReconTableRow[] = [];
    const differingRows: ReconTableRow[] = [];

    // construct objects to make traversing more efficient
    const goodfundMap: { [key: string]: ReconTransactionRecord } = {};
    const sinvestMap: { [key: string]: ReconTransactionRecord } = {};

    goodfundArray.forEach((record) => (goodfundMap[record.productId] = record));
    sinvestArray.forEach((record) => (sinvestMap[record.productId] = record));

    // Create a Set of all unique productIds from both arrays
    const allProductIds = new Set<string | number>([
      ...goodfundArray.map((record) => record.productId),
      ...sinvestArray.map((record) => record.productId),
    ]);

    // Loop over the unique productIds once
    allProductIds.forEach((productId) => {
      const goodfundRecord = goodfundMap[productId];
      const sinvestRecord = sinvestMap[productId];

      const result: ReconTableRow = {
        productName: goodfundRecord?.productName || sinvestRecord?.productName,
        productId,
        sub: getValue(goodfundRecord?.sub, sinvestRecord?.sub),
        red: getValue(goodfundRecord?.red, sinvestRecord?.red),
        switchIn: getValue(goodfundRecord?.switchIn, sinvestRecord?.switchIn),
        switchOut: getValue(
          goodfundRecord?.switchOut,
          sinvestRecord?.switchOut
        ),
        sinvestSub: getValue(sinvestRecord?.sub, goodfundRecord?.sub), // `sinvest` prefixed values from array2
        sinvestRed: getValue(sinvestRecord?.red, goodfundRecord?.red),
        sinvestSwitchIn: getValue(
          sinvestRecord?.switchIn,
          goodfundRecord?.switchIn
        ),
        sinvestSwitchOut: getValue(
          sinvestRecord?.switchOut,
          goodfundRecord?.switchOut
        ),
      };

      // Only check the first set of values (sub, red, switchIn, switchOut) for differences
      const hasDifferences =
        result.sub.isDifferent ||
        result.red.isDifferent ||
        result.switchIn.isDifferent ||
        result.switchOut.isDifferent;

      if (hasDifferences) {
        differingRows.push(result);
      } else {
        matchingRows.push(result);
      }
    });

    return { matched: matchingRows, differing: differingRows };
  }, [goodfundArray, sinvestArray]);

  useEffect(() => {
    setReconTableData(tableData);
  }, [tableData]);

  return {
    data: reconTableData,
  };
}
