import { CustomResourceProps } from "interfaces";
import { BookOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { PortfolioCreate, RollingBalanceList } from "components";

export const portfolioResources: CustomResourceProps[] = [
  {
    name: "portfolio",
    meta: {
      icon: <BookOutlined />,
      label: "Portfolio",
    },
  },
  {
    name: "customers/user-portfolio-histories",
    identifier: "portfolio/run",
    meta: {
      label: "Run",
      parent: "portfolio",
      canList: true,
      listComponent: <PortfolioCreate />,
      formColumns: [
        {
          key: "date",
          type: "daterange",
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["dateFrom", "dateTo"],
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
            disableGetOne: false,
          },
        },
      ],
    },
  },
  {
    name: "customers/user-portfolios",
    identifier: "portfolio/latest",
    meta: {
      label: "Latest",
      parent: "portfolio",
      // canShow: true,
      canList: true,
      // canCreate: true,
      // canEdit: true,
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "fullName" },
        { key: "unitHolderId", label: "Unit Holder ID" },
        { key: "product", hideOnRecursor: true },
        { key: "date" },
        {
          key: "profitOrLoss",
          label: "Profit / Loss",
          type: "currency",
          isProfitLossIndicator: true,
        },
        {
          key: "profitOrLossInPercent",
          label: "Profit / Loss (%)",
          type: "percentage",
          isProfitLossIndicator: true,
        },
        { key: "currentNav", label: "Current NAV", type: "currency" },
        { key: "runNavValue", label: "Average Unit Cost", type: "unit" },
        { key: "beginningUnit", type: "unit" },
        { key: "subscriptionUnit", type: "unit" },
        { key: "redeemUnit", type: "unit" },
        { key: "endingUnit", type: "unit" },
        { key: "capital", type: "currency" },
        { key: "beginningBalance", type: "currency" },
        { key: "endingBalance", type: "currency" },
        { key: "agent" },
        { key: "sellingAgent" },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
    },
  },
  {
    name: "customers/user-portfolio-histories",
    identifier: "portfolio/history",
    meta: {
      label: "History",
      parent: "portfolio",
      canList: true,
      listComponent: <RollingBalanceList />,
      initialSorters: [{ field: "date", order: "desc" }],
      pagination: { pageSize: 500 },
      initialFilters: [
        { field: "date", operator: "gte", value: dayjs().startOf("month") },
        { field: "date", operator: "lte", value: dayjs().endOf("day") },
        { field: "unitHolderId", operator: "eq", value: "0" },
        { field: "productId", operator: "eq", value: 0 },
      ],
      filters: [
        {
          key: "date",
          placeholder: ["Date (Start)", "Date (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
          required: true,
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
          },
          required: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID", isSortable: false },
        { key: "date" },
        { key: "fullName", isSortable: false },
        { key: "unitHolderId", isSortable: false },
        { key: "name", label: "Product", isSortable: false },
        { key: "agentCode", isSortable: false },
        { key: "sellingAgentName", isSortable: false },
        { key: "currentNav", label: "Current NAV", isSortable: false },
        { key: "runNavValue", label: "Average Unit Cost", isSortable: false },
        {
          key: "profitOrLoss",
          label: "Profit / Loss",
          type: "currency",
          isProfitLossIndicator: true,
          isSortable: false,
        },
        {
          key: "profitOrLossInPercent",
          label: "Profit / Loss (%)",
          type: "percentage",
          isProfitLossIndicator: true,
          isSortable: false,
        },
        { key: "beginningUnit", type: "unit", isSortable: false },
        { key: "subscriptionUnit", type: "unit", isSortable: false },
        { key: "redeemUnit", type: "unit", isSortable: false },
        { key: "endingUnit", type: "unit", isSortable: false },
        { key: "capital", type: "currency", isSortable: false },
        { key: "beginningBalance", type: "currency", isSortable: false },
        { key: "endingBalance", type: "currency", isSortable: false },
      ],
    },
  },
];
