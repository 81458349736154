import React, { useEffect, useState } from "react";
import { Space, Form } from "antd";
import { CustomFormComponent } from "interfaces";
import { Text } from "components/atoms";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { formatThousands } from "utils/number";

export const DisplayRemainingPortofolio: CustomFormComponent = ({
  formColumn,
  formProps,
}) => {
  const [displayValue, setDisplayValue] = useState<string>();

  const meta = formColumn?.optionsMeta;

  const value = Form.useWatch(
    formColumn?.customValueKeys["value"] || "",
    formProps?.form
  );

  const convertedValue = Number(value) || 0;

  const valueType = Form.useWatch(
    formColumn?.customValueKeys["valueType"] || "",
    formProps?.form
  );

  const dynamicSelectTableValues = useSelector(
    (state: RootState) => state.temp.dynamicSelectTableValues
  );

  useEffect(() => {
    const currentCustomer =
      dynamicSelectTableValues?.[
        formColumn?.customValueKeys["customerKey"] || ""
      ];

    const currentProduct =
      dynamicSelectTableValues?.[meta?.filterFromOtherColumn?.formKey || ""];

    const currentUnit = currentProduct?.endingUnit || 0;
    const currentBalance = currentProduct?.endingBalance || 0;

    // Check if current selected user has current product
    const isProductAvailable =
      currentProduct?.id &&
      currentProduct.unitHolderId === currentCustomer?.unitHolderId;

    const remainingAmount = valueType
      ? currentUnit - convertedValue
      : currentBalance - convertedValue;

    // if product not available either caused by loading state or user changed
    // selected customer after selecting product show N/A, else show actual amount
    setDisplayValue(
      isProductAvailable && remainingAmount >= 0
        ? formatThousands(remainingAmount)
        : "N/A"
    );
  }, [valueType, convertedValue, dynamicSelectTableValues, meta, formColumn]);

  return (
    <Space direction="vertical" style={{ width: "100%", paddingBottom: "8px" }}>
      <Text size="sm">{`Remaining ${valueType ? "Units" : "Amount"}`}</Text>
      <Text size="sm" strong>
        {displayValue}
      </Text>
    </Space>
  );
};
