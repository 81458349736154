import {
  BaseRecord,
  LogicalFilter,
  CrudSorting,
  CrudOperators,
} from "@refinedev/core";
import { TimelineItemProps, StepProps } from "antd";
import {
  IAccessControlRule,
  IResourceTimeline,
  IRoles,
  IResourceTransfer,
  IResourceDisplayColumn,
} from "interfaces";
import { axiosInstance } from "./axiosInstance";
import {
  IdcardOutlined,
  SolutionOutlined,
  AuditOutlined,
  BankOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { startCase } from "lodash";
import { DynamicDisplayItem } from "components";

interface props {
  data?: BaseRecord[];
}

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "ne":
      return `_${operator}`;
    case "gte":
      return `From`;
    case "lte":
      return `To`;
  }

  return ""; // default "eq"
};

const mapTransfer = ({
  data,
  disabled,
}: {
  data?: BaseRecord[];
  disabled?: boolean;
}): IResourceTransfer[] => {
  return (
    data?.map((data: any) => ({
      key: data?.id?.toString() || "",
      title: data?.firstName + (data?.lastName ? " " + data?.lastName : "") || "",
      disabled: disabled || false,
    })) || []
  );
};

const mapStatus = ({ data }: props): TimelineItemProps[] => {
  var timelineItems: IResourceTimeline[] = [];

  const STATUS_COLORS = {
    magenta: "#eb2f96",
    pink: "#eb2f96",
    red: "#f5222d",
    volcano: "#fa541c",
    orange: "#fa8c16",
    gold: "#faad14",
    lime: "#a0d911",
    green: "#52c41a",
    cyan: "#13c2c2",
    blue: "#1890ff",
    geekblue: "#2f54eb",
    purple: "#722ed1",
    gray: "#bfbfbf",
  };

  data?.forEach((record) => {
    const statusParent =
      record?.kycStatus ||
      record?.transactionStatusDetail?.transactionStatus ||
      record?.approvalStatus;

    const status =
      statusParent?.kycStatusName ||
      statusParent?.transactionStatusName ||
      statusParent?.approvalStatusName ||
      "";

    const approvedBy = record?.createdBy;

    const color =
      STATUS_COLORS?.[
        statusParent?.colorCode?.toLowerCase() as keyof typeof STATUS_COLORS
      ] || "gray";

    const date = dayjs(record?.createdAt).format("DD MMM YYYY");
    const time = dayjs(record?.createdAt).format("HH:mm:ss");

    const approvedAt = record?.createdAt ? date + ", " + time : "";

    const item = {
      status,
      approvedAt,
      approvedBy,
      color,
    };

    if (status === timelineItems?.[timelineItems?.length - 1]?.status) {
      // replace the last item if the status is the same
      timelineItems[timelineItems.length - 1] = item;
    } else {
      // push a new item if the status is different
      timelineItems.push(item);
    }
  });

  return timelineItems;
};

const mapKycSteps = (data: any) => {
  let stepItems: StepProps[] = [
    {
      status: "finish",
      title: "ID Card",
      description: "Completed",
      icon: <IdcardOutlined />,
    },
    {
      status: "finish",
      title: "ID Details",
      description: "Completed",
      icon: <SolutionOutlined />,
    },
    {
      status: "finish",
      title: "Personal Info",
      description: "Completed",
      icon: <AuditOutlined />,
    },
    {
      status: "finish",
      title: "Bank Account",
      description: "Completed",
      icon: <BankOutlined />,
    },
  ];

  if (data.data === "4") {
    return stepItems;
  }

  stepItems[parseInt(data.data)].status = "process";
  stepItems[parseInt(data.data)].description = "In-Progress";
  delete stepItems[parseInt(data.data)].icon;

  for (let i = parseInt(data.data) + 1; i < stepItems.length; i++) {
    stepItems[i].status = "wait";
    stepItems[i].description = "Incomplete";
  }

  return stepItems;
};

const mapFilter = (filters?: LogicalFilter[]) => {
  const queryFilters: { [key: string]: string } = {};

  filters?.forEach(({ field, operator, value }) => {
    if (operator === "gte" || operator === "lte") {
      value = dayjs(value).format("YYYY-MM-DD");
    }

    const mappedOperator = mapOperator(operator);
    if (!queryFilters[`${field || ""}${mappedOperator}`]) {
      queryFilters[`${field || ""}${mappedOperator}`] = value;
    }
  });

  return queryFilters;
};

const mapSorter = (sort?: CrudSorting) => {
  let _order = ["id"]; // default sorting field
  let _sort = ["asc"]; // default sorting

  if (sort && sort.length > 0) {
    _sort = [];
    _order = [];

    sort.forEach((item) => {
      _order.push(item.field);
      _sort.push(item.order);
    });
  }

  return { _sort, _order };
};

const mapAccessControlRules = (input: IRoles[]) => {
  const sortedRules = input?.sort((a: IRoles, b: IRoles) =>
    a?.modules?.endpoint?.localeCompare(b?.modules?.endpoint)
  );

  // map access control rules to a compatible format that contains role, route, and permissions
  const mappedRules = sortedRules
    ?.filter((rule: IRoles) => rule?.modules?.endpoint !== "")
    ?.reduce((results: IAccessControlRule[], rule: IRoles) => {
      if (rule?.status) {
        const role = rule?.userGroup?.name;
        const route = rule?.modules?.endpoint;
        let permissions = [rule?.modules?.requestType];
        if (rule?.modules?.requestType === "*") {
          permissions = ["list", "show", "create", "edit", "delete"];
        }

        // merge rules with the same route
        const existingRule = results?.find(
          (r: IAccessControlRule) => r?.route === route
        );
        if (existingRule) {
          // merge the permissions of each rule
          existingRule.permissions =
            existingRule?.permissions?.concat(permissions);

          // filter duplicate permissions
          existingRule.permissions = [...new Set(existingRule.permissions)];
        } else {
          results?.push({ role, route, permissions });
        }
      }

      return results;
    }, []);

  return mappedRules;
};

const mapIdCardOCRValues = async (input?: any) => {
  const API_BACKOFFICE_URL =
    process.env.REACT_APP_BACKOFFICE_API_URL ||
    "http://localhost:4000/api/v1/backoffice";

  let output = {};

  // parse id card number (NIK)
  if (input?.idNumber) {
    const idCardNo = input?.idNumber;
    output = { ...output, idCardNo };
  }

  // parse first and last name
  if (input?.name) {
    const name = input?.name?.split(" ");
    const firstName = name?.[0];
    const lastName = name?.slice?.(1)?.join(" ");
    output = { ...output, firstName, lastName };
  }

  // parse place and date of birth
  if (input?.birthPlaceBirthday) {
    const birthPlaceBirthday = input?.birthPlaceBirthday?.split(", ");
    const placeOfBirth = birthPlaceBirthday?.[0];
    const dateOfBirth = birthPlaceBirthday?.[1]?.split("-").reverse().join("-");
    output = { ...output, placeOfBirth, dateOfBirth };
  }

  // map gender id
  if (input?.gender) {
    const genderId = input?.gender?.toLowerCase()?.includes("laki")
      ? 1
      : input?.gender?.toLowerCase()?.includes("perempuan")
      ? 2
      : 3;
    output = { ...output, genderId };
  }

  // merge address, rtrw, village, district
  let addressList = [];
  if (input?.address) addressList.push(input?.address);
  if (input?.rtrw) addressList.push(`RT/RW ${input?.rtrw}`);
  if (input?.village) addressList.push(`KEL/DESA ${input?.village}`);
  if (input?.district) addressList.push(`KECAMATAN ${input?.district}`);
  const address = addressList.filter(Boolean).join(", ");
  output = { ...output, address };

  // get city from database
  if (input?.city) {
    const cityString = input?.city?.replace("KABUPATEN ", "");
    const getCities = await axiosInstance.get(
      API_BACKOFFICE_URL + "/static/cities",
      { params: { search: cityString } }
    );
    const city = getCities?.data?.body?.rows?.[0];
    output = { ...output, cityId: city?.id };
  }

  // get religion from database
  if (input?.religion) {
    const getReligions = await axiosInstance.get(
      API_BACKOFFICE_URL + "/static/religions",
      { params: { search: input?.religion } }
    );
    const religion = getReligions?.data?.body?.rows?.[0];
    output = { ...output, religionId: religion?.id };
  }

  // map marital status id
  if (input?.maritalStatus) {
    const maritalStatusId = input?.maritalStatus
      ?.toLowerCase()
      ?.includes("belum")
      ? 1
      : input?.maritalStatus?.toLowerCase()?.includes("cerai")
      ? 3
      : 2;
    output = { ...output, maritalStatusId };
  }

  // get occupation
  if (input?.occupation) {
    const getOccupations = await axiosInstance.get(
      API_BACKOFFICE_URL + "/static/occupations",
      { params: { search: input?.occupation } }
    );
    const occupation = getOccupations?.data?.body?.rows?.[0];
    output = { ...output, occupationId: occupation?.id };
  }

  return output;
};

const mapUserHistories = ({ data }: props): TimelineItemProps[] => {
  var timelineItems: IResourceTimeline[] = [];

  const STATUS_COLORS = [
    "#eb2f96",
    "#f5222d",
    "#fa541c",
    "#fa8c16",
    "#faad14",
    "#a0d911",
    "#52c41a",
    "#13c2c2",
    "#1890ff",
    "#2f54eb",
    "#722ed1",
    "#bfbfbf",
  ];

  data?.forEach((record, index) => {
    const recordParent = record?.userGroup;

    const status = recordParent?.name || "Name";

    const approvedBy = record?.createdBy || "Created By";

    const color = STATUS_COLORS[index] || "gray";

    const date = dayjs(record?.createdAt).format("DD MMM YYYY");
    const time = dayjs(record?.createdAt).format("HH:mm:ss");

    const approvedAt = record?.createdAt ? date + ", " + time : "";

    if (status !== timelineItems?.[timelineItems?.length - 1]?.status) {
      timelineItems.push({ status, approvedAt, approvedBy, color });
    }
  });

  return timelineItems;
};

const mapOldNewAmendment = (
  oldData?: BaseRecord,
  newData?: BaseRecord,
  displayColumns?: IResourceDisplayColumn[]
) => {
  const combinedData = [{}];
  displayColumns?.forEach((column) => {
    const oldValue = DynamicDisplayItem({
      displayColumn: column,
      value: oldData?.[column?.key || ""],
    });
    const newValue = DynamicDisplayItem({
      displayColumn: column,
      value: newData?.[column?.key || ""],
    });

    if (column?.hideIfEmpty && !oldValue && !newValue) return;
    if (oldValue !== newValue) {
      combinedData.push({
        key: column?.label || startCase(column?.key || ""),
        before: oldValue,
        amendment: newValue,
      });
    } else {
      combinedData.push({
        key: column?.label || startCase(column?.key || ""),
        before: oldValue,
        amendment: undefined,
      });
    }
  });
  return combinedData.slice(1, combinedData.length);
};

export {
  mapStatus,
  mapFilter,
  mapSorter,
  mapAccessControlRules,
  mapIdCardOCRValues,
  mapKycSteps,
  mapUserHistories,
  mapTransfer,
  mapOldNewAmendment,
};
