import React from "react";

import { Col, Row } from "antd";

import {
  AssetUnderManagement,
  CalendarUpcomingEvents,
  TotalTransactions,
  TotalCustomers,
  MostActiveProduct,
  TotalProducts,
  TopGainerLoser,
  TotalProductTransactions,
  TotalAumProduct,
  LatestNavDate,
} from "./components";

export const DashboardPage: React.FC = () => {
  return (
    <div className="page-container">
      <Row>
        <Col xs={24} sm={24}>
          <LatestNavDate />
        </Col>
      </Row>

      <Row gutter={[16, 32]} style={{ marginTop: "32px" }}>
        <AssetUnderManagement />

        <Col xs={24} sm={24} xl={4}>
          <TotalCustomers />
        </Col>

        <Col xs={24} sm={24} xl={4}>
          <TotalTransactions />
        </Col>

        <Col xs={24} sm={24} xl={4}>
          <TotalProducts />
        </Col>

        <Col xs={24} sm={24} xl={4}>
          <MostActiveProduct />
        </Col>
      </Row>

      <Row gutter={[16, 32]} style={{ marginTop: "32px" }}>
        <Col xs={24} sm={24} xl={8}>
          <TopGainerLoser isTop={true} />
        </Col>
        <Col xs={24} sm={24} xl={8}>
          <TopGainerLoser isTop={false} />
        </Col>

        <Col xs={24} sm={24} xl={8}>
          <TotalAumProduct />
        </Col>
      </Row>

      <Row gutter={[16, 32]} style={{ marginTop: "32px" }}>
        <Col xs={24} sm={24} xl={14} xxl={16}>
          <TotalProductTransactions />
        </Col>

        <Col xs={24} sm={24} xl={10} xxl={8}>
          <CalendarUpcomingEvents />
        </Col>
      </Row>
    </div>
  );
};
