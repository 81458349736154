import React from "react";
import {
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { CustomErrorComponent, FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { setFormInitialValues } from "utils";
import { ButtonProps } from "antd";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface DefaultEditProps extends IResourceComponentsProps {
  id?: string | number;
  saveButtonProps?: ButtonProps;
}

export const DefaultEdit: React.FC<DefaultEditProps> = ({
  id,
  saveButtonProps,
}) => {
  const { id: idFromParams } = useParsed();
  const { resource } = useResource();

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    queryResult,
    formLoading,
  } = useForm({
    id: id || idFromParams,
    resource: resource?.meta?.editResource,
    meta: { method: resource?.meta?.editMethod || "put" },
  });

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  // set the form's initial values
  setFormInitialValues({ formColumns: resource?.meta?.formColumns, formProps });

  return (
    <Edit
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      isLoading={formLoading}
      headerProps={{ breadcrumb: undefined, extra: <CustomRefreshButton /> }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading,
        ...saveButtonProps,
      }}
    >
      <FormCard formProps={formProps} resource={resource} />
    </Edit>
  );
};
