import React, { useEffect, useState } from "react";

import {
  useTranslate,
  useNotification,
  useApiUrl,
  useGetLocale,
} from "@refinedev/core";
import { DollarCircleOutlined } from "@ant-design/icons";

import { axiosInstance } from "utils";
import { CounterCard } from "components/molecules";

export const TotalTransactions: React.FC = () => {
  const locale = useGetLocale();
  const currentLocale = locale();
  const translate = useTranslate();
  const { open } = useNotification();

  const [totalData, setTotalData] = useState<number>(0);
  const [newData, setNewData] = useState<number>(0);
  const [newDataPercentage, setNewDataPercentage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/dashboard/total-transaction-and-changes`;

  useEffect(() => {
    setIsLoading(true);

    axiosInstance
      .get(url)
      .then((res) => {
        const data = res?.data?.body;
        setTotalData(data?.totalTransactions || 0);
        setNewData(data?.newTransactions || 0);
        const custPercentage =
          ((data?.newTransactions || 0) / (data?.totalTransactions || 0)) * 100;
        setNewDataPercentage(custPercentage || 0);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [translate, open, url]);

  const newDataWithStyle = (
    <div
      style={{
        color: newData > 0 ? "#119761" : newData < 0 ? "#D80027" : "gray",
        fontWeight: "bold",
      }}
    >
      {newData >= 0 && "+"}
      {newData?.toLocaleString(currentLocale)}
      {` (${newDataPercentage?.toFixed(2)}%)`}
    </div>
  );

  return (
    <CounterCard
      title={translate(
        "page.dashboard.totalTransactions",
        `Total Transactions`
      )}
      value={totalData}
      subValue={newDataWithStyle}
      icon={<DollarCircleOutlined />}
      isLoading={isLoading}
    />
  );
};
