import { useGetLocale } from "@refinedev/core";
import { Card, Col, Row, Skeleton, Tooltip, theme } from "antd";
import { IconWrapper } from "components/atoms";
import React from "react";

interface CounterCardProps {
  title?: React.ReactNode;
  titleSkeleton?: boolean;
  titleData?: string;
  value?: React.ReactNode;
  subValue?: React.ReactNode;
  tooltipValue?: React.ReactNode;
  tooltipEnabled?: boolean;
  icon?: React.ReactNode;
  color?: string;
  isLoading?: boolean;
  additionalInformation?: React.ReactNode;
  additionalInformationIcon?: React.ReactNode;
  additionalInformationColor?: React.ReactNode;
}

export const CounterCard: React.FC<CounterCardProps> = ({
  title,
  titleSkeleton,
  titleData,
  value,
  subValue,
  tooltipValue,
  tooltipEnabled = true,
  icon,
  color,
  isLoading,
  additionalInformation,
  additionalInformationIcon,
  additionalInformationColor,
}) => {
  const { token } = theme.useToken();
  const locale = useGetLocale();
  const currentLocale = locale();

  // To check if the item is invalid
  const isInvalidItem = (item: any) => {
    return item === 0 || item === "-";
  };

  return (
    <Card
      size="small"
      style={{ height: "100%" }}
      bodyStyle={{ height: "100%" }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Row gutter={[8, 8]} align="middle" wrap={false}>
          <Col flex="auto">
            <Tooltip title={tooltipEnabled ? title : ""}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {title}
                {titleSkeleton && isLoading && isInvalidItem(titleData) ? (
                  <div>
                    <Skeleton.Button active={true} />
                  </div>
                ) : (
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: token?.colorPrimary,
                    }}
                  >
                    {titleData}
                  </div>
                )}
              </div>
            </Tooltip>
          </Col>

          <Col flex="none">
            <IconWrapper color={color}>{icon}</IconWrapper>
          </Col>
        </Row>

        {isLoading && isInvalidItem(value) ? (
          <Skeleton.Button active={true} />
        ) : (
          <Row gutter={[8, 8]} align="middle">
            <Col>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "32px",
                  color: token?.colorPrimary,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                <Tooltip
                  title={
                    tooltipValue ||
                    value?.toLocaleString(currentLocale, {
                      maximumFractionDigits: 2,
                    })
                  }
                >
                  {value?.toLocaleString(currentLocale, {
                    notation: "compact",
                    maximumFractionDigits: 2,
                  })}
                </Tooltip>
              </div>
            </Col>
            <Col>
              {additionalInformation && (
                <Tooltip title={additionalInformation}>
                  <div>
                    <IconWrapper
                      width={24}
                      height={24}
                      color={additionalInformationColor as string}
                    >
                      {additionalInformationIcon}
                    </IconWrapper>
                  </div>
                </Tooltip>
              )}
            </Col>
          </Row>
        )}

        {isLoading && (isInvalidItem(subValue) || subValue) ? (
          <Skeleton.Input size="small" block active={true} />
        ) : (
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {subValue}
          </div>
        )}
      </div>
    </Card>
  );
};
