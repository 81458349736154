import { List } from "@refinedev/antd";
import { Col, Divider, Row } from "antd";
import React from "react";
import { TransactionUploadDragger } from "./transactionUploadDragger";
import { startCase } from "lodash";
import { useParsed, useResource, useTranslate } from "@refinedev/core";

interface IUploadTransactionFilesProps {
  needsPaymentProof: boolean;
  needsSupportingDocument: boolean;
  customerType: string;
}

export const UploadTransactionFiles: React.FC<IUploadTransactionFilesProps> = ({
  needsPaymentProof,
  needsSupportingDocument,
  customerType,
}) => {
  const translate = useTranslate();
  const { id, pathname } = useParsed();
  const { resource } = useResource(
    pathname?.split("/").filter(Boolean).slice(0, -2).join("/")
  );

  // Handle if the passed customerType has value of "retail" or "conventional"
  const type = customerType === "retail" || customerType === "conventional" ? "conventional" : "institution";

  return (
    <List
      title={`${
        resource?.meta?.label ||
        startCase(resource?.identifier || resource?.name || "")
      } - ${translate("uploadDragger.title", "Upload File")}`}
      headerProps={{
        breadcrumb: undefined,
      }}
    >
      <Row gutter={[16, 16]}>
        {needsPaymentProof && (
          <Col
            xs={{ span: 24 }}
            xl={{ span: needsSupportingDocument ? 12 : 24 }}
          >
            <TransactionUploadDragger
              label={translate("page.transaction.uploadPaymentProof")}
              path={`transactions/${
                type === "conventional" ? "retail/" : ""
              }${type}/buy/payment-proof/${id}`}
              formats={["png", "jpg", "jpeg"]}
            />
          </Col>
        )}

        {needsSupportingDocument && (
          <Col xs={{ span: 24 }} xl={{ span: needsPaymentProof ? 12 : 24 }}>
            <TransactionUploadDragger
              label={translate("page.transaction.uploadSupportingDocument")}
              path={`transactions/documents/${customerType}/${id}`}
              isFormData
              formats={["pdf", "png", "jpg", "jpeg"]}
            />
          </Col>
        )}
      </Row>

      <Divider />
    </List>
  );
};
