import React, { useState, useEffect } from "react";

import {
  BaseRecord,
  useNotification,
  useApiUrl,
  useTranslate,
  useGetLocale,
} from "@refinedev/core";

import { TrophyOutlined } from "@ant-design/icons";
import { Card, Table, Empty, Tooltip } from "antd";

import { Text } from "../../../atoms/text";
import type { ColumnsType } from "antd/es/table";
import { axiosInstance } from "utils";

export const TotalAumProduct: React.FC = () => {
  const translate = useTranslate();
  const locale = useGetLocale();
  const currentLocale = locale();
  const { open } = useNotification();
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/dashboard/total-aum-and-changes-per-product`;

  const formatter = new Intl.NumberFormat(currentLocale, {
    notation: "compact",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [tableData, setTableData] = useState<BaseRecord[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(url, {
        params: {
          limit: "5",
        },
      })
      .then((res) => {
        const data = res?.data?.body?.rows;
        setTableData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [open, translate, url]);

  const columns: ColumnsType<BaseRecord> = [
    {
      title: "Product",
      dataIndex: "productName",
      key: "productName",
      render(value, record, index) {
        return (
          <Text strong size="md">
            {value}
          </Text>
        );
      },
    },
    {
      title: "AUM",
      dataIndex: "aum",
      key: "aum",
      align: "right",
      fixed: "right",
      render(value, record, index) {
        const changes =
          Math.round(
            (record?.changes / (record?.aum - record?.changes)) * 10000
          ) / 10000;
        if (changes < 0) {
          return (
            <Tooltip title={`${changes}%`}>
              <Text strong size="md" style={{ color: "#D80027" }}>
                {`${formatter.format(record?.aum)} (${changes.toFixed(2)}%)`}
              </Text>
            </Tooltip>
          );
        } else if (changes > 0) {
          return (
            <Tooltip title={`${changes}%`}>
              <Text strong size="md" style={{ color: "#119761" }}>
                {`${formatter.format(record?.aum)} (${"+"}${changes.toFixed(
                  2
                )}%)`}
              </Text>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={`${changes}%`}>
              <Text strong size="md">
                {`${formatter.format(record?.aum)} (${"+"}${changes.toFixed(
                  2
                )}%)`}
              </Text>
            </Tooltip>
          );
        }
      },
    },
  ];

  return (
    <Card
      headStyle={{ padding: "8px 16px", backgroundColor: "#434343" }}
      bodyStyle={{ padding: "0 1rem", height: "100%", minHeight: "200px" }}
      style={{ height: "100%", minHeight: "200px" }}
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <TrophyOutlined style={{ color: "#FFFF" }} />
          <Text size="sm" strong style={{ marginLeft: ".7rem", color: "#fff" }}>
            {translate("page.dashboard.topAumProduct", `Top AUM`)}
          </Text>
        </div>
      }
    >
      {!isLoading && !tableData?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{
            margin: 0,
            padding: 0,
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        />
      ) : (
        <Table
          rowKey="id"
          style={{ marginTop: "10px" }}
          columns={columns}
          dataSource={tableData}
          showHeader={false}
          loading={isLoading}
          scroll={{ x: "max-content" }}
          pagination={{ position: ["none"] }}
        />
      )}
    </Card>
  );
};
