import {
  BankOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  TeamOutlined,
  EditOutlined,
  UserOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import {
  BankAccountShow,
  CustomerShow,
  AmendmentShow,
  AmendmentApprove,
  AmendmentCreate,
} from "components";
import {
  CustomResourceProps,
  IResourceDisplayColumn,
  IResourceFormColumn,
} from "interfaces";
import dayjs from "dayjs";

const institutionStaticDataResources: CustomResourceProps[] = [
  {
    name: "static/company-characteristics",
    identifier: "institution/static-data/company-characteristics",
    meta: {
      label: "Company Characteristics",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "characteristicCode", type: "number", requiredOnCreate: true },
        { key: "characteristicName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/company-types",
    identifier: "institution/static-data/company-types",
    meta: {
      label: "Company Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "companyTypeCode", type: "number", requiredOnCreate: true },
        { key: "companyTypeName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/institution-income-levels",
    identifier: "institution/static-data/institution-income-levels",
    meta: {
      label: "Income Levels",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "institutionIncomeLevelCode",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "institutionIncomeLevelName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/institution-investment-objectives",
    identifier: "institution/static-data/institution-investment-objectives",
    meta: {
      label: "Investment Objectives",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "institutionInvestmentObjectiveCode",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "institutionInvestmentObjectiveName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/institution-asset-informations",
    identifier: "institution/static-data/institution-asset-informations",
    meta: {
      label: "Institution Asset Informations",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "institutionAssetInformationCode",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "institutionAssetInformationName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/institution-risk-profiles",
    identifier: "institution/static-data/institution-risk-profiles",
    meta: {
      label: "Risk Profiles",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "institutionRiskProfileCode",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "institutionRiskProfileName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/institution-source-of-funds",
    identifier: "institution/static-data/institution-source-of-funds",
    meta: {
      label: "Source of Funds",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "institutionSourceOfFundCode",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "institutionSourceOfFundName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/investor-types",
    identifier: "institution/static-data/investor-types",
    meta: {
      label: "Investor Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "investorTypeCode", type: "number", requiredOnCreate: true },
        { key: "investorTypeName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/institution-document-types",
    identifier: "institution/static-data/institution-document-types",
    meta: {
      label: "Document Types",
      canList: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
    },
  },
];

const institutionCustomersDisplayColumns: IResourceDisplayColumn[] = [
  { key: "id", label: "ID" },
  { key: "sid", label: "SID" },
  { key: "ifua", label: "IFUA" },
  { key: "unitHolderId", label: "Unit Holder ID" },
  { span: 2 },

  { key: "companyName" },
  { key: "email" },
  { key: "telpNo", label: "Phone Number", span: 1 },
  { key: "faxNo", label: "FAX Number", span: 1 },
  { key: "placeOfEstablishment", span: 1 },
  { key: "dateOfEstablishment", span: 1 },
  { key: "companyAddress", span: 1 },
  { key: "countryOfDomicile", span: 1 },
  { span: 2 },

  { key: "companyType", span: 1 },
  { key: "companyCharacteristic", span: 1 },
  { key: "institutionIncomeLevel", span: 1, label: "Income Level" },
  {
    key: "last1YearAsset",
    label: "Asset Information for Last Year",
    childKey: "institutionAssetInformationName",
    span: 1,
  },
  {
    key: "last2YearAsset",
    label: "Asset Information for 2 Years Ago",
    childKey: "institutionAssetInformationName",
    span: 1,
  },
  {
    key: "last3YearAsset",
    label: "Asset Information for 3 Years Ago",
    childKey: "institutionAssetInformationName",
    span: 1,
  },
  { key: "institutionSourceOfFund", label: "Source of Fund", span: 1 },
  { key: "institutionInvestmentObjective", label: "Investment Objective" },
  { span: 2 },

  { key: "npwpNo", label: "NPWP", span: 1 },
  { key: "npwpRegistrationDate", label: "NPWP Registration Date", span: 1 },
  { key: "siupNo", label: "SIUP", span: 1 },
  {
    key: "siupExpirationDate",
    label: "SIUP Expiration Date",
    span: 1,
  },
  { key: "tdpNo", label: "TDP", span: 1 },
  {
    key: "tdpRegistrationDate",
    label: "TDP Registration Date",
    span: 1,
  },

  { key: "skdNo", label: "SKD", span: 1 },
  { key: "skdExpirationDate", label: "SKD Expiration Date", span: 1 },
  { key: "articlesOfAssociationNo", label: "Articles of Association No" },
  { key: "statementType" },
  { key: "sellingAgent", span: 1 },
  { span: 2 },

  { key: "kycStatus", span: 1 },
  { key: "revisionNotes", span: 1, hideIfEmpty: true },
  { key: "isActive", label: "Active" },
  { span: 2 },

  { key: "bankAccounts", childKey: "bankAccountNumber" },
];

const institutionCustomersFormColumns: IResourceFormColumn[] = [
  { key: "companyName", type: "text", requiredOnCreate: true },
  { key: "email", type: "email", requiredOnCreate: true },
  {
    key: "telpNo",
    label: "Phone Number",
    type: "text",
    requiredOnCreate: true,
  },
  { key: "faxNo", label: "Fax Number", type: "text" },
  {
    key: "companyTypeId",
    type: "select",
    optionsMeta: { resourceName: "static/company-types" },
    requiredOnCreate: true,
  },
  {
    key: "companyCharacteristicId",
    type: "select",
    optionsMeta: { resourceName: "static/company-characteristics" },
    requiredOnCreate: true,
  },
  { key: "siupNo", label: "SIUP/NIB", type: "text", requiredOnCreate: true },
  {
    key: "siupExpirationDate",
    label: "SIUP/NIB Expiration Date",
    type: "date",
  },
  { key: "npwpNo", label: "NPWP", type: "text", requiredOnCreate: true },
  {
    key: "npwpRegistrationDate",
    label: "NPWP Registration Date",
    type: "date",
    requiredOnCreate: true,
  },
  { key: "skdNo", label: "SKD", type: "text" },
  { key: "skdRegistrationDate", label: "SKD Registration Date", type: "date" },
  { key: "skdExpirationDate", label: "SKD Expiration Date", type: "date" },
  {
    key: "articlesOfAssociationNo",
    type: "text",
    requiredOnCreate: true,
  },
  { key: "tdpNo", label: "TDP", type: "text" },
  { key: "tdpRegistrationDate", label: "TDP Registration Date", type: "date" },
  {
    key: "institutionIncomeLevelId",
    label: "Income Level",
    type: "select",
    optionsMeta: { resourceName: "static/institution-income-levels" },
    requiredOnCreate: true,
  },
  {
    key: "institutionSourceOfFundId",
    label: "Source of Fund",
    type: "select",
    optionsMeta: {
      resourceName: "static/institution-source-of-funds",
    },
    requiredOnCreate: true,
  },
  {
    key: "institutionInvestmentObjectiveId",
    label: "Investment Objective",
    type: "select",
    optionsMeta: {
      resourceName: "static/institution-investment-objectives",
    },
    requiredOnCreate: true,
  },
  {
    key: "last1YearAssetId",
    label: "Asset Information for the Past 3 Years (IDR) - Last Year",
    type: "select",
    optionsMeta: {
      labelKey: "institutionAssetInformationName",
      resourceName: "static/institution-asset-informations",
    },
  },
  {
    key: "last2YearAssetId",
    label: "Asset Information for the Past 3 Years (IDR) - 2 Years Ago",
    type: "select",
    optionsMeta: {
      labelKey: "institutionAssetInformationName",
      resourceName: "static/institution-asset-informations",
    },
  },
  {
    key: "last3YearAssetId",
    label: "Asset Information for the Past 3 Years (IDR) - 3 Years Ago",
    type: "select",
    optionsMeta: {
      labelKey: "institutionAssetInformationName",
      resourceName: "static/institution-asset-informations",
    },
  },
  {
    key: "companyAddress",
    type: "textarea",
    requiredOnCreate: true,
    span: 2,
  },
  {
    key: "companyCityCodeId",
    label: "City",
    type: "select",
    optionsMeta: { resourceName: "static/cities" },
    requiredOnCreate: true,
  },
  {
    key: "countryOfDomicileId",
    type: "select",
    optionsMeta: { resourceName: "static/countries" },
    requiredOnCreate: true,
  },
  { key: "placeOfEstablishment", type: "text", requiredOnCreate: true },
  { key: "dateOfEstablishment", type: "date", requiredOnCreate: true },

  {
    key: "statementTypeId",
    type: "select",
    optionsMeta: { resourceName: "static/statement-types" },
    requiredOnCreate: true,
  },
  {
    key: "agentId",
    type: "select",
    optionsMeta: {
      resourceName: "agents",
      initialFilters: [
        { field: "approvalStatusId", operator: "eq", value: 3 },
        { field: "isActive", operator: "eq", value: true },
      ],
    },
    requiredOnCreate: true,
  },
  {
    key: "bankId",
    type: "select",
    optionsMeta: { resourceName: "static/banks" },
    requiredOnCreate: true,
  },
  { key: "bankAccountNumber", type: "number", requiredOnCreate: true },
  { key: "bankAccountName", type: "text", requiredOnCreate: true },
  {
    key: "currencyId",
    type: "select",
    label: "Bank Account Currency",
    optionsMeta: { resourceName: "static/currencies" },
    requiredOnCreate: true,
  },
  { key: "sid", label: "SID", type: "text" },
  { key: "ifua", label: "IFUA", type: "text" },
];

const institutionCustomers: CustomResourceProps[] = [
  {
    name: "customers/institution",
    identifier: "institution/customers/all",
    meta: {
      label: "All",
      canList: true,
      canShow: true,
      showComponent: <CustomerShow />,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      // downloadButtons: [{ resource: "export/kyc/institution" }],
      statusResource: "customers/institution/histories",
      statusForeignKey: "customerInstitutionId",
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: institutionCustomersDisplayColumns,
      formColumns: institutionCustomersFormColumns,
    },
  },
  {
    name: "customers/institution",
    identifier: "institution/customers/pending",
    meta: {
      label: "Pending",
      canList: true,
      canShow: true,
      showComponent: <CustomerShow />,
      canCreate: true,
      canEdit: true,
      canApprove: true,
      rejectRemarkKey: "revisionNotes",
      canDelete: true,
      downloadButtons: [{ resource: "export/kyc/institution" }],
      statusResource: "customers/institution/histories",
      statusForeignKey: "customerInstitutionId",
      permanentFilters: [{ field: "kycStatus", operator: "eq", value: 2 }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: institutionCustomersDisplayColumns,
      formColumns: institutionCustomersFormColumns,
    },
  },
  {
    name: "customers/institution",
    identifier: "institution/customers/approved",
    meta: {
      label: "Approved",
      canList: true,
      canShow: true,
      showComponent: <CustomerShow />,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      // downloadButtons: [{ resource: "export/kyc/institution" }],
      statusResource: "customers/institution/histories",
      statusForeignKey: "customerInstitutionId",
      permanentFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: institutionCustomersDisplayColumns,
      formColumns: institutionCustomersFormColumns,
    },
  },
  {
    name: "customers/institution",
    identifier: "institution/customers/rejected",
    meta: {
      label: "Rejected",
      canList: true,
      canShow: true,
      showComponent: <CustomerShow />,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      // downloadButtons: [{ resource: "export/kyc/institution" }],
      statusResource: "customers/institution/histories",
      statusForeignKey: "customerInstitutionId",
      permanentFilters: [{ field: "kycStatus", operator: "eq", value: 7 }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: institutionCustomersDisplayColumns,
      formColumns: institutionCustomersFormColumns,
    },
  },
];

const institutionAmendment: CustomResourceProps = {
  name: "customers/institution/amendment",
  identifier: "institution/customers/amendment",
  meta: {
    icon: <EditOutlined />,
    label: "Amendment",
    canList: true,
    canShow: true,
    rejectRemarkKey: "remark",
    canCreate: true,
    createButtonLabel: "Amend",
    createComponent: <AmendmentCreate />,
    showComponent: <AmendmentShow />,
    approveComponent: <AmendmentApprove />,
    // canEdit: true,
    canApprove: true,
    // canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    initialFilters: [{ field: "kycStatus", operator: "eq", value: 2 }],
    downloadButtons: [{ resource: "export/kyc/institution/amendment" }],
    amendmentDropdownFormColumn: {
      key: "customerInstitution",
      type: "select",
      optionsMeta: {
        resourceName: "customers/institution",
        disableGetOne: true,
        initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
      },
    },
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
      {
        key: "kycStatus",
        label: "Status",
        type: "select",
        options: [
          { label: "Pending", value: 2 },
          { label: "Verified", value: 5 },
          { label: "Rejected", value: 7 },
        ],
        initialValue: 2,
      },
      {
        key: "approvedAt",
        placeholder: ["Approved At (Start)", "Approved At (End)"],
        type: "daterange",
        maxDate: dayjs().endOf("day"),
      },
    ],
    fixedColumns: 3,
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "version" },
      { key: "createdAt" },
      { key: "approvedAt" },
      { key: "kycStatus", label: "Status" },
      { key: "remark", hideIfEmpty: true },
      {},
      { key: "companyName" },
      { key: "countryOfDomicile" },
      { key: "tdpNo", label: "TDP" },
      { key: "tdpRegistrationDate", label: "TDP Registration Date" },
      { key: "siupNo", label: "SIUP" },
      { key: "skdNo", label: "SKD" },
      { key: "skdRegistrationDate", label: "SKD Registration Date" },
      { key: "skdExpirationDate", label: "SKD Expiration Date" },
      { key: "npwpNo", label: "NPWP" },
      { key: "npwpRegistrationDate", label: "NPWP Registration Date" },
      { key: "placeOfEstablishment" },
      { key: "dateOfEstablishment" },
      { key: "articlesOfAssociationNo" },
      { key: "companyType" },
      { key: "companyCharacteristic" },
      { key: "institutionIncomeLevel" },
      { key: "institutionInvestmentObjective" },
      { key: "institutionSourceOfFund" },
      { key: "companyAddress" },
      { key: "city" },
      { key: "email" },
      { key: "telpNo" },
      { key: "faxNo" },
    ],
    formColumns: [
      { key: "companyName", type: "text" },
      { key: "registrationDate", type: "date" },
      {
        key: "countryOfDomicileId",
        type: "select",
        optionsMeta: { resourceName: "static/countries" },
      },
      { key: "tdpNo", label: "TDP", type: "text" },
      {
        key: "tdpRegistrationDate",
        label: "TDP Registration Date",
        type: "date",
      },
      { key: "siupNo", label: "SIUP", type: "text" },
      { key: "skdNo", label: "SKD", type: "text" },
      {
        key: "skdRegistrationDate",
        label: "SKD Registration Date",
        type: "date",
      },
      { key: "skdExpirationDate", label: "SKD Expiration Date", type: "date" },
      { key: "npwpNo", label: "NPWP", type: "text" },
      { key: "placeOfEstablishment", type: "text" },
      { key: "dateOfEstablishment", type: "date" },
      {
        key: "articlesOfAssociationNo",
        type: "text",
      },
      {
        key: "companyTypeId",
        type: "select",
        optionsMeta: { resourceName: "static/company-types" },
      },
      {
        key: "companyCharacteristicId",
        type: "select",
        optionsMeta: { resourceName: "static/company-characteristics" },
      },
      {
        key: "institutionIncomeLevelId",
        label: "Income Level",
        type: "select",
        optionsMeta: { resourceName: "static/institution-income-levels" },
      },
      {
        key: "institutionInvestmentObjectiveId",
        label: "Investment Objective",
        type: "select",
        optionsMeta: {
          resourceName: "static/institution-investment-objectives",
        },
      },
      {
        key: "institutionSourceOfFundId",
        label: "Source of Fund",
        type: "select",
        optionsMeta: {
          resourceName: "static/institution-source-of-funds",
        },
      },
      {
        key: "companyAddress",
        type: "textarea",
        span: 2,
      },
      {
        key: "companyCityCodeId",
        label: "City",
        type: "select",
        optionsMeta: { resourceName: "static/cities" },
      },
      { key: "email", type: "email" },
      {
        key: "telpNo",
        label: "Phone Number",
        type: "text",
      },
      {
        key: "faxNo",
        label: "Fax Number",
        type: "text",
      },
    ],
  },
};

const institutionBankAccounts: CustomResourceProps = {
  name: "customers/institution/bank-accounts",
  identifier: "institution/customers/bank-accounts",
  meta: {
    icon: <BankOutlined />,
    label: "Bank Accounts",
    canList: true,
    canShow: true,
    showComponent: <BankAccountShow />,
    canCreate: true,
    // canEdit: true,
    canApprove: true,
    // canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    downloadButtons: [{ resource: "export/kyc/institution/bank-account" }],
    statusResource: "customers/institution/bank-accounts/approval",
    statusForeignKey: "institutionBankAccountId",
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
      {
        key: "customerInstitutionId",
        placeholder: "Customer",
        type: "select",
        optionsMeta: { resourceName: "institution/customers/all" },
      },
      {
        key: "approvalStatusId",
        placeholder: "Approval Status",
        type: "select",
        optionsMeta: {
          resourceName: "static/approval-statuses",
          pagination: { mode: "off" },
          disableGetOne: true,
          disableSearch: true,
        },
      },
      {
        key: "isActive",
        label: "Active",
        type: "select",
        options: [
          { label: "Active", value: true },
          { label: "Inactive", value: false },
        ],
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "bankAccountName" },
      { key: "bank", hideOnRecursor: true },
      { key: "bankAccountNumber" },
      { key: "currency" },
      { key: "isMainBankAccount", label: "Main Bank Account" },
      { key: "isUploaded", label: "Uploaded" },
      { key: "isActive", label: "Active" },
      { key: "approvalStatus" },
      {
        key: "customerInstitution",
        resourceName: "institution/customers/all",
        hideOnParent: true,
      },
    ],
    formColumns: [
      {
        key: "bankAccountNumber",
        type: "number",
        requiredOnCreate: true,
      },
      { key: "bankAccountName", type: "text", requiredOnCreate: true },
      {
        key: "customerInstitutionId",
        type: "select",
        optionsMeta: { resourceName: "customers/institution" },
        requiredOnCreate: true,
      },
      {
        key: "currencyId",
        type: "select",
        optionsMeta: { resourceName: "static/currencies" },
        requiredOnCreate: true,
      },
      {
        key: "bankId",
        type: "select",
        optionsMeta: { resourceName: "static/banks" },
        requiredOnCreate: true,
      },
    ],
  },
};

const institutionBeneficiaryOwner: CustomResourceProps = {
  name: "customers/institution/beneficiary-owners",
  identifier: "institution/customers/beneficiary-owners",
  meta: {
    icon: <UserOutlined />,
    label: "Beneficiary Owners",
    canList: true,
    canShow: true,
    canCreate: true,
    // isFormData: true,
    // canEdit: true,
    canApprove: true,
    rejectRemarkKey: "remark",
    canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    filters: [
      {
        key: "customerInstitutionId",
        type: "select",
        optionsMeta: {
          resourceName: "customers/institution",
          initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
        },
      },
      {
        key: "kycStatus",
        label: "KYC Status",
        type: "select",
        optionsMeta: {
          resourceName: "static/kyc-statuses",
          pagination: { mode: "off" },
          disableGetOne: true,
          disableSearch: true,
        },
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "fullName", span: 1 },
      { key: "aliasName", span: 1 },
      {
        key: "customerInstitution",
        hideOnRecursor: true,
        label: "Institution Name",
      },
      { key: "idCardNo", label: "ID Card Number" },
      { key: "address", span: 1 },
      { key: "domicileAddress", span: 1 },
      { key: "placeOfBirth", span: 1 },
      { key: "dateOfBirth", span: 1 },
      { key: "occupation" },
      { key: "nationality" },
      { key: "gender" },
      { key: "maritalStatus" },
      { key: "workAddress" },
      { key: "workPhoneNumber" },
      { key: "kycStatus", label: "Status", span: 1 },
      { key: "remark", span: 1 },
      { key: "approvedAt", span: 1 },
      { key: "approvedBy", span: 1 },
    ],
    formColumns: [
      { key: "fullName", type: "text", requiredOnCreate: true },
      { key: "aliasName", type: "text", requiredOnCreate: true },
      {
        key: "idCardNo",
        label: "ID Card",
        type: "text",
        requiredOnCreate: true,
      },
      { key: "address", type: "textarea", requiredOnCreate: true },
      {
        key: "domicileAddress",
        type: "textarea",
        requiredOnCreate: true,
        span: 2,
      },
      { key: "placeOfBirth", type: "text", requiredOnCreate: true },
      { key: "dateOfBirth", type: "date", requiredOnCreate: true },
      {
        key: "nationalityId",
        type: "select",
        optionsMeta: { resourceName: "static/countries" },
        requiredOnCreate: true,
      },
      {
        key: "occupationId",
        type: "select",
        optionsMeta: { resourceName: "static/occupations" },
        requiredOnCreate: true,
      },
      {
        key: "workAddress",
        type: "textarea",
        requiredOnCreate: true,
        span: 2,
      },
      {
        key: "workPhoneNumber",
        type: "text",
        requiredOnCreate: true,
        span: 2,
      },
      {
        key: "genderId",
        type: "select",
        optionsMeta: { resourceName: "static/genders" },
        requiredOnCreate: true,
      },
      {
        key: "maritalStatusId",
        type: "select",
        optionsMeta: { resourceName: "static/marital-statuses" },
        requiredOnCreate: true,
      },
      {
        key: "customerInstitutionId",
        type: "select",
        optionsMeta: {
          resourceName: "customers/institution",
          initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
        },
        requiredOnCreate: true,
      },
    ],
  },
};

const institutionAuthorizedIndividual: CustomResourceProps = {
  name: "customers/institution/authorized-individuals",
  identifier: "institution/customers/authorized-individuals",
  meta: {
    icon: <CheckCircleOutlined />,
    label: "Authorized Individuals",
    canList: true,
    canShow: true,
    canCreate: true,
    canEdit: true,
    editMethod: "patch",
    initialSorters: [{ field: "id", order: "desc" }],
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
      {
        key: "customerInstitutionId",
        placeholder: "Customer",
        type: "select",
        optionsMeta: {
          resourceName: "customers/institution",
          initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
        },
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      {
        key: "customerInstitution",
        hideOnRecursor: true,
        label: "Institution Name",
      },
      { key: "firstName", span: 1 },
      { key: "middleName", label: "Middle Name", span: 1 },
      { key: "lastName", span: 1 },
      { key: "position", span: 1 },
      { key: "phoneNumber", span: 1 },
      { key: "email", span: 1 },
      { key: "npwpNo", label: "NPWP", span: 1 },
      { key: "idCardNo", label: "ID Card Number", span: 1 },
      { key: "passportNo", label: "Passport Number", span: 1 },
      { key: "passportExpiredDate", label: "Passport Expired Date", span: 1 },
    ],
    formColumns: [
      {
        key: "customerInstitutionId",
        placeholder: "Customer",
        type: "select",
        requiredOnCreate: true,
        disabledOnEdit: true,
        ignoreOnEdit: true,
        optionsMeta: {
          resourceName: "customers/institution",
        },
      },
      {
        key: "firstName",
        type: "text",
        requiredOnCreate: true,
      },
      {
        key: "middleName",
        label: "Middle Name",
        type: "text",
      },
      { key: "lastName", type: "text" },
      {
        key: "position",
        type: "text",
        requiredOnCreate: true,
      },
      {
        key: "phoneNumber",
        type: "text",
      },
      {
        key: "email",
        type: "text",
      },
      {
        key: "npwpNo",
        label: "NPWP",
        type: "text",
      },
      { key: "idCardNo", label: "ID Card Number", type: "text" },
      {
        key: "passportNo",
        type: "text",
        label: "Passport Number",
      },
      {
        key: "passportExpiredDate",
        label: "Passport Expired Date",
        type: "date",
      },
    ],
  },
};

const institutionDirectors: CustomResourceProps = {
  name: "customers/institution/directors",
  identifier: "institution/customers/directors",
  meta: {
    icon: <UserOutlined />,
    label: "Directors",
    canList: true,
    canShow: true,
    canCreate: true,
    canEdit: true,
    editMethod: "patch",
    initialSorters: [{ field: "id", order: "desc" }],
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
      {
        key: "customerInstitutionId",
        placeholder: "Customer",
        type: "select",
        optionsMeta: {
          resourceName: "customers/institution",
          initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
        },
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      {
        key: "customerInstitution",
        hideOnRecursor: true,
        label: "Institution Name",
      },
      { key: "firstName", span: 1 },
      { key: "middleName", label: "Middle Name", span: 1 },
      { key: "lastName", span: 1 },
      { key: "position", span: 1 },
      { key: "nationality", span: 1, hideOnRecursor: true },
    ],
    formColumns: [
      {
        key: "customerInstitutionId",
        type: "select",
        disabledOnEdit: true,
        ignoreOnEdit: true,
        optionsMeta: {
          resourceName: "customers/institution",
        },
        requiredOnCreate: true,
      },
      {
        key: "firstName",
        type: "text",
        requiredOnCreate: true,
        requiredOnEdit: true,
      },
      { key: "middleName", label: "Middle Name", type: "text" },
      { key: "lastName", type: "text" },
      {
        key: "position",
        type: "text",
        requiredOnCreate: true,
        requiredOnEdit: true,
      },
      {
        key: "nationalityId",
        type: "select",
        optionsMeta: { resourceName: "static/countries" },
        span: 2,
        requiredOnCreate: true,
        requiredOnEdit: true,
      },
    ],
  },
};

const institutionDocuments: CustomResourceProps = {
  name: "customers/institution/documents",
  identifier: "institution/customers/documents",
  meta: {
    icon: <FileTextOutlined />,
    label: "Documents",
    canList: true,
    canShow: true,
    canCreate: true,
    isFormData: true,
    // canEdit: true,
    canApprove: true,
    rejectRemarkKey: "remark",
    canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    filters: [
      {
        key: "customerInstitutionId",
        placeholder: "Customer",
        type: "select",
        optionsMeta: {
          resourceName: "customers/institution",
          initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
        },
      },
      {
        key: "institutionDocumentTypeId",
        placeholder: "Document Type",
        type: "select",
        optionsMeta: {
          resourceName: "static/institution-document-types",
          pagination: { mode: "off" },
          disableGetOne: true,
          disableSearch: true,
        },
      },
      {
        key: "approvalStatusId",
        type: "select",
        optionsMeta: {
          resourceName: "static/approval-statuses",
          pagination: { mode: "off" },
          disableGetOne: true,
          disableSearch: true,
        },
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "file", hideOnList: true },
      {
        key: "customerInstitution",
        hideOnRecursor: true,
        label: "Institution Name",
      },
      { key: "documentType" },
      { key: "isLatest" },
      { key: "approvalStatus" },
    ],
    formColumns: [
      {
        key: "customerInstitutionId",
        type: "select",
        optionsMeta: {
          resourceName: "customers/institution",
          initialFilters: [{ field: "kycStatus", operator: "eq", value: 5 }],
        },
        requiredOnCreate: true,
      },
      {
        key: "documentTypeId",
        type: "select",
        optionsMeta: {
          resourceName: "static/institution-document-types",
          pagination: { mode: "off" },
          disableGetOne: true,
          disableSearch: true,
        },
        requiredOnCreate: true,
      },
      {
        key: "file",
        type: "file",
        requiredOnCreate: true,
        span: 2,
      },
    ],
  },
};

export const institutionResources: CustomResourceProps[] = [
  {
    name: "institution",
    meta: {
      icon: <BankOutlined />,
    },
  },
  {
    name: "institution/customers",
    meta: {
      icon: <TeamOutlined />,
      label: "Customers",
      parent: "institution",
    },
  },
  ...institutionCustomers.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "institution/customers",
    },
  })),

  {
    ...institutionBeneficiaryOwner,
    meta: {
      ...institutionBeneficiaryOwner.meta,
      parent: institutionBeneficiaryOwner?.meta?.parent || "institution",
    },
  },
  {
    ...institutionDirectors,
    meta: {
      ...institutionDirectors.meta,
      parent: institutionDirectors?.meta?.parent || "institution",
    },
  },

  {
    ...institutionAuthorizedIndividual,
    meta: {
      ...institutionAuthorizedIndividual.meta,
      parent: institutionAuthorizedIndividual?.meta?.parent || "institution",
    },
  },

  {
    ...institutionDocuments,
    meta: {
      ...institutionDocuments.meta,
      parent: institutionDocuments?.meta?.parent || "institution",
    },
  },

  {
    ...institutionAmendment,
    meta: {
      ...institutionAmendment.meta,
      parent: institutionAmendment?.meta?.parent || "institution",
    },
  },

  {
    ...institutionBankAccounts,
    meta: {
      ...institutionBankAccounts.meta,
      parent: institutionBankAccounts?.meta?.parent || "institution",
    },
  },

  {
    name: "institution/static-data",
    meta: {
      icon: <DatabaseOutlined />,
      label: "Static Data",
      parent: "institution",
    },
  },

  ...institutionStaticDataResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "institution/static-data",
    },
  })),
];
