import React from "react";
import {
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { CustomErrorComponent } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { setFormInitialValues } from "utils";
import { ButtonProps } from "antd";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";
import { KycRevisionForm } from "./components";

interface KYCEditProps extends IResourceComponentsProps {
  id?: string | number;
  saveButtonProps?: ButtonProps;
}

export const KYCEdit: React.FC<KYCEditProps> = ({ id }) => {
  const { id: idFromParams } = useParsed();
  const { resource } = useResource();
  const { resource: customerResource } = useResource(
    "customers/retail/details"
  );

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    queryResult,
    formLoading,
  } = useForm({
    id: id || idFromParams,
    resource: customerResource?.name,
    meta: { method: resource?.meta?.editMethod || "put" },
    warnWhenUnsavedChanges: false,
  });

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  // set the form's initial values
  setFormInitialValues({ formColumns: resource?.meta?.formColumns, formProps });

  return (
    <Edit
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      isLoading={formLoading}
      headerProps={{ breadcrumb: undefined, extra: <CustomRefreshButton /> }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading,
      }}
    >
      <KycRevisionForm formProps={formProps} resource={resource} />
    </Edit>
  );
};
