import React, { useEffect, useState } from "react";
import { useTranslate, useNotification, useApiUrl } from "@refinedev/core";
import { FireOutlined } from "@ant-design/icons";
import { axiosInstance } from "utils";
import { CounterCard } from "components/molecules";

export const MostActiveProduct: React.FC = () => {
  const translate = useTranslate();
  const { open } = useNotification();

  const [productCode, setProductCode] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeTransactions, setActiveTransactions] = useState<number>(0);
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/dashboard/most-active-product`;

  useEffect(() => {
    setIsLoading(true);

    axiosInstance
      .get(url)
      .then((res) => {
        const data = res?.data?.body;
        setProductName(data?.name);
        setProductCode(data?.siarCode);
        setActiveTransactions(data?.activeTransactions || 0);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [translate, open, url]);

  const percentage = (
    <div>
      {translate("page.dashboard.todayTransaction", `Today's transactions:`)}
      <span
        style={{
          color:
            activeTransactions > 0
              ? "#119761"
              : activeTransactions < 0
              ? "#D80027"
              : "gray",
          fontWeight: "bold",
        }}
      >
        {" "}
        {activeTransactions >= 0 && "+"}
        {activeTransactions}
      </span>
    </div>
  );

  return (
    <CounterCard
      title={translate(
        "page.dashboard.mostActiveProduct",
        `Most Active Product`
      )}
      value={productCode || "-"}
      tooltipValue={productName}
      subValue={percentage}
      icon={<FireOutlined />}
      isLoading={isLoading}
    />
  );
};
