import { ITreeMenu, useMenu, useNavigation } from "@refinedev/core";
import { Menu, MenuProps, theme } from "antd";
import React, { useEffect, useState } from "react";
import { NavbarMenuItem } from "./navbarMenuItem";
import TopBarProgress from "react-topbar-progress-indicator";

interface NavbarMenuProps {
  mode?: "inline" | "vertical" | "horizontal";
  meta?: Record<string, unknown> | undefined;
}

export const NavbarMenu: React.FC<NavbarMenuProps> = ({
  mode = "inline",
  meta,
}) => {
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu({ meta });
  const { list } = useNavigation();

  const items: MenuProps["items"] = menuItems
    ?.map((item: ITreeMenu) => NavbarMenuItem({ item }))
    ?.filter(Boolean);

  const { token } = theme.useToken();
  TopBarProgress.config({
    barColors: {
      "0": token.colorPrimary,
      "1.0": token.colorPrimary,
    },
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => setLoading(false), [items]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      {loading && <TopBarProgress />}
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={mode === "inline" ? defaultOpenKeys : []}
        style={{ border: "none" }}
        mode={mode}
        items={items}
        onClick={({ keyPath }) => {
          setLoading(true);
          const path = keyPath[0]?.replace(keyPath[1], "").slice(1);
          list(path || "");
        }}
      />
    </>
  );
};
