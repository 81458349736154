import { generalResources } from "./general";
import { agentResources } from "./agent";
import { productResources } from "./product";
import { transactionResources } from "./transaction";
import { myheroResources } from "./myhero";
import { retailResources } from "./retail";
import { institutionResources } from "./institution";
import { periodicResources } from "./periodic";
import { portfolioResources } from "./portfolio";
import { rollingBalanceResources } from "./rollingBalance";
import { reportResources } from "./report";
import { CheckCircleOutlined, HomeOutlined } from "@ant-design/icons";
import { DashboardPage, ReconPage } from "components";
import { CustomResourceProps } from "interfaces";

export const resources: CustomResourceProps[] = [
  {
    name: "dashboard",
    meta: {
      label: "Dashboard",
      icon: <HomeOutlined />,
      canList: true,
      listComponent: <DashboardPage />,
    },
  },
  {
    name: "recon",
    meta: {
      label: "Recon",
      icon: <CheckCircleOutlined />,
      canList: true,
      listComponent: <ReconPage />,
    },
  },
  ...generalResources,
  ...periodicResources,
  ...agentResources,
  ...productResources,
  ...transactionResources,
  ...myheroResources,
  ...retailResources,
  ...institutionResources,
  ...portfolioResources,
  ...rollingBalanceResources,
  ...reportResources,
];

resources.forEach((resource) => {
  if (resource?.meta?.canList && !resource?.list) {
    resource.list = `${
      resource?.identifier ?? resource?.meta?.listResource ?? resource?.name
    }`;
  }
  if (resource?.meta?.canShow && !resource?.show) {
    resource.show = `${
      resource?.identifier ?? resource?.meta?.showResource ?? resource?.name
    }/show/:id`;
  }
  if (resource?.meta?.canCreate && !resource?.create) {
    resource.create = `${
      resource?.identifier ?? resource?.meta?.createResource ?? resource?.name
    }/create`;
  }
  if (resource?.meta?.canEdit && !resource?.edit) {
    resource.edit = `${
      resource?.identifier ?? resource?.meta?.editResource ?? resource?.name
    }/edit/:id`;
  }
});
