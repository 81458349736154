import React, { useContext } from "react";
import {
  TitleProps,
  useRouterContext,
  useRouterType,
  useLink,
} from "@refinedev/core";
import { ColorModeContext } from "contexts/color-mode";

interface CustomTitleProps extends TitleProps {
  size?: "small" | "large";
  mode?: "light" | "dark";
}

export const Title: React.FC<CustomTitleProps> = ({
  collapsed,
  size = "small",
  mode = "light",
}) => {
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const { mode: modeFromContext } = useContext(ColorModeContext);

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  return (
    <ActiveLink to="/">
      {collapsed ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              mode === "dark" || modeFromContext === "dark"
                ? "/images/logo/logo-henan-darktheme-collapsed.png"
                : "/images/logo/logo-henan-collapsed.png"
            }
            alt="MyHero"
            style={{
              // width: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              height: size === "small" ? "60px" : "120px",
              padding: "10px",
            }}
          />
        </div>
      ) : (
        <img
          src={
            mode === "dark" || modeFromContext === "dark"
              ? "/images/logo/logo-henan-darktheme.png"
              : "/images/logo/logo-henan.png"
          }
          alt="MyHero"
          style={{
            // width: "100%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: size === "small" ? "60px" : "120px",
            padding: "10px",
          }}
        />
      )}
    </ActiveLink>
  );
};
