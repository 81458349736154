import React from "react";
import {
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { CustomErrorComponent, FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { setFormInitialValues } from "utils";
import { ButtonProps } from "antd";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";
import { IResourceFormColumn } from "interfaces";

interface DividendDistributionEditProps extends IResourceComponentsProps {
  id?: string | number;
  saveButtonProps?: ButtonProps;
}

export const DividendDistributionEdit: React.FC<
  DividendDistributionEditProps
> = ({ id, saveButtonProps }) => {
  const { id: idFromParams } = useParsed();
  const { resource } = useResource();

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    queryResult,
    formLoading,
  } = useForm({
    id: id || idFromParams,
    resource: resource?.meta?.editResource,
    meta: { method: resource?.meta?.editMethod || "put" },
  });
  const record = queryResult?.data?.data;
  const customerType =
    record?.unitHolderId?.[4] === "I" ? "retail" : "institution";

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  const filteredFormColumns =
    resource?.meta?.formColumns?.map((column: IResourceFormColumn) => {
      if (column.key === "bankAccountNumber" && customerType === "retail") {
        return {
          key: "bankAccountNumber",
          label: "Customer Bank Account",
          type: "select",
          optionsMeta: {
            resourceName: "customers/retail/bank-accounts",
            valueKey: "bankAccountNumber",
            disableGetOne: true,
            labelKey: "bank",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            fillOtherColumns: {
              bankId: "bank.id",
            },
            filterFromOtherColumn: {
              formKey: "customerDetailId",
              filterKey: "customerDetailId",
              required: true,
            },
          },
        };
      }

      if (
        column.key === "bankAccountNumber" &&
        customerType === "institution"
      ) {
        return {
          key: "bankAccountNumber",
          label: "Customer Bank Account",
          type: "select",
          optionsMeta: {
            resourceName: "customers/institution/bank-accounts",
            valueKey: "bankAccountNumber",
            disableGetOne: true,
            labelKey: "bank",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            fillOtherColumns: {
              bankId: "bank.id",
            },
            filterFromOtherColumn: {
              formKey: "customerInstitutionId",
              filterKey: "customerInstitutionId",
              required: true,
            },
          },
        };
      }
      return column;
    }) || [];
  // set the form's initial values
  setFormInitialValues({ formColumns: resource?.meta?.formColumns, formProps });

  return (
    <Edit
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      isLoading={formLoading}
      headerProps={{ breadcrumb: undefined, extra: <CustomRefreshButton /> }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading,
        ...saveButtonProps,
      }}
    >
      <FormCard
        formProps={formProps}
        resource={
          resource?.name
            ? {
                ...resource,
                meta: { ...resource?.meta, formColumns: filteredFormColumns },
              }
            : resource
        }
      />
    </Edit>
  );
};
