import React, { useState } from "react";
import { Button, Form, Input, Space } from "antd";
import { EditOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { DynamicDisplayItem } from "components/molecules";
import { useForm } from "@refinedev/antd";
import styles from "./index.module.css";
import { Text } from "components/atoms";

export const ChangePasswordForm: React.FC = () => {
  const translate = useTranslate();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { formProps, saveButtonProps } = useForm({
    resource: "auth/change-password",
    action: "edit",
    meta: { method: "patch" },
    redirect: false,
    onMutationSuccess: () => setIsEditing(false),
  });

  return (
    <Form layout="vertical" {...formProps}>
      <div className={styles.container}>
        <div className={styles.icon}>
          <LockOutlined className="tertiary" />
        </div>

        <div className={styles.content}>
          <div className={styles.input}>
            <Text size="sm" type="secondary" className={styles.label}>
              {translate("accountSettings.password", "Password")}
            </Text>

            <div style={{ margin: 8 }} />

            {/* FORM FIELDS */}
            {isEditing ? (
              <Space
                size="middle"
                direction="vertical"
                style={{ width: "100%" }}
              >
                <Form.Item
                  name="oldPassword"
                  rules={[{ required: true }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input.Password
                    placeholder={translate(
                      "accountSettings.oldPassword",
                      "Old Password"
                    )}
                  />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  rules={[{ required: true }]}
                  style={{ marginBottom: 0 }}
                >
                  <Input.Password
                    placeholder={translate(
                      "accountSettings.newPassword",
                      "New Password"
                    )}
                  />
                </Form.Item>

                {/* FORM TO CONFIRM NEW PASSWORD */}
                <Form.Item
                  name="confirmNewPassword"
                  dependencies={["newPassword"]}
                  style={{ marginBottom: 0 }}
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          translate(
                            "accountSettings.passwordsDontMatch",
                            "Passwords don't match"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder={translate(
                      "accountSettings.confirmNewPassword",
                      "Confirm New Password"
                    )}
                  />
                </Form.Item>
              </Space>
            ) : (
              <DynamicDisplayItem
                value="●●●●●●●●"
                displayColumn={{ key: "password" }}
              />
            )}
          </div>

          {/* ACTION BUTTONS */}
          {isEditing ? (
            <div className={styles.buttons}>
              <Button
                onClick={() => {
                  formProps?.form?.resetFields();
                  setIsEditing(false);
                }}
              >
                {translate("buttons.cancel", "Cancel")}
              </Button>
              <Button type="primary" {...saveButtonProps}>
                {translate("buttons.save", "Save")}
              </Button>
            </div>
          ) : (
            <div className={styles.actions}>
              <Button
                onClick={() => setIsEditing(true)}
                icon={<EditOutlined />}
              />
            </div>
          )}
        </div>
      </div>
    </Form>
  );
};
