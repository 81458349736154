import {
  BaseRecord,
  useUpdate,
  useNavigation,
  useResource,
} from "@refinedev/core";
import { Row, Form, Col } from "antd";
import React, { useEffect, useState } from "react";
import { isExactMatch, kycFormToPayload, kycPayloadToForm } from "utils";
import { KycForm } from "./kycForm";
import { KycPhotos } from "./kycPhotos";
import { KycButtons } from "./kycButtons";
import { startCase } from "lodash";
import { IResourceDisplayColumn } from "interfaces";

interface KycShowLayoutProps {
  objectData?: BaseRecord;
}

export const KycShowLayout: React.FC<KycShowLayoutProps> = ({ objectData }) => {
  const fetchPathName = () =>
    window.location.pathname.split("/").filter(Boolean).slice(0, -2).join("/");
  const pathName = fetchPathName();
  const { resource } = useResource(pathName);
  const [form] = Form.useForm();
  const { mutate, isLoading } = useUpdate();
  const { list } = useNavigation();

  const [revisionForms, setRevisionForms] = useState<any>();
  const [isApproved, setIsApproved] = useState(true);
  const [isRevision, setIsRevision] = useState(false);
  const [unapprovedText, setUnapprovedText] = useState<string>();

  // SET REVISION FORMS
  useEffect(() => {
    const initialValue = kycPayloadToForm({ data: objectData });
    form.setFieldsValue(initialValue);
    setRevisionForms(initialValue);
    setUnapprovedText(initialValue?.unapprovedText);
  }, [objectData, form]);

  // SET IS REVISED
  useEffect(() => {
    const isRevisionAvailable = Object.values(revisionForms || {})?.find(
      (value) => value === true
    );
    setIsRevision(!!isRevisionAvailable);
  }, [revisionForms]);

  // SUBMIT KYC FORM
  const submitKYCForm = (name: string, { values }: { values: any }) => {
    if (name !== "KYCForm") return;

    const KYCPayload = kycFormToPayload({
      resource,
      data: objectData,
      values: { unapprovedText, checkDttot: true, ...values },
      isApproved,
      isRevision,
    });

    mutate(
      {
        resource: resource?.meta?.approveResource,
        id: objectData?.id || "",
        values: KYCPayload,
        meta: { method: "patch" },
      },
      { onSuccess: () => list(resource?.identifier || resource?.name || "") }
    );
  };

  return (
    <Form.Provider onFormFinish={submitKYCForm}>
      <Row gutter={[32, 32]}>
        {/* PHOTOS */}
        <Col xs={24} md={6}>
          <KycPhotos
            displayColumns={resource?.meta?.formColumns}
            objectData={objectData}
          />
        </Col>

        {/* FORM ITEMS */}
        <Col
          xs={24}
          md={
            resource?.meta?.formColumns?.find(
              (displayColumn: IResourceDisplayColumn) =>
                isExactMatch(startCase(displayColumn?.key), "Photo")
            )
              ? 18
              : 24
          }
        >
          <KycForm
            resource={resource}
            form={form}
            revisionForms={revisionForms}
            objectData={objectData}
            setRevisionForms={setRevisionForms}
            setIsApproved={setIsApproved}
          />

          {/* SUBMIT BUTTONS */}
          <KycButtons
            resource={resource}
            isApproved={isApproved}
            setIsApproved={setIsApproved}
            isRevision={isRevision}
            setIsRevision={setIsRevision}
            onClick={form.submit}
            remark={unapprovedText}
            onChangeRemark={setUnapprovedText}
            isLoading={isLoading}
          />
        </Col>
      </Row>
    </Form.Provider>
  );
};
