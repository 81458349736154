import React from "react";
import {
  useShow,
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
  BaseRecord,
  useList,
  CrudFilter,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { mapOldNewAmendment } from "utils/mappers";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Space, Table } from "antd";
import { CustomErrorComponent } from "components/organisms";
import type { ColumnsType } from "antd/es/table";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface AmendmentShowProps extends IResourceComponentsProps {
  id?: string | number;
}

const columns: ColumnsType<BaseRecord> = [
  {
    title: "",
    dataIndex: "key",
    key: "key",
    ellipsis: true,
    onHeaderCell: (column) => ({ style: { border: "none" } }),
  },
  {
    title: "Before",
    dataIndex: "before",
    key: "before",
    onHeaderCell: (column) => {
      return {
        style: {
          backgroundColor: "#0a78bd",
          color: "white",
          fontWeight: "bolder",
          border: "none",
        },
      };
    },
  },
  {
    title: "After",
    dataIndex: "amendment",
    key: "amendment",
    onHeaderCell: (column) => {
      return {
        style: {
          backgroundColor: "#119761",
          color: "white",
          fontWeight: "bolder",
          border: "none",
        },
      };
    },
  },
];

export const AmendmentShow: React.FC<AmendmentShowProps> = ({ id }) => {
  const { resource } = useResource();
  const { id: idFromParams } = useParsed();
  const newQueryResult = useShow({
    resource: resource?.meta?.showResource,
    id: id || idFromParams,
  });

  let filters: CrudFilter[] = [];

  if (resource?.name.includes("customers")) {
    filters.push({ field: "kycStatus", operator: "eq", value: 5 });
  } else if (resource?.name.includes("products")) {
    filters.push({ field: "approvalStatusId", operator: "eq", value: 3 });
  }

  const newData = newQueryResult?.queryResult?.data?.data;
  const newIsLoading = newQueryResult?.queryResult?.isLoading;

  const oldQueryResult = useList({
    resource: resource?.name,
    filters: [
      ...filters,
      {
        field: "beforeVersion",
        operator: "eq",
        value: newData?.version || 2,
      },
      {
        field: `${resource?.meta?.amendmentDropdownFormColumn?.key}Id`,
        operator: "eq",
        value:
          newData?.[`${resource?.meta?.amendmentDropdownFormColumn?.key}Id`] ||
          newData?.[`${resource?.meta?.amendmentDropdownFormColumn?.key}`].id,
      },
    ],
    queryOptions: { enabled: !!newData },
    pagination: {
      pageSize: 1,
    },
    sorters: [{ field: "id", order: "desc" }],
  });
  const oldData = oldQueryResult?.data?.data[0];
  const oldIsLoading = oldQueryResult?.isLoading;

  const error = newData?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  const tableData = mapOldNewAmendment(
    oldData,
    newData,
    resource?.meta?.displayColumns
  );

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={newIsLoading && oldIsLoading}
        headerProps={{ breadcrumb: undefined, extra: <CustomRefreshButton /> }}
      >
        <Table
          rowKey="key"
          style={{ margin: "10px 0" }}
          columns={columns}
          dataSource={tableData}
          loading={oldIsLoading && newIsLoading}
          bordered
          pagination={{ pageSize: tableData.length, position: ["none"] }}
        />
      </Show>
    </Space>
  );
};
