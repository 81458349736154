import { useTable, CreateButton, List, useTableProps } from "@refinedev/antd";
import {
  BaseRecord,
  useResource,
  CrudFilters,
  HttpError,
} from "@refinedev/core";
import { DownloadButton } from "components/atoms";
import {
  ListDynamicFilter,
  ListTable,
  UploadDragger,
} from "components/molecules";
import { IFilterVariables, IResourceFilter } from "interfaces";
import { Col, Row, Divider } from "antd";
import { startCase } from "lodash";
import React from "react";

export interface ListContainerProps {
  title?: string;
  resourceName?: string;
  extraButton?: React.ReactNode;
  disableActions?: boolean;
  disableLoading?: boolean;
  disableFilter?: boolean;
  isLoading?: boolean;
  rowSelection?: any;
  onRow?: (record: BaseRecord) => any;
  useTableProps?: useTableProps<
    BaseRecord,
    HttpError,
    IFilterVariables,
    BaseRecord
  >;
}

export const ListContainer: React.FC<ListContainerProps> = ({
  title,
  resourceName,
  extraButton,
  disableActions,
  disableFilter,
  disableLoading,
  isLoading,
  rowSelection,
  onRow,
  useTableProps,
}) => {
  const { resource } = useResource(resourceName);

  const { tableProps, searchFormProps, sorters, filters } = useTable<
    BaseRecord,
    HttpError,
    IFilterVariables
  >({
    resource: resource?.meta?.listResource || resource?.name,
    pagination: resource?.meta?.pagination,
    sorters: { initial: resource?.meta?.initialSorters },
    filters: {
      defaultBehavior: "replace",
      initial: resource?.meta?.initialFilters,
      permanent: resource?.meta?.permanentFilters,
      ...useTableProps?.filters,
    },
    ...useTableProps,
    onSearch: (filterParams) => {
      const filters: CrudFilters = [];

      resource?.meta?.filters?.forEach((filter: IResourceFilter) => {
        const filterParam =
          filterParams[filter?.key as keyof IFilterVariables] !== ""
            ? filterParams[filter?.key as keyof IFilterVariables]
            : undefined;

        if (filter?.type === "daterange") {
          filters.push({
            field: filter?.key,
            operator: "gte",
            value: filterParam?.[0]?.format("YYYY-MM-DD") ?? undefined,
          });
          filters.push({
            field: filter?.key,
            operator: "lte",
            value: filterParam?.[1]?.format("YYYY-MM-DD") ?? undefined,
          });
        } else {
          filters.push({
            field: filter?.key,
            operator: filter?.operator,
            value: filterParam,
          });
        }
      });

      return filters;
    },
  });

  return (
    <>
      {resource?.meta?.uploadResource && (
        <Col xs={24}>
          <UploadDragger
            resource={resource?.meta?.uploadResource}
            formats={resource?.meta?.uploadFormats}
          />
          <Divider />
        </Col>
      )}

      <List
        resource={resource?.meta?.listResource || resource?.name}
        title={
          title ||
          resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
        }
        headerProps={{
          breadcrumb: undefined,
          extra: !disableActions && (
            <>
              {extraButton}

              {resource?.meta?.downloadButtons?.map((button: any) => (
                <DownloadButton
                  key={button?.resource}
                  resource={button?.resource}
                  label={button?.label}
                />
              ))}

              {resource?.meta?.canCreate && (
                <CreateButton>{resource?.meta?.createButtonLabel}</CreateButton>
              )}
            </>
          ),
        }}
      >
        <div style={{ margin: "24px" }} />

        <Row gutter={[16, 16]}>
          {resource?.meta?.filters && !disableFilter && (
            <Col
              xs={24}
              // lg={24} xl={6}
            >
              {/* <Card title="Filters" bordered={false}> */}
              <ListDynamicFilter
                tableProps={tableProps}
                formProps={searchFormProps}
                filters={resource?.meta?.filters}
              />
              {/* </Card> */}
            </Col>
          )}

          <Col
            xs={24}
            // xl={resourceInfo?.filters ? 18 : 24}
          >
            <ListTable
              resourceName={resource?.identifier || resource?.name}
              tableProps={{
                ...tableProps,
                rowSelection: rowSelection
                  ? { ...tableProps.rowSelection, ...rowSelection }
                  : undefined,
                onRow,
                loading: disableLoading
                  ? false
                  : tableProps.loading || isLoading,
              }}
              sorters={sorters}
              filters={filters}
              fixedColumns={resource?.meta?.fixedColumns}
              disableActions={disableActions}
            />
          </Col>
        </Row>
      </List>
    </>
  );
};
