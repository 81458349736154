import React from "react";
import {
  useShow,
  IResourceComponentsProps,
  useResource,
  useCan,
  useInvalidate,
  BaseRecord,
  useUpdate,
  HttpError,
  useTranslate,
  useCreate,
  useParsed,
} from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Space, Dropdown, Button } from "antd";
import { CustomErrorComponent, UserShowLayout } from "components/organisms";
import { ButtonWithConfirmation } from "components/atoms";
import {
  EllipsisOutlined,
  MailOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface UserShowProps extends IResourceComponentsProps {
  id?: string | number;
}

export const UserShow: React.FC<UserShowProps> = ({ id }) => {
  const { resource } = useResource();
  const { id: idFromParams } = useParsed();
  const { queryResult } = useShow({
    resource: resource?.meta?.showResource,
    id: id || idFromParams,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // handle the access control of the buttons
  const canDeactivate = useCan({
    resource: "users/deactivate",
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  // handle the access control of the buttons
  const canResendEmail = useCan({
    resource: "users",
    action: "create",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  const { mutate: mutateCreate, isLoading: isSendingEmail } =
    useCreate<BaseRecord>();
  const { mutate: mutateUpdate, isLoading: isDeactivating } =
    useUpdate<BaseRecord>();
  const invalidate = useInvalidate();
  const translate = useTranslate();

  const handleResendEmail = async () => {
    mutateCreate(
      { resource: `users/${record?.id}`, values: {} },
      {
        onSettled: () => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const handleDeactivate = async () => {
    mutateUpdate(
      {
        resource: "users/deactivate",
        id: id || idFromParams || record?.id || "",
        values: { isActive: !record?.isActive },
        meta: { method: "patch" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  const items: MenuProps["items"] = [
    {
      label: (
        <ButtonWithConfirmation
          icon={<MailOutlined />}
          type="text"
          onClick={handleResendEmail}
          loading={isSendingEmail}
          disabled={record?.isEmailVerified || !canResendEmail?.data?.can}
        >
          {translate("page.user.resendEmailMessage", "Send Verification Email")}
        </ButtonWithConfirmation>
      ),
      type: "group",
    },
    {
      label: (
        <ButtonWithConfirmation
          type="text"
          danger={record?.isActive}
          onClick={handleDeactivate}
          loading={isDeactivating}
          disabled={!canDeactivate?.data?.can}
          icon={<PoweroffOutlined />}
        >
          {record?.isActive
            ? translate("buttons.deactivate")
            : translate("buttons.activate")}
        </ButtonWithConfirmation>
      ),
      type: "group",
    },
    {
      label: <EditButton disabled={!resource?.meta?.canEdit} type="text" />,
      type: "group",
    },
    {
      label: <CustomRefreshButton type="text" />,
      type: "group",
    },
  ];

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        contentProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            margin: "0px -24px",
          },
        }}
        isLoading={isLoading}
        headerProps={{
          breadcrumb: undefined,
          extra: (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          ),
        }}
      >
        <UserShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
        />
      </Show>
    </Space>
  );
};
