import React, { useState } from "react";
import {
  IResourceComponentsProps,
  useResource,
  useApiUrl,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import { Create, UseFormProps, useForm } from "@refinedev/antd";
import { Button, Space } from "antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { FormCard } from "components/organisms";

import { DownloadOutlined, UndoOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import { axiosInstance, getKeysByColumnType } from "utils";
import { IResourceFormColumn } from "interfaces";

export const ReportCreate: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };
  const [isLoading, setIsLoading] = useState(false);
  const { formProps } = useForm(useFormProps);

  const apiUrl = useApiUrl();
  const { open } = useNotification();
  const translate = useTranslate();

  const url = `${apiUrl}/${resource?.name}`;

  const onClickReset = () => {
    formProps?.form?.resetFields();
  };

  const onClick = async () => {
    try {
      setIsLoading(true);
      await formProps?.form?.validateFields();

      let formValues: any = formProps?.form?.getFieldsValue();
      // Remove undefined values
      Object.keys(formValues).forEach((key) => {
        if (formValues[key] === undefined) delete formValues[key];
      });

      // Convert date range to dateFrom and dateTo
      const dateRangeKeys = getKeysByColumnType(
        formValues,
        resource?.meta?.formColumns,
        "daterange"
      );

      dateRangeKeys?.forEach((key) => {
        const formColumn = resource?.meta?.formColumns?.find(
          (formColumn: IResourceFormColumn) => formColumn?.key === key
        );

        formValues[key]?.forEach((date: any, index: number) => {
          const defaultKey = index === 0 ? "dateFrom" : "dateTo";

          Object.assign(formValues, {
            [formColumn?.rangeKeys?.[index] || defaultKey]: date,
          });
          delete formValues[key];
        });
      });

      const res = await axiosInstance.post(url, formValues, {
        responseType: "blob",
      });

      let fileName = "download.csv";
      const contentDisposition = res.headers["content-disposition"];

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename=(.+)/);
        if (filenameMatch?.length === 2) fileName = filenameMatch[1];
      }
      fileDownload(res?.data, fileName);
      formProps?.form?.resetFields();
    } catch (err: any) {
      const errorBlob = err?.response?.data;

      // convert blob to json
      errorBlob?.text()?.then((text: any) => {
        const error = JSON.parse(text);

        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (resource?.meta?.formColumns) {
    return (
      <Create
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        headerProps={{ breadcrumb: undefined }}
        footerButtons={() => (
          <>
            <Button onClick={onClickReset} icon={<UndoOutlined />}>
              {translate("buttons.reset", "Reset")}
            </Button>
            <Button
              type="primary"
              onClick={onClick}
              loading={isLoading}
              icon={<DownloadOutlined />}
            >
              {translate("buttons.download", "Download")}
            </Button>
          </>
        )}
      >
        <FormCard formProps={formProps} resource={resource} />
      </Create>
    );
  }

  return (
    <Create
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      footerButtons={() => <></>}
    >
      <Space
        direction="horizontal"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Button
          size="large"
          type="primary"
          onClick={onClick}
          loading={isLoading}
          icon={<DownloadOutlined />}
        >
          {translate("buttons.download", "Download")}
        </Button>
      </Space>
    </Create>
  );
};
