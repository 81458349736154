const TRANSACTION_TYPE = {
  SUB: 1,
  RED: 2,
  SWT: 3,
} as const;

const TRANSFER_TYPE_NAME = {
  1: "SKNBI",
  2: "RTGS",
  3: "N/A",
};

export { TRANSACTION_TYPE, TRANSFER_TYPE_NAME };
