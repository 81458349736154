import React, { useEffect, useState } from "react";

import { useTranslate, useNotification, useApiUrl } from "@refinedev/core";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { axiosInstance } from "utils";
import { CounterCard } from "components/molecules";

export const LatestNavDate: React.FC = () => {
  const translate = useTranslate();
  const { open } = useNotification();

  const [navDate, setNavDate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiUrl = useApiUrl();
  const navDateUrl = `${apiUrl}/dashboard/total-nav-per-product`;

  useEffect(() => {
    setIsLoading(true);

    axiosInstance
      .get(navDateUrl)
      .then((res) => {
        const data = res?.data?.body?.date;
        setNavDate(data);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [translate, open, navDateUrl]);

  return (
    <CounterCard
      title={"Latest NAV Date"}
      titleData={navDate ? dayjs(navDate).format("DD MMMM YYYY") : "-"}
      titleSkeleton={true}
      icon={<CalendarOutlined />}
      isLoading={isLoading}
      tooltipEnabled={false}
    />
  );
};
