import React from "react";
import { Typography, Timeline as AntdTimeline } from "antd";
import styled from "@emotion/styled";
import { IResourceTimeline } from "interfaces";
import { convertHexToRGBA } from "utils";
import { useTranslate } from "@refinedev/core";

const Timeline = styled(AntdTimeline)`
  .ant-timeline-item-head {
    background-color: transparent;
  }
`;

const TimelineContent = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) =>
    convertHexToRGBA(backgroundColor, 0.5)};
`;

const CreatedAt = styled(Typography.Text)`
  font-size: 12px;
  cursor: default;
`;

const { Text } = Typography;

interface CustomTimelineItemProps {
  items?: IResourceTimeline[];
}

export const CustomTimelineItem: React.FC<CustomTimelineItemProps> = ({
  items,
}) => {
  const translate = useTranslate();
  const timelineItems = items?.map(
    ({ status, approvedAt, approvedBy, color }) => ({
      key: `${status}`,
      color: color,
      children: (
        <TimelineContent backgroundColor={color || "transparent"}>
          <CreatedAt italic>{approvedAt}</CreatedAt>
          <Text strong>{status}</Text>
          <Text>
            {translate(
              "timelineItem.byText",
              { by: approvedBy },
              `by ${approvedBy}`
            )}
          </Text>
        </TimelineContent>
      ),
    })
  );

  return (
    <Timeline
      items={timelineItems}
      style={{ width: "100%", marginTop: "8px" }}
    />
  );
};
