import { getDefaultSortOrder } from "@refinedev/antd";
import {
  BaseRecord,
  CrudSorting,
  useResource,
  CrudFilters,
  getDefaultFilter,
} from "@refinedev/core";
import { Table, TableProps } from "antd";
import { PaginationTotal } from "components/atoms";
import { DynamicDisplayItem, ListActions } from "components/molecules";
import { IResourceDisplayColumn } from "interfaces";

import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { isObject, getNameKey } from "utils";

export interface ListTableProps {
  resourceName?: string;
  tableProps: TableProps<BaseRecord>;
  sorters?: CrudSorting;
  filters?: CrudFilters;
  fixedColumns?: number;
  disableActions?: boolean;
}

export const ListTable: React.FC<ListTableProps> = ({
  resourceName,
  tableProps,
  sorters,
  filters,
  fixedColumns = 2,
  disableActions,
}) => {
  const { resource } = useResource(resourceName);
  const [displayColumns, setDisplayColumns] =
    useState<IResourceDisplayColumn[]>();

  useEffect(() => {
    if (resource?.meta?.displayColumns) {
      setDisplayColumns(
        resource?.meta?.displayColumns.filter(
          (column: any) => !column?.hideOnList
        )
      );
    } else if (tableProps?.dataSource && tableProps?.dataSource?.length > 0) {
      const blacklist = [
        "Password",
        "Created At",
        "Updated At",
        "Deleted At",
        "Created By",
        "Updated By",
        "Deleted By",
        "Avatar",
        "Image",
        "Img",
        "Photo",
        "Logo",
      ];

      const newColumns: IResourceDisplayColumn[] = Object.entries(
        tableProps?.dataSource[0]
      )
        ?.filter(([key, value]) => !blacklist.includes(startCase(key)))
        ?.map(([key, value]) => {
          if (isObject(value)) {
            return {
              key,
              childKey: getNameKey({ record: value }),
            }; // displayColumn with childKey means the data is an object
          } else {
            return { key };
          }
        });

      setDisplayColumns(newColumns);
    }
  }, [resource?.meta?.displayColumns, tableProps?.dataSource]);

  function calculateWidth(text: string): number {
    const element = document.createElement("canvas");
    const context = element.getContext("2d");
    if (context) context.font = `16px -apple-system`;
    return (context?.measureText(text).width || 0) + 40;
  }

  return (
    <Table
      {...tableProps}
      pagination={{
        ...tableProps?.pagination,
        showSizeChanger: true,
        showTotal: (total, range) => (
          <PaginationTotal total={total} range={range} />
        ),
      }}
      rowKey="id"
      size="small"
      scroll={{ x: "max-content", y: 425 }}
      bordered
    >
      {!!tableProps?.dataSource?.length &&
        displayColumns
          ?.filter((displayColumn) => displayColumn?.key)
          ?.map((displayColumn, index) => {
            const key = displayColumn?.key;
            const keyWithChildKey = key + " " + displayColumn?.childKey;

            if (displayColumn?.hideOnParent) {
              return null;
            }

            return (
              <Table.Column
                key={displayColumn?.childKey ? keyWithChildKey : key}
                title={displayColumn?.label || startCase(key)}
                dataIndex={key}
                fixed={index <= fixedColumns - 1 ? "left" : undefined}
                ellipsis
                sorter={
                  displayColumn?.isSortable !== false &&
                  !isObject(tableProps?.dataSource?.[0]?.[key || ""]) &&
                  !displayColumn?.childKey
                }
                defaultSortOrder={getDefaultSortOrder(key || "id", sorters)}
                defaultFilteredValue={getDefaultFilter(key || "id", filters)}
                render={(value) => DynamicDisplayItem({ displayColumn, value })}
                width={calculateWidth(displayColumn?.label || startCase(key))}
              />
            );
          })}

      {(resource?.meta?.canShow ||
        resource?.meta?.canEdit ||
        resource?.meta?.canApprove ||
        resource?.meta?.canDelete ||
        resource?.meta?.listToggleResource) &&
        !!tableProps?.dataSource?.length &&
        !disableActions && (
          <Table.Column<BaseRecord>
            title="Actions"
            dataIndex="actions"
            align="center"
            fixed="right"
            width={70}
            render={(_, record) => (
              <ListActions resource={resource} record={record} />
            )}
          />
        )}
    </Table>
  );
};
