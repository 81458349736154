import React from "react";

type PaginationTotalProps = {
  total: number;
  range: number[];
};

export const PaginationTotal: React.FC<PaginationTotalProps> = ({
  total,
  range,
}) => {
  return (
    <span style={{ marginRight: "16px" }}>
      {`${range[0]?.toLocaleString()}-${range[1]?.toLocaleString()} of ${total?.toLocaleString()} items`}
    </span>
  );
};
