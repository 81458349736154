import { DatabaseOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { AgentMovement } from "components";
import { SearchOutlined } from "@ant-design/icons";
import { CustomResourceProps } from "interfaces";

const agentListResources: CustomResourceProps[] = [
  {
    name: "agents",
    identifier: "agent/agents",
    meta: {
      label: "Agents",
      canList: true,
      canShow: true,
      statusResource: "agents/approval",
      statusForeignKey: "agentId",
      canCreate: true,
      canEdit: true,
      showToggleResource: "agents/active",
      editMethod: "patch",
      canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          label: "Approval Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "isActive",
          label: "Active",
          type: "select",
          options: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
          ],
        },
        {
          key: "isParentAgent",
          label: "Is Parent Agent",
          type: "select",
          options: [
            { label: "Parent Agent", value: true },
            { label: "Not Parent Agent", value: false },
          ],
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "agentName", span: 1 },
        { key: "agentCode", span: 1 },
        { key: "parentAgentCode", span: 1 },
        { key: "agentNik", label: "Agent NIK", span: 1 },
        { key: "agentType", span: 1 },
        { key: "isParentAgent", label: "Parent Agent", span: 1 },
        { key: "agentPhoneNumber", label: "Phone Number" },
        { key: "user", hideOnRecursor: true },
        { key: "licenseCode" },
        { key: "branch", hideOnRecursor: true },
        { span: 2 },
        { key: "joinDate", span: 1 },
        { key: "resignDate", span: 1 },
        { key: "isActive", label: "Active" },
        { key: "approvalStatus" },
        { key: "approvedBy", span: 1 },
        { key: "approvedAt", span: 1 },
        { key: "createdAt", span: 1 },
      ],
      formColumns: [
        {
          key: "agentCode",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        { key: "agentName", type: "text", requiredOnCreate: true },
        {
          key: "isParentAgent",
          label: "Parent Agent",
          type: "boolean",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "parentAgentCode",
          label: "Parent Agent",
          requiredOnCreate: true,
          hideOnEdit: true,
          type: "select",
          optionsMeta: {
            resourceName: "agents",
            valueKey: "agentCode",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "agentPhoneNumber",
          label: "Phone Number",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "agentNik",
          label: "NIK",
          type: "text",
          requiredOnCreate: true,
        },
        { key: "licenseCode", type: "text", requiredOnCreate: true },
        { key: "joinDate", type: "date", requiredOnCreate: true },
        { key: "resignDate", type: "date", hideOnCreate: true },
        {
          key: "agentTypeId",
          type: "select",
          optionsMeta: { resourceName: "static/agent-types" },
          requiredOnCreate: true,
        },
        {
          key: "branchId",
          type: "select",
          optionsMeta: { resourceName: "branches" },
          requiredOnCreate: true,
        },
        {
          key: "userId",
          type: "select",
          optionsMeta: { resourceName: "users" },
        },
      ],
    },
  },

  {
    name: "agents/histories",
    identifier: "agent/history",
    meta: {
      label: "History",
      canList: true,
      canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "customerDetail",
          childKey: "firstName",
          label: "Customer First Name",
        },
        {
          key: "customerDetail",
          childKey: "lastName",
          label: "Customer Last Name",
        },
        { key: "agent" },
        { key: "createdAt" },
        { key: "createdBy" },
      ],
    },
  },

  {
    name: "agents/movement",
    identifier: "agent/movement",
    meta: {
      label: "Movement",
      defaultAction: "create",
      canList: true,
      canBulkApprove: true,
      listComponent: <AgentMovement />,
      listPermissionResource: "agents",
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "customerDetail",
          childKey: "firstName",
          label: "Customer First Name",
        },
        {
          key: "customerDetail",
          childKey: "lastName",
          label: "Customer Last Name",
        },
        { key: "targetAgent" },
        { key: "movementDate" },
        { key: "approvalStatus" },
        { key: "approvedBy" },
        { key: "approvedAt" },
      ],
      filters: [
        {
          key: "customerDetailId",
          type: "select",
          label: "Customer",
          optionsMeta: {
            resourceName: "customers/retail/details",
            labelKey: ["firstName", "lastName"],
          },
        },
        {
          key: "agentId",
          type: "select",
          optionsMeta: { resourceName: "agents" },
        },

        {
          key: "movementDate",
          type: "daterange",
          maxDate: new Date(),
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
    },
  },
  {
    name: "branches",
    identifier: "agent/branches",
    meta: {
      label: "Branches",
      canList: true,
      canShow: true,
      canEdit: true,
      editMethod: "patch",
      canCreate: true,
      canApprove: true,
      canDelete: true,
      statusResource: "branches/approval",
      statusForeignKey: "branchId",
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "branchName" },
        { key: "branchAddress", span: 1 },
        { key: "branchPhoneNumber", span: 1 },
        { key: "branchFaxNumber", span: 1 },
        { key: "branchFoundingDate", span: 1 },
        { key: "isDefaultBranch", span: 1 },
        { key: "isActive", span: 1 },
        { key: "approvalStatus" },
        { key: "approvedBy", span: 1 },
        { key: "approvedAt", span: 1 },
      ],
      formColumns: [
        {
          key: "branchName",
          type: "text",
          label: "Branch Name",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "branchAddress",
          type: "text",
          label: "Branch Address",
          requiredOnCreate: true,
        },
        {
          key: "branchPhoneNumber",
          type: "number",
          label: "Branch Phone Number",
          requiredOnCreate: true,
        },
        {
          key: "branchFaxNumber",
          type: "number",
          label: "Branch Fax Number",
        },
        {
          key: "branchFoundingDate",
          type: "date",
          label: "Branch Founding Date",
          requiredOnCreate: true,
        },
      ],
    },
  },
];

const agentStaticDataResources: CustomResourceProps[] = [
  {
    name: "static/agent-types",
    identifier: "agent/static/agent-types",
    meta: {
      label: "Agent Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "agentTypeCode", type: "text", requiredOnCreate: true },
        { key: "agentTypeName", type: "text", requiredOnCreate: true },
      ],
    },
  },
];

export const agentResources: CustomResourceProps[] = [
  {
    name: "agent-list",
    meta: {
      icon: <UsergroupAddOutlined />,
      label: "Agents",
    },
  },
  ...agentListResources.map((resource) => ({
    ...resource,
    meta: { ...resource.meta, parent: resource?.meta?.parent || "agent-list" },
  })),

  {
    name: "agent/static-data",
    meta: {
      icon: <DatabaseOutlined />,
      label: "Static Data",
      parent: "agent-list",
    },
  },
  ...agentStaticDataResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "agent/static-data",
    },
  })),
];
