import React from "react";
import { startCase } from "lodash";
import { Tag } from "antd";

export const EnvBadge: React.FC = () => {
  const environment: string =
    process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV || "";

  const TAG_COLOR: any = {
    development: "red",
    staging: "yellow",
  };

  return (
    <Tag
      style={{
        display: !TAG_COLOR?.[environment] ? "none" : undefined,
        position: "absolute",
        right: 0,
        margin: 0,
      }}
      color={TAG_COLOR?.[environment]}
    >
      {startCase(environment)}
    </Tag>
  );
};
