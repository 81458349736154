import React, { useEffect, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useResource,
  useCan,
  useInvalidate,
  BaseRecord,
  useUpdate,
  HttpError,
  useTranslate,
  useParsed,
} from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Space, TimelineItemProps, StepProps, Row, Col } from "antd";
import { mapStatus, useChildRecords, mapKycSteps } from "utils";
import {
  CustomErrorComponent,
  ShowItemRecursor,
  ShowLayout,
} from "components/organisms";
import { ButtonWithConfirmation, DownloadButton } from "components/atoms";
import { PoweroffOutlined } from "@ant-design/icons";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface CustomerShowProps extends IResourceComponentsProps {
  id?: string | number;
}

export const CustomerShow: React.FC<CustomerShowProps> = ({ id }) => {
  const { resource } = useResource();
  const { id: idFromParams } = useParsed();
  const { queryResult } = useShow({
    resource: resource?.meta?.showResource,
    id: id || idFromParams,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const customerType = resource?.name?.includes("retail")
    ? "retail"
    : "institution";

  // handle the population of the data's status
  const statusData = useChildRecords({
    resourceName: resource?.meta?.statusResource,
    foreignKey: resource?.meta?.statusForeignKey,
    parentId: record?.id,
  });

  const [timelineItemProps, setTimelineItemProps] =
    useState<TimelineItemProps[]>();
  useEffect(() => {
    if (statusData && statusData.length > 0)
      setTimelineItemProps(mapStatus({ data: statusData }));
  }, [resource, statusData, record]);

  const [stepProps, setStepProps] = useState<StepProps[]>();
  useEffect(() => {
    if (record?.progressBar !== undefined)
      setStepProps(mapKycSteps({ data: record.progressBar }));
  }, [resource, statusData, record]);

  // handle the access control of the buttons
  const canDeactivate = useCan({
    resource: `customers/${customerType}/deactivate`,
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  const { mutate: mutateUpdate } = useUpdate<BaseRecord>();
  const invalidate = useInvalidate();
  const translate = useTranslate();

  const handleDeactivate = async () => {
    mutateUpdate(
      {
        resource: `customers/${customerType}/deactivate`,
        id: id || idFromParams || record?.id || "",
        values: {},
        meta: { method: "patch" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          breadcrumb: undefined,
          extra: (
            <Row gutter={[10, 10]}>
              <Col>
                {resource?.meta?.downloadButtons?.map((button: any) => (
                  <DownloadButton
                    key={`${button?.resource}/${
                      id || idFromParams || record?.id || ""
                    }`}
                    resource={button?.resource}
                    id={(id || idFromParams || record?.id || "") as string}
                    label={button?.label}
                  />
                ))}
              </Col>

              <Col>
                <ButtonWithConfirmation
                  type="primary"
                  danger={true}
                  onClick={handleDeactivate}
                  disabled={!record?.isActive || !canDeactivate?.data?.can}
                  icon={<PoweroffOutlined />}
                >
                  {translate("buttons.deactivate", "Deactivate")}
                </ButtonWithConfirmation>
              </Col>

              {resource?.meta?.canEdit && (
                <Col>
                  <EditButton />
                </Col>
              )}

              <Col>
                <CustomRefreshButton />
              </Col>
            </Row>
          ),
        }}
      >
        <ShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
          timelineItemProps={timelineItemProps}
          stepProps={stepProps}
        />
      </Show>

      <ShowItemRecursor
        parentName={resource?.identifier || resource?.name}
        parentData={record}
        hideOnChildRecursor={resource?.meta?.hideOnChildRecursor}
      />
    </Space>
  );
};
