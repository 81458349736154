import { useTitle } from "@refinedev/core";
import { Title as DefaultTitle, RefineLayoutSiderProps } from "@refinedev/antd";
import { Button, Drawer, Layout, theme } from "antd";
import React, { CSSProperties, lazy, useState } from "react";
import { BarsOutlined } from "@ant-design/icons";

const NavbarMenu = lazy(() =>
  import("components/molecules").then((module) => ({
    default: module.NavbarMenu,
  }))
);

const drawerButtonStyles: CSSProperties = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  position: "fixed",
  top: 64,
  zIndex: 999,
};

export const MobileNavbar: React.FC<RefineLayoutSiderProps> = ({
  Title: TitleFromProps,
  render,
  meta,
}) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const TitleFromContext = useTitle();
  const { token } = theme.useToken();
  const RenderTitle = TitleFromProps ?? TitleFromContext ?? DefaultTitle;

  return (
    <>
      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        placement="left"
        closable={false}
        width={200}
        styles={{ body: { padding: 0 } }}
        maskClosable={true}
      >
        <Layout>
          <Layout.Sider
            style={{
              height: "100vh",
              backgroundColor: token.colorBgContainer,
            }}
          >
            <RenderTitle collapsed={false} />
            <NavbarMenu meta={meta} />
          </Layout.Sider>
        </Layout>
      </Drawer>

      <Button
        style={drawerButtonStyles}
        size="large"
        onClick={() => setDrawerOpen(true)}
        icon={<BarsOutlined />}
      ></Button>
    </>
  );
};
