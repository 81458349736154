import { AccessControlProvider } from "@refinedev/core";
import { newModel, StringAdapter, Enforcer, newEnforcer } from "casbin";
import { IAccessControlRule } from "interfaces";
import { setAccessControlCache } from "redux/slices/accessControlCacheSlice";
import { store } from "redux/store";

const model = newModel(`
[request_definition]
r = subject, object, action

[policy_definition]
p = subject, object, action, eft

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow)) \
&& !some(where (p.eft == deny))

[matchers]
m = g(r.subject, p.subject) \
&& keyMatch(r.object, p.object) \
&& regexMatch(r.action, p.action)
`);

let enforcer: Enforcer | null = null;

async function createEnforcer(rules: IAccessControlRule[]) {
  const ruleString: string = rules
    ?.map((item: IAccessControlRule) => {
      return `p, ${item?.role}, ${item?.route}, ${item?.permissions?.join(
        "|"
      )}`;
    })
    ?.join("\n");
  const adapter = new StringAdapter(ruleString);
  return await newEnforcer(model, adapter);
}

export const accessControlProvider: AccessControlProvider = {
  can: async ({ resource, action, params }) => {
    const role = store?.getState()?.user?.role;
    const accessControlRules = store?.getState()?.temp?.accessControlRules;
    const accessControlCache = store?.getState()?.accessControlCache;

    let targetUrl = params?.route || resource;
    if (targetUrl?.charAt(0) === "/") targetUrl = targetUrl.slice(1);

    const cacheKey = `${role} ${targetUrl} ${action}`;

    if (accessControlCache?.[cacheKey] !== undefined) {
      return { can: accessControlCache?.[cacheKey] || false };
    }
    if (!(accessControlRules?.length && accessControlRules?.length > 0)) {
      return { can: false };
    }
    if (!enforcer) enforcer = await createEnforcer(accessControlRules || []);

    const can = await enforcer?.enforce(role, targetUrl, action);

    store?.dispatch(setAccessControlCache({ [cacheKey]: can }));
    return { can };
  },
};
