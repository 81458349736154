export const unitAmountToggleHandler = (toggleKey: string) => (values: any) => {
  const toggleValue = values[toggleKey || ""];
  const currentValue = values["value"];

  if (toggleValue) {
    values["amount"] = 0;
    values["unit"] = Number(currentValue);
    return;
  }

  values["unit"] = 0;
  values["amount"] = Number(currentValue);
};
