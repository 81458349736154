import { useEffect, useRef } from "react";

export function useMouseHoverPosition<T extends HTMLElement = HTMLDivElement>(
  backgroundSize: string | number
) {
  const ref = useRef<T>(null);
  const hoverRef = useRef(false);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (ref.current) {
        // Get the bounding rectangle of the element
        const rect = ref.current.getBoundingClientRect();

        // Check if the mouse is hovering inside component boundary
        const isInside =
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom;
        if (isInside) {
          // Set hovering state
          hoverRef.current = true;

          // Calculate the relative position within the element
          // This will be used to offset the background position
          const relativeX = event.clientX - rect.left;
          const relativeY = event.clientY - rect.top;

          // Convert to percentage
          const xPercent = (relativeX / rect.width) * 100;
          const yPercent = (relativeY / rect.height) * 100;

          const newState = {
            xPercent: Math.min(Math.max(xPercent, 0), 100),
            yPercent: Math.min(Math.max(yPercent, 0), 100),
          };

          ref.current.style.backgroundSize = `${backgroundSize}`;
          ref.current.style.backgroundPosition = `${newState.xPercent}% ${newState.yPercent}%`;
        } else {
          if (hoverRef) {
            hoverRef.current = false;
            ref.current.style.backgroundSize = "";
            ref.current.style.objectFit = "contain";
            ref.current.style.backgroundPosition = `0 0`;
            ref.current.style.backgroundSize = "100%";
          }
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [ref, backgroundSize]);

  return {
    ref,
  };
}

export default useMouseHoverPosition;
