import React, { useEffect, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useUpdate,
  useNavigation,
  useResource,
  useCan,
  useParsed,
  HttpError,
  BaseRecord,
  useList,
  CrudFilter,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Space, Table } from "antd";
import { CustomErrorComponent } from "components/organisms";
import { ApproveButtons } from "components/molecules";
import { mapOldNewAmendment } from "utils/mappers";
import type { ColumnsType } from "antd/es/table";
import { DownloadButton } from "components/atoms";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface AmendmentShowProps extends IResourceComponentsProps {
  id?: string | number;
}

const columns: ColumnsType<BaseRecord> = [
  {
    title: "",
    dataIndex: "key",
    key: "key",
    ellipsis: true,
    onHeaderCell: (column) => ({ style: { border: "none" } }),
  },
  {
    title: "Before",
    dataIndex: "before",
    key: "before",
    onHeaderCell: (column) => {
      return {
        style: {
          backgroundColor: "#0a78bd",
          color: "white",
          fontWeight: "bolder",
          border: "none",
        },
      };
    },
  },
  {
    title: "After",
    dataIndex: "amendment",
    key: "amendment",
    onHeaderCell: (column) => {
      return {
        style: {
          backgroundColor: "#119761",
          color: "white",
          fontWeight: "bolder",
          border: "none",
        },
      };
    },
  },
];

export const AmendmentApprove: React.FC<AmendmentShowProps> = ({ id }) => {
  const { mutate, isLoading: isMutateLoading } = useUpdate();
  const { list } = useNavigation();
  const { id: idFromParams, pathname } = useParsed();
  const { resource } = useResource(
    pathname?.split("/").filter(Boolean).slice(0, -2).join("/")
  );

  const newQueryResult = useShow({
    resource: resource?.meta?.showResource || resource?.name,
    id: id || idFromParams,
  });

  const newData = newQueryResult?.queryResult?.data?.data;
  const newIsLoading = newQueryResult?.queryResult?.isLoading;

  let filters: CrudFilter[] = [];

  if (resource?.name.includes("customers")) {
    filters.push({ field: "kycStatus", operator: "eq", value: 5 });
  } else if (resource?.name.includes("products")) {
    filters.push({ field: "approvalStatusId", operator: "eq", value: 3 });
  }

  const oldQueryResult = useList({
    resource: resource?.name,
    filters: [
      ...filters,
      {
        field: "beforeVersion",
        operator: "eq",
        value: newData?.version || 2,
      },
      {
        field: `${resource?.meta?.amendmentDropdownFormColumn?.key}Id`,
        operator: "eq",
        value:
          newData?.[`${resource?.meta?.amendmentDropdownFormColumn?.key}Id`] ||
          newData?.[`${resource?.meta?.amendmentDropdownFormColumn?.key}`].id,
      },
    ],
    queryOptions: { enabled: !!newData },
    pagination: {
      pageSize: 1,
    },
    sorters: [{ field: "id", order: "desc" }],
  });

  const oldData = oldQueryResult?.data?.data[0];
  const oldIsLoading = oldQueryResult?.isLoading;

  const tableData = mapOldNewAmendment(
    oldData,
    newData,
    resource?.meta?.displayColumns
  );

  const canApprove = useCan({
    resource: resource?.meta?.approveResource || `${resource?.name}/approval`,
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  // enable or disable the approval button
  const [needsApproval, setNeedsApproval] = React.useState(false);

  useEffect(() => {
    const status = (
      newData?.approvalStatus?.approvalStatusName ||
      newData?.kycStatus?.kycStatusName ||
      newData?.transactionStatus?.transactionStatusName
    )?.toLowerCase();

    const needsApproval =
      status === (resource?.meta?.statusToApprove || "pending");
    setNeedsApproval(needsApproval);
  }, [newData, resource]);

  // handle the approval button
  const [remark, setRemark] = useState<string>();
  const handleApproval = ({ isApproved }: { isApproved: boolean }) => {
    let values = { isApproved };
    if (!!resource?.meta?.rejectRemarkKey) {
      values = { ...values, [resource?.meta?.rejectRemarkKey]: remark };
    }

    mutate(
      {
        resource:
          resource?.meta?.approveResource || `${resource?.name}/approval`,
        id: id || idFromParams || newData?.id || "",
        values: values,
        meta: { method: "patch" },
      },
      {
        onSuccess: () => {
          list(resource?.identifier || resource?.name || "");
        },
      }
    );
  };

  const error = newData?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={oldIsLoading && newIsLoading}
        headerProps={{
          onBack: () => list(resource?.identifier || resource?.name || ""),
          breadcrumb: undefined,
          extra: (
            <>
              {resource?.meta?.downloadButtons?.map((button: any) => (
                <DownloadButton
                  id={(id || idFromParams || newData?.id || "") as string}
                  key={button?.resource}
                  resource={button?.resource}
                  label={button?.label}
                />
              ))}

              <CustomRefreshButton />
            </>
          ),
        }}
      >
        <Table
          rowKey="id"
          style={{ margin: "10px 0" }}
          columns={columns}
          dataSource={tableData}
          loading={oldIsLoading && newIsLoading}
          bordered
          pagination={{ pageSize: tableData.length, position: ["none"] }}
        />
      </Show>

      <ApproveButtons
        onClick={handleApproval}
        onRejectRemark={
          !!resource?.meta?.rejectRemarkKey ? setRemark : undefined
        }
        disableApprove={!needsApproval || !canApprove?.data?.can}
        disableReject={!needsApproval || !canApprove?.data?.can}
        isLoading={isMutateLoading}
      />
    </Space>
  );
};
