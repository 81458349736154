import React from "react";
import { ITreeMenu, useCan } from "@refinedev/core";
import { UnorderedListOutlined } from "@ant-design/icons";
import { startCase } from "lodash";

interface NavbarMenuItemProps {
  item: ITreeMenu;
}

export const NavbarMenuItem = ({ item }: NavbarMenuItemProps): any => {
  const childrenToRender = item?.children
    ?.map((item: ITreeMenu) => NavbarMenuItem({ item }))
    ?.filter(Boolean);

  if (item?.children?.length > 0 && childrenToRender?.length === 0) {
    return null;
  }

  if (item?.children?.length > 0) {
    return {
      key: item?.key,
      name: item?.identifier || item?.name,
      label: item?.meta?.label ?? startCase(item?.name),
      icon: item?.meta?.icon ?? <UnorderedListOutlined />,
      children: childrenToRender,
    };
  }

  return NavbarMenuChild({ item });
};

const NavbarMenuChild = ({ item }: { item: ITreeMenu }) => {
  const { data } = useCan({
    resource: item?.name || "",
    action: item?.meta?.defaultAction || "list",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  if (!!data?.can) {
    return {
      key: item?.key,
      name: item?.identifier || item?.name,
      label: item?.meta?.label ?? startCase(item?.name),
      icon: item?.meta?.icon,
    };
  }

  return null;
};
