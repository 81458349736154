import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  useInvalidate,
  useList,
  useResource,
} from "@refinedev/core";
import { Create, UseFormProps, useForm } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { FormCard } from "components/organisms";
import { ButtonProps, Form } from "antd";

interface TransactionCreateProps extends IResourceComponentsProps {
  saveButtonProps?: ButtonProps;
  transactionType?: number;
}

export const TransactionCreate: React.FC<TransactionCreateProps> = ({
  saveButtonProps,
  transactionType,
}) => {
  const { resource } = useResource();
  const invalidate = useInvalidate();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };

  if (resource?.meta?.defaultAction === "create") {
    useFormProps = {
      ...useFormProps,
      onMutationSuccess: () => {
        formProps?.form?.resetFields();
        invalidate({
          resource: resource?.identifier || resource?.name || "",
          invalidates: ["all"],
        });
      },
    };
  }

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    formLoading,
  } = useForm(useFormProps);

  const productIdName = transactionType === 3 ? "productFromId" : "productId";

  const productId = Form.useWatch(productIdName, formProps.form);

  const getInitialDate = useList({
    resource: "products/settlement-date",
    filters: [
      { field: "transactionTypeId", operator: "eq", value: transactionType },
      { field: "productId", operator: "eq", value: productId },
    ],
    pagination: { mode: "off" },
    queryOptions: { enabled: productId !== undefined },
  });

  let initialDate: any = getInitialDate?.data?.data;

  useEffect(() => {
    if (formProps?.form && getInitialDate?.data?.data) {
      formProps.form.setFieldsValue({
        settlementDate: initialDate?.settlementDate || "",
      });
    }
  }, [getInitialDate, formProps?.form, initialDate]);

  return (
    <Create
      key={resource?.identifier || resource?.name}
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading,
        ...saveButtonProps,
      }}
    >
      <FormCard
        key={resource?.identifier || resource?.name}
        formProps={formProps}
        resource={resource}
      />
    </Create>
  );
};
