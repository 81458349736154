import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IUserState {
  id?: number;
  name?: string;
  email?: string;
  role?: string;
  createdAt?: string;
  updatedAt?: string;
  groupId?: number;
  isEmailVerified?: boolean;
  emailVerifiedAt?: string;
  userGroup?: any;
  avatar?: string;
}

const initialState: IUserState = {
  id: 0,
  name: "",
  email: "",
  role: "",
  createdAt: "",
  updatedAt: "",
  groupId: 0,
  isEmailVerified: false,
  emailVerifiedAt: "",
  userGroup: undefined,
  avatar: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserState>) => {
      Object.entries(action?.payload)?.forEach(([key, value]) => {
        if (value !== undefined && !key.includes("password")) {
          state[key as keyof IUserState] = value;
        }
      });
    },
    resetUser: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
