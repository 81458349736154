import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Form,
  Row,
  Col,
  Descriptions,
  Input,
  Table,
  Typography,
  Button,
  Radio,
  Alert,
  Space,
  Tag,
} from "antd";
import { DynamicDisplayItem } from "components/molecules";
import { IResourceFormColumn, CustomResourceProps } from "interfaces";
import { startCase } from "lodash";
import {
  useTranslate,
  BaseRecord,
  useNotification,
  useApiUrl,
} from "@refinedev/core";
import type { ColumnsType } from "antd/es/table";
import { ScheduleOutlined, ReloadOutlined } from "@ant-design/icons";
import { axiosInstance } from "utils";

interface KycFormProps {
  resource?: CustomResourceProps;
  form: any;
  revisionForms: any;
  setRevisionForms: (value: any) => void;
  objectData: any;
  setIsApproved: (value: boolean) => void;
}

export const KycForm: React.FC<KycFormProps> = ({
  resource,
  form,
  revisionForms,
  setRevisionForms,
  objectData,
  setIsApproved,
}) => {
  Form.useWatch([], form);
  const translate = useTranslate();
  const { open } = useNotification();
  const [isTableShown, setIsTableShown] = useState<boolean>(false);
  const [tableData, setTableData] = useState<BaseRecord[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sidIfuaLoading, setSidIfuaLoading] = useState<boolean>(false);
  const [isDttotPass, setIsDttotPass] = useState<boolean>(false);
  const [isSinvestFetchSuccess, setIsSinvestFetchSuccess] =
    useState<boolean>(false);
  const [sid, setSid] = useState("");
  const [ifua, setIfua] = useState("");
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/outbound/pep-check`;
  const sidIfuaUrl = `${apiUrl}/kyc/sinvest/${objectData?.id}`;

  useEffect(() => {
    if (resource?.meta?.approveResource === "kyc/co-2") {
      setIsApproved(false);
    }
  }, [resource?.meta?.approveResource, setIsApproved]);

  const columns: ColumnsType<BaseRecord> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Alias Name",
      dataIndex: "aliasName",
      key: "aliasName",
      render: (data) => {
        return (
          <Space wrap>
            {data?.map((item: string) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Date of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      render: (data) => {
        return (
          <Space wrap>
            {data?.map((item: string) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Hit Category",
      dataIndex: "hitCategory",
      key: "hitCategory",
      render: (data) => {
        return (
          <Space wrap>
            {data?.map((item: string) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Match Score",
      dataIndex: "matchScore",
      key: "matchScore",
    },
    {
      title: "Match Status",
      dataIndex: "matchStatus",
      key: "matchStatus",
    },
    {
      title: "Profile ID",
      dataIndex: "profileId",
      key: "profileId",
    },
    {
      title: "Result ID",
      dataIndex: "resultId",
      key: "resultId",
    },
  ];

  const fetchData = () => {
    setIsLoading(true);
    axiosInstance
      .post(url, {
        name: [objectData?.firstName, objectData?.lastName]
          ?.filter(Boolean)
          ?.join(" "),
        subjectType: "PERSON",
        countryLocation: "IDN",
        referenceId: objectData?.id.toString(),
      })
      .then((res) => {
        const data = res?.data?.body;
        setTableData(data);
        setIsDttotPass(data ? false : true);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSIDIFUA = () => {
    // TODO: Delete this when bridging is no longer used
    if (objectData?.sid || objectData?.ifua) {
      setSid(objectData?.sid);
      setIfua(objectData?.ifua);

      form.setFieldsValue({
        sid: objectData?.sid,
        ifua: objectData?.ifua,
      });

      setIsSinvestFetchSuccess(true);
      setIsApproved(true);
      return;
    }
    setSidIfuaLoading(true);
    axiosInstance
      .get(sidIfuaUrl)
      .then((res) => {
        const data = res?.data?.body?.data;
        if (Object.keys(data).length === 0) {
          open?.({
            type: "error",
            message: translate(
              "notifications.sidIfuaEmpty",
              "Failed to get SID and IFUA"
            ),
          });
        } else {
          setSid(data?.sid);
          setIfua(data?.ifua);
          form.setFieldsValue({
            sid: data?.sid,
            ifua: data?.ifua,
            // checkDttot: true,
          });
        }
      })
      .catch((err) => {
        setSid("");
        setIfua("");
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => {
        setSidIfuaLoading(false);
        setIsSinvestFetchSuccess(true);
        setIsApproved(true);
      });
  };

  return (
    <Form
      form={form}
      name="KYCForm"
      labelCol={{ span: 4 }}
      layout="horizontal"
      labelWrap={true}
      colon={false}
      size="small"
    >
      {resource?.meta?.formColumns?.map(
        (formColumn: IResourceFormColumn, index: number) => {
          const checkboxName = `${formColumn?.key}_isRevised`;
          const isColumnRevised = revisionForms?.[checkboxName];
          const value = objectData?.[formColumn?.key || ""];
          let childValue = value;
          if (Array.isArray(formColumn?.optionsMeta?.labelKey)) {
            childValue = formColumn?.optionsMeta?.labelKey
              .map((key) => value?.[key])
              .filter((value) => !!value)
              .join(" ");
          } else if (formColumn?.optionsMeta?.labelKey) {
            childValue = value?.[formColumn?.optionsMeta?.labelKey];
          }

          return (
            <Form.Item key={formColumn?.key} style={{ margin: 0 }}>
              <Row gutter={[24, 8]}>
                <Col flex="auto">
                  <Form.Item>
                    <Descriptions
                      bordered
                      column={1}
                      size="small"
                      labelStyle={{ width: "200px" }}
                    >
                      <Descriptions.Item
                        key={formColumn?.key || index}
                        label={
                          formColumn?.label ||
                          startCase(formColumn?.key?.replace(/id$/gi, "")) ||
                          undefined
                        }
                      >
                        <DynamicDisplayItem
                          displayColumn={formColumn}
                          value={childValue}
                          displayType="show"
                        />
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>
                </Col>

                <Col flex="none">
                  <Checkbox
                    checked={isColumnRevised}
                    onChange={(e) =>
                      setRevisionForms({
                        ...revisionForms,
                        [checkboxName]: e.target.checked,
                      })
                    }
                  >
                    {translate("buttons.revision", "Revision")}
                  </Checkbox>
                </Col>

                {isColumnRevised && (
                  <Col xs={24}>
                    <Form.Item
                      name={formColumn?.key}
                      rules={[{ required: isColumnRevised }]}
                    >
                      <Input
                        size="large"
                        addonBefore="Revision Note"
                        disabled={!isColumnRevised}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form.Item>
          );
        }
      )}

      {resource?.meta?.approveResource === "kyc/co-2" && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingBottom: "12px",
            }}
          >
            <Typography
              style={{
                marginRight: "130px",
                display: "flex",
                textWrap: "nowrap",
                alignItems: "center",
              }}
            >
              {translate("page.kyc.checkDttot", `Check DTTOT?`)}
            </Typography>
            {!isTableShown && (
              <Button
                size="middle"
                type="primary"
                style={{
                  height: "40px",
                  width: "150px",
                }}
                icon={<ScheduleOutlined />}
                onClick={() => {
                  fetchData();
                  setIsTableShown(!isTableShown);
                }}
              >
                {translate("page.kyc.checkList", `Check di Daftar`)}
              </Button>
            )}
            {isTableShown && (
              <div
                style={{
                  width: "100%",
                  display: "flex",

                  justifyContent: "right",
                }}
              >
                <Button
                  size="middle"
                  type="primary"
                  style={{
                    height: "40px",
                    width: "150px",
                  }}
                  icon={<ReloadOutlined />}
                  onClick={() => fetchData()}
                >
                  {translate("buttons.reload", `Muat Ulang`)}
                </Button>
              </div>
            )}
          </div>
          {isTableShown &&
            !isLoading &&
            (tableData ? (
              <Alert
                message={translate(
                  "page.kyc.redCustomer",
                  `Yuk di cek dulu! Nama nasabahnya masuk dalam daftar DTTOT nih, cek yuk!`
                )}
                type="error"
                showIcon
              />
            ) : (
              <Alert
                message={translate(
                  "page.kyc.greenCustomer",
                  `Yey! Nama nasabah tidak ada dalam daftar DTTOT nih!`
                )}
                type="success"
                showIcon
                style={{ marginBottom: "12px" }}
              />
            ))}

          {isTableShown &&
            (isLoading ? (
              <Table
                style={{ marginTop: "10px", paddingBottom: "12px" }}
                columns={columns}
                loading={isLoading}
                scroll={{ x: "max-content" }}
                pagination={{ position: ["none"] }}
              />
            ) : (
              tableData && (
                <Table
                  style={{ marginTop: "10px", paddingBottom: "12px" }}
                  columns={columns}
                  dataSource={tableData}
                  loading={isLoading}
                  scroll={{ x: "max-content" }}
                  pagination={{ position: ["none"] }}
                />
              )
            ))}
          {tableData && (
            <Form.Item
              label={translate(
                "page.kyc.checkResult",
                `Hasil Pengecekan DTTOT?`
              )}
              name={"checkDttot"}
              key={"checkDttot"}
              labelAlign="left"
              labelCol={{ span: 5 }}
            >
              <Radio
                value={true}
                onChange={() => {
                  form.setFieldsValue({
                    checkDttot: true,
                  });
                  setIsDttotPass(true);
                }}
                checked={isDttotPass}
              >
                {translate("page.kyc.noDttot", `Lolos Daftar DTTOT`)}
              </Radio>
              <Radio
                value={false}
                onChange={() => {
                  form.setFieldsValue({
                    checkDttot: false,
                    sid: null,
                    ifua: null,
                  });
                  setIsDttotPass(false);
                  setIfua("");
                  setSid("");
                  setIsApproved(false);
                }}
                checked={!isDttotPass}
              >
                {translate("page.kyc.yesDttot", `Masuk Dalam Daftar DTTOT`)}
              </Radio>
            </Form.Item>
          )}

          {isTableShown && isDttotPass && (
            <div>
              {sid !== "" && ifua !== "" ? null : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginBottom: "12px",
                    justifyContent: "right",
                  }}
                >
                  <Button
                    size="middle"
                    type="primary"
                    style={{
                      height: "40px",
                      // width: "150px",
                    }}
                    // icon={<ReloadOutlined />}
                    onClick={() => fetchSIDIFUA()}
                    disabled={!isDttotPass}
                    loading={sidIfuaLoading}
                  >
                    Generate SID & IFUA
                    {/* {translate("buttons.reload", `Muat Ulang`)} */}
                  </Button>
                </div>
              )}

              <Descriptions
                bordered
                column={1}
                size="small"
                labelStyle={{ width: "200px" }}
                style={{ marginBottom: 24 }}
              >
                <Descriptions.Item
                  key={"sid"}
                  label={"SID"}
                  labelStyle={{ padding: "8px 12px 8px 12px" }}
                  style={{ padding: "0" }}
                >
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={"sid"}
                    key={"sid"}
                    initialValue={sid}
                  >
                    <Input
                      type={"text"}
                      style={{
                        width: "100%",
                        borderStyle: "none",
                        height: "38px",
                      }}
                      disabled={!isSinvestFetchSuccess || !!sid}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>

              <Descriptions
                bordered
                column={1}
                size="small"
                labelStyle={{ width: "200px" }}
                style={{ marginBottom: 24 }}
              >
                <Descriptions.Item
                  key={"ifua"}
                  label={"IFUA"}
                  labelStyle={{ padding: "8px 12px 8px 12px" }}
                  style={{ padding: "0" }}
                >
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name={"ifua"}
                    key={"ifua"}
                  >
                    <Input
                      type={"text"}
                      style={{
                        width: "100%",
                        borderStyle: "none",
                        height: "38px",
                      }}
                      disabled={!isSinvestFetchSuccess || !!ifua}
                    />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
