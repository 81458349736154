import { BaseRecord, useResource, useShow } from "@refinedev/core";
import {
  Col,
  Descriptions,
  Grid,
  Row,
  Space,
  TimelineItemProps,
  StepProps,
  Steps,
} from "antd";
import { DynamicDisplayItem } from "components/molecules";
import { IResourceDisplayColumn } from "interfaces";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { isImage } from "utils";
import { CustomTimelineItem } from "components/molecules";
import HoverableImage from "components/molecules/hoverableImage";

export interface ShowLayoutProps {
  objectName?: string;
  objectData?: BaseRecord;
  resourceName?: string;
  timelineItemProps?: TimelineItemProps[];
  stepProps?: StepProps[];
}

export const ShowLayout: React.FC<ShowLayoutProps> = ({
  objectName,
  objectData,
  resourceName,
  timelineItemProps,
  stepProps,
}) => {
  const placeholderUrl = `${process.env.PUBLIC_URL}/images/placeholder/placeholder-img.jpg`;
  const breakpoint = Grid.useBreakpoint();
  const isNotFullscreen =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;
  const { resources } = useResource();

  const [defaultColumns, setDefaultColumns] =
    useState<IResourceDisplayColumn[]>();
  const [imageColumns, setImageColumns] = useState<IResourceDisplayColumn[]>();
  const [tableColumns, setTableColumns] = useState<IResourceDisplayColumn[]>();

  // if resourceName is provided, use that for useShow
  const { queryResult } = useShow({
    resource: resourceName,
    id: objectData?.id,
    queryOptions: { enabled: !!resourceName },
  });
  const record = queryResult?.data?.data;

  useEffect(() => {
    const resourceInfo = resources?.find(
      ({ name, identifier, meta }) =>
        name === objectName || identifier === objectName
    );

    if (resourceInfo?.meta?.displayColumns) {
      setDefaultColumns(resourceInfo?.meta?.displayColumns);
    }
  }, [resources, objectName]);

  useEffect(() => {
    const allColumns = Object.keys(record || objectData || {});

    const blacklist = [
      // "Id",
      "Password",
      "Created At",
      "Updated At",
      "Deleted At",
      "Created By",
      "Updated By",
      "Deleted By",
    ];

    const filteredCols = allColumns?.filter(
      (displayColumn) => !blacklist.includes(startCase(displayColumn))
    );

    const imageCols = filteredCols?.filter((displayColumn) =>
      isImage((record || objectData)?.[displayColumn], { key: displayColumn })
    );

    const tableCols = filteredCols?.filter(
      (displayColumn) => !imageCols?.includes(displayColumn)
    );

    if (imageCols && imageCols?.length > 0) {
      const imageAttrs = imageCols?.map((col) => ({ key: col }));
      setImageColumns(imageAttrs);
    }

    if (tableCols && tableCols?.length > 0) {
      const tableAttrs = tableCols?.map((col) => ({ key: col }));
      setTableColumns(tableAttrs);
    }
  }, [objectName, objectData, record]);

  const imageSpan = 7;
  const tableSpan = 13;
  const timelineSpan = 4;
  // these values need to be 24 in total

  return (
    <Row gutter={[32, 32]} justify="space-between">
      {imageColumns && (
        <Col xs={24} md={imageSpan} xl={imageSpan}>
          <Row justify="center" style={{ position: "sticky", top: "100px" }}>
            <Space
              size="large"
              align="center"
              direction="vertical"
              style={{ width: "100%" }}
            >
              {imageColumns?.map((displayColumn, index) => (
                <HoverableImage
                  backgroundImage={`url(${
                    (record || objectData)?.[displayColumn?.key || ""]
                  })`}
                  key={
                    `${displayColumn?.key}_${displayColumn?.childKey}` || index
                  }
                  imageStyle={{
                    opacity: !!(record || objectData)?.[
                      displayColumn?.key || ""
                    ]
                      ? 1
                      : 0.5,
                  }}
                  preview={!!(record || objectData)?.[displayColumn?.key || ""]}
                  value={(record || objectData)?.[displayColumn?.key || ""]}
                  fallback={placeholderUrl}
                />
              ))}
            </Space>
          </Row>
        </Col>
      )}

      <Col
        xs={24}
        md={!!imageColumns ? tableSpan + timelineSpan : 24}
        xl={
          !!imageColumns && (timelineItemProps || [])?.length > 0
            ? tableSpan
            : !!imageColumns
            ? tableSpan + timelineSpan
            : (timelineItemProps || [])?.length > 0
            ? imageSpan + tableSpan
            : 24
        }
      >
        {stepProps && (
          <Steps
            size="small"
            labelPlacement={breakpoint.xl ? "horizontal" : "vertical"}
            items={stepProps}
            style={{ marginBottom: "32px" }}
          />
        )}

        <Descriptions bordered column={2}>
          {(defaultColumns || tableColumns)?.map((displayColumn, index) => {
            const value = (record || objectData)?.[displayColumn?.key || ""];
            if (
              ((value === undefined || value === null) &&
                displayColumn?.hideIfEmpty) ||
              displayColumn?.hideOnParent
            ) {
              return null;
            }
            return (
              <Descriptions.Item
                key={`${displayColumn?.key || index}_${
                  displayColumn?.childKey
                }`}
                span={!isNotFullscreen ? displayColumn?.span || 2 : 2}
                label={
                  displayColumn?.label ||
                  startCase(displayColumn?.key) ||
                  undefined
                }
                labelStyle={
                  displayColumn?.span === 1 ? { width: "100px" } : undefined
                }
              >
                <DynamicDisplayItem
                  displayColumn={displayColumn}
                  value={value}
                  displayType="show"
                />
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </Col>

      {timelineItemProps && (
        <Col xs={24} md={24} xl={timelineSpan}>
          <Row justify="center" style={{ position: "sticky", top: "100px" }}>
            <CustomTimelineItem items={timelineItemProps} />
          </Row>
        </Col>
      )}
    </Row>
  );
};
