import { BaseRecord, useResource } from "@refinedev/core";
import { Table } from "antd";
import { PaginationTotal } from "components/atoms";
import { DynamicDisplayItem } from "components/molecules";
import { IResourceDisplayColumn } from "interfaces";
import { startCase } from "lodash";
import React, { useState, useEffect } from "react";

export interface ShowTableLayoutProps {
  objectName?: string;
  objectData?: BaseRecord[];
}

export const ShowTableLayout: React.FC<ShowTableLayoutProps> = ({
  objectName,
  objectData,
}) => {
  const { resources } = useResource();
  const [displayColumns, setDisplayColumns] =
    useState<IResourceDisplayColumn[]>();

  useEffect(() => {
    const resourceInfo = resources?.find(
      ({ name, identifier, meta }) =>
        name === objectName || identifier === objectName
    );

    if (resourceInfo?.meta?.displayColumns) {
      setDisplayColumns(resourceInfo?.meta?.displayColumns);
    } else {
      const defaultColumns = Object.keys(objectData?.[0] || {})?.map((col) => ({
        key: col,
      }));
      setDisplayColumns(defaultColumns);
    }
  }, [resources, objectData, objectName]);

  return (
    <Table
      dataSource={objectData}
      rowKey="id"
      size="small"
      scroll={{ x: true }}
      bordered
      pagination={{
        showSizeChanger: true,
        showTotal: (total, range) => (
          <PaginationTotal total={total} range={range} />
        ),
      }}
    >
      {displayColumns?.map((displayColumn, index) => (
        <Table.Column
          key={displayColumn?.key}
          title={displayColumn?.label || startCase(displayColumn?.key)}
          dataIndex={displayColumn?.key}
          fixed={index <= 1 ? "left" : undefined}
          ellipsis
          render={(value) => DynamicDisplayItem({ displayColumn, value })}
        />
      ))}
    </Table>
  );
};
