import React, { useState, useEffect } from "react";
import { BaseKey, BaseRecord, CrudFilters, useResource } from "@refinedev/core";
import { IResourceFormColumn } from "interfaces";
import { startCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setTemp } from "redux/slices/tempSlice";
import { ListContainer } from "components/organisms";
import { RootState } from "redux/store";
import { FormProps } from "antd";

export interface DynamicSelectTableProps {
  formColumn?: IResourceFormColumn;
  formProps?: FormProps;
  selectionType?: "radio" | "checkbox";
  onChange?: (value: any) => void;
}

export const DynamicSelectTable: React.FC<DynamicSelectTableProps> = ({
  formColumn,
  formProps,
  selectionType = "radio",
  onChange,
}) => {
  const meta = formColumn?.optionsMeta;
  const { resource } = useResource(meta?.resourceName);

  const dispatch = useDispatch();
  const dynamicSelectTableValues = useSelector(
    (state: RootState) => state.temp.dynamicSelectTableValues
  );
  useEffect(() => {
    dispatch(setTemp({ dynamicSelectTableValues: {} }));
  }, [resource?.name, resource?.identifier, dispatch]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filterFromRedux, setFilterFromRedux] = useState<CrudFilters>();

  useEffect(() => {
    const formKey = meta?.filterFromRedux?.formKey;
    // formKey contains value like "user.id", i need to use reduce function to get the value from redux
    const valueFromRedux = formKey?.split(".").reduce((prev, curr) => {
      return prev?.[curr];
    }, dynamicSelectTableValues);

    if (valueFromRedux === undefined) {
      setFilterFromRedux(undefined);
    } else {
      setFilterFromRedux([
        {
          field: meta?.filterFromRedux?.filterKey || "search",
          operator: "eq",
          value: valueFromRedux,
        },
      ]);
    }
  }, [dynamicSelectTableValues, meta]);

  const setValuesForRadio = (record: BaseRecord) => {
    if (!record?.id) return;
    const newSelectedRowKeys = [record?.id];
    setSelectedRowKeys(newSelectedRowKeys);

    const value = (meta?.valueKey || "id")?.split(".").reduce((prev, curr) => {
      return prev?.[curr];
    }, record);
    onChange?.(value);

    const valueForRedux = {
      ...dynamicSelectTableValues,
      [formColumn?.key || ""]: record,
    };
    dispatch(setTemp({ dynamicSelectTableValues: valueForRedux }));

    if (
      meta?.fillOtherColumns &&
      Object.keys(meta?.fillOtherColumns).length !== 0
    ) {
      Object.keys(meta?.fillOtherColumns).forEach((currentKey) => {
        const dataKey = meta?.fillOtherColumns?.[currentKey];
        if (dataKey) {
          const currentValue = dataKey.split(".").reduce((prev, curr) => {
            return prev?.[curr];
          }, record);
          formProps?.form?.setFieldValue(currentKey, currentValue);
        }
      });
    }
  };

  const setValuesForCheckbox = (records: BaseRecord[]) => {
    if (!(records?.length > 0)) return;
    const newSelectedRowKeys = records
      ?.map((record) => record?.id as BaseKey)
      ?.filter(Boolean);
    setSelectedRowKeys(newSelectedRowKeys);

    const values = records?.map((record) => {
      return (meta?.valueKey || "id")?.split(".").reduce((prev, curr) => {
        return prev?.[curr];
      }, record);
    });
    onChange?.(values);

    const valueForRedux = {
      ...dynamicSelectTableValues,
      [formColumn?.key || ""]: records,
    };
    dispatch(setTemp({ dynamicSelectTableValues: valueForRedux }));
  };

  return (
    <ListContainer
      key={resource?.identifier || resource?.name}
      title={
        formColumn?.label || startCase(formColumn?.key?.replace(/id$/gi, ""))
      }
      resourceName={meta?.resourceName || resource?.name || ""}
      disableActions
      disableFilter={meta?.disableFilter}
      disableLoading={!!meta?.filterFromRedux?.required && !filterFromRedux}
      rowSelection={{
        selectedRowKeys,
        type: selectionType,
        onChange: (selectedRowKeys: BaseKey[], selectedRows: BaseRecord[]) => {
          switch (selectionType) {
            case "checkbox":
              setValuesForCheckbox(selectedRows);
              break;
            case "radio":
              setValuesForRadio(selectedRows?.[0]);
              break;
            default:
              break;
          }
        },
      }}
      onRow={(record: BaseRecord) => ({
        onClick: () => {
          switch (selectionType) {
            case "checkbox":
              setValuesForCheckbox([record]);
              break;

            case "radio":
              setValuesForRadio(record);
              break;
            default:
              return;
          }
        },
      })}
      useTableProps={{
        queryOptions: {
          enabled:
            !!meta?.resourceName &&
            !formColumn?.isDisabled &&
            !(!!meta?.filterFromRedux?.required && !filterFromRedux),
        },
        filters: {
          initial: meta?.initialFilters,
          permanent: filterFromRedux,
        },
      }}
    />
  );
};
