import React, { useEffect, useMemo, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useUpdate,
  useNavigation,
  useResource,
  useCan,
  useParsed,
  HttpError,
  useList,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { ApproveButtons } from "components/molecules";
import { Card, Descriptions, Skeleton, Space, TimelineItemProps } from "antd";
import {
  CustomErrorComponent,
  ShowItemRecursor,
  ShowLayout,
} from "components/organisms";
import { mapStatus, useChildRecords } from "utils";
import { UploadTransactionFiles } from "./components/uploadTransactionFiles";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";
import { Text } from "components/atoms";
import { formatThousands } from "utils/number";
import { TRANSACTION_TYPE } from "constants/transaction";

export const TransactionApprove: React.FC<IResourceComponentsProps> = () => {
  const { mutate, isLoading: isUpdateLoading } = useUpdate();
  const { list } = useNavigation();
  const { id, pathname } = useParsed();
  const { resource } = useResource(
    pathname?.split("/").filter(Boolean).slice(0, -2).join("/")
  );

  const { queryResult } = useShow({
    resource: resource?.meta?.showResource || resource?.name,
    id,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const customerType =
    record?.unitHolderId?.[4] === "I" ? "retail" : "institution";
  const transactionType = !!record?.switchingCode
    ? "switch"
    : record?.transactionType?.transactionTypeName?.toLowerCase();
  const paymentMethod = record?.paymentMethod?.paymentMethodName?.toLowerCase();

  const isStatusPending = record?.transactionStatus.id === 1;
  const isTransactionREDOrSWT =
    record?.transactionType.id === TRANSACTION_TYPE.RED ||
    record?.transactionType.id === TRANSACTION_TYPE.SWT;

  const userPortofolio = useList({
    resource: "customers/user-portfolios",
    pagination: { pageSize: 500 },
    queryOptions: { enabled: isStatusPending && isTransactionREDOrSWT },
    filters: [
      {
        field: "search",
        operator: "eq",
        value: record?.unitHolderId,
      },
    ],
  });
  const isUserPortfolioLoading = isLoading || userPortofolio.isLoading;

  const currentPortofolioProduct = useMemo(() => {
    return userPortofolio?.data?.data
      ? userPortofolio.data.data.find(
          (item) => item.productId === record?.product?.id
        )
      : {};
  }, [userPortofolio, record]);

  const remainingAmount = useMemo(() => {
    const remainingBalance = {
      isNominal: record?.nominal > 0,
      amount: "0",
      isInsufficient: false,
      subtractor: "0",
      amountBefore: "0",
    };

    if (remainingBalance.isNominal) {
      const endingBalance =
        Number(currentPortofolioProduct?.endingBalance) || 0;
      const transactionNominal = record?.nominal || 0;

      remainingBalance.isInsufficient = endingBalance - transactionNominal < 0;
      remainingBalance.amount = `Rp. ${formatThousands(
        endingBalance - transactionNominal
      )}`;
      remainingBalance.amountBefore = `Rp. ${formatThousands(endingBalance)}`;
      remainingBalance.subtractor = `Rp. ${formatThousands(
        transactionNominal
      )}`;
    } else {
      const endingUnits = Number(currentPortofolioProduct?.endingUnit) || 0;
      const transactionUnits = Number(record?.unit) || 0;

      remainingBalance.isInsufficient = endingUnits - transactionUnits < 0;
      remainingBalance.amount = formatThousands(endingUnits - transactionUnits);
      remainingBalance.amountBefore = formatThousands(endingUnits);
      remainingBalance.subtractor = formatThousands(transactionUnits);
    }

    return remainingBalance;
  }, [currentPortofolioProduct, record]);

  const status = (
    record?.approvalStatus?.approvalStatusName ||
    record?.kycStatus?.kycStatusName ||
    record?.transactionStatus?.transactionStatusName
  )?.toLowerCase();

  const sellingAgent = record?.sellingAgent?.sellingAgentCode?.toLowerCase();

  const needsPaymentProof =
    status?.includes("pending") &&
    transactionType?.includes("sub") &&
    paymentMethod?.includes("manual") &&
    !record?.switchingCode &&
    !record?.paymentProofFile;

  const needsSupportingDocument =
    status?.includes("pending") &&
    sellingAgent?.includes("hp002") &&
    !record?.supportingDocumentFile;

  // handle the population of the data's status
  const statusData = useChildRecords({
    resourceName: resource?.meta?.statusResource,
    foreignKey: resource?.meta?.statusForeignKey,
    parentId: record?.id,
  });

  const [timelineItemProps, setTimelineItemProps] =
    useState<TimelineItemProps[]>();

  useEffect(() => {
    if (statusData && statusData.length > 0)
      setTimelineItemProps(mapStatus({ data: statusData }));
    else setTimelineItemProps(undefined);
  }, [resource, statusData, record]);

  // enable or disable the approval button
  const canApprove = useCan({
    resource: resource?.meta?.approveResource || `${resource?.name}/approval`,
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  const [needsApproval, setNeedsApproval] = React.useState(false);

  useEffect(() => {
    const needsApproval =
      status === (resource?.meta?.statusToApprove || "pending") &&
      !(status === "goodfund" && paymentMethod?.includes("virtual"));
    setNeedsApproval(needsApproval);
  }, [status, record, resource, paymentMethod]);

  // handle the approval button
  const [remark, setRemark] = useState<string>();
  const handleApproval = ({ isApproved }: { isApproved: boolean }) => {
    let values = { isApproved };
    if (!!resource?.meta?.rejectRemarkKey) {
      values = { ...values, [resource?.meta?.rejectRemarkKey]: remark };
    }

    mutate(
      {
        resource:
          resource?.meta?.approveResource || `${resource?.name}/approval`,
        id: id || "",
        values: values,
        meta: { method: "patch" },
      },
      {
        onSuccess: () => {
          list(resource?.identifier || resource?.name || "");
        },
      }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      {(needsPaymentProof || needsSupportingDocument) && (
        <UploadTransactionFiles
          {...{ needsPaymentProof, needsSupportingDocument, customerType }}
        />
      )}

      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          onBack: () => list(resource?.identifier || resource?.name || ""),
          breadcrumb: undefined,
          extra: <CustomRefreshButton />,
        }}
      >
        <ShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
          timelineItemProps={timelineItemProps}
        />
      </Show>

      <ShowItemRecursor
        parentName={resource?.identifier || resource?.name}
        parentData={record}
      />
      {isStatusPending && isTransactionREDOrSWT && (
        <Card bordered={false}>
          <Descriptions column={1} style={{ marginTop: "16px" }}>
            <Descriptions.Item
              key="amount-before"
              labelStyle={{
                color:
                  remainingAmount.isInsufficient && !isUserPortfolioLoading
                    ? "red"
                    : undefined,
              }}
              label={`${
                remainingAmount.isNominal ? "Balance" : "Units"
              } Before Transaction`}
            >
              {isUserPortfolioLoading ? (
                <Skeleton.Input size="small" active />
              ) : (
                <Text
                  style={{
                    color: remainingAmount.isInsufficient ? "red" : undefined,
                  }}
                  strong
                >
                  {remainingAmount.amountBefore}
                </Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              key="total-substractor"
              label={`Estimated Transaction ${
                remainingAmount.isNominal ? "Nominal" : "Units"
              }`}
            >
              {isUserPortfolioLoading ? (
                <Skeleton.Input style={{ width: "100%" }} size="small" active />
              ) : (
                <Text strong>{remainingAmount.subtractor}</Text>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              key="remaining-amount"
              label={`Estimated Remaining ${
                remainingAmount.isNominal ? "Balance" : "Units"
              } After Transaction`}
              labelStyle={{
                color:
                  remainingAmount.isInsufficient && !isUserPortfolioLoading
                    ? "red"
                    : undefined,
              }}
            >
              {isUserPortfolioLoading ? (
                <Skeleton.Input size="small" active />
              ) : (
                <Text
                  style={{
                    color: remainingAmount.isInsufficient ? "red" : undefined,
                  }}
                  strong
                >
                  {remainingAmount.amount}
                </Text>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}

      <ApproveButtons
        onClick={handleApproval}
        onRejectRemark={
          !!resource?.meta?.rejectRemarkKey ? setRemark : undefined
        }
        disableApprove={
          !needsApproval ||
          !canApprove?.data?.can ||
          status?.toLowerCase() === "goodfund"
        }
        disableReject={!needsApproval || !canApprove?.data?.can}
        isLoading={isUpdateLoading}
        remarkResource={{
          key: "remark",
          label: "Remark",
          optionsMeta: {
            resourceName: "static/reject-reasons",
            labelKey: "reason",
          },
          type: "select",
        }}
      />
    </Space>
  );
};
