import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IGeneralState {
  colorMode?: string;
  navbarMode?: "vertical" | "horizontal";
  apiUrl?: string;
  dataProviderName?: string;
}

const initialState: IGeneralState = {
  colorMode: "light",
  navbarMode: "vertical",
  apiUrl:
    process.env.REACT_APP_BACKOFFICE_API_URL ||
    "http://localhost:4000/api/v1/backoffice",
  dataProviderName: "default",
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setGeneral: (state, action: PayloadAction<IGeneralState>) => {
      Object.entries(action?.payload)?.forEach(([key, value]) => {
        if (value !== undefined) state[key as keyof IGeneralState] = value;
      });
    },
    resetGeneral: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setGeneral, resetGeneral } = generalSlice.actions;

export default generalSlice.reducer;
