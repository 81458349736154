import {
  DatabaseOutlined,
  GlobalOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import {
  ExternalTokenCreate,
  PermissionList,
  UserEdit,
  UserShow,
} from "components";
import { SearchOutlined, TeamOutlined } from "@ant-design/icons";
import { CustomResourceProps } from "interfaces";
import dayjs from "dayjs";

const userResource: CustomResourceProps = {
  name: "users",
  identifier: "general/users",
  meta: {
    label: "Users",
    icon: <TeamOutlined />,
    canList: true,
    canShow: true,
    showComponent: <UserShow />,
    editComponent: <UserEdit />,
    canCreate: true,
    canEdit: true,
    editMethod: "patch",
    // statusResource: "users/histories",
    // statusForeignKey: "userId",
    // canApprove: true,
    canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "name" },
      { key: "email" },
      { key: "userGroup", hideOnRecursor: true },
      { key: "groupProduct", label: "Product Group" },
      { key: "branch" },
      { key: "isActive", label: "Active" },
      { key: "isEmailVerified", label: "Email Verified", span: 1 },
      { key: "emailVerifiedAt", span: 1 },
      { key: "lastLoginAt", span: 1 },
      { key: "lastLoginIp", label: "Last Login IP", span: 1 },
    ],
    formColumns: [
      {
        key: "name",
        type: "text",
        requiredOnCreate: true,
      },
      {
        key: "email",
        type: "email",
        requiredOnCreate: true,
        hideOnEdit: true,
      },
      {
        key: "groupId",
        label: "User Group",
        type: "select",
        requiredOnCreate: true,
        // options: ["superAdmin", "fundAdmin", "csAdmin", "co1Admin", "co2Admin"],
        optionsMeta: {
          resourceName: "admin/user-groups",
          objectName: "userGroup",
          pagination: { mode: "server", pageSize: 100 },
        },
      },
      {
        key: "password",
        type: "password",
        requiredOnCreate: true,
      },
      {
        key: "confirmPassword",
        type: "confirmPassword",
        dependencies: ["password"],
        hideOnCreate: true,
      },
      {
        key: "groupProductId",
        label: "Product Group",
        type: "select",
        hideOnCreate: true,
        optionsMeta: {
          resourceName: "admin/group-products",
          pagination: { mode: "server", pageSize: 100 },
        },
      },
    ],
  },
};

const adminResources: CustomResourceProps[] = [
  {
    name: "admin/modules",
    identifier: "general/access-controls/modules",
    meta: {
      label: "Modules",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      editMethod: "patch",
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "module",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "description",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "requestType",
          type: "select",
          options: [
            { label: "All", value: "*" },
            { label: "List", value: "list" },
            { label: "Show", value: "show" },
            { label: "Create", value: "create" },
            { label: "Edit", value: "edit" },
            { label: "Delete", value: "delete" },
          ],
          requiredOnCreate: true,
        },
        {
          key: "endpoint",
          type: "text",
        },
        {
          key: "isBackofficeEndpoint",
          type: "boolean",
          requiredOnCreate: true,
        },
        {
          key: "parentModule",
          type: "text",
        },
      ],
    },
  },
  {
    name: "admin/user-groups",
    identifier: "general/access-controls/user-groups",
    meta: {
      label: "User Groups",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      editMethod: "patch",
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "name",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        { key: "information", type: "text", requiredOnCreate: true },
        { key: "status", type: "boolean", requiredOnCreate: true },
        {
          key: "isAdmin",
          label: "Admin",
          type: "boolean",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "admin/roles",
    identifier: "general/access-controls/user-permissions",
    meta: {
      label: "User Permissions",
      canList: true,
      listComponent: <PermissionList />,
      // canShow: true,
      canCreate: true,
      canEdit: true,
      editMethod: "patch",
      // canApprove: true,
      canDelete: true,
      listToggleResource: "admin/roles/status",
      pagination: { pageSize: 1000 },
      initialFilters: [{ field: "groupId", operator: "eq", value: 1 }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "groupId",
          label: "User Group",
          type: "select",
          optionsMeta: {
            resourceName: "admin/user-groups",
            pagination: { mode: "server", pageSize: 100 },
          },
          initialValue: 1,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "userGroup", hideOnRecursor: true },
        {
          key: "modules",
          childKey: "module",
          label: "Module",
          type: "text",
          hideOnRecursor: true,
        },
        {
          key: "modules",
          childKey: "endpoint",
          label: "Endpoint",
          type: "text",
          hideOnRecursor: true,
        },
        { key: "description" },
        // { key: "status", canSort: true },
      ],
      formColumns: [
        {
          key: "groupId",
          label: "User Group",
          type: "select",
          optionsMeta: {
            resourceName: "admin/user-groups",
            objectName: "userGroup",
          },
          requiredOnCreate: true,
        },
        {
          key: "moduleId",
          type: "select",
          optionsMeta: {
            resourceName: "admin/modules",
            objectName: "modules",
          },
          requiredOnCreate: true,
        },
        {
          key: "description",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "admin/user-branches",
    identifier: "general/access-controls/user-branches",
    meta: {
      label: "User Branches",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      editMethod: "patch",
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "userId",
          type: "select",
          optionsMeta: { resourceName: "users" },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "user", isSortable: false },
        { key: "branch" },
      ],
      formColumns: [
        {
          key: "userId",
          type: "select",
          optionsMeta: {
            resourceName: "users",
          },
          requiredOnCreate: true,
        },
        {
          key: "branchId",
          type: "select",
          optionsMeta: {
            resourceName: "branches",
          },
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "admin/group-products",
    identifier: "general/access-controls/product-groups",
    meta: {
      label: "Product Groups",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      editMethod: "patch",
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "groupProductName",
          type: "text",
          requiredOnCreate: true,
        },
        { key: "remark", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "admin/group-product-lists",
    identifier: "general/access-controls/product-list-groups",
    meta: {
      label: "Product Permissions",
      canList: true,
      // canShow: true,
      canCreate: true,
      canEdit: true,
      editMethod: "patch",
      // canApprove: true,
      canDelete: true,
      listToggleResource: "admin/group-product-lists/status",
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "groupProductId",
          label: "Product Group",
          type: "select",
          optionsMeta: { resourceName: "admin/group-products" },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "product", hideOnRecursor: true },
        { key: "groupProduct" },
        { key: "status" },
      ],
      formColumns: [
        {
          key: "groupProductId",
          type: "select",
          optionsMeta: { resourceName: "admin/group-products" },
          requiredOnCreate: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "external/access-token",
    identifier: "general/access-controls/external-access",
    meta: {
      label: "External Access",
      canList: true,
      canShow: true,
      canCreate: true,
      createComponent: <ExternalTokenCreate />,
      canEdit: true,
      editMethod: "patch",
      canApprove: true,
      rejectRemarkKey: "remark",
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "name" },
        { key: "requestType" },
        { key: "endpoint" },
        { key: "token", span: 1 },
        { key: "expiredAt", span: 1 },
        { key: "approvalStatus", span: 1 },
        { key: "remark", span: 1 },
        { key: "approvedAt", span: 1 },
        { key: "approvedBy", span: 1 },
      ],
      formColumns: [
        { key: "name", type: "text", requiredOnCreate: true },
        { key: "endpoint", type: "text", requiredOnCreate: true },
        {
          key: "requestType",
          type: "select",
          options: [
            { label: "GET", value: "GET" },
            { label: "POST", value: "POST" },
            { label: "PUT", value: "PUT" },
            { label: "PATCH", value: "PATCH" },
            { label: "DELETE", value: "DELETE" },
          ],
          requiredOnCreate: true,
        },
        { key: "expiredAt", type: "date", requiredOnCreate: true },
      ],
    },
  },
];

const generalStaticDataResources: CustomResourceProps[] = [
  {
    name: "static/banks",
    identifier: "general/static-data/banks",
    meta: {
      label: "Banks",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      // isFormData: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "bankSiarId",
          label: "Bank SIAR ID",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "bankCountryId",
          label: "Country",
          type: "select",
          optionsMeta: { resourceName: "static/countries" },
          requiredOnCreate: true,
        },
        {
          key: "bankSwiftCode",
          label: "Swift Code",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "bankName",
          label: "Name",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "isAutoDebet",
          label: "Auto Debet",
          type: "boolean",
          requiredOnCreate: true,
        },
        { key: "bankLogo", type: "image" },
        { key: "bankBiId", label: "Bank BI ID", type: "text" },
        { key: "bankIssuerCode", label: "Issuer Code", type: "text" },
        { key: "bankPlacementCode", label: "Placement Code", type: "text" },
        {
          key: "mainBankBranchId",
          type: "select",
          optionsMeta: { resourceName: "static/bank-branches" },
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "bankSiarId", label: "Bank SIAR ID" },
        { key: "bankCountry", label: "Country" },
        { key: "bankSwiftCode", label: "Swift Code" },
        { key: "bankName", label: "Name" },
        { key: "isAutoDebet", label: "Auto Debet" },
        { key: "bankLogo", label: "Logo" },
        { key: "bankBiId", label: "Bank BI ID" },
        { key: "bankIssuerCode", label: "Issuer Code" },
        { key: "bankPlacementCode", label: "Placement Code" },
        { key: "mainBankBranch" },
      ],
    },
  },
  {
    name: "static/bank-branches",
    identifier: "general/static-data/bank-branches",
    meta: {
      label: "Bank Branches",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      // isFormData: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "bankSiarId",
          label: "Bank SIAR ID",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "bankBranchSiarId",
          label: "Branch SIAR ID",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "bankBranchName",
          label: "Branch Name",
          type: "text",
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "bankSiarId", label: "Bank SIAR ID" },
        { key: "bankBranchSiarId", label: "Branch SIAR ID" },
        { key: "bankBranchName", label: "Branch Name" },
      ],
    },
  },
  {
    name: "static/va-bank-codes",
    identifier: "general/static-data/va-bank-codes",
    meta: {
      label: "VA Bank Codes",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      // isFormData: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "vaBankCode", label: "VA Bank Code" },
        { key: "bank", hideOnRecursor: true },
        { key: "bankName", label: "Bank Name" },
        { key: "isVaPayment", label: "VA Payment" },
      ],
      formColumns: [
        {
          key: "vaBankCode",
          label: "VA Bank Code",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "bankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
          requiredOnCreate: true,
        },
        { key: "bankName", type: "text", requiredOnCreate: true },
        {
          key: "isVaPayment",
          label: "VA Payment",
          type: "boolean",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/advance-ai-bank-codes",
    identifier: "general/static-data/advance-ai-bank-codes",
    meta: {
      label: "Advance AI Bank Codes",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      // isFormData: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "bankCode", label: "Code", span: 1 },
        { key: "bankName", label: "Name", span: 1 },
        { key: "bank", hideOnRecursor: true },
      ],
      formColumns: [
        { key: "bankCode", type: "text", requiredOnCreate: true },
        {
          key: "bankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
          requiredOnCreate: true,
        },
        { key: "bankName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/cities",
    identifier: "general/static-data/cities",
    meta: {
      label: "Cities",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "ariaCode",
          label: "ARIA Code",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "provinceAriaCode",
          label: "Province ARIA Code",
          type: "select",
          optionsMeta: {
            resourceName: "static/provinces",
            objectName: "province",
            valueKey: "ariaCode",
          },
          requiredOnCreate: true,
        },
        {
          key: "cityName",
          label: "City",
          type: "text",
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "ariaCode", label: "ARIA Code" },
        { key: "province", hideOnRecursor: true },
        { key: "cityName", label: "City" },
      ],
    },
  },
  {
    name: "static/provinces",
    identifier: "general/static-data/provinces",
    meta: {
      label: "Provinces",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "ariaCode",
          label: "ARIA Code",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "provinceName",
          label: "Province",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "countryId",
          type: "select",
          optionsMeta: { resourceName: "static/countries" },
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "ariaCode", label: "ARIA Code" },
        { key: "provinceName", label: "Province" },
        { key: "country", hideOnRecursor: true },
      ],
    },
  },
  {
    name: "static/countries",
    identifier: "general/static-data/countries",
    meta: {
      label: "Countries",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "dialCode", type: "text", requiredOnCreate: true },
        {
          key: "isoCode",
          label: "ISO Code",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "countryName",
          label: "Country",
          type: "text",
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "dialCode" },
        { key: "isoCode", label: "ISO Code" },
        { key: "countryName", label: "Country" },
      ],
    },
  },
  {
    name: "static/currencies",
    identifier: "general/static-data/currencies",
    meta: {
      label: "Currencies",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "currencyCode", type: "text", requiredOnCreate: true },
        { key: "currencyName", type: "text", requiredOnCreate: true },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "currencyCode", label: "Code" },
        { key: "currencyName", label: "Name" },
      ],
    },
  },
  {
    name: "static/statement-types",
    identifier: "general/static-data/statement-types",
    meta: {
      label: "Statement Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "statementTypeCode",
          label: "Code",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "statementTypeName",
          label: "Name",
          type: "text",
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "statementTypeCode", label: "Code" },
        { key: "statementTypeName", label: "Name" },
      ],
    },
  },
  {
    name: "static/upload-kyc-types",
    identifier: "general/static-data/upload-kyc-types",
    meta: {
      label: "Upload KYC Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "uploadKycTypeCode", type: "number", requiredOnCreate: true },
        { key: "uploadKycTypeName", type: "text", requiredOnCreate: true },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "uploadKycTypeCode", label: "Code" },
        { key: "uploadKycTypeName", label: "Name" },
      ],
    },
  },
  {
    name: "static/investment-managers",
    identifier: "general/static-data/investment-managers",
    meta: {
      label: "Investment Managers",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "investmentManagerCode",
          label: "Code",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "investmentManagerName",
          label: "Name",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "description",
          type: "textarea",
          requiredOnCreate: true,
          span: 2,
        },
        { key: "address", type: "textarea", requiredOnCreate: true },
        { key: "phoneNumber", type: "number", requiredOnCreate: true },
        { key: "faxNumber", type: "number", requiredOnCreate: true },
        {
          key: "countryId",
          type: "select",
          optionsMeta: { resourceName: "static/countries" },
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "investmentManagerCode", label: "Code" },
        { key: "investmentManagerName", label: "Name" },
        { key: "description", span: 2 },
        { key: "address", span: 2 },
        { key: "phoneNumber" },
        { key: "faxNumber" },
        { key: "country", hideOnRecursor: true },
      ],
    },
  },
  {
    name: "static/public-holidays",
    identifier: "general/static-data/public-holidays",
    meta: {
      label: "Public Holidays",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "publicHolidayDate",
          label: "Date",
          type: "date",
          requiredOnCreate: true,
        },
        {
          key: "publicHolidayRemarks",
          label: "Remark",
          type: "text",
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "publicHolidayDate", label: "Date" },
        { key: "publicHolidayRemarks", label: "Remark" },
      ],
    },
  },
  {
    name: "static/sid-generation-statuses",
    identifier: "general/static-data/sid-generation-statuses",
    meta: {
      label: "SID Generation Status",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "sidGenerationStatusCode",
          label: "Code",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "sidGenerationStatusName",
          label: "Name",
          type: "text",
          requiredOnCreate: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "sidGenerationStatusCode", label: "Code" },
        { key: "sidGenerationStatusName", label: "Name" },
      ],
    },
  },
];

export const generalResources: CustomResourceProps[] = [
  {
    name: "general",
    meta: {
      icon: <GlobalOutlined />,
      label: "General",
    },
  },

  {
    ...userResource,
    meta: {
      ...userResource.meta,
      parent: userResource?.meta?.parent || "general",
    },
  },

  {
    name: "access-controls",
    meta: {
      icon: <SafetyOutlined />,
      parent: "general",
    },
  },
  ...adminResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "access-controls",
    },
  })),

  {
    name: "static-data",
    meta: {
      icon: <DatabaseOutlined />,
      parent: "general",
    },
  },

  ...generalStaticDataResources.map((resource) => ({
    ...resource,
    meta: { ...resource.meta, parent: resource?.meta?.parent || "static-data" },
  })),
];
