import React from "react";
import { BaseRecord, useList, useResource } from "@refinedev/core";
import { Card, Descriptions, Divider, Space, Typography } from "antd";
import { DescriptionsProps } from "antd/lib";
import dayjs from "dayjs";
import { Show } from "@refinedev/antd";
import { ShowTableLayout } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface ResultCardProps {
  feeHistories?: BaseRecord[];
  setFeeHistories?: (value?: BaseRecord[]) => void;
  fee?: number;
  unit?: number;
}

export const ResultCard: React.FC<ResultCardProps> = ({
  feeHistories = [],
  setFeeHistories,
  fee = 0,
  unit = 0,
}) => {
  const { resource } = useResource();
  const dynamicSelectTableValues = useSelector(
    (state: RootState) => state.temp.dynamicSelectTableValues
  );

  const getNav = useList({
    resource: "periodic/navs",
    pagination: { pageSize: 1 },
    queryOptions: { enabled: feeHistories?.length > 0 },
    sorters: [{ field: "date", order: "desc" }],
    filters: [
      {
        field: "productId",
        operator: "eq",
        value: feeHistories?.[0]?.productId,
      },
    ],
  });
  const nav = getNav?.data?.data?.[0]?.nav || 0;
  const currentDate = dayjs().format("D MMMM YYYY");
  const name = dynamicSelectTableValues?.productId?.fullName || "";
  const nominal = (unit * nav || 0)?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  });

  const calculationResultItems: DescriptionsProps["items"] = [
    { key: "name", label: "Customer Name", children: name },
    { key: "nominal", label: "Redemption Nominal", children: nominal },
    { key: "date", label: "Redemption Date", children: currentDate },
    { key: "fee", label: "Redemption Fee", children: `${fee}%` },
    { key: "unit", label: "Redemption Unit", children: unit },
    { key: "nav", label: "Latest NAV", children: nav },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        headerProps={{
          extra: undefined,
          breadcrumb: undefined,
          onBack: () => setFeeHistories?.(undefined),
        }}
      >
        <ShowTableLayout objectData={feeHistories as BaseRecord[]} />
      </Show>

      <Card bordered={false}>
        <Descriptions
          column={2}
          title="Result"
          items={calculationResultItems}
        />
        <Divider />
        <Typography.Text type="secondary">Final Redemption Fee</Typography.Text>
        <Typography.Title
          level={3}
          style={{ marginTop: "8px" }}
          copyable={{ text: feeHistories?.[0]?.finalFee }}
        >
          {feeHistories?.[0]?.finalFee}%
        </Typography.Title>
      </Card>
    </Space>
  );
};
