import React from "react";
import {
  IResourceComponentsProps,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, SaveButton } from "@refinedev/antd";
import { FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";

export const RiskProfileCreate: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const translate = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm({
    resource: resource?.meta?.createResource || resource?.name,
    redirect: false,
  });

  return (
    <Create
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      goBack={false}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={{ ...saveButtonProps, loading: formLoading }}
      footerButtons={({ saveButtonProps }) => (
        <>
          <SaveButton
            {...saveButtonProps}
            type="primary"
            style={{ marginRight: 8 }}
            icon={formLoading ? <LoadingOutlined /> : <SyncOutlined />}
          >
            {translate("buttons.run", "Run")}
          </SaveButton>
        </>
      )}
    >
      <FormCard formProps={formProps} resource={resource} />
    </Create>
  );
};
