import React, { useEffect } from "react";
import { DeleteButton, EditButton, ShowButton } from "@refinedev/antd";
import {
  BaseRecord,
  useCan,
  useNavigation,
  useInvalidate,
  useGetIdentity,
  useTranslate,
  useUpdateMany,
} from "@refinedev/core";
import { Button, Popconfirm, Space, Switch } from "antd";
import { SafetyOutlined } from "@ant-design/icons";
import { PoweroffOutlined } from "@ant-design/icons";
import { CustomResourceProps, IUser } from "interfaces";

export interface ListActionsProps {
  resource: CustomResourceProps;
  record: BaseRecord;
}

export const ListActions: React.FC<ListActionsProps> = ({
  resource,
  record,
}) => {
  const { push } = useNavigation();
  const { mutate: mutateMany } = useUpdateMany<BaseRecord>();
  const invalidate = useInvalidate();
  const { data: user } = useGetIdentity<IUser>();
  const translate = useTranslate();

  const canChangeStatus = useCan({
    resource: resource?.meta?.listToggleResource,
    action: "edit",
    queryOptions: {
      enabled: !!resource?.meta?.listToggleResource,
      staleTime: 5 * 60 * 1000,
    },
  });

  const canEdit = useCan({
    resource:
      resource?.meta?.editPermissionResource ||
      resource?.meta?.editResource ||
      resource?.name,
    action: "edit",
    queryOptions: {
      enabled: !!resource?.meta?.canEdit,
      staleTime: 5 * 60 * 1000,
    },
  });

  const canApprove = useCan({
    resource:
      resource?.meta?.approvePermissionResource ||
      resource?.meta?.approveResource ||
      `${resource?.name}/approval`,
    action: resource?.meta?.approvePermissionAction || "edit",
    queryOptions: {
      enabled: !!resource?.meta?.canApprove,
      staleTime: 5 * 60 * 1000,
    },
  });

  // enable or disable the approval button
  const [needsApproval, setNeedsApproval] = React.useState(false);

  // enable or disable the edit button
  const [needsEdit, setNeedsEdit] = React.useState(false);

  useEffect(() => {
    const status = (
      record?.approvalStatus?.approvalStatusName ||
      record?.kycStatus?.kycStatusName ||
      record?.transactionStatus?.transactionStatusName
    )?.toLowerCase();

    const needsApproval =
      status === (resource?.meta?.statusToApprove || "pending");
    setNeedsApproval(needsApproval);

    const needsEdit =
      (record?.isConventional || record?.isConventional === undefined) &&
      (!resource?.meta?.statusToEdit ||
        resource?.meta?.statusToEdit === status);

    setNeedsEdit(needsEdit);
  }, [record, resource]);

  const handleChangeStatus = async () => {
    const childIds =
      record?.children?.map((item: BaseRecord) => item?.id)?.filter(Boolean) ||
      [];

    mutateMany(
      {
        resource:
          resource?.meta?.listToggleResource || `${resource?.name}/status`,
        ids: [record?.id, ...childIds],
        values: { status: !record?.status },
        meta: { method: "patch" },
      },
      {
        onSuccess: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });

          var groupId = record?.userGroup?.id;
          const currentGroupId = user?.groupId;
          if (groupId === currentGroupId) window.location.reload();
        },
      }
    );
  };

  return (
    <Space>
      {!!resource?.meta?.listToggleResource && (
        <Popconfirm
          placement="topLeft"
          title={translate("buttonConfirmation.title", "Are you sure?")}
          okText={translate("buttons.yes")}
          cancelText={translate("buttons.no")}
          onConfirm={handleChangeStatus}
          disabled={!canChangeStatus?.data?.can}
        >
          <Switch
            checked={record?.status}
            disabled={!canChangeStatus?.data?.can}
            checkedChildren={<PoweroffOutlined />}
            unCheckedChildren={<PoweroffOutlined />}
          />
        </Popconfirm>
      )}

      {resource?.show && (
        <ShowButton
          hideText
          size="small"
          resource={resource?.meta?.showResource}
          recordItemId={record.id}
        />
      )}

      {resource?.edit && (
        <EditButton
          hideText
          size="small"
          resource={resource?.meta?.editResource}
          recordItemId={record.id}
          disabled={
            (record?.transactionType?.transactionTypeCode === "SUB" &&
              !!record?.switchingCode) ||
            !canEdit?.data?.can ||
            !needsEdit
          }
        />
      )}

      {resource?.meta?.canDelete && (
        <DeleteButton hideText size="small" recordItemId={record.id} />
      )}

      {resource?.meta?.canApprove && (
        <Button
          size="small"
          type="primary"
          icon={<SafetyOutlined />}
          onClick={() =>
            push(
              `/${resource?.identifier || resource?.name}/approve/${record.id}`
            )
          }
          disabled={!needsApproval || !canApprove?.data?.can}
        />
      )}
    </Space>
  );
};
