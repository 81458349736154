import { useState, useEffect, useCallback } from "react";
import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axiosInstance";

const useAxiosQuery = <T>(url: string, params?: AxiosRequestConfig) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    axiosInstance
      .get<T>(url, {
        params: {
          limit: 100,
        },
      })
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [url]);

  useEffect(() => {
    fetchData();
    // use fetchData dependencies as depency array instead of fetchData itself for a more stable behaviour
    // eslint-disable-next-line
  }, [url]);

  return { data, loading, error, refetch: fetchData };
};

export default useAxiosQuery;
