import React from "react";
import { CustomResourceProps } from "interfaces";
import { Space } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ButtonWithConfirmation } from "components/atoms";
import { useTranslate } from "@refinedev/core";

interface KycButtonsProps {
  resource?: CustomResourceProps;
  isApproved: boolean;
  setIsApproved: (value: boolean) => void;
  isRevision: boolean;
  setIsRevision: (value: boolean) => void;
  onClick: () => void;
  remark?: string;
  onChangeRemark?: (remark: string) => void;
  isLoading?: boolean;
}

export const KycButtons: React.FC<KycButtonsProps> = ({
  resource,
  isApproved,
  setIsApproved,
  isRevision,
  setIsRevision,
  onClick,
  remark,
  onChangeRemark,
  isLoading,
}) => {
  const translate = useTranslate();

  return (
    <Space style={{ width: "100%", justifyContent: "flex-end" }}>
      {/* APPROVE BUTTON */}
      <ButtonWithConfirmation
        type="primary"
        icon={<CheckCircleOutlined />}
        disabled={!isApproved || isRevision}
        title={translate(
          "page.kyc.approveCustomer",
          "Are you sure you want to approve this customer?"
        )}
        onClick={() => {
          setIsApproved(true);
          setIsRevision(false);
          onClick();
        }}
        loading={isLoading}
      >
        {translate("buttons.approve", "Approve")}
      </ButtonWithConfirmation>

      {/* REVISION BUTTON */}
      <ButtonWithConfirmation
        danger
        type="primary"
        disabled={!isRevision}
        icon={<CloseCircleOutlined />}
        title={translate(
          "page.kyc.dataRevised",
          "Are you sure this data needs to be revised?"
        )}
        // remark={remark}
        // onChangeRemark={onChangeRemark}
        onClick={() => {
          setIsApproved(false);
          setIsRevision(true);
          onClick();
        }}
        loading={isLoading}
      >
        {translate("buttons.revision", "Revision")}
      </ButtonWithConfirmation>

      {/* REJECT BUTTON */}
      {(resource?.meta?.approveResource === "kyc/co-2" ||
        resource?.meta?.approveResource === "kyc/cs") && (
        <ButtonWithConfirmation
          danger
          type="primary"
          disabled={isRevision}
          icon={<CloseCircleOutlined />}
          title={translate(
            "page.kyc.rejectCustomer",
            "Are you sure you want to PERMANENTLY REJECT this customer?"
          )}
          remark={remark}
          onChangeRemark={onChangeRemark}
          onClick={() => {
            setIsApproved(false);
            setIsRevision(false);
            onClick();
          }}
          loading={isLoading}
        >
          {translate("buttons.reject", "Reject")}
        </ButtonWithConfirmation>
      )}
    </Space>
  );
};
