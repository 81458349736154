import { BaseRecord, file2Base64 } from "@refinedev/core";
import { CustomResourceProps, IResourceFormColumn } from "interfaces";
import { isObject } from "./validators";

interface kycFormToPayloadProps {
  resource?: CustomResourceProps;
  data?: BaseRecord;
  values: any;
  isApproved: boolean;
  isRevision: boolean;
}

type ApprovePhaseMapping = {
  [key: string]: string | { name: string; additionalProps: any };
};

const kycFormToPayload = ({
  resource,
  data,
  values,
  isApproved,
  isRevision,
}: kycFormToPayloadProps) => {
  const { unapprovedText, sid, ifua, checkDttot, ...remainingValues } = values;

  // Start with an empty result object
  let result: any = {};

  // Map approvePhase to corresponding property in the result object
  const approvePhaseMapping: ApprovePhaseMapping = {
    "kyc/cs": {
      name: "isCsApproved",
      additionalProps: {
        ...(!isApproved && {
          isRevision,
        }),
      },
    },
    "kyc/co-1": "isKyc1Approved",
    "kyc/co-2": {
      name: "isKyc2Approved",
      additionalProps: { isRevision, sid, ifua, checkDttot },
    },
  };

  const mappedProperty =
    approvePhaseMapping[resource?.meta?.approveResource || ""];

  // If approveResource exists in mapping, update result object
  if (mappedProperty) {
    if (typeof mappedProperty === "string") {
      result[mappedProperty] = isApproved;
    } else {
      result[mappedProperty.name] = isApproved;
      Object.assign(result, mappedProperty.additionalProps);
    }
  }

  if (!isApproved && isRevision) {
    const revisionNotes: any = {
      kyc1: {},
      kyc2: {},
      kyc3: {},
      kyc4: {},
    };

    // Find corresponding KYC_PROPERTY for each key in remainingValues
    for (const [key, value] of Object.entries(remainingValues)) {
      const formColumn = resource?.meta?.formColumns?.find(
        (formColumn: IResourceFormColumn) => formColumn.key === key
      );
      if (formColumn && value) {
        const keyWithIdSuffix = isObject(data?.[key]) ? `${key}Id` : key;
        const kycStep = `kyc${formColumn.kycStep}`;
        revisionNotes[kycStep] = {
          ...revisionNotes[kycStep],
          [keyWithIdSuffix]: value,
        };
      }
    }

    result = { ...result, revisionNotes };
  } else if (!isApproved && !isRevision) {
    result = { ...result, unapprovedText };
  } else {
    delete result.isRevision;
  }

  return result;
};

const kycPayloadToForm = ({ data }: { data?: BaseRecord }) => {
  const result = {
    ...data?.revisionNotes?.kyc1,
    ...data?.revisionNotes?.kyc2,
    ...data?.revisionNotes?.kyc3,
    ...data?.revisionNotes?.kyc4,
  };

  // filter all properties whose value is 'ok'
  Object.keys(result).forEach((key) => {
    if (result[key] === "ok") {
      delete result[key];
    }
  });

  // remove "Id" string from all properties
  Object.keys(result).forEach((key) => {
    if (key.endsWith("Id")) {
      const newKey = key.replace("Id", "");
      result[newKey] = result[key];
      delete result[key];
    }
  });

  // duplicate all properties, and add "_isRevised" suffix to the new properties
  Object.keys(result).forEach((key) =>
    Object.assign(result, { [`${key}_isRevised`]: true })
  );

  result["unapprovedText"] = data?.unapprovedText;

  return result;
};

const imagesToBase64 = async (items: Object): Promise<any[][]> => {
  const promises = Object?.entries(items)?.map(
    async ([key, value]: [string, any]) => {
      if (
        Array.isArray(value) &&
        value?.length > 0 &&
        value?.[0]?.uid &&
        value?.[0]?.originFileObj &&
        value?.[0]?.type?.includes("image")
      ) {
        const base64String = await file2Base64(value?.[0]);
        return [key, base64String];
      } else {
        return [key, value];
      }
    }
  );
  return Promise.all(promises);
};

const timeToHHmm = (timeString: string) => {
  try {
    // Parse the input time string into a Date object
    const timeObj = new Date(`1970-01-01T${timeString}`);

    // Format the Date object into the desired HH:mm format
    const formattedTime = timeObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formattedTime;
  } catch (error) {
    return "";
  }
};

const jsonToFormData = (values: any) => {
  const formData = new FormData();
  Object.entries(values)?.forEach(([key, value]) => {
    if (
      Array.isArray(value) &&
      value?.length > 0 &&
      (value?.[0] instanceof File || value?.[0]?.originFileObj instanceof File)
    ) {
      value?.forEach((file) => {
        formData?.append(key, file?.originFileObj || file);
      });
    } else {
      formData?.append(key, value as string | Blob);
    }
  });
  return formData;
};

const convertHexToRGBA = (hexCode: string, opacity = 0.3) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) opacity = opacity / 100;

  return `rgba(${r},${g},${b},${opacity})`;
};

export {
  kycFormToPayload,
  kycPayloadToForm,
  imagesToBase64,
  timeToHHmm,
  jsonToFormData,
  convertHexToRGBA,
};
