import { InboxOutlined } from "@ant-design/icons";
import {
  file2Base64,
  useCreate,
  useInvalidate,
  useNotification,
  useParsed,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { Button, Space, Upload, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import React, { useState } from "react";
import mime from "mime";

interface ITransactionUploadDraggerProps {
  label?: string;
  path: string;
  isFormData?: boolean;
  formats?: string[];
}

export const TransactionUploadDragger: React.FC<
  ITransactionUploadDraggerProps
> = ({ label, path, isFormData, formats }) => {
  const { pathname } = useParsed();
  const { resource } = useResource(
    pathname?.split("/").filter(Boolean).slice(0, -2).join("/")
  );
  const translate = useTranslate();
  const { mutate, isLoading } = useCreate();
  const invalidate = useInvalidate();
  const { open } = useNotification();

  const [file, setFile] = useState<UploadFile>();
  const [fileBase64, setFileBase64] = useState<string>();

  const handleUpload = async () => {
    const formData = new FormData();
    if (file) formData.append("file", file?.originFileObj || "");

    mutate(
      !isFormData
        ? {
            resource: path,
            values: { file: fileBase64 },
          }
        : {
            resource: path,
            values: formData,
            meta: { headers: { contentType: "multipart/form-data" } },
          },
      {
        onSuccess: () => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file: RcFile) => {
      const mimeTypes = formats?.map((format) => mime.getType(format));
      const isCorrectFormat = mimeTypes?.includes(file?.type);

      if (!isCorrectFormat) {
        open?.({
          type: "error",
          message: translate("notifications.err"),
          description: translate("notifications.uploadFormatError", {
            format: formats?.join(", "),
          }),
        });
      } else {
        setFile(file);
      }
      return false;
    },
    onChange: async ({ file, fileList }) => {
      if (fileList?.length > 0) {
        const base64File = await file2Base64(fileList?.[0]);
        setFileBase64(base64File);
        setFile(fileList?.[0]);
      }
    },
    fileList: file ? [file] : [],
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon" style={{ height: "75px" }}></p>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {label ||
            translate(
              "uploadDragger.instructions",
              "Click or drag file to this area"
            )}
        </p>
        <p className="ant-upload-hint" style={{ height: "75px" }}>
          {translate("uploadDragger.hint", { format: formats?.join(" / ") })}
        </p>
      </Upload.Dragger>

      <Button
        type="primary"
        onClick={handleUpload}
        loading={isLoading}
        disabled={!file}
        style={{ width: "100%" }}
      >
        {translate("buttons.upload", "Upload")}
      </Button>
    </Space>
  );
};
