import { BaseRecord, useList, CrudFilters } from "@refinedev/core";
import { useSelect } from "@refinedev/antd";
import { FormProps, Select, Form, SelectProps } from "antd";
import { IResourceFormColumn } from "interfaces";
import { debounce } from "lodash";
import React, { useEffect, useMemo } from "react";
import { DefaultOptionType } from "antd/es/select";
import { getNameKey, isObject } from "utils";

interface DynamicSelectProps extends SelectProps {
  formColumn?: IResourceFormColumn & {
    optionsWidth?: number;
  };
  options?: DefaultOptionType[];
  placeholder?: string;
  formProps?: FormProps;
  onChange?:
    | ((
        value: any,
        option: DefaultOptionType | DefaultOptionType[],
        record?: BaseRecord
      ) => void)
    | undefined;
  disableGetOne?: boolean;
  setSelectedData?: React.Dispatch<
    React.SetStateAction<BaseRecord | undefined>
  >;
}

export const DynamicSelect: React.FC<DynamicSelectProps> = ({
  formColumn,
  options,
  placeholder,
  formProps,
  onChange,
  disableGetOne,
  setSelectedData,
  ...rest
}) => {
  const meta = formColumn?.optionsMeta;
  const [pageSize, setPageSize] = React.useState(
    meta?.pagination?.pageSize || 10
  );

  // monitor the changes of the form
  Form.useWatch([], formProps?.form);

  // if labelKey is not provided in meta, get first data on the table, then get its nameKey
  const getFirstData = useList({
    resource: meta?.resourceName,
    pagination: { mode: "off", pageSize: 1 },
    queryOptions: { enabled: !!meta?.resourceName && !meta?.labelKey },
  });
  const defaultLabelKey = getNameKey(getFirstData?.data?.data?.[0]);
  const valueFromOtherColumn = formProps?.form?.getFieldValue(
    meta?.filterFromOtherColumn?.formKey || ""
  );

  const { queryResult, selectProps } = useSelect({
    resource: meta?.resourceName || "",
    optionLabel: ((item: BaseRecord) => {
      let label = item[defaultLabelKey || "title"];

      if (Array.isArray(meta?.labelKey)) {
        label = meta?.labelKey
          .map((key) => item[key])
          .map((value) =>
            isObject(value) ? value?.[getNameKey(value) || "id"] : value
          )
          .filter((value) => !!value)
          .join(" ");
      } else if (meta?.labelKey) {
        label = item[meta?.labelKey];
      }

      if (isObject(label)) label = label?.[getNameKey(label) || "id"];

      const keys = meta?.additionalLabelKeys;

      if (Array.isArray(keys)) {
        label += " (";
        keys.forEach((key) => {
          if (key.includes(".")) {
            const nestedKeys = key.split(".");
            label += `${nestedKeys.reduce((prev, curr) => prev?.[curr], item)}`;
          } else if (item[key]) {
            label += `${item[key]}`;
          }
        });
        label += ")";
      }

      return label;
    }) as unknown as undefined,
    optionValue: meta?.valueKey as undefined,
    defaultValue: rest?.defaultValue || rest?.value,
    pagination: { mode: "server", ...meta?.pagination, pageSize },
    filters: [
      ...(meta?.initialFilters || []),
      {
        field: meta?.filterFromOtherColumn?.filterKey,
        operator: "eq",
        value: valueFromOtherColumn,
      },
    ],
    onSearch: (value) => {
      const filters: CrudFilters = [];
      if (value) filters.push({ field: "search", operator: "contains", value });
      return filters;
    },
    queryOptions: {
      enabled: !!meta?.resourceName && !!(defaultLabelKey || meta?.labelKey),
    },
    defaultValueQueryOptions: {
      enabled:
        !disableGetOne &&
        !!meta?.resourceName &&
        !meta?.disableGetOne &&
        !(!!meta?.valueKey && meta?.valueKey !== "id"),
    },
  });

  useEffect(() => {
    setSelectedData?.(
      queryResult?.data?.data?.find(
        (item: BaseRecord) =>
          item[meta?.valueKey || "id"] === (rest?.defaultValue || rest?.value)
      )
    );
  }, [
    meta?.valueKey,
    queryResult?.data?.data,
    rest?.value,
    rest?.defaultValue,
    setSelectedData,
  ]);

  const records = queryResult?.data?.data;

  const staticOptions = useMemo(
    () =>
      options?.map((option) => {
        if (typeof option === "string") return { label: option, value: option };
        else return option;
      }),
    [options]
  );

  let dropdownStyle;

  if (meta?.optionsWidth) {
    dropdownStyle = {
      width: meta?.optionsWidth,
    };
  }

  return (
    <Select
      dropdownStyle={{
        ...dropdownStyle,
        zIndex: 9999,
      }}
      {...rest}
      {...(meta?.resourceName
        ? { ...selectProps, showSearch: !meta?.disableSearch }
        : undefined)}
      options={!!meta?.resourceName ? selectProps?.options : staticOptions}
      placeholder={placeholder}
      loading={queryResult?.fetchStatus === "fetching" && !!meta?.resourceName}
      disabled={
        formColumn?.isDisabled ||
        (!!meta?.filterFromOtherColumn?.required &&
          valueFromOtherColumn === undefined)
      }
      allowClear
      onChange={(value, option) => {
        const selectedData = records?.find(
          (item: BaseRecord) => item[meta?.valueKey || "id"] === value
        );
        onChange?.(value, option, selectedData);
        setSelectedData?.(selectedData);

        if (
          meta?.fillOtherColumns &&
          Object.keys(meta?.fillOtherColumns).length !== 0
        ) {
          Object.keys(meta?.fillOtherColumns).forEach((currentKey) => {
            const dataKey = meta?.fillOtherColumns?.[currentKey];
            if (dataKey) {
              const currentValue = dataKey.split(".").reduce((prev, curr) => {
                return prev?.[curr];
              }, selectedData as BaseRecord);
              formProps?.form?.setFieldValue(currentKey, currentValue);
            }
          });
        }
      }}
      onPopupScroll={debounce((e) => {
        const target = e.target as HTMLDivElement;
        if (
          target.scrollTop + target.clientHeight >=
          target.scrollHeight - 24
        ) {
          setPageSize((prev) => prev + 20);
        }
      }, 100)}
    />
  );
};
