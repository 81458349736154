import { ImageField, getValueFromEvent } from "@refinedev/antd";
import { Form, Space, Typography, Upload, UploadFile } from "antd";
import { IResourceFormColumn } from "interfaces";
import { startCase } from "lodash";
import React from "react";
import { file2Base64, useCreate, useTranslate } from "@refinedev/core";
import { FormProps } from "antd";
import { mapIdCardOCRValues } from "utils";
import { Text } from "./text";

interface FormImageItemProps {
  formColumn?: IResourceFormColumn;
  required?: boolean;
  onChange?: (value?: string, fileList?: UploadFile[]) => void;
  formProps?: FormProps;
  revisionColumns?: any;
}

export const FormImageItem: React.FC<FormImageItemProps> = ({
  formColumn,
  required,
  onChange,
  formProps,
  revisionColumns,
  ...rest
}) => {
  const isEditPage = formProps?.form?.getFieldValue("id") ? true : false;
  const placeholderUrl = `${process.env.PUBLIC_URL}/images/placeholder/placeholder-img-sqr.jpg`;
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const { mutate: mutateCreate } = useCreate();
  const translate = useTranslate();

  const handleUpload = async (options: any) => {
    const { file, onError, onSuccess } = options;

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64file = reader.result;
      mutateCreate(
        {
          resource: formColumn?.uploadResource || "",
          dataProviderName: formColumn?.dataProviderName,
          values: { image: base64file },
        },
        { onSuccess, onError }
      );
    };
  };

  const isRevision = revisionColumns?.hasOwnProperty(
    formColumn?.key?.replace(/Id$/, "")
  );

  return (
    <Form.Item {...rest}>
      <Form.Item
        name={formColumn?.key}
        valuePropName="fileList"
        getValueFromEvent={getValueFromEvent}
        noStyle
        required={required}
        getValueProps={(value) => {
          if (!Array.isArray(value)) setImageUrl(value);
          if (typeof value !== "string") setFileList(value);
          return value;
        }}
      >
        <Upload.Dragger
          name={formColumn?.key}
          listType="picture"
          maxCount={1}
          disabled={formColumn?.isDisabled || (!isRevision && revisionColumns)}
          openFileDialogOnClick={!fileList?.length}
          beforeUpload={!!formColumn?.uploadResource ? undefined : () => false}
          customRequest={handleUpload}
          fileList={fileList}
          onChange={async ({ file, fileList }) => {
            setFileList(fileList);
            if (fileList?.length > 0) {
              // Remap values from OCR
              if (file?.response?.data && formColumn?.remapOCR) {
                const values = file?.response?.data;
                const remappedValues = await mapIdCardOCRValues(values);
                formProps?.form?.setFieldsValue(remappedValues);
              }

              const base64File = await file2Base64(fileList?.[0]);
              setImageUrl(base64File);

              if (isEditPage) {
                formProps?.form?.setFieldsValue({
                  [String(formColumn?.key)]: base64File,
                });
              }

              onChange?.(base64File, fileList);
            } else {
              setImageUrl(undefined);
              onChange?.(undefined);
            }
          }}
        >
          <Space direction="vertical" size={8} style={{ marginInline: "1rem" }}>
            <ImageField
              style={{
                height: "180px",
                opacity: isEditPage ? 1 : 0.5,
                objectFit: "contain",
              }}
              value={imageUrl}
              alt={formColumn?.key}
              preview={false}
              fallback={imageUrl ?? placeholderUrl}
            />
            <Typography.Text style={{ fontWeight: 800, fontSize: "16px" }}>
              {formColumn?.label ||
                startCase(
                  formColumn?.key ??
                    translate("formImageItem.label", "Upload Image Here")
                )}
            </Typography.Text>
          </Space>
        </Upload.Dragger>
        {isRevision && (
          <Space
            direction="vertical"
            style={{
              width: "100%",
              marginTop: "8px",
            }}
          >
            <Text size="sm">Revision Notes</Text>
            <Text size="sm" strong>
              {revisionColumns[formColumn?.key?.replace(/Id$/, "") || ""]}
            </Text>
          </Space>
        )}
      </Form.Item>
    </Form.Item>
  );
};
