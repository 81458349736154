import React, { useEffect, useState } from "react";
import { HttpError, useGo, useRouterType } from "@refinedev/core";
import { RefineErrorPageProps } from "@refinedev/ui-types";
import { Button, Result, Typography, Space } from "antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { ResultStatusType } from "antd/es/result";

/**
 * When the app is navigated to a non-existent route, refine shows a default error page.
 * A custom error component can be used for this error page.
 *
 * @see {@link https://refine.dev/docs/packages/documentation/routers/} for more details.
 */

interface CustomErrorComponentProps extends RefineErrorPageProps {
  error?: HttpError;
}

export const CustomErrorComponent: React.FC<CustomErrorComponentProps> = ({
  error,
}) => {
  const translate = useTranslate();
  const { push } = useNavigation();
  const go = useGo();
  const routerType = useRouterType();
  const [status, setStatus] = useState<ResultStatusType | undefined>("404");

  useEffect(() => {
    const RESULT_STATUS_CODES = [
      "success",
      "error",
      "info",
      "warning",
      "404",
      "403",
      "500",
    ];
    if (RESULT_STATUS_CODES?.includes(error?.statusCode?.toString() || "")) {
      setStatus(error?.statusCode?.toString() as ResultStatusType);
    }
  }, [error?.statusCode]);

  return (
    <Result
      status={status}
      title={error?.statusCode?.toString() || "404"}
      subTitle={
        <Space direction="vertical" size="middle">
          {error?.message ||
            translate(
              "pages.error.404",
              "Sorry, the page you visited does not exist."
            )}

          {error?.eventId && (
            <Space direction="horizontal">
              {`Event ID:`}
              <Typography.Text code copyable style={{ fontSize: `16px` }}>
                {error?.eventId}
              </Typography.Text>
            </Space>
          )}
        </Space>
      }
      extra={
        <Button
          type="primary"
          onClick={() => {
            if (routerType === "legacy") push("/");
            else go({ to: "/" });
          }}
        >
          {translate("pages.error.backHome", "Back Home")}
        </Button>
      }
    />
  );
};
