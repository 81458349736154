import { ImageField } from "@refinedev/antd";
import useMouseHoverPosition from "./hooks/useMouseHover";
import { ImageFieldProps } from "@refinedev/antd";
import { CSSProperties } from "react";

type HoverableImageProps = {
  backgroundImage: string;
  imageStyle: CSSProperties;
  zoomImageSize?: string | number;
} & Pick<ImageFieldProps, "preview" | "fallback" | "value">;

export default function HoverableImage({
  backgroundImage,
  imageStyle,
  preview,
  zoomImageSize = "200%",
  value,
  fallback,
}: HoverableImageProps) {
  const { ref } = useMouseHoverPosition(zoomImageSize);
  return (
    <div>
      <div
        ref={ref}
        style={{
          cursor: "pointer",
          backgroundSize: zoomImageSize,
          backgroundImage: backgroundImage,
        }}
      >
        <ImageField
          style={{
            visibility: "hidden",
            cursor: preview ? "pointer" : "default",
            width: "100%",
            maxHeight: "300px",
            ...imageStyle,
          }}
          preview={
            preview && {
              ...(typeof preview === "object" ? preview : {}),
              mask: null,
            }
          }
          value={value}
          fallback={fallback}
        />
      </div>
    </div>
  );
}
