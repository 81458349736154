import React, { useEffect, useMemo } from "react";
import { useResource } from "@refinedev/core";
import { Col, Form, Row, Typography } from "antd";
import { IResourceFormColumn } from "interfaces";

import { isObject, getKeysByColumnType, getSwitchingPairCode } from "utils";
import { FormDynamicItem } from "components/molecules";
import { FormCardProps } from "components/organisms";
import { TRANSACTION_TYPE } from "constants/transaction";
type BackdateFormCardProps = FormCardProps & {
  showSwitchIn?: boolean;
};
export const BackdateFormCard: React.FC<BackdateFormCardProps> = ({
  formProps,
  resource,
  showSwitchIn,
}) => {
  const { resource: defaultResource, action } = useResource();
  const transactionTypeId = Form.useWatch("transactionTypeId", formProps?.form);
  const sellingAgentId = Form.useWatch("sellingAgentId", formProps?.form);

  const headerFormKeys = [
    "customerDetailId",
    "unitHolderId",
    "customerBankAccountNumber",
    "transactionTypeId",
    "switchingCode",
  ];

  const footerFormKeys = [
    "transferType",
    "paymentMethodId",
    "transactionDate",
    "settlementDate",
    "agentId",
    "sellingAgentId",
    "reason",
  ];

  const switchInFormKeys = ["productId", "unit", "amount", "netAmount"];

  const filteredFormColumns: IResourceFormColumn[] = useMemo(
    () =>
      (resource || defaultResource)?.meta?.formColumns?.filter(
        (formColumn: IResourceFormColumn) => {
          if (action === "create" && formColumn?.hideOnCreate) return false;
          if (action === "edit" && formColumn?.hideOnEdit) return false;
          return formColumn?.key;
        }
      ),
    [action, defaultResource, resource]
  );

  const formColumns = useMemo(
    () =>
      filteredFormColumns?.map((formColumn: IResourceFormColumn) => {
        const isDisabledOnEdit =
          action === "edit" && formColumn?.disabledOnEdit;
        const isProductBankAccount = formColumn?.key === "productBankAccountId";
        return {
          ...formColumn,
          optionsMeta: {
            ...formColumn?.optionsMeta,
            ...(isProductBankAccount && {
              initialFilters: [
                ...(formColumn?.optionsMeta?.initialFilters || []),
                ...(transactionTypeId !== TRANSACTION_TYPE.SWT
                  ? [
                      {
                        field: "transactionTypeId",
                        operator: "eq",
                        value: transactionTypeId,
                      },
                    ]
                  : []),
                {
                  field: "sellingAgentId",
                  operator: "eq",
                  value: sellingAgentId,
                },
              ],
            }),
          },
          isDisabled: isDisabledOnEdit || formColumn?.isDisabled,
        };
      }),
    [action, filteredFormColumns, transactionTypeId, sellingAgentId]
  );

  const newInitialValueList = useMemo(
    () =>
      Object.entries(formProps?.initialValues || {}).map(([key, value]) => {
        if (isObject(value)) return { [`${key}Id`]: value?.id };
        else return { [key]: value };
      }),
    [formProps?.initialValues]
  );

  const initialValues = Object.assign({}, ...newInitialValueList);

  const reformatAndSubmit = async (values: any) => {
    if (formProps?.onFinish) {
      resource?.meta?.submitCallback?.(values);

      // find all formColumns with property "ignoreOnSubmit" set to true
      const ignoreOnSubmitKeys =
        formColumns
          ?.filter(
            (formColumn) =>
              formColumn?.ignoreOnSubmit ||
              (formColumn?.ignoreOnEdit && action === "edit")
          )
          ?.map((formColumn) => formColumn?.key) || [];
      // remove the values of the formColumns with property "ignoreOnSubmit" set to true
      ignoreOnSubmitKeys?.forEach((key) => {
        delete values[key || ""];
      });

      // Change daterange format to date
      const dateRangeKeys = getKeysByColumnType(
        values,
        formColumns,
        "daterange"
      );
      dateRangeKeys?.forEach((key) => {
        const formColumn = formColumns?.find(
          (formColumn) => formColumn?.key === key
        );

        values[key]?.forEach((date: any, index: number) => {
          const defaultKey = index === 0 ? "dateFrom" : "dateTo";

          Object.assign(values, {
            [formColumn?.rangeKeys?.[index] || defaultKey]:
              date?.format("YYYY-MM-DD"),
          });
          delete values[key];
        });
      });

      // find the values inside values that are null or undefined, and remove them
      Object?.entries(values)?.forEach(([key, value]) => {
        if (value === null || value === undefined || value === "")
          delete values[key];
      });

      const valuesCopy = { ...values };
      if (transactionTypeId === TRANSACTION_TYPE.SWT) {
        // make values not needed in switchIn form 0 or undefined
        Object.keys(valuesCopy).forEach((key) => {
          if (
            ![
              ...headerFormKeys,
              ...footerFormKeys,
              ...switchInFormKeys,
            ].includes(key)
          ) {
            valuesCopy[key] =
              typeof valuesCopy?.[key] === "number" ? 0 : undefined;

            if (key === "productBankAccountId") {
              delete valuesCopy[key];
            }
          }

          if (switchInFormKeys.includes(key)) {
            valuesCopy[key] = valuesCopy?.[`${key}In`];
            delete values[`${key}In`];
            delete valuesCopy[`${key}In`];
          }
        });

        Object?.entries(valuesCopy)?.forEach(([key, value]) => {
          if (value === null || value === undefined || value === "")
            delete valuesCopy[key];
        });

        formProps?.onFinish({
          ...valuesCopy,
          isAllUnits: false,
          transactionTypeId: TRANSACTION_TYPE.SUB,
        });
      }

      formProps?.onFinish({
        ...values,
        ...(transactionTypeId === TRANSACTION_TYPE.SWT && {
          transactionTypeId: TRANSACTION_TYPE.RED,
        }),
      });
    }
  };

  useEffect(() => {
    if (transactionTypeId === TRANSACTION_TYPE.SWT) {
      if (formProps?.form?.getFieldValue("switchingCode")) {
        return;
      }

      formProps?.form?.setFieldValue("switchingCode", getSwitchingPairCode());
      return;
    }

    formProps?.form?.setFieldValue("switchingCode", undefined);
  }, [transactionTypeId, formProps?.form]);

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={initialValues || formProps?.initialValues}
      onFinish={reformatAndSubmit}
    >
      <Row gutter={32}>
        {formColumns
          ?.filter((formColumn) =>
            headerFormKeys.includes(formColumn?.key || "")
          )
          ?.map((formColumn) => (
            <FormDynamicItem
              key={`${formColumn?.key} ${
                resource?.identifier || resource?.name || ""
              }`}
              formColumn={formColumn}
              formProps={formProps}
              required={
                action === "edit"
                  ? formColumn?.requiredOnEdit
                  : formColumn?.requiredOnCreate
              }
            />
          ))}
      </Row>
      <Row gutter={32}>
        <Col xs={showSwitchIn ? 12 : 24}>
          {showSwitchIn && (
            <Typography.Title level={5}>Switch Out</Typography.Title>
          )}
          <Row gutter={32}>
            {formColumns
              ?.filter(
                (formColumn) =>
                  !(formColumn?.key === "transactionTypeId") &&
                  ![...headerFormKeys, ...footerFormKeys].includes(
                    formColumn?.key || ""
                  )
              )
              ?.map((formColumn) => (
                <FormDynamicItem
                  key={`${formColumn?.key} ${
                    resource?.identifier || resource?.name || ""
                  }`}
                  formColumn={{
                    ...formColumn,
                    span: showSwitchIn ? 2 : formColumn.span,
                  }}
                  formProps={formProps}
                  required={
                    action === "edit"
                      ? formColumn?.requiredOnEdit
                      : formColumn?.requiredOnCreate
                  }
                />
              ))}
          </Row>
        </Col>
        {showSwitchIn && (
          <Col xs={12}>
            {showSwitchIn && (
              <Typography.Title level={5}>Switch In</Typography.Title>
            )}
            <Row gutter={32}>
              {formColumns
                ?.filter((formColumn) =>
                  switchInFormKeys.includes(formColumn?.key || "")
                )
                ?.map((formColumn) => (
                  <FormDynamicItem
                    key={`${formColumn?.key}In ${
                      resource?.identifier || resource?.name || ""
                    }`}
                    formColumn={{
                      ...formColumn,
                      key: `${formColumn.key}In`,
                      span: 2,
                    }}
                    formProps={formProps}
                    required={
                      action === "edit"
                        ? formColumn?.requiredOnEdit
                        : formColumn?.requiredOnCreate
                    }
                  />
                ))}
            </Row>
          </Col>
        )}
      </Row>
      <Row gutter={32}>
        {formColumns
          ?.filter((formColumn) =>
            footerFormKeys.includes(formColumn?.key || "")
          )
          ?.map((formColumn) => (
            <FormDynamicItem
              key={`${formColumn?.key} ${
                resource?.identifier || resource?.name || ""
              }`}
              formColumn={formColumn}
              formProps={formProps}
              required={
                action === "edit"
                  ? formColumn?.requiredOnEdit
                  : formColumn?.requiredOnCreate
              }
            />
          ))}
      </Row>
    </Form>
  );
};
