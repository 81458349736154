import React from "react";
import { IResourceFilter } from "interfaces";
import { startCase } from "lodash";
import { Button, Col, DatePicker, Form, FormProps, Input, Row } from "antd";
import { DynamicSelect } from "components/atoms";

export interface RollingBalanceListDynamicFilterProps {
  formProps?: FormProps;
  filters?: IResourceFilter[];
  setEnableQuery: (value: boolean) => void;
}

export const RollingBalanceListDynamicFilter: React.FC<
  RollingBalanceListDynamicFilterProps
> = ({ formProps, filters, setEnableQuery }) => {
  return (
    <Form
      layout="vertical"
      {...formProps}
      onFinish={(values) => {
        setEnableQuery(true);
        formProps?.onFinish?.(values);
      }}
    >
      <Row gutter={[12, 12]}>
        {filters?.map((filter) => {
          return (
            <Col key={filter?.key} flex={1}>
              <Form.Item
                style={{ marginBottom: 0 }}
                initialValue={filter?.initialValue}
                name={filter?.key}
                rules={[
                  {
                    required: filter?.required,
                    message: `Please input ${
                      filter?.label || startCase(filter?.key)
                    }`,
                  },
                ]}
              >
                {filter.type === "daterange" ? (
                  <DatePicker.RangePicker
                    placeholder={filter?.placeholder as [string, string]}
                    style={{ width: "100%" }}
                    format={"D MMM YYYY"}
                    disabledDate={(date) =>
                      date < filter?.minDate || date > filter?.maxDate
                    }
                  />
                ) : filter.type === "date" ? (
                  <DatePicker
                    placeholder={
                      (filter?.placeholder || filter?.label) as string
                    }
                    style={{ width: "100%" }}
                    disabledDate={(date) =>
                      date < filter?.minDate || date > filter?.maxDate
                    }
                    // disabled={filter?.isDisabled}
                    format={"D MMM YYYY"}
                  />
                ) : filter.type === "select" ? (
                  <DynamicSelect
                    defaultValue={filter?.initialValue}
                    style={{ width: "100%" }}
                    formColumn={filter}
                    options={filter?.options}
                    disableGetOne
                    placeholder={
                      (filter?.placeholder as string) ||
                      filter?.label ||
                      startCase(filter?.key)
                    }
                  />
                ) : (
                  <Input
                    type={filter?.type}
                    placeholder={
                      (filter?.placeholder as string) || startCase(filter?.key)
                    }
                    prefix={filter?.prefix}
                  />
                )}
              </Form.Item>
            </Col>
          );
        })}

        <Col>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button htmlType="submit" type="primary" style={{ width: "200px" }}>
              Search
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
