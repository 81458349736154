import {
  useGetLocale,
  useSetLocale,
  useLogout,
  useActiveAuthProvider,
  useTranslate,
} from "@refinedev/core";
import { Avatar, Dropdown, Space, Typography, Grid, MenuProps } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SettingOutlined,
  LogoutOutlined,
  CaretDownOutlined,
  TranslationOutlined,
  LayoutOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import { ColorModeContext } from "../../contexts/color-mode";
import { setGeneral } from "redux/slices/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { AccountSettings } from "components/organisms";
import { CustomAvatar } from "components/atoms";
import { startCase } from "lodash";

const { Text } = Typography;

interface HeaderToolbarProps {
  style?: React.CSSProperties;
}

export const HeaderToolbar: React.FC<HeaderToolbarProps> = ({ style }) => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();
  const translate = useTranslate();
  const { mode: themeMode, setMode } = useContext(ColorModeContext);
  const breakpoint = Grid.useBreakpoint();
  const isMobile =
    typeof breakpoint.sm === "undefined" ? false : !breakpoint.sm;
  const authProvider = useActiveAuthProvider();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const navbarMode = useSelector(
    (state: RootState) => state.general.navbarMode
  );

  const [openAccountSettings, setOpenAccountSettings] = useState(false);

  const languageDropdownItems: MenuProps["items"] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: () => changeLanguage(lang),
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={`/images/flags/${lang}.svg`} />
        </span>
      ),
      label: lang?.toUpperCase(),
    }));

  const profileDropdownItems: MenuProps["items"] = [
    {
      key: "language",
      label: translate("headerToolbar.language", "Language"),
      icon: <TranslationOutlined />,
      children: languageDropdownItems,
    },
    {
      key: "navbar",
      label: translate("headerToolbar.navbar", "Navbar"),
      icon: <LayoutOutlined />,
      children: [
        {
          key: "vertical",
          label: translate("headerToolbar.vertical", "Vertical"),
          onClick: () => dispatch(setGeneral({ navbarMode: "vertical" })),
        },
        {
          key: "horizontal",
          label: translate("headerToolbar.horizontal", "Horizontal"),
          onClick: () => dispatch(setGeneral({ navbarMode: "horizontal" })),
        },
      ],
    },
    {
      key: "theme",
      label: translate("headerToolbar.theme", "Theme"),
      icon: <BgColorsOutlined />,
      children: [
        {
          key: "light",
          label: "🔆 " + translate("headerToolbar.light", "Light"),
          onClick: () => setMode("light"),
        },
        {
          key: "dark",
          label: "🌛 " + translate("headerToolbar.dark", "Dark"),
          onClick: () => setMode("dark"),
        },
      ],
    },
    {
      key: "profile",
      label: translate("headerToolbar.accountSettings", "Account Settings"),
      icon: <SettingOutlined />,
      onClick: () => setOpenAccountSettings(true),
    },
    {
      key: "logout",
      label: (
        <span style={{ color: "tomato" }}>
          {translate("buttons.logout", "Logout")}
        </span>
      ),
      icon: <LogoutOutlined style={{ color: "tomato" }} />,
      onClick: () => mutateLogout(),
    },
  ];

  return (
    <Space size="large" style={style}>
      <Dropdown
        menu={{
          items: profileDropdownItems,
          selectedKeys: [
            currentLocale || "en",
            navbarMode || "light",
            themeMode,
          ],
        }}
      >
        <Space size="middle">
          <Text strong>{`${
            user?.name ||
            user?.email ||
            translate("headerToolbar.unknownUser", "Unknown User")
          }${
            !isMobile
              ? ` (${
                  startCase(user?.role) ||
                  translate("headerToolbar.unknownRole", "Unknown Role")
                })`
              : ""
          }`}</Text>
          <CustomAvatar src={user?.avatar} name={user?.name} />
          <CaretDownOutlined />
        </Space>
      </Dropdown>

      <AccountSettings
        opened={openAccountSettings}
        setOpened={setOpenAccountSettings}
      />
    </Space>
  );
};
