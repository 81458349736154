import { ColumnsType } from "antd/es/table";
import { ReconTableCellValue, ReconTableRow } from "../table";
import { ReconTableValueCell } from "../../components/reconTableValueCell";
export const RECON_TABLE_COLUMNS: ColumnsType<ReconTableRow> = [
  {
    key: "productName",
    dataIndex: "productName",
    title: "Product Name",
    sorter: (a, b) => a?.productName?.localeCompare(b?.productName || "") || 0,
  },
  {
    key: "productId",
    dataIndex: "productId",
    title: "Product ID",
    sorter: (a, b) => Number(a?.productId || 0) - Number(b?.productId || 0),
  },
  {
    title: "Goodfund",
    children: [
      {
        key: "sub",
        dataIndex: "sub",
        title: "Sub",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
      {
        key: "red",
        dataIndex: "red",
        title: "Red",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
      {
        key: "switchIn",
        dataIndex: "switchIn",
        title: "Switch In",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
      {
        key: "switchOut",
        dataIndex: "switchOut",
        title: "Switch Out",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
    ],
  },
  {
    title: "SInvest",
    children: [
      {
        key: "sinvestSub",
        dataIndex: "sinvestSub",
        title: "Sub",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
      {
        key: "sinvestRed",
        dataIndex: "sinvestRed",
        title: "Red",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
      {
        key: "sinvestSwitchIn",
        dataIndex: "sinvestSwitchIn",
        title: "Switch In",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
      {
        key: "sinvestSwitchOut",
        dataIndex: "sinvestSwitchOut",
        title: "Switch Out",
        render: (val: ReconTableCellValue) => <ReconTableValueCell {...val} />,
      },
    ],
  },
];
