import React, { useEffect, useState } from "react";

import { useTranslate, useNotification, useApiUrl } from "@refinedev/core";
import { ShoppingOutlined } from "@ant-design/icons";

import { axiosInstance } from "utils";
import { CounterCard } from "components/molecules";

export const TotalProducts: React.FC = () => {
  const translate = useTranslate();
  const { open } = useNotification();

  const [totalData, setTotalData] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/dashboard/total-product`;

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(url)
      .then((res) => {
        const data = res?.data?.body;
        setTotalData(data?.totalProduct || 0);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [translate, open, url]);

  return (
    <CounterCard
      title={translate("page.dashboard.totalProducts", `Total Products`)}
      value={totalData}
      icon={<ShoppingOutlined />}
      isLoading={isLoading}
    />
  );
};
