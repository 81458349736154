import { Card, Space, Tabs } from "antd";
import { BaseRecord, useResource } from "@refinedev/core";
import { IResourceDisplayColumn } from "interfaces";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { isObject } from "utils";
import { ShowLayout } from "./showLayout";
import { ShowTableLayout } from "./showTableLayout";

export interface ShowItemRecursorProps {
  parentName?: string;
  parentData?: BaseRecord;
  minColumns?: number;
  hideOnChildRecursor?: string[];
}

export const ShowItemRecursor: React.FC<ShowItemRecursorProps> = ({
  parentName,
  parentData,
  minColumns = 4,
  hideOnChildRecursor = [],
}) => {
  const { resources } = useResource();

  const [parentColumns, setParentColumns] = useState<IResourceDisplayColumn[]>(
    []
  );
  const [childColumns, setChildColumns] = useState<IResourceDisplayColumn[]>(
    []
  );
  const [childObjectColumns, setChildObjectColumns] = useState<
    IResourceDisplayColumn[]
  >([]);
  const [childArrayColumns, setChildArrayColumns] = useState<
    IResourceDisplayColumn[]
  >([]);

  // SET PARENT COLUMNS
  useEffect(() => {
    const resourceInfo = resources?.find(
      ({ name, identifier }) => name === parentName || identifier === parentName
    );
    if (resourceInfo?.meta?.displayColumns) {
      setParentColumns(resourceInfo?.meta?.displayColumns);
    } else {
      const defaultParentColumns = Object.keys(parentData || {})?.map(
        (col) => ({ key: col })
      );
      setParentColumns(defaultParentColumns);
    }
  }, [resources, parentName, parentData]);

  // SET OBJECT COLUMNS
  useEffect(() => {
    // remove columns that are not an object
    let objectColumns = parentColumns?.filter((displayColumn) =>
      isObject(parentData?.[displayColumn?.key || ""])
    );

    // remove columns that are masterdata (has less than 4 keys)
    objectColumns = objectColumns?.filter(
      (displayColumn) =>
        Object.keys(parentData?.[displayColumn?.key || ""] || {})?.length >
          minColumns &&
        !displayColumn?.hideOnRecursor &&
        !hideOnChildRecursor.includes(displayColumn?.key || "")
    );
    setChildObjectColumns(objectColumns);
  }, [parentData, parentColumns, minColumns, hideOnChildRecursor]);

  // SET ARRAY COLUMNS
  useEffect(() => {
    const arrayColumns = parentColumns?.filter(
      (displayColumn) =>
        Array.isArray(parentData?.[displayColumn?.key || ""]) &&
        isObject(parentData?.[displayColumn?.key || ""][0])
    );
    setChildArrayColumns(arrayColumns);
  }, [parentData, parentColumns]);

  // SET CHILD COLUMNS
  useEffect(() => {
    const childColumns = [...childArrayColumns, ...childObjectColumns];
    setChildColumns(childColumns);
  }, [childObjectColumns, childArrayColumns]);

  if (childColumns?.length > 0) {
    return (
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        {/* CARD FOR DISPLAYING DATA */}

        <Card bordered={false}>
          <Tabs
            items={childColumns?.map((displayColumn) => {
              const item = {
                key: `${displayColumn?.key}`,
                label: startCase(displayColumn?.key),
                children: (
                  <>
                    {Array.isArray(parentData?.[displayColumn?.key || ""]) ? (
                      <ShowTableLayout
                        objectName={
                          displayColumn?.resourceName || displayColumn?.key
                        }
                        objectData={parentData?.[displayColumn?.key || ""]}
                      />
                    ) : (
                      <ShowLayout
                        resourceName={displayColumn?.resourceName}
                        objectName={
                          displayColumn?.resourceName || displayColumn?.key
                        }
                        objectData={parentData?.[displayColumn?.key || ""]}
                      />
                    )}
                  </>
                ),
              };
              return item;
            })}
            type="card"
          />
        </Card>

        {/* RECURSIVE CALL FOR DISPLAYING CHILD OBJECTS */}
        {childObjectColumns?.length > 0 &&
          childObjectColumns?.map((displayColumn) => (
            <ShowItemRecursor
              hideOnChildRecursor={hideOnChildRecursor}
              key={displayColumn?.key}
              parentName={displayColumn?.resourceName || displayColumn?.key}
              parentData={parentData?.[displayColumn?.key || ""]}
            />
          ))}
      </Space>
    );
  } else {
    return null;
  }
};
