import { Row, Space } from "antd";
// import { ImageField } from "@refinedev/antd";
import { IResourceDisplayColumn } from "interfaces";
import React from "react";
import { isExactMatch } from "utils";
import { startCase } from "lodash";
import HoverableImage from "components/molecules/hoverableImage";

interface KycPhotosProps {
  displayColumns?: IResourceDisplayColumn[];
  objectData?: any;
}

export const KycPhotos: React.FC<KycPhotosProps> = ({
  displayColumns,
  objectData,
}) => {
  const placeholderUrl = `${process.env.PUBLIC_URL}/images/placeholder/placeholder-img.jpg`;
  return (
    <Row justify="center" style={{ position: "sticky", top: "100px" }}>
      <Space size="large" align="center" direction="vertical">
        {displayColumns?.map((displayColumn: IResourceDisplayColumn) =>
          isExactMatch(startCase(displayColumn?.key), "Photo") ? (
            <HoverableImage
            backgroundImage={`url(${
              (objectData)?.[displayColumn?.key || ""]
            })`}
            key={
              `${displayColumn?.key}_${displayColumn?.childKey}` 
            }
            imageStyle={{
              opacity: !!(objectData)?.[
                displayColumn?.key || ""
              ]
                ? 1
                : 0.5,
            }}
            preview={!!(objectData)?.[displayColumn?.key || ""]}
            value={(objectData)?.[displayColumn?.key || ""]}
            fallback={placeholderUrl}
            />
          ) : null
        )}
      </Space>
    </Row>
  );
};
