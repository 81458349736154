/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpError } from "@refinedev/core";
import axios, { InternalAxiosRequestConfig } from "axios";
import { setAuth } from "redux/slices/authSlice";
import { setTemp } from "redux/slices/tempSlice";
import { store } from "redux/store";

const API_URL =
  process.env.REACT_APP_BACKOFFICE_API_URL ||
  "http://localhost:4000/api/v1/backoffice";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig) => {
    const accessToken = store?.getState()?.temp?.accessToken;

    request.headers = request.headers || {};
    request.headers["Authorization"] = `Bearer ${accessToken}`;

    return request;
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const customError: HttpError = {
      ...error,
      message:
        error?.response?.data?.message ||
        error?.response?.data?.error?.message ||
        error?.response?.data?.error ||
        error?.response?.data,
      statusCode:
        error?.response?.data?.error?.error?.statusCode ||
        error?.response?.status,
      eventId: error?.response?.data?.eventId,
    };

    if (
      error?.response?.status !== 401 ||
      error?.config?.url?.includes("/refresh") ||
      error?.config?.url?.includes("/logout")
    ) {
      return Promise.reject(customError);
    }

    try {
      const originalConfig = error.config;
      const oldAccessToken = store?.getState()?.temp?.accessToken;
      const oldRefreshToken = store?.getState()?.auth?.refreshToken;

      // Check if access token is valid
      const checkAccessToken = await axiosInstance.post(
        API_URL + "/auth/check-access-token",
        { accessToken: oldAccessToken }
      );

      // If access token is already valid, we can safely reject it
      const isAuthenticated = checkAccessToken?.data?.body?.status;
      if (isAuthenticated) return Promise.reject(customError);

      // If access token is not valid, we try to refresh it
      const res = await axiosInstance.post(API_URL + "/auth/refresh", {
        refreshToken: oldRefreshToken,
      });

      const accessToken =
        res?.data?.body?.access?.token || res?.data?.data?.access?.token;
      const refreshToken =
        res?.data?.body?.refresh?.token || res?.data?.data?.refresh?.token;
      store?.dispatch(setTemp({ accessToken }));
      store?.dispatch(setAuth({ refreshToken }));

      return axiosInstance(originalConfig);
    } catch (secondError: any) {
      return Promise.reject(customError);
    }
  }
);
