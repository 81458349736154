import { SInvestGoodfundTransactionItem } from "../models/api";
import { ReconTransactionData } from "../models/data";

export const goodfundTransactionResponseAdapter = (
  items: SInvestGoodfundTransactionItem[]
): ReconTransactionData => {
  const summary = {
    sub: 0,
    red: 0,
    switchIn: 0,
    switchOut: 0,
  };

  const rows = items.map((item) => {
    summary.sub += item.countSub;
    summary.red += item.countRed;
    summary.switchIn += item.countSwitchIn;
    summary.switchOut += item.countSwitchOut;

    return {
      productName: item.productName,
      productId: item.productId,
      sub: item.countSub,
      red: item.countRed,
      switchIn: item.countSwitchIn,
      switchOut: item.countSwitchOut,
    };
  });

  return { rows, summary };
};
