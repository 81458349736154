import { useTable, List } from "@refinedev/antd";
import {
  BaseRecord,
  useResource,
  CrudFilters,
  HttpError,
  useTranslate,
} from "@refinedev/core";
import { ListTable } from "components/molecules";
import { IFilterVariables, IResourceFilter } from "interfaces";
import { Alert, Col, Row } from "antd";
import { startCase } from "lodash";
import React, { useState } from "react";
import { RollingBalanceListDynamicFilter } from "./components/rollingBalanceListDynamicFilter";

export interface RollingBalanceListProps {
  title?: string;
}

export const RollingBalanceList: React.FC<RollingBalanceListProps> = ({
  title,
}) => {
  const [enableQuery, setEnableQuery] = useState(false);
  const { resource } = useResource();
  const translate = useTranslate();

  const { tableProps, searchFormProps, sorters, filters } = useTable<
    BaseRecord,
    HttpError,
    IFilterVariables
  >({
    resource: resource?.meta?.listResource || resource?.name,
    pagination: resource?.meta?.pagination,
    sorters: { initial: resource?.meta?.initialSorters },
    filters: {
      defaultBehavior: "replace",
      initial: resource?.meta?.initialFilters,
      permanent: resource?.meta?.permanentFilters,
    },
    queryOptions: { enabled: enableQuery },
    meta: { singlePage: true },
    onSearch: (filterParams) => {
      const filters: CrudFilters = [];

      resource?.meta?.filters?.forEach((filter: IResourceFilter) => {
        const filterParam =
          filterParams[filter?.key as keyof IFilterVariables] !== ""
            ? filterParams[filter?.key as keyof IFilterVariables]
            : undefined;

        if (filter?.type === "daterange") {
          filters.push({
            field: filter?.key,
            operator: "gte",
            value: filterParam?.[0]?.format("YYYY-MM-DD") ?? undefined,
          });
          filters.push({
            field: filter?.key,
            operator: "lte",
            value: filterParam?.[1]?.format("YYYY-MM-DD") ?? undefined,
          });
        } else {
          filters.push({
            field: filter?.key,
            operator: filter?.operator,
            value: filterParam,
          });
        }
      });

      return filters;
    },
  });

  return (
    <List
      resource={resource?.meta?.listResource || resource?.name}
      title={
        title ||
        resource?.meta?.label ||
        startCase(resource?.identifier || resource?.name || "")
      }
      headerProps={{ breadcrumb: undefined }}
    >
      {!enableQuery && (
        <Alert
          message={translate("notifications.pleaseUseFilters")}
          type="warning"
          showIcon
        />
      )}

      <div style={{ margin: "24px" }} />

      <Row gutter={[16, 16]}>
        {resource?.meta?.filters && (
          <Col
            xs={24}
            // lg={24} xl={6}
          >
            {/* <Card title="Filters" bordered={false}> */}
            <RollingBalanceListDynamicFilter
              formProps={searchFormProps}
              filters={resource?.meta?.filters}
              setEnableQuery={setEnableQuery}
            />
            {/* </Card> */}
          </Col>
        )}

        <Col
          xs={24}
          // xl={resourceInfo?.filters ? 18 : 24}
        >
          <ListTable
            resourceName={resource?.identifier || resource?.name}
            tableProps={{
              ...tableProps,
              loading: enableQuery && tableProps.loading,
            }}
            sorters={sorters}
            filters={filters}
            fixedColumns={resource?.meta?.fixedColumns}
          />
        </Col>
      </Row>
    </List>
  );
};
