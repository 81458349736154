import React, { useEffect } from "react";
import { BaseRecord, useTranslate } from "@refinedev/core";
import { IResourceFilter } from "interfaces";
import { startCase } from "lodash";
import {
  Button,
  Col,
  DatePicker,
  FormProps,
  Input,
  Row,
  TableProps,
} from "antd";
import { DynamicSelect } from "components/atoms";

export interface ListDynamicFilterProps {
  tableProps?: TableProps<BaseRecord>;
  formProps?: FormProps;
  filters?: IResourceFilter[];
}

export const ListDynamicFilter: React.FC<ListDynamicFilterProps> = ({
  formProps,
  filters,
}) => {
  const [values, setValues] = React.useState<any>({});
  const translate = useTranslate();

  useEffect(() => {
    filters?.forEach((filter) => {
      if (filter?.initialValue) {
        setValues((prev: any) => ({
          ...prev,
          [filter?.key]: filter?.initialValue,
        }));
      }
    });
  }, [filters]);

  return (
    <Row gutter={[12, 12]} align="bottom">
      {filters?.map((filter) => {
        return (
          <Col key={filter?.key} flex={1}>
            {filter.type === "daterange" ? (
              <DatePicker.RangePicker
                placeholder={filter?.placeholder as [string, string]}
                defaultValue={filter?.initialValue}
                style={{ width: "100%" }}
                format={"D MMM YYYY"}
                disabledDate={(date) =>
                  date < filter?.minDate || date > filter?.maxDate
                }
                onChange={(value) => {
                  setValues({ ...values, [filter?.key]: value });
                }}
              />
            ) : filter.type === "date" ? (
              <DatePicker
                placeholder={(filter?.placeholder || filter?.label) as string}
                defaultValue={filter?.initialValue}
                style={{ width: "100%" }}
                disabledDate={(date) =>
                  date < filter?.minDate || date > filter?.maxDate
                }
                // disabled={filter?.isDisabled}
                format={"D MMM YYYY"}
                onChange={(value) => {
                  const newValues = { ...values, [filter?.key]: value };
                  setValues(newValues);
                  formProps?.onFinish?.(newValues);
                }}
              />
            ) : filter.type === "select" ? (
              <DynamicSelect
                defaultValue={filter?.initialValue}
                style={{ width: "100%" }}
                formColumn={filter}
                options={filter?.options}
                disableGetOne
                placeholder={
                  (filter?.placeholder as string) ||
                  filter?.label ||
                  startCase(filter?.key?.replace(/id$/gi, ""))
                }
                onChange={(value) => {
                  const newValues = { ...values, [filter?.key]: value };
                  setValues(newValues);
                  formProps?.onFinish?.(newValues);
                }}
              />
            ) : (
              <Input
                defaultValue={filter?.initialValue}
                style={{ width: "100%" }}
                type={filter?.type}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                placeholder={
                  (filter?.placeholder as string) || startCase(filter?.key)
                }
                prefix={filter?.prefix}
                onChange={(e) => {
                  setValues({ ...values, [filter?.key]: e?.target?.value });
                }}
                onPressEnter={() => formProps?.onFinish?.(values)}
              />
            )}
          </Col>
        );
      })}

      <Col>
        <Button
          type="primary"
          style={{ width: "200px" }}
          onClick={() => formProps?.onFinish?.(values)}
        >
          {translate("buttons.search", "Search")}
        </Button>
      </Col>
    </Row>
  );
};
