import React, { useEffect, useState } from "react";

import {
  useTranslate,
  useNotification,
  useApiUrl,
  useGetLocale,
} from "@refinedev/core";
import { FundOutlined } from "@ant-design/icons";

import { axiosInstance } from "utils";
import { CounterCard } from "components/molecules";
import { Col } from "antd";

type KpdProps = {
  kpd: number;
  nkpd: number;
};

export const AssetUnderManagement: React.FC = () => {
  const translate = useTranslate();
  const locale = useGetLocale();
  const currentLocale = locale();
  const { open } = useNotification();

  const [totalData, setTotalData] = useState<KpdProps>({ kpd: 0, nkpd: 0 });
  const [newData, setNewData] = useState<KpdProps>({ kpd: 0, nkpd: 0 });
  const [newDataPercentage, setNewDataPercentage] = useState<KpdProps>({
    kpd: 0,
    nkpd: 0,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/dashboard/total-aum-and-changes`;
  const additionalInformationUrl = `${apiUrl}/dashboard/total-nav-per-product`;

  const formatter = new Intl.NumberFormat(currentLocale, {
    notation: "compact",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    setIsLoading(true);

    axiosInstance
      .get(url)
      .then((res) => {
        const data = res?.data?.body;

        const aumKpd = data?.aum?.kpd || 0;
        const aumNkpd = data?.aum?.nonKpd || 0;
        const changesKpd = data?.changes?.kpd || 0;
        const changesNkpd = data?.changes?.nonKpd || 0;
        const percentageKpd = (changesKpd / aumKpd) * 100 || 0;
        const percentageNkpd = (changesNkpd / aumNkpd) * 100 || 0;
        setTotalData({ kpd: aumKpd, nkpd: aumNkpd });
        setNewData({ kpd: changesKpd, nkpd: changesNkpd });
        setNewDataPercentage({ kpd: percentageKpd, nkpd: percentageNkpd });
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [translate, open, url, additionalInformationUrl]);

  const newDataWithStyle = (newData: number, newDataPercentage: number) => {
    return (
      <div
        style={{
          color: newData > 0 ? "#119761" : newData < 0 ? "#D80027" : "gray",
          fontWeight: "bold",
        }}
      >
        {newData >= 0 && "+"}
        {formatter.format(newData)}
        {` (${newDataPercentage?.toFixed(2)}%)`}
      </div>
    );
  };

  return (
    <>
      <Col xs={24} sm={24} xl={4}>
        <CounterCard
          title={translate(
            "page.dashboard.assetsUnderManagement.kpd",
            `AUM (KPD)`
          )}
          value={totalData?.kpd}
          subValue={newDataWithStyle(newData?.kpd, newDataPercentage?.kpd)}
          icon={<FundOutlined />}
          isLoading={isLoading}
        />
      </Col>
      <Col xs={24} sm={24} xl={4}>
        <CounterCard
          title={translate(
            "page.dashboard.assetsUnderManagement.nkpd",
            `AUM (Non-KPD)`
          )}
          value={totalData?.nkpd}
          subValue={newDataWithStyle(newData?.nkpd, newDataPercentage?.nkpd)}
          icon={<FundOutlined />}
          isLoading={isLoading}
        />
      </Col>
    </>
  );
};
