import React from "react";
import {
  IResourceComponentsProps,
  useInvalidate,
  useResource,
} from "@refinedev/core";
import { Create, UseFormProps, useForm } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { ButtonProps, Form } from "antd";
import { BackdateFormCard } from "./organisms/backdateFormCard";
import { TRANSACTION_TYPE } from "constants/transaction";

interface TransactionCreateBackdateProps extends IResourceComponentsProps {
  saveButtonProps?: ButtonProps;
}

export const TransactionCreateBackdate: React.FC<
  TransactionCreateBackdateProps
> = ({ saveButtonProps }) => {
  const { resource } = useResource();
  const invalidate = useInvalidate();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };

  if (resource?.meta?.defaultAction === "create") {
    useFormProps = {
      ...useFormProps,
      onMutationSuccess: () => {
        formProps?.form?.resetFields();
        invalidate({
          resource: resource?.identifier || resource?.name || "",
          invalidates: ["all"],
        });
      },
    };
  }

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    formLoading,
  } = useForm(useFormProps);

  const transactionTypeId = Form.useWatch("transactionTypeId", formProps?.form);

  return (
    <Create
      key={resource?.identifier || resource?.name}
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading,
        ...saveButtonProps,
      }}
    >
      <BackdateFormCard
        key={resource?.identifier || resource?.name}
        formProps={formProps}
        resource={resource}
        showSwitchIn={transactionTypeId === TRANSACTION_TYPE.SWT}
      />
    </Create>
  );
};
