import React, { useState } from "react";

import { BaseRecord, useList, useTranslate } from "@refinedev/core";

import { CalendarOutlined } from "@ant-design/icons";
import { Card, Empty, Table } from "antd";
import dayjs from "dayjs";

import { Text } from "../../../atoms/text";
import type { ColumnsType } from "antd/es/table";

const columns: ColumnsType<BaseRecord> = [
  {
    title: "Title",
    dataIndex: "publicHolidayRemarks",
    key: "publicHolidayRemarks",
  },
  {
    title: "Date",
    dataIndex: "publicHolidayDate",
    key: "publicHolidayDate",
    align: "right",
    fixed: "right",
    render(value, record, index) {
      return <Text size="sm">{dayjs(value).format("DD MMMM YYYY")}</Text>;
    },
  },
];

export const CalendarUpcomingEvents: React.FC = () => {
  const translate = useTranslate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const resource = "dashboard/public-holidays";

  const { data, isLoading } = useList({
    resource: resource,
    queryOptions: { enabled: !!resource },
    pagination: { pageSize: 6, current: currentPage },
    sorters: [{ field: "publicHolidayDate", order: "asc" }],
    filters: [
      {
        field: "date",
        operator: "gte",
        value: dayjs().format("YYYY-MM-DD"),
      },
    ],
    meta: { fields: ["id", "publicHolidayRemarks", "publicHolidayDate"] },
  });

  return (
    <Card
      headStyle={{ padding: "8px 16px", backgroundColor: "#0a78bd" }}
      bodyStyle={{ padding: "0 1rem", height: "100%", minHeight: "200px" }}
      style={{ height: "100%", minHeight: "200px" }}
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CalendarOutlined style={{ color: "#FFFF" }} />
          <Text size="sm" strong style={{ marginLeft: ".7rem", color: "#fff" }}>
            {translate("page.dashboard.holidays", `Holidays`)}
          </Text>
        </div>
      }
    >
      {!isLoading && !data?.data?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No upcoming events"
          style={{
            margin: 0,
            padding: 0,
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        />
      ) : (
        <Table
          rowKey="id"
          style={{ margin: "10px 0" }}
          columns={columns}
          dataSource={data?.data}
          showHeader={false}
          loading={isLoading}
          scroll={{ x: "max-content" }}
          pagination={{
            pageSize: 6,
            total: data?.total,
            onChange: setCurrentPage,
            disabled: false,
          }}
        />
      )}
    </Card>
  );
};
