import React, { useState, useEffect } from "react";
import { BaseRecord, useList } from "@refinedev/core";
import { Descriptions, Row, Col, FormProps, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { startCase } from "lodash";
import { IResourceFormColumn } from "interfaces";

import { getNameKey, useChildRecords } from "utils";
import { DynamicSelect } from "components/atoms";
import { DynamicDisplayItem } from "./dynamicDisplayItem";

interface DynamicSelectDetailsProps extends SelectProps {
  formColumn?: IResourceFormColumn;
  options?: DefaultOptionType[];
  placeholder?: string;
  formProps?: FormProps;
}

export const DynamicSelectDetails: React.FC<DynamicSelectDetailsProps> = ({
  formColumn,
  options,
  placeholder,
  formProps,
  ...rest
}) => {
  const meta = formColumn?.optionsMeta;

  const [selectedData, setSelectedData] = useState<BaseRecord>();

  const getApprovalStatuses = useList({
    resource: "static/approval-statuses",
    pagination: { mode: "off" },
    queryOptions: { retry: false, enabled: !!meta?.childApprovalStatus },
  });
  const approvalStatuses = getApprovalStatuses?.data?.data;
  const approvalStatusApproved = approvalStatuses?.find(
    (approvalStatus: BaseRecord) =>
      approvalStatus?.approvalStatusName?.toLowerCase() ===
      !!meta?.childApprovalStatus
  );

  const childRecords = useChildRecords({
    resourceName: meta?.childResourceName,
    foreignKey: meta?.childForeignKey,
    parentId: selectedData?.id,
    approvalStatus: approvalStatusApproved,
  });

  useEffect(() => {
    if (childRecords && childRecords?.length > 0) {
      setSelectedData((prev) => ({ ...prev, details: childRecords[0] }));
    }
  }, [childRecords]);

  return (
    <Row gutter={[32, 32]}>
      <Col flex={formColumn?.direction === "horizontal" ? "50%" : "100%"}>
        <DynamicSelect
          {...rest}
          formColumn={formColumn}
          options={formColumn?.options}
          placeholder={placeholder}
          formProps={formProps}
          onChange={rest?.onChange}
          setSelectedData={setSelectedData}
        />
      </Col>

      {meta?.displayColumns && (
        <Col flex={formColumn?.direction === "horizontal" ? "50%" : "100%"}>
          <Descriptions
            bordered
            column={1}
            size="small"
            labelStyle={{ width: "200px" }}
          >
            {meta?.displayColumns?.map((subColumn, index) => {
              const nameKey = getNameKey(selectedData);
              const label = subColumn?.label || startCase(subColumn?.key);
              const value =
                selectedData?.[subColumn?.key || "id"]?.[
                  subColumn?.childKey || nameKey || "id"
                ] || selectedData?.[subColumn?.key || "id"];

              return (
                <Descriptions.Item key={subColumn?.key || index} label={label}>
                  <DynamicDisplayItem
                    displayColumn={subColumn}
                    value={value}
                    displayType="show"
                  />
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Col>
      )}
    </Row>
  );
};
