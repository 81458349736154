import { useTranslate } from "@refinedev/core";
import { Card, Skeleton, theme } from "antd";
import { Text } from "components/atoms";
import { useMemo } from "react";
import { formatThousands } from "utils/number";

type SummaryCardItemProps = {
  loading: boolean;
  goodfundValue: number;
  SInvestValue: number;
  status: "initial" | "loading" | "available";
  isDifferent: boolean;
  title: string;
};

export const SummaryCardItem = ({
  loading,
  goodfundValue,
  SInvestValue,
  status,
  isDifferent,
  title,
}: SummaryCardItemProps) => {
  const translate = useTranslate();
  const { token } = theme.useToken();
  const goodfundDisplay = useMemo(
    () => formatThousands(goodfundValue),
    [goodfundValue]
  );
  const sinvestDisplay = useMemo(
    () => formatThousands(SInvestValue),
    [SInvestValue]
  );

  const redReject = "#ffa39e";
  const greenApprove = "#b7eb8f";

  const backgroundColor = useMemo(() => {
    if (status === "available") {
      if (isDifferent) {
        return redReject;
      }

      return greenApprove;
    }

    return "white";
  }, [status, isDifferent]);
  return (
    <Card
      bodyStyle={{
        background: backgroundColor,
        display: "flex",
        flexDirection: "column",
      }}
      size="small"
    >
      {loading ? (
        <Skeleton.Input active />
      ) : (
        <Text style={{ marginBottom: "16px" }} strong size="lg">
          {title}
        </Text>
      )}
      <div
        style={{
          minWidth: "140px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text size="sm" strong>
          {translate("page.recon.summary.item.goodfund", "CIS")}
        </Text>
        {loading ? (
          <Skeleton.Input size="small" active />
        ) : (
          <Text size="md" strong style={{ color: token?.colorPrimary }}>
            {goodfundDisplay}
          </Text>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text size="sm" strong>
          {translate("page.recon.summary.item.sinvest", "SInvest")}
        </Text>
        {loading ? (
          <Skeleton.Input size="small" active />
        ) : (
          <Text size="md" strong style={{ color: token?.colorPrimary }}>
            {sinvestDisplay}
          </Text>
        )}
      </div>
    </Card>
  );
};
