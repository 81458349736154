import React, { useState } from "react";
import {
  IResourceComponentsProps,
  UseFormProps,
  useNavigation,
  useResource,
  BaseRecord,
  useInvalidate,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Divider, Typography } from "antd";
import { IResourceFormColumn } from "interfaces";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation();
  const { resource } = useResource();
  const invalidate = useInvalidate();
  const { resource: childResource } = useResource(
    resource?.meta?.childResource
  );
  const [parentData, setParentData] = useState<BaseRecord>();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };

  if (resource?.meta?.defaultAction === "create") {
    useFormProps = {
      ...useFormProps,
      onMutationSuccess: () => {
        parentFormProps?.form?.resetFields();
        invalidate({
          resource: resource?.identifier || resource?.name || "",
          invalidates: ["all"],
        });
      },
    };
  }

  const { formProps: childFormProps, saveButtonProps: childSaveButtonProps } =
    useForm({
      resource: childResource?.name,
      redirect: false,
      onMutationSuccess: () =>
        list(resource?.identifier || resource?.name || "/"),
    });

  if (resource?.meta?.childResource) {
    useFormProps = {
      ...useFormProps,
      redirect: false,
      onMutationSuccess: (data) => {
        setParentData(data?.data);
        childFormProps?.form?.setFieldsValue({
          [resource?.meta?.childForeignKey]: data?.data?.id,
        });
        childFormProps?.form?.submit();
      },
    };

    childResource?.meta?.formColumns?.find(
      (formColumn: IResourceFormColumn) => {
        if (formColumn?.key === resource?.meta?.childForeignKey) {
          formColumn.isDisabled = true;
          return true;
        }
        return false;
      }
    );
  }

  // Parent form props is called at the end of the function
  // to ensure that the useFormProps is defined based on the child resource
  const { formProps: parentFormProps, saveButtonProps: parentSaveButtonProps } =
    useForm(useFormProps);

  return (
    <Create
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={
        !parentData ? parentSaveButtonProps : childSaveButtonProps
      }
    >
      {/* PARENT FORM */}
      <FormCard formProps={parentFormProps} resource={resource} />

      {resource?.meta?.childResource && (
        <>
          <Divider />
          <Typography.Title level={3}>
            {`${pluralize.singular(
              childResource?.meta?.label || startCase(childResource?.name || "")
            )}`}
          </Typography.Title>

          {/* CHILD FORM */}
          <FormCard formProps={childFormProps} resource={childResource} />
        </>
      )}
    </Create>
  );
};
