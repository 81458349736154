import React, { useEffect, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useResource,
  HttpError,
  useParsed,
  useTranslate,
  useUpdate,
  BaseRecord,
  useInvalidate,
  useCan,
} from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Space, TimelineItemProps, StepProps } from "antd";
import { mapStatus, useChildRecords, mapKycSteps } from "utils";
import {
  CustomErrorComponent,
  ShowItemRecursor,
  ShowLayout,
} from "components/organisms";
import { ButtonWithConfirmation } from "components/atoms";
import { PoweroffOutlined } from "@ant-design/icons";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface DefaultShowProps extends IResourceComponentsProps {
  id?: string | number;
}

export const DefaultShow: React.FC<DefaultShowProps> = ({ id }) => {
  const { resource } = useResource();
  const { id: idFromParams } = useParsed();
  const translate = useTranslate();
  const invalidate = useInvalidate();

  const { queryResult } = useShow({
    resource: resource?.meta?.showResource,
    id: id || idFromParams,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // handle the population of the data's status
  const statusData = useChildRecords({
    resourceName: resource?.meta?.statusResource,
    foreignKey: resource?.meta?.statusForeignKey,
    parentId: record?.id,
  });

  const [timelineItemProps, setTimelineItemProps] =
    useState<TimelineItemProps[]>();
  useEffect(() => {
    if (statusData && statusData.length > 0)
      setTimelineItemProps(mapStatus({ data: statusData }));
  }, [resource, statusData, record]);

  const [stepProps, setStepProps] = useState<StepProps[]>();
  useEffect(() => {
    if (record?.progressBar !== undefined)
      setStepProps(mapKycSteps({ data: record.progressBar }));
  }, [resource, statusData, record]);

  const { mutate: mutateUpdate, isLoading: isDeactivating } =
    useUpdate<BaseRecord>();

  const handleUpdateActive = async () => {
    mutateUpdate(
      {
        resource: resource?.meta?.showToggleResource,
        id: id || idFromParams || record?.id || "",
        values: { isActive: !record?.isActive },
        meta: { method: "patch" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const canUpdateActive = useCan({
    resource: resource?.meta?.showToggleResource,
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          breadcrumb: undefined,
          extra: (
            <>
              {resource?.meta?.showToggleResource && (
                <ButtonWithConfirmation
                  type="primary"
                  danger={record?.isActive}
                  onClick={handleUpdateActive}
                  loading={isDeactivating}
                  disabled={!canUpdateActive?.data?.can}
                  icon={<PoweroffOutlined />}
                >
                  {record?.isActive
                    ? translate("buttons.deactivate")
                    : translate("buttons.activate")}
                </ButtonWithConfirmation>
              )}
              {resource?.meta?.canEdit && <EditButton />}

              <CustomRefreshButton />
            </>
          ),
        }}
      >
        <ShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
          timelineItemProps={timelineItemProps}
          stepProps={stepProps}
        />
      </Show>

      <ShowItemRecursor
        parentName={resource?.identifier || resource?.name}
        parentData={record}
      />
    </Space>
  );
};
