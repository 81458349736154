import { ConfigProvider, theme } from "antd";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGeneral } from "redux/slices/generalSlice";
import { RootState } from "redux/store";

type ColorModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const colorModeFromStorage = useSelector(
    (state: RootState) => state?.general?.colorMode
  );
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  const systemPreference = isSystemPreferenceDark ? "dark" : "light";
  const [mode, setMode] = useState(colorModeFromStorage || systemPreference);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGeneral({ colorMode: mode }));

    const bodyElement = document.body;
    bodyElement.style.colorScheme = mode;
  }, [dispatch, mode]);

  const { darkAlgorithm, defaultAlgorithm } = theme;

  return (
    <ColorModeContext.Provider value={{ mode, setMode }}>
      <ConfigProvider
        theme={{
          token: { colorPrimary: "#088adb", borderRadius: 0 },
          algorithm: mode === "light" ? defaultAlgorithm : darkAlgorithm,
        }}
      >
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
