import React from "react";
import { useNavigation, useResource, useUpdate } from "@refinedev/core";
import { Col, Form, FormProps, Row } from "antd";
import { CustomResourceProps, IResourceFormColumn } from "interfaces";

import {
  isObject,
  getKeysByColumnType,
  imagesToBase64,
  kycPayloadToForm,
} from "utils";
import { FormImageItem } from "components/atoms";
import { FormDynamicItem } from "components/molecules";

export interface KycRevisionFormProps {
  formProps: FormProps;
  resource?: CustomResourceProps;
}

export const KycRevisionForm: React.FC<KycRevisionFormProps> = ({
  formProps,
  resource,
}) => {
  const { resource: defaultResource, action } = useResource();
  const { mutate } = useUpdate();
  const { list } = useNavigation();

  const formColumns: IResourceFormColumn[] = (resource || defaultResource)?.meta
    ?.formColumns;

  const newInitialValueList = Object.entries(
    formProps?.initialValues || {}
  ).map(([key, value]) => {
    if (isObject(value) && key !== "revisionNotes")
      return { [`${key}Id`]: value?.id };
    else return { [key]: value };
  });

  const initialValues = Object.assign({}, ...newInitialValueList);

  const reformatAndSubmit = async (values: any) => {
    if (formProps?.onFinish) {
      resource?.meta?.submitCallback?.(values);

      // find the value which formColumn's type is "image"
      const imageKeys = getKeysByColumnType(values, formColumns, "image");
      // if the value is string, set the initial values of image fields to undefined
      imageKeys?.forEach((key) => {
        if (
          typeof values?.[key] === "string" &&
          !values?.[key].includes("base64")
        ) {
          values[key] = undefined;
        }
      });

      // Change daterange format to date
      const dateRangeKeys = getKeysByColumnType(
        values,
        formColumns,
        "daterange"
      );
      dateRangeKeys?.forEach((key) => {
        const formColumn = formColumns?.find(
          (formColumn) => formColumn?.key === key
        );

        values[key]?.forEach((date: any, index: number) => {
          const defaultKey = index === 0 ? "dateFrom" : "dateTo";

          Object.assign(values, {
            [formColumn?.rangeKeys?.[index] || defaultKey]:
              date?.format("YYYY-MM-DD"),
          });
          delete values[key];
        });
      });

      // find the values inside values that are null or undefined, and remove them
      Object?.entries(values)?.forEach(([key, value]) => {
        if (value === null || value === undefined || value === "")
          delete values[key];
      });

      const newValuesPromise = await imagesToBase64(values);
      const newValues = Object.fromEntries(newValuesPromise);

      mutate(
        {
          resource: resource?.name || "",
          id: formProps?.initialValues?.id || "",
          values: newValues,
          meta: { method: "patch" },
        },
        {
          onSuccess: () => {
            list(resource?.identifier || resource?.name || "");
          },
        }
      );
    }
  };

  const revisionColumns = kycPayloadToForm({ data: initialValues });

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={initialValues || formProps?.initialValues}
      onFinish={reformatAndSubmit}
    >
      <Row gutter={32}>
        <Col xs={24} xl={6}>
          <Row justify="center" style={{ position: "sticky", top: "100px" }}>
            {formColumns
              ?.filter((formColumn) => formColumn?.type === "image")
              ?.map((formColumn) => (
                <Col xs={24} key={formColumn?.key}>
                  <FormImageItem
                    formColumn={formColumn}
                    required={
                      action === "edit"
                        ? formColumn?.requiredOnEdit
                        : formColumn?.requiredOnCreate
                    }
                    formProps={formProps}
                    revisionColumns={revisionColumns}
                  />
                </Col>
              ))}
          </Row>
        </Col>

        <Col
          xs={24}
          xl={
            formColumns?.find((formColumn) => formColumn?.type === "image")
              ? 18
              : 24
          }
        >
          <Row gutter={32}>
            {formColumns
              ?.filter((formColumn) => !(formColumn?.type === "image"))
              ?.map((formColumn) => (
                <FormDynamicItem
                  key={`${formColumn?.key} ${
                    resource?.identifier || resource?.name || ""
                  }`}
                  formColumn={formColumn}
                  formProps={formProps}
                  required={
                    action === "edit"
                      ? formColumn?.requiredOnEdit
                      : formColumn?.requiredOnCreate
                  }
                  revisionColumns={revisionColumns}
                />
              ))}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
