import React from "react";

import type { UseFormProps } from "@refinedev/antd";
import { useForm } from "@refinedev/antd";

import { EditOutlined } from "@ant-design/icons";
import type { FormItemProps, FormProps } from "antd";
import { Button, Form, Skeleton } from "antd";

import styles from "./index.module.css";
import { Text } from "components/atoms";
import {
  BaseRecord,
  CreateResponse,
  UpdateResponse,
  useTranslate,
} from "@refinedev/core";

type SingleElementFormProps = {
  icon?: React.ReactNode;
  formItemProps?: FormItemProps;
  extra?: React.ReactNode;
  view?: React.ReactNode;
  state?: "empty" | "form" | "view";
  onUpdate?: (
    data: UpdateResponse<BaseRecord> | CreateResponse<BaseRecord>,
    variables: any,
    context: any,
    isAutoSave?: boolean | undefined
  ) => void;
  onCancel?: () => void;
  onClick?: () => void;
  loading?: boolean;
  style?: React.CSSProperties;
  useFormProps?: UseFormProps;
  formProps?: FormProps;
} & React.PropsWithChildren;

export const SingleElementForm: React.FC<SingleElementFormProps> = ({
  state = "view",
  view,
  icon,
  formItemProps,
  children,
  onClick,
  onUpdate,
  onCancel,
  loading,
  style,
  extra,
  useFormProps,
  formProps: formPropsFromProp,
}) => {
  const { formProps, saveButtonProps } = useForm({
    action: "edit",
    redirect: false,
    autoSave: { enabled: false },
    queryOptions: { enabled: false },
    onMutationSuccess: onUpdate,
    mutationMode: "optimistic",
    ...useFormProps,
  });
  const translate = useTranslate();

  return (
    <Form layout="vertical" {...formProps} {...formPropsFromProp}>
      <div className={styles.container} style={style}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.content}>
          <div className={styles.input}>
            <Text size="sm" type="secondary" className={styles.label}>
              {formItemProps?.label}
            </Text>
            {loading && (
              <Skeleton.Input className={styles.skeleton} size="small" active />
            )}
            {state === "form" && !loading && (
              <div className={styles.formItem}>
                <Form.Item {...formItemProps} noStyle>
                  {children}
                </Form.Item>
                {extra}
              </div>
            )}
            {state === "view" && view}
          </div>

          {state === "form" && (
            <div className={styles.buttons}>
              <Button onClick={() => onCancel?.()}>
                {translate("buttons.cancel", "Cancel")}
              </Button>
              <Button type="primary" {...saveButtonProps}>
                {translate("buttons.save", "Save")}
              </Button>
            </div>
          )}
        </div>

        {state === "view" && !!useFormProps && (
          <div className={styles.actions}>
            <Button onClick={onClick} icon={<EditOutlined />} />
          </div>
        )}
      </div>
    </Form>
  );
};
