import React, { useState } from "react";

import { BaseRecord, useList, useTranslate } from "@refinedev/core";

import { DollarOutlined } from "@ant-design/icons";
import { Card, Empty, Table } from "antd";

import { Text } from "../../../atoms/text";
import type { ColumnsType } from "antd/es/table";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "IDR",
});

const sumNumber: React.FC<{ value: number }> = ({ value }) => {
  if (value > 0)
    return (
      <Text strong size="md" style={{ color: "#119761" }}>
        {formatter.format(value)}
      </Text>
    );
  else if (value < 0) {
    return (
      <Text strong size="md" style={{ color: "#D80027" }}>
        {formatter.format(value)}
      </Text>
    );
  } else {
    return <Text size="md">{formatter.format(value)}</Text>;
  }
};

const freqNumber: React.FC<{ value: number }> = ({ value }) => (
  <Text strong size="md">
    {value}
  </Text>
);

const unitNumber: React.FC<{ value: number }> = ({ value }) => {
  // Split the integer and fractional parts
  const [integerPart, fractionalPart] = value.toFixed(4).split('.');

  // Add commas to the integer part
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer part with the fractional part
  const formattedValue = `${integerWithCommas}.${fractionalPart}`;

  return (
    <Text strong size="md">
      {formattedValue}
    </Text>
  );
};


const columns: ColumnsType<BaseRecord> = [
  {
    title: "Product",
    dataIndex: "productName",
    key: "productName",
    render(value, record, index) {
      return freqNumber({ value: value });
    },
  },
  {
    title: "Freq Sub",
    dataIndex: "countSub",
    key: "countSub",
    render(value, record, index) {
      return freqNumber({ value: value });
    },
  },
  {
    title: "Total Sub",
    dataIndex: "sumSub",
    key: "sumSub",
    render(value, record, index) {
      return sumNumber({ value: value });
    },
  },
  {
    title: "Freq Red",
    dataIndex: "countRed",
    key: "countRed",
    render(value, record, index) {
      return freqNumber({ value: value });
    },
  },
  {
    title: "Total Red (Nominal)",
    dataIndex: "sumRedNominal",
    key: "sumRedNominal",
    render(value, record, index) {
      return sumNumber({ value: value });
    },
  },
  {
    title: "Total Red (Unit)",
    dataIndex: "sumRedUnit",
    key: "sumRedUnit",
    render(value, record, index) {
      return unitNumber({ value: value });
    },
  },
  {
    title: "Freq Swt In",
    dataIndex: "countSwitchIn",
    key: "countSwitchIn",
    render(value, record, index) {
      return freqNumber({ value: value });
    },
  },
  {
    title: "Freq Swt Out",
    dataIndex: "countSwitchOut",
    key: "countSwitchOut",
    render(value, record, index) {
      return freqNumber({ value: value });
    },
  },
  {
    title: "Total Swt Out (Nominal)",
    dataIndex: "sumSwitchOutNominal",
    key: "sumSwitchOutNominal",
    render(value, record, index) {
      return sumNumber({ value: value });
    },
  },
  {
    title: "Total Swt Out (Unit)",
    dataIndex: "sumSwitchOutUnit",
    key: "sumSwitchOutUnit",
    render(value, record, index) {
      return unitNumber({ value: value });
    },
  },
];

export const TotalProductTransactions: React.FC = () => {
  const translate = useTranslate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const resource = "dashboard/total-transaction-per-product";

  const { data, isLoading } = useList({
    resource: resource,
    queryOptions: { enabled: !!resource },
    pagination: { pageSize: 5, current: currentPage },
    sorters: [{ field: "id", order: "asc" }],
    meta: {
      fields: [
        "id",
        "productName",
        "sumSub",
        "countSub",
        "sumRed",
        "countRed",
        "sumSwitchIn",
        "countSwitchIn",
        "sumSwitchOut",
        "countSwitchOut",
      ],
    },
  });

  return (
    <Card
      headStyle={{ padding: "8px 16px", backgroundColor: "#434343" }}
      bodyStyle={{ padding: "0 1rem", height: "100%", minHeight: "200px" }}
      style={{ height: "100%", minHeight: "200px" }}
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <DollarOutlined style={{ color: "#FFFF" }} />
          <Text size="sm" strong style={{ marginLeft: ".7rem", color: "#fff" }}>
            {translate(
              "page.dashboard.transactionSummary",
              `Transaction Summary`
            )}
          </Text>
        </div>
      }
    >
      {!isLoading && !data?.data?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{
            margin: 0,
            padding: 0,
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        />
      ) : (
        <Table
          rowKey="productName"
          style={{ marginTop: "10px" }}
          columns={columns}
          dataSource={data?.data}
          loading={isLoading}
          scroll={{ x: "max-content" }}
          pagination={{
            pageSize: 5,
            total: data?.total,
            onChange: setCurrentPage,
            disabled: false,
            showSizeChanger: false,
          }}
        />
      )}
    </Card>
  );
};
