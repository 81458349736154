import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IAccessControlCacheState {
  [key: string]: boolean;
}

const initialState: IAccessControlCacheState = {};

export const accessControlCacheSlice = createSlice({
  name: "accessControlCache",
  initialState,
  reducers: {
    setAccessControlCache: (
      state,
      action: PayloadAction<IAccessControlCacheState>
    ) => {
      Object.entries(action?.payload)?.forEach(([key, value]) => {
        if (value !== undefined)
          state[key as keyof IAccessControlCacheState] = value;
      });
    },
    resetAccessControlCache: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setAccessControlCache, resetAccessControlCache } =
  accessControlCacheSlice.actions;

export default accessControlCacheSlice.reducer;
