import { FormInstance } from "rc-field-form";

export const requiredFromColumnRule =
  (value: string | number | number[] | string[], key: string) =>
  (form: FormInstance) => {
    const formValue = form.getFieldValue(key);

    if (Array.isArray(value)) {
      return { required: value.find((item) => item === formValue) };
    }

    return {
      required: value === formValue,
    };
  };
