import { BaseRecord, useOne, useUpdate, useTranslate } from "@refinedev/core";
import {
  UserOutlined,
  SafetyCertificateOutlined,
  MailOutlined,
  TeamOutlined,
  CalendarOutlined,
  LoginOutlined,
  AimOutlined,
  ClockCircleOutlined,
  ShopOutlined,
  LineChartOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { Card, Drawer, Space, Spin, Typography } from "antd";

import styles from "./index.module.css";
import { CustomAvatar, Text } from "components/atoms";
import {
  ChangePasswordForm,
  DynamicDisplayItem,
  SingleElementForm,
} from "components/molecules";
import { startCase } from "lodash";
import { setUser } from "redux/slices/userSlice";
import { useDispatch } from "react-redux";

type Props = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
};

export const AccountSettings = ({ opened, setOpened }: Props) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { mutate: updateMutation } = useUpdate<BaseRecord>();
  const { data, isLoading, isError } = useOne<BaseRecord>({
    resource: "users",
    id: "me",
    queryOptions: { enabled: opened },
  });
  const {
    id,
    name,
    avatar,
    email,
    role,
    isEmailVerified,
    emailVerifiedAt,
    lastLoginAt,
    lastLoginIp,
    groupProducts,
    userBranches,
  } = data?.data ?? {};

  const updateName = (value: string) => {
    updateMutation(
      {
        resource: "users",
        id: id || "",
        values: { name: value },
        meta: { method: "patch" },
      },
      {
        onSuccess: (data, variables, context) => {
          dispatch(setUser(variables?.values));
        },
      }
    );
  };

  if (isError) {
    setOpened(false);
    return null;
  }

  const emailItems = [
    {
      name: "email",
      label: translate("accountSettings.emailAddress", "Email Address"),
      icon: <MailOutlined className="tertiary" />,
      value: email,
    },
    {
      name: "isEmailVerified",
      label: translate("accountSettings.isEmailVerified", "Is Email Verified"),
      icon: <SafetyCertificateOutlined className="tertiary" />,
      value: isEmailVerified,
    },
    {
      name: "emailVerifiedAt",
      label: translate("accountSettings.emailVerifiedAt", "Email Verified At"),
      icon: <CalendarOutlined className="tertiary" />,
      value: emailVerifiedAt,
    },
    {
      name: "role",
      label: translate("accountSettings.userGroup", "User Group"),
      icon: <TeamOutlined className="tertiary" />,
      value: startCase(role),
    },
    {
      name: "groupProduct",
      label: translate("accountSettings.productGroup", "Product Group"),
      icon: <LineChartOutlined className="tertiary" />,
      value: groupProducts,
    },
    {
      name: "branch",
      label: translate("accountSettings.branch", "Branch"),
      icon: <ShopOutlined className="tertiary" />,
      value: userBranches?.map((item: any) => ({ ...item?.branch })),
    },
  ];

  const loggingItems = [
    {
      name: "lastLoginAt",
      label: translate("accountSettings.lastLoginAt", "Last Login At"),
      icon: <ClockCircleOutlined className="tertiary" />,
      value: lastLoginAt,
    },
    {
      name: "lastLoginIp",
      label: translate("accountSettings.lastLoginIp", "Last Login IP"),
      icon: <AimOutlined className="tertiary" />,
      value: lastLoginIp,
    },
  ];

  return (
    <Drawer
      title={translate("accountSettings.accountSettings", "Account Settings")}
      open={opened}
      onClose={() => setOpened(false)}
      destroyOnClose
      width={756}
      styles={{ body: { padding: 0, maxWidth: "100%" } }}
    >
      {isLoading ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.name}>
            <CustomAvatar
              style={{ marginRight: "2rem", flexShrink: 0, fontSize: "40px" }}
              size={96}
              src={avatar}
              name={name}
            />
            <Typography.Title level={3} editable={{ onChange: updateName }}>
              {name}
            </Typography.Title>
          </div>

          <Card
            title={
              <Space size={15}>
                <UserOutlined />
                <Text size="sm">
                  {translate("accountSettings.profile", "Profile")}
                </Text>
              </Space>
            }
            headStyle={{ padding: "0 12px" }}
            bodyStyle={{ padding: 0 }}
          >
            {emailItems.map(({ name, label, icon, value }) => (
              <SingleElementForm
                key={name}
                icon={icon}
                formProps={{ initialValues: { [name]: value } }}
                formItemProps={{ name, label: label || startCase(name) }}
                view={
                  <DynamicDisplayItem
                    value={value}
                    displayColumn={{ key: name }}
                  />
                }
              />
            ))}
          </Card>

          <Card
            title={
              <Space size={15}>
                <KeyOutlined />
                <Text size="sm">
                  {translate(
                    "accountSettings.authentication",
                    "Authentication"
                  )}
                </Text>
              </Space>
            }
            headStyle={{ padding: "0 12px" }}
            bodyStyle={{ padding: 0 }}
          >
            <ChangePasswordForm />
          </Card>

          <Card
            title={
              <Space size={15}>
                <LoginOutlined />
                <Text size="sm">{translate("accountSettings.log", "Log")}</Text>
              </Space>
            }
            headStyle={{ padding: "0 12px" }}
            bodyStyle={{ padding: 0 }}
          >
            {loggingItems.map(({ name, label, icon, value }) => (
              <SingleElementForm
                key={name}
                icon={icon}
                formProps={{ initialValues: { [name]: value } }}
                formItemProps={{ name, label: label || startCase(name) }}
                view={
                  <DynamicDisplayItem
                    value={value}
                    displayColumn={{ key: name }}
                  />
                }
              />
            ))}
          </Card>
        </div>
      )}
    </Drawer>
  );
};
