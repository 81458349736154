import React, { useEffect, useState } from "react";
import { InputProps, FormProps, Space, Input, InputNumber, Form } from "antd";
import { IResourceFormColumn } from "interfaces";
import { useList } from "@refinedev/core";

interface InputWithConverterProps extends InputProps {
  formColumn?: IResourceFormColumn;
  formProps?: FormProps;
}

export const InputWithConverter: React.FC<InputWithConverterProps> = ({
  formColumn,
  formProps,
  ...rest
}) => {
  const meta = formColumn?.optionsMeta;

  const filterValue = Form.useWatch(
    meta?.filterFromOtherColumn?.formKey,
    formProps?.form
  );

  const getNav = useList({
    resource: meta?.resourceName,
    pagination: { pageSize: 1 },
    queryOptions: { enabled: !!meta?.resourceName && !!filterValue },
    sorters: meta?.initialSorters,
    filters: [
      ...(meta?.initialFilters || []),
      {
        field: meta?.filterFromOtherColumn?.filterKey || "",
        operator: "eq",
        value: filterValue,
      },
    ],
  });

  const multiplier = getNav?.data?.data?.[0]?.[meta?.valueKey || ""] || 0;

  const [nominal, setNominal] = useState<number | undefined>(undefined);

  // calculate nominal based on inputted unit
  const unitToNominal = (e: any) => {
    rest?.onChange?.(parseFloat(e?.target?.value || 0) as any);
    const nominal = ((e?.target?.value || 0) * (multiplier || 0)).toFixed(4);
    setNominal(parseFloat(nominal));
  };

  // calculate unit based on inputted nominal
  const nominalToUnit = (value: any) => {
    setNominal(value || 0);
    const unit = ((value || 0) / (multiplier || 0)).toFixed(4);
    rest?.onChange?.(Number(unit) as any);
  };

  useEffect(() => {
    const nominal = (
      ((rest?.value as number) || 0) * (multiplier || 0)
    ).toFixed(4);
    setNominal(parseFloat(nominal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplier]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Input
        {...rest}
        type="number"
        style={{ width: "100%" }}
        disabled={formColumn?.isDisabled || !multiplier}
        onChange={unitToNominal}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
      />

      <InputNumber
        style={{ width: "100%" }}
        disabled={formColumn?.isDisabled || !multiplier}
        addonBefore="Rp"
        value={nominal}
        onChange={nominalToUnit}
        formatter={(value) => {
          const [integerPart, decimalPart] = `${value}`.split(".");
          const formattedIntegerPart = integerPart.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
          return decimalPart
            ? `${formattedIntegerPart}.${decimalPart}`
            : formattedIntegerPart;
        }}
        parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
      />
    </Space>
  );
};
