import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import thunk from "redux-thunk";
import CryptoJS from 'crypto-js';
import authSlice from "./slices/authSlice";
import tempSlice from "./slices/tempSlice";
import generalSlice from "./slices/generalSlice";
import userSlice from "./slices/userSlice";
import accessControlCacheSlice from "./slices/accessControlCacheSlice";

const SECRET_KEY =  JSON.stringify(process.env) || "my-super-secret-key"

const encryptor = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    return CryptoJS.AES.encrypt(JSON.stringify(inboundState), SECRET_KEY).toString();
  },
  // transform state being rehydrated
  (outboundState, key) => {
    const bytes = CryptoJS.AES.decrypt(outboundState, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  },
);

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["temp", "accessControlCache"],
  transforms: [encryptor] 
};

const rootReducer = combineReducers({
  accessControlCache: accessControlCacheSlice,
  auth: authSlice,
  general: generalSlice,
  temp: tempSlice,
  user: userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
