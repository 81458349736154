import { CustomResourceProps } from "interfaces";
import { FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import { ReportCreate } from "components";
import dayjs from "dayjs";

const generateReportResources: CustomResourceProps[] = [
  {
    name: "reports/opening-account",
    identifier: "report/generate/opening-account",
    meta: {
      label: "Opening Account",
      defaultAction: "create",
      canList: true,
      canCreate: true,
      listComponent: <ReportCreate />,
      formColumns: [
        {
          key: "date",
          type: "daterange",
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["dateFrom", "dateTo"],
          maxDate: dayjs().endOf("day"),
          requiredOnCreate: true,
        },
        {
          key: "religionId",
          type: "select",
          optionsMeta: { resourceName: "static/religions" },
        },
        {
          key: "cityId",
          type: "select",
          optionsMeta: { resourceName: "static/cities" },
        },
        {
          key: "occupationId",
          type: "select",
          optionsMeta: { resourceName: "static/occupations" },
        },
        {
          key: "educationalBackgroundId",
          type: "select",
          optionsMeta: { resourceName: "static/educational-backgrounds" },
        },
        {
          key: "incomeLevelId",
          type: "select",
          optionsMeta: { resourceName: "static/income-levels" },
        },
        {
          key: "sourceOfFundId",
          type: "select",
          optionsMeta: { resourceName: "static/source-of-funds" },
        },
        {
          key: "kycStatus",
          label: "KYC Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/kyc-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "investmentObjectiveId",
          type: "select",
          optionsMeta: { resourceName: "static/investment-objectives" },
        },
        {
          key: "riskProfileId",
          type: "select",
          optionsMeta: { resourceName: "static/risk-profiles" },
        },
        {
          key: "genderId",
          type: "select",
          optionsMeta: { resourceName: "static/genders" },
        },
        {
          key: "maritalStatusId",
          type: "select",
          optionsMeta: { resourceName: "static/marital-statuses" },
        },
        {
          key: "bankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
        },
        {
          key: "agentId",
          type: "select",
          optionsMeta: { resourceName: "agents" },
        },
      ],
    },
  },
  {
    name: "reports/risk-profiles",
    identifier: "report/generate/risk-profiles",
    meta: {
      label: "Customer Risk Profile",
      defaultAction: "create",
      canList: true,
      canCreate: true,
      listComponent: <ReportCreate />,
      formColumns: [
        {
          key: "kycVerifiedAt",
          type: "daterange",
          label: "KYC Verified At",
          requiredOnCreate: true,
          span: 2,
          placeholder: ["KYC Verified At (Start)", "KYC Verified At (End)"],
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "reports/customer-saldo",
    identifier: "report/generate/customer-saldo",
    meta: {
      label: "Customer Balance",
      defaultAction: "create",
      canList: true,
      canCreate: true,
      listComponent: <ReportCreate />,
      formColumns: [
        {
          key: "date",
          type: "daterange",
          label: "Date",
          span: 2,
          requiredOnCreate: true,
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
            disableGetOne: false,
          },
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
      ],
    },
  },
  {
    name: "reports/transactions",
    identifier: "report/generate/transactions",
    meta: {
      label: "Transactions",
      defaultAction: "create",
      canList: true,
      canCreate: true,
      listComponent: <ReportCreate />,
      formColumns: [
        {
          key: "date",
          type: "daterange",
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["startDate", "endDate"],
          maxDate: dayjs().endOf("day"),
          requiredOnCreate: true,
        },
        {
          key: "paymentMethodId",
          type: "select",
          optionsMeta: { resourceName: "static/payment-methods" },
        },
        {
          key: "transactionStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "transactionTypeId",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "branchId",
          type: "select",
          optionsMeta: {
            resourceName: "branches",
          },
        },
        {
          key: "agentId",
          type: "select",
          optionsMeta: {
            resourceName: "agents",
          },
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
            disableGetOne: false,
          },
        },
        {
          key: "promoCode",
          type: "text",
        },
      ],
    },
  },
  {
    name: "reports/transaction-batch",
    identifier: "report/generate/transaction-batch",
    meta: {
      label: "Transactions Batch",
      defaultAction: "create",
      canList: true,
      canCreate: true,
      listComponent: <ReportCreate />,
    },
  },
  {
    name: "reports/transaction-batch/sub-red",
    identifier: "report/generate/transaction-batch/sub-red",
    meta: {
      label: "Transactions Batch (Sub & Red)",
      defaultAction: "create",
      canList: true,
      canCreate: true,
      listComponent: <ReportCreate />,
    },
  },
];

export const reportResources: CustomResourceProps[] = [
  {
    name: "report",
    meta: {
      icon: <FileTextOutlined />,
    },
  },
  {
    name: "report/generate",
    meta: {
      label: "Generate",
      parent: "report",
      icon: <FileAddOutlined />,
    },
  },
  ...generateReportResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "report/generate",
    },
  })),

  {
    name: "reports/histories",
    meta: {
      label: "Histories",
      parent: "report",
      canList: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      filters: [
        {
          key: "reportTypeId",
          type: "select",
          optionsMeta: {
            resourceName: "reports/types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "reportType", label: "Report Type" },
        { key: "createdBy", label: "Downloaded By" },
        { key: "createdAt", label: "Downloaded At" },
      ],
    },
  },
];
