import React from "react";
import { Col, Row } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { ButtonWithConfirmation } from "components/atoms";
import { useTranslate } from "@refinedev/core";
import { IResourceFormColumn } from "interfaces";

export interface ApproveButtonsProps {
  disableApprove?: boolean;
  disableReject?: boolean;
  remarkResource?: IResourceFormColumn;
  onApproveRemark?: (remark: string) => void;
  onRejectRemark?: (remark: string) => void;
  onClick: ({ isApproved }: { isApproved: boolean }) => void;
  isLoading?: boolean;
  approveLabel?: string;
  rejectLabel?: string;
}

export const ApproveButtons: React.FC<ApproveButtonsProps> = ({
  disableApprove,
  disableReject,
  remarkResource,
  onApproveRemark,
  onRejectRemark,
  onClick,
  isLoading,
  approveLabel,
  rejectLabel,
}) => {
  const translate = useTranslate();

  return (
    <Row gutter={[8, 8]} style={{ justifyContent: "flex-end" }}>
      {/* APPROVE BUTTON */}
      <Col>
        <ButtonWithConfirmation
          type="primary"
          disabled={disableApprove}
          icon={<CheckCircleOutlined />}
          onChangeRemark={onApproveRemark}
          onClick={() => onClick({ isApproved: true })}
          loading={isLoading}
        >
          {approveLabel || translate("buttons.approve", "Approve")}
        </ButtonWithConfirmation>
      </Col>

      {/* REJECT BUTTON */}
      <Col>
        <ButtonWithConfirmation
          danger
          type="primary"
          disabled={disableReject}
          icon={<CloseCircleOutlined />}
          onChangeRemark={onRejectRemark}
          remarkResource={remarkResource}
          onClick={() => onClick({ isApproved: false })}
          loading={isLoading}
        >
          {rejectLabel || translate("buttons.reject", "Reject")}
        </ButtonWithConfirmation>
      </Col>
    </Row>
  );
};
