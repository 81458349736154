import { AxiosInstance } from "axios";
import queryString from "query-string";
import { DataProvider, LogicalFilter } from "@refinedev/core";
import { axiosInstance, mapSorter, mapFilter } from "utils";

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    const url = `${apiUrl}/${resource}`;

    const { mode = "server", current = 1, pageSize = 10 } = pagination ?? {};

    const { _sort, _order } = mapSorter(sorters);
    const queryFilters = mapFilter(filters as LogicalFilter[]);

    const paginationQuery = {
      limit: pageSize,
      order: _order.join(","),
      sort: _sort.join(","),
    };

    if (!meta?.singlePage) Object.assign(paginationQuery, { page: current });

    let urlWithQuery = `${url}?${queryString.stringify(queryFilters)}`;

    if (mode === "server") {
      urlWithQuery = `${urlWithQuery}&${queryString.stringify(
        paginationQuery
      )}`;
    }

    const response = await httpClient.get(urlWithQuery);

    const total =
      response?.data?.body?.data?.count ||
      response?.data?.body?.count ||
      response?.data?.data?.count ||
      0;

    let data =
      response?.data?.body?.data?.rows ||
      response?.data?.body?.rows ||
      response?.data?.data?.rows ||
      response?.data?.body ||
      [];

    return { data, total };
  },

  getMany: async ({ resource, ids }) => {
    if (ids?.length > 1) {
      const response = await httpClient.get(
        `${apiUrl}/${resource}?${queryString.stringify({ id: ids })}`
      );

      const data = response?.data?.body || response?.data?.data;
      return { data };
    } else {
      const response = await httpClient.get(`${apiUrl}/${resource}/${ids}`);
      const data = response?.data?.body || response?.data?.data;
      return { data: [data] };
    }
  },

  create: async ({ resource, variables, meta }) => {
    const url = `${apiUrl}/${resource}`;

    const response = await httpClient.post(
      url,
      variables,
      variables instanceof FormData
        ? {
            headers: { "Content-Type": "multipart/form-data" },
            params: meta?.query,
          }
        : {
            headers: meta?.headers,
            params: meta?.query,
          }
    );

    const data = response?.data?.body || response?.data?.data;

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;
    const method = meta?.method;
    var response;

    if (method?.toLowerCase() === "patch") {
      response = await httpClient.patch(
        url,
        variables,
        variables instanceof FormData
          ? { headers: { "Content-Type": "multipart/form-data" } }
          : undefined
      );
    } else {
      response = await httpClient.put(
        url,
        variables,
        variables instanceof FormData
          ? { headers: { "Content-Type": "multipart/form-data" } }
          : undefined
      );
    }

    const data = response?.data?.body || response?.data?.data;

    return {
      data,
    };
  },

  updateMany: async ({ resource, ids, variables, meta }) => {
    const method = meta?.method;
    var response;

    for (const id of ids) {
      const url = `${apiUrl}/${resource}/${id}`;

      if (method?.toLowerCase() === "patch") {
        response = await httpClient.patch(
          url,
          variables,
          variables instanceof FormData
            ? { headers: { "Content-Type": "multipart/form-data" } }
            : undefined
        );
      } else {
        response = await httpClient.put(
          url,
          variables,
          variables instanceof FormData
            ? { headers: { "Content-Type": "multipart/form-data" } }
            : undefined
        );
      }
    }

    const data = response?.data?.body || response?.data?.data;

    return {
      data,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const response = await httpClient.get(url);
    const data = response?.data?.body || response?.data?.data;

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const response = await httpClient.delete(url, {
      data: variables,
    });

    const data = response?.data?.body || response?.data?.data;

    return {
      data,
    };
  },

  getApiUrl: () => {
    return apiUrl;
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = mapSorter(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(","),
          _order: _order.join(","),
        };
        requestUrl = `${requestUrl}&${queryString.stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = mapFilter(filters as LogicalFilter[]);
      requestUrl = `${requestUrl}&${queryString.stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${queryString.stringify(query)}`;
    }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload, { headers });
        break;
      case "delete":
        axiosResponse = await httpClient.delete(url, {
          data: payload,
          headers,
        });
        break;
      default:
        axiosResponse = await httpClient.get(requestUrl, { headers });
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
