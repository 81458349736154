import {
  BaseRecord,
  useResource,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Col, Row, Space, Card } from "antd";
import { DynamicDisplayItem, SingleElementForm } from "components/molecules";
import { IResourceDisplayColumn } from "interfaces";
import { startCase } from "lodash";
import React, { useEffect, useState, useContext } from "react";
import { convertHexToRGBA, isImage } from "utils";
import {
  ContactsOutlined,
  UserOutlined,
  MailOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  SafetyOutlined,
  UsergroupAddOutlined,
  ProjectOutlined,
  PartitionOutlined,
} from "@ant-design/icons";
import { Text } from "components/atoms";
import { ColorModeContext } from "contexts/color-mode";

interface ShowLayoutProps {
  objectName?: string;
  objectData?: BaseRecord;
  resourceName?: string;
}

interface SingleElementFormProps {
  name?: string;
  label?: string;
  icon?: React.ReactNode;
  value?: string;
}

type Icon = {
  [key: string]: React.ReactNode;
};

const icons: Icon = {
  id: <ContactsOutlined />,
  name: <UserOutlined />,
  email: <TeamOutlined />,
  isActive: <CheckCircleOutlined />,
  isEmailVerified: <MailOutlined />,
  emailVerifiedAt: <SafetyOutlined />,
  userGroup: <UsergroupAddOutlined />,
  groupProduct: <ProjectOutlined />,
  branch: <PartitionOutlined />,
  lastLoginAt: <ContactsOutlined />,
  lastLoginIp: <ContactsOutlined />,
};

export const UserShowLayout: React.FC<ShowLayoutProps> = ({
  objectName,
  objectData,
  resourceName,
}) => {
  const { resources } = useResource();

  const [defaultColumns, setDefaultColumns] =
    useState<IResourceDisplayColumn[]>();
  const [tableColumns, setTableColumns] = useState<IResourceDisplayColumn[]>();
  const [userData, setUserData] = useState<SingleElementFormProps[]>();
  const [groupData, setGroupData] = useState<SingleElementFormProps[]>();
  const [loginData, setLoginData] = useState<SingleElementFormProps[]>();
  const { mode } = useContext(ColorModeContext);

  const translate = useTranslate();

  // if resourceName is provided, use that for useShow
  const { queryResult } = useShow({
    resource: resourceName,
    id: objectData?.id,
    queryOptions: { enabled: !!resourceName },
  });
  const record = queryResult?.data?.data;

  useEffect(() => {
    const resourceInfo = resources?.find(
      ({ name, identifier, meta }) =>
        name === objectName || identifier === objectName
    );

    if (resourceInfo?.meta?.displayColumns) {
      setDefaultColumns(resourceInfo?.meta?.displayColumns);
    }
  }, [resources, objectName]);

  useEffect(() => {
    const allColumns = Object.keys(record || objectData || {});

    const blacklist = [
      // "Id",
      "Password",
      "Created At",
      "Updated At",
      "Deleted At",
      "Created By",
      "Updated By",
      "Deleted By",
    ];

    const filteredCols = allColumns?.filter(
      (displayColumn) => !blacklist.includes(startCase(displayColumn))
    );

    const imageCols = filteredCols?.filter((displayColumn) =>
      isImage((record || objectData)?.[displayColumn], { key: displayColumn })
    );

    const tableCols = filteredCols?.filter(
      (displayColumn) => !imageCols?.includes(displayColumn)
    );

    if (tableCols && tableCols?.length > 0) {
      const tableAttrs = tableCols?.map((col) => ({ key: col }));
      setTableColumns(tableAttrs);
    }
  }, [objectName, objectData, record]);

  useEffect(() => {
    var user: any = [];
    var group: any = [];
    var login: any = [];
    (defaultColumns || tableColumns)?.forEach((displayColumn, index) => {
      const value = (record || objectData)?.[displayColumn?.key || ""];
      if (
        ((value !== undefined || value !== null) &&
          !displayColumn?.hideIfEmpty) ||
        !displayColumn?.hideOnParent
      ) {
        if (
          displayColumn?.key?.toLowerCase().includes("group") ||
          displayColumn?.key?.toLowerCase().includes("branch")
        ) {
          group.push({
            name: displayColumn?.key,
            label: displayColumn?.label || startCase(displayColumn?.key),
            icon: icons?.[displayColumn?.key] || null,
            value: value,
          });
        } else if (displayColumn?.key?.toLowerCase().includes("login")) {
          login.push({
            name: displayColumn?.key,
            label: displayColumn?.label || startCase(displayColumn?.key),
            icon: icons?.[displayColumn?.key] || null,
            value: value,
          });
        } else {
          user.push({
            name: displayColumn?.key,
            label: displayColumn?.label || startCase(displayColumn?.key),
            icon: icons?.[displayColumn?.key || ""] || null,
            value: value,
          });
        }
      }
    });
    setUserData(user);
    setGroupData(group);
    setLoginData(login);
  }, [defaultColumns, tableColumns, record, objectData]);

  return (
    <Row gutter={[24, 24]} justify="space-evenly">
      <Col xs={24} md={24} xl={12}>
        <Card
          title={
            <Space size={15}>
              <Text size="sm">
                {translate("page.user.userInformation", "Informasi User")}
              </Text>
            </Space>
          }
          headStyle={{
            padding: "0 12px",
            backgroundColor: mode === "dark" ? "#1d1d1d" : "#fafafa",
          }}
          bodyStyle={{ padding: 0 }}
        >
          {userData?.map(({ name, label, icon, value }) => (
            <SingleElementForm
              key={name}
              icon={icon}
              formProps={{ initialValues: { name: value } }}
              formItemProps={{ name, label: label || startCase(name) }}
              style={{
                borderBottom: "1px solid",
                borderColor: convertHexToRGBA(
                  mode === "dark" ? "#FFFFF" : "#00000",
                  0.1
                ),
                marginTop: "4px",
              }}
              view={
                <DynamicDisplayItem
                  value={value}
                  displayColumn={{ key: name }}
                />
              }
            />
          ))}
        </Card>
      </Col>
      <Col xs={24} md={24} xl={12}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Card
            title={
              <Space size={15}>
                <Text size="sm">Group</Text>
              </Space>
            }
            headStyle={{
              padding: "0 12px",
              backgroundColor: mode === "dark" ? "#1d1d1d" : "#fafafa",
            }}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: "10px" }}
          >
            {groupData?.map(({ name, label, icon, value }) => (
              <SingleElementForm
                key={name}
                icon={icon}
                formProps={{ initialValues: { name: value } }}
                formItemProps={{ name, label: label || startCase(name) }}
                style={{
                  borderBottom: "1px solid",
                  borderColor: convertHexToRGBA(
                    mode === "dark" ? "#FFFFF" : "#00000",
                    0.1
                  ),
                  marginTop: "4px",
                }}
                view={
                  <DynamicDisplayItem
                    value={value}
                    displayColumn={{ key: name }}
                  />
                }
              />
            ))}
          </Card>
          <Card
            title={
              <Space size={15}>
                <Text size="sm">
                  {translate("page.user.userLogin", "Login User")}
                </Text>
              </Space>
            }
            headStyle={{
              padding: "0 12px",
              backgroundColor: mode === "dark" ? "#1d1d1d" : "#fafafa",
            }}
            bodyStyle={{ padding: 0 }}
          >
            {loginData?.map(({ name, label, icon, value }) => (
              <SingleElementForm
                key={name}
                icon={icon}
                formProps={{ initialValues: { name: value } }}
                formItemProps={{ name, label: label || startCase(name) }}
                style={{
                  borderBottom: "1px solid",
                  borderColor: convertHexToRGBA(
                    mode === "dark" ? "#FFFFF" : "#00000",
                    0.1
                  ),
                  marginTop: "4px",
                }}
                view={
                  <DynamicDisplayItem
                    value={value}
                    displayColumn={{ key: name }}
                  />
                }
              />
            ))}
          </Card>
        </Space>
      </Col>
    </Row>
  );
};
