import { DevtoolsProvider, DevtoolsPanel } from "@refinedev/devtools";
import { Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import routerBindings, {
  UnsavedChangesNotifier,
  DocumentTitleHandler,
} from "@refinedev/react-router-v6";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { resources } from "resources";
import {
  accessControlProvider,
  authProvider,
  dataProvider,
  RouteProvider,
} from "providers";
import jsonServerDataProvider from "@refinedev/simple-rest";
import { App as AntdApp } from "antd";

import "@refinedev/antd/dist/reset.css";
import "./utils/init-dayjs";
import { useNotificationProvider } from "@refinedev/antd";

const API_BACKOFFICE_URL =
  process.env.REACT_APP_BACKOFFICE_API_URL ||
  "http://localhost:4000/api/v1/backoffice";

const API_MOBILE_URL =
  process.env.REACT_APP_MOBILE_API_URL || "http://localhost:4000/api/v1/app";

const DUMMY_API_URL = "https://api.finefoods.refine.dev";

function App() {
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <DevtoolsProvider>
              <Refine
                resources={resources}
                dataProvider={{
                  default: dataProvider(API_BACKOFFICE_URL),
                  app: dataProvider(API_MOBILE_URL),
                  dummy: jsonServerDataProvider(DUMMY_API_URL),
                }}
                notificationProvider={useNotificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                options={{
                  warnWhenUnsavedChanges: true,
                  reactQuery: {
                    clientConfig: {
                      defaultOptions: { queries: { retry: false } },
                    },
                  },
                  projectId: "ATMxip-asV6AQ-Hwwgju",
                }}
                accessControlProvider={accessControlProvider}
              >
                <RouteProvider resources={resources} />
                <RefineKbar />
                <UnsavedChangesNotifier />
                <DocumentTitleHandler
                  handler={({ autoGeneratedTitle }) => {
                    let title =
                      "Customer Information System - Henan Asset Management";

                    if (!!autoGeneratedTitle) {
                      title = autoGeneratedTitle?.replace("refine", title);
                    }

                    return title;
                  }}
                />
              </Refine>
              <DevtoolsPanel />
            </DevtoolsProvider>
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
