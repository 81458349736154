import React, { useEffect, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useResource,
  useUpdate,
  BaseRecord,
  useInvalidate,
  HttpError,
  useTranslate,
  useCan,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Button, Dropdown, MenuProps, Space, TimelineItemProps } from "antd";
import { mapStatus, useChildRecords } from "utils";
import {
  CustomErrorComponent,
  ShowItemRecursor,
  ShowLayout,
} from "components/organisms";
import { ButtonWithConfirmation, DownloadButton } from "components/atoms";
import {
  EllipsisOutlined,
  PoweroffOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface BankAccountShowShowProps extends IResourceComponentsProps {
  id?: string | number;
}

export const BankAccountShow: React.FC<BankAccountShowShowProps> = ({ id }) => {
  const { resource } = useResource();
  const { queryResult } = useShow({ resource: resource?.meta?.endpoint, id });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // handle the population of the data's status
  const statusData = useChildRecords({
    resourceName: resource?.meta?.statusResource,
    foreignKey: resource?.meta?.statusForeignKey,
    parentId: record?.id,
  });

  const [timelineItemProps, setTimelineItemProps] =
    useState<TimelineItemProps[]>();
  useEffect(() => {
    if (statusData && statusData.length > 0)
      setTimelineItemProps(mapStatus({ data: statusData }));
  }, [resource, statusData, record]);

  const { mutate } = useUpdate<BaseRecord>();
  const invalidate = useInvalidate();
  const translate = useTranslate();

  const canSetAsMainBankAccount = useCan({
    resource: `${resource?.name}/main`,
    action: "edit",
    queryOptions: {
      enabled: !record?.isMainBankAccount,
      staleTime: 5 * 60 * 1000,
    },
  });

  const setAsMainBankAccount = async () => {
    mutate(
      {
        resource: `${resource?.name}/main`,
        id: id || record?.id || "",
        values: {},
        meta: { method: "put" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const canSetAsUploaded = useCan({
    resource: `${resource?.name}/uploaded`,
    action: "edit",
    queryOptions: {
      enabled: !record?.isUploaded,
      staleTime: 5 * 60 * 1000,
    },
  });

  const setAsUploaded = async () => {
    mutate(
      {
        resource: `${resource?.name}/uploaded`,
        id: id || record?.id || "",
        values: {},
        meta: { method: "patch" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const canDeactivate = useCan({
    resource: `${resource?.name}/deactivate`,
    action: "edit",
    queryOptions: { staleTime: 5 * 60 * 1000 },
  });

  const handleDeactivate = async () => {
    mutate(
      {
        resource: `${resource?.name}/deactivate`,
        id: id || record?.id || "",
        values: {},
        meta: { method: "patch" },
      },
      {
        onSettled: (data, variables, context) => {
          invalidate({
            resource: resource?.identifier || resource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  const items: MenuProps["items"] = [
    {
      label: (
        <ButtonWithConfirmation
          type="text"
          onClick={setAsMainBankAccount}
          disabled={
            !!record?.isMainBankAccount || !canSetAsMainBankAccount?.data?.can
          }
          style={{ width: "100%", textAlign: "left" }}
        >
          {translate(
            "page.bankAccount.setMainBankAccount",
            "Set as main bank account"
          )}
        </ButtonWithConfirmation>
      ),
      type: "group",
    },
    {
      label: (
        <ButtonWithConfirmation
          type="text"
          onClick={setAsUploaded}
          disabled={!!record?.isUploaded || !canSetAsUploaded?.data?.can}
          style={{ width: "100%", textAlign: "left" }}
          icon={<UploadOutlined />}
        >
          {translate("page.bankAccount.setUploaded", "Set as uploaded")}
        </ButtonWithConfirmation>
      ),
      type: "group",
    },
    {
      label: (
        <ButtonWithConfirmation
          type="text"
          onClick={handleDeactivate}
          disabled={!record?.isActive || !canDeactivate?.data?.can}
          style={{ width: "100%", textAlign: "left" }}
          icon={<PoweroffOutlined />}
        >
          {translate("buttons.deactivate", "Deactivate")}
        </ButtonWithConfirmation>
      ),
      type: "group",
    },
    { type: "divider" },
    ...(resource?.meta?.downloadButtons?.map((button: any, index: number) => ({
      label: (
        <DownloadButton
          type="text"
          key={`${button?.resource}/${id || record?.id || ""}`}
          resource={button?.resource}
          id={(id || record?.id || "") as string}
          label={button?.label}
          style={{ width: "100%", textAlign: "left" }}
        />
      ),
      type: "group",
    })) || []),
    {
      label: (
        <CustomRefreshButton
          type="text"
          style={{ width: "100%", textAlign: "left" }}
        />
      ),
      type: "group",
    },
  ];

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          breadcrumb: undefined,
          extra: (
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          ),
        }}
      >
        <ShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
          timelineItemProps={timelineItemProps}
        />
      </Show>

      <ShowItemRecursor
        parentName={resource?.identifier || resource?.name}
        parentData={record}
      />
    </Space>
  );
};
