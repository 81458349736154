import { getValueFromEvent } from "@refinedev/antd";
import {
  Col,
  DatePicker,
  Form,
  FormProps,
  Input,
  Radio,
  TimePicker,
  Upload,
  ColorPicker,
  InputNumber,
  Space,
} from "antd";
import { IResourceFormColumn } from "interfaces";
import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { DynamicSelectDetails } from "./dynamicSelectDetails";
import { DynamicSelectTable } from "./dynamicSelectTable";
import { DynamicSelect, InputWithConverter, Text } from "components/atoms";
import { useResource, useTranslate, useApiUrl } from "@refinedev/core";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { requiredFromColumnRule } from "utils/customFormRule";

export interface FormDynamicItemProps {
  formProps?: FormProps;
  required?: boolean;
  formColumn: IResourceFormColumn;
  revisionColumns?: any;
}

export const FormDynamicItem: React.FC<FormDynamicItemProps> = ({
  formProps,
  required,
  formColumn,
  revisionColumns,
}) => {
  const apiUrl = useApiUrl();
  const translate = useTranslate();
  const [colProps, setColProps] = useState({});
  const id = formProps?.initialValues?.id;
  const accessToken = useSelector((state: RootState) => state.temp.accessToken);

  useEffect(() => {
    if (formColumn?.span === 2) setColProps({ xs: 24 });
    else setColProps({ xs: 24, sm: 12 });
  }, [formColumn?.span]);

  const { resource } = useResource();

  const requiredFromColumn = formColumn?.requiredFromColumn;
  const hasAllRequiredFromColumnParams =
    requiredFromColumn && requiredFromColumn?.key && requiredFromColumn?.value;

  // update if theres any additional custom rule
  const hasCustomRule = Boolean(hasAllRequiredFromColumnParams);
  const formItemProps = {
    label:
      formColumn?.label ?? startCase(formColumn?.key?.replace(/id$/gi, "")),
    name: formColumn?.key,
    hidden: formColumn?.hidden,
    shouldUpdate: hasCustomRule || undefined,
    rules: [
      hasAllRequiredFromColumnParams
        ? requiredFromColumnRule(
            requiredFromColumn?.value,
            requiredFromColumn?.key
          )
        : { required },
      formColumn?.length ? { len: formColumn?.length } : undefined,
      formColumn?.minLength ? { len: formColumn?.minLength } : undefined,
      formColumn?.maxLength ? { len: formColumn?.maxLength } : undefined,
    ].filter(Boolean) as any[],
    disabled: formColumn?.isDisabled,
    key: `${formColumn?.key} ${resource?.identifier || resource?.name || ""}`,
    dependencies: formColumn?.dependencies,
    initialValue: formColumn?.initialValue,
  };

  const isRevision = revisionColumns?.hasOwnProperty(
    formColumn?.key?.replace(/Id$/, "")
  );

  return (
    <Col {...colProps}>
      {formColumn?.type === "boolean" ? (
        <Form.Item {...formItemProps}>
          <Radio.Group disabled={formColumn?.isDisabled}>
            <Radio value={formColumn?.valueTrue ?? true}>
              {formColumn?.labelTrue || translate("buttons.true", "True")}
            </Radio>
            <Radio value={formColumn?.valueFalse ?? false}>
              {formColumn?.labelFalse || translate("buttons.false", "False")}
            </Radio>
          </Radio.Group>
        </Form.Item>
      ) : formColumn?.type === "daterange" ? (
        <Form.Item
          {...formItemProps}
          getValueProps={(value) => {
            const [dateFrom, dateTo] = value || [];
            return {
              value: [
                dateFrom ? dayjs(dateFrom) : "",
                dateTo ? dayjs(dateTo) : "",
              ],
            };
          }}
        >
          <DatePicker.RangePicker
            placeholder={formColumn?.placeholder as [string, string]}
            style={{ width: "100%" }}
            format={"D MMMM YYYY"}
            disabledDate={(date) =>
              date < formColumn?.minDate || date > formColumn?.maxDate
            }
            disabled={formColumn?.isDisabled}
          />
        </Form.Item>
      ) : formColumn?.type === "date" ? (
        <Form.Item
          {...formItemProps}
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabledDate={(date) =>
              date < formColumn?.minDate || date > formColumn?.maxDate
            }
            disabled={
              formColumn?.isDisabled || (!isRevision && revisionColumns)
            }
            format={"D MMM YYYY"}
          />
        </Form.Item>
      ) : formColumn?.type === "time" ? (
        <Form.Item
          {...formItemProps}
          getValueProps={(value) => ({
            value: value ? dayjs(value, "HH:mm:ss") : "",
          })}
        >
          <TimePicker
            style={{ width: "100%" }}
            disabled={formColumn?.isDisabled}
            onChange={(_, dateString) =>
              formProps?.form?.setFieldsValue({
                [formColumn?.key || ""]: dateString,
              })
            }
          />
        </Form.Item>
      ) : formColumn?.type === "file" ? (
        <Form.Item
          label={
            formColumn?.label ??
            startCase(formColumn?.key?.replace(/id$/gi, ""))
          }
        >
          <Form.Item
            name={formColumn?.key}
            rules={[{ required }]}
            valuePropName={formColumn?.key}
            getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name={formColumn?.key}
              maxCount={1}
              disabled={formColumn?.isDisabled}
              beforeUpload={
                !!formColumn?.uploadResource ? undefined : () => false
              }
              action={
                `${apiUrl}/${formColumn?.uploadResource}` + (id ? `/${id}` : "")
              }
              method={formColumn?.uploadMethod}
              headers={{ Authorization: `Bearer ${accessToken}` }}
            >
              <p style={{ padding: "1rem" }}>{"Upload file here"}</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      ) : formColumn?.type === "select" ? (
        <Form.Item {...formItemProps}>
          <DynamicSelect
            formColumn={{
              ...formColumn,
              isDisabled:
                formColumn?.isDisabled || (!isRevision && revisionColumns),
            }}
            formProps={formProps}
            options={formColumn?.options}
          />
        </Form.Item>
      ) : formColumn?.type === "select-details" ? (
        <Form.Item {...formItemProps}>
          <DynamicSelectDetails
            formColumn={formColumn}
            options={formColumn?.options}
            formProps={formProps}
          />
        </Form.Item>
      ) : formColumn?.type === "select-table" ? (
        <Form.Item {...formItemProps} label={undefined}>
          <DynamicSelectTable formColumn={formColumn} formProps={formProps} />
        </Form.Item>
      ) : formColumn?.type === "textarea" ? (
        <Form.Item {...formItemProps}>
          <Input.TextArea
            showCount
            disabled={
              formColumn?.isDisabled || (!isRevision && revisionColumns)
            }
          />
        </Form.Item>
      ) : formColumn?.type === "email" ? (
        <Form.Item
          {...formItemProps}
          rules={[
            ...formItemProps.rules,
            {
              type: "email",
              message: translate(
                "pages.login.errors.validEmail",
                "Invalid email address"
              ),
            },
          ]}
        >
          <Input type={formColumn?.type} disabled={formColumn?.isDisabled} />
        </Form.Item>
      ) : formColumn?.type === "color" ? (
        <Form.Item {...formItemProps}>
          <ColorPicker
            onChange={(_, hex) => {
              formProps?.form?.setFieldsValue({
                [formColumn?.key || ""]: hex,
              });
            }}
            disabled={formColumn?.isDisabled}
          />
        </Form.Item>
      ) : formColumn?.type === "number-with-converter" ? (
        <Form.Item {...formItemProps}>
          <InputWithConverter formColumn={formColumn} />
        </Form.Item>
      ) : formColumn?.type === "password" ? (
        <div style={{ marginBottom: "16" }}>
          <Form.Item {...formItemProps}>
            <Input.Password disabled={formColumn?.isDisabled} />
          </Form.Item>
        </div>
      ) : formColumn?.type === "confirmPassword" ? (
        <Form.Item
          {...formItemProps}
          rules={[
            ...formItemProps.rules,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(formItemProps.dependencies) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  translate(
                    "accountSettings.passwordsDontMatch",
                    "Passwords don't match"
                  )
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={translate(
              "accountSettings.confirmNewPassword",
              "Confirm New Password"
            )}
          />
        </Form.Item>
      ) : formColumn?.type === "rupiah" ? (
        <Form.Item {...formItemProps}>
          <InputNumber
            style={{ width: "100%" }}
            disabled={formColumn?.isDisabled}
            addonBefore="Rp"
            min={formColumn?.min}
            max={formColumn?.max}
            formatter={(value) => {
              const [integerPart, decimalPart] = `${value}`.split(".");
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
              return decimalPart
                ? `${formattedIntegerPart}.${decimalPart}`
                : formattedIntegerPart;
            }}
            parser={(value) => Number(value!.replace(/\$\s?|(,*)/g, ""))}
          />
        </Form.Item>
      ) : ["email", "range", "text", "number", "url"].includes(
          formColumn?.type || " "
        ) ? (
        <Form.Item {...formItemProps}>
          <Input
            type={formColumn?.type}
            disabled={
              formColumn?.isDisabled || (!isRevision && revisionColumns)
            }
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
          />
        </Form.Item>
      ) : formColumn?.type === "formatted-number" ? (
        <Form.Item {...formItemProps}>
          <InputNumber
            type={formColumn?.type}
            disabled={formColumn?.isDisabled}
            style={{ width: "100%" }}
            min={formColumn?.min}
            max={formColumn?.max}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            formatter={(value) => {
              const [integerPart, decimalPart] = `${value}`.split(".");
              const formattedIntegerPart = integerPart.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              );
              return decimalPart
                ? `${formattedIntegerPart}.${decimalPart}`
                : formattedIntegerPart;
            }}
          />
        </Form.Item>
      ) : formColumn?.type === "custom" ? (
        formColumn.CustomComponent && (
          <formColumn.CustomComponent
            formColumn={formColumn}
            formProps={formProps}
            required={required}
            formItemProps={formItemProps}
          />
        )
      ) : null}
      {isRevision && (
        <Space
          direction="vertical"
          style={{
            width: "100%",
            paddingBottom: "24px",
          }}
        >
          <Text size="sm">Revision Notes</Text>
          <Text size="sm" strong>
            {revisionColumns[formColumn?.key?.replace(/Id$/, "") || ""]}
          </Text>
        </Space>
      )}
    </Col>
  );
};
