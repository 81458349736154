import { CustomResourceProps } from "interfaces";
import {
  BankOutlined,
  DatabaseOutlined,
  DollarOutlined,
  FundOutlined,
  ApartmentOutlined,
  SearchOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  AmendmentApprove,
  AmendmentCreate,
  AmendmentShow,
  DefaultEdit,
  ProductApprove,
  ProductCreate,
  // ProductEdit,
  ProductShow,
} from "components";
import dayjs from "dayjs";

const productStaticDataResources: CustomResourceProps[] = [
  {
    name: "static/benchmarks",
    identifier: "product/static-data/benchmarks",
    meta: {
      label: "Benchmarks",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "benchmarkCode", type: "text", requiredOnCreate: true },
        { key: "benchmarkName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/benefits",
    identifier: "product/static-data/benefits",
    meta: {
      label: "Benefits",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "benefitCode", type: "number", requiredOnCreate: true },
        { key: "benefitName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/dividend-types",
    identifier: "product/static-data/dividend-types",
    meta: {
      label: "Dividend Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "dividendTypeCode", type: "text", requiredOnCreate: true },
        { key: "dividendTypeName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/holdings",
    identifier: "product/static-data/holdings",
    meta: {
      label: "Holdings",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "holdingCode", type: "text", requiredOnCreate: true },
        { key: "holdingName", type: "text", requiredOnCreate: true },
        {
          key: "sectorId",
          type: "select",
          optionsMeta: { resourceName: "static/sectors" },
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/investment-policies",
    identifier: "product/static-data/investment-policies",
    meta: {
      label: "Investment Policies",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "investmentPolicyCode",
          type: "number",
          requiredOnCreate: true,
        },
        { key: "investmentPolicyName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/mutual-fund-types",
    identifier: "product/static-data/mutual-fund-types",
    meta: {
      label: "Mutual Fund Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "mutualFundTypeCode", type: "number", requiredOnCreate: true },
        { key: "mutualFundTypeName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/portfolio-composites",
    identifier: "product/static-data/portfolio-composites",
    meta: {
      label: "Portfolio Composites",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "portfolioCompositeCode",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "portfolioCompositeName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/product-types",
    identifier: "product/static-data/product-types",
    meta: {
      label: "Product Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "productTypeCode", type: "text", requiredOnCreate: true },
        { key: "productTypeName", type: "text", requiredOnCreate: true },
        { key: "colorCode", type: "color", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/product-fee-types",
    identifier: "product/static-data/product-fee-types",
    meta: {
      label: "Product Fee Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "productFeeTypeCode" },
        { key: "productFeeTypeName" },
      ],
      formColumns: [
        { key: "productFeeTypeName", type: "text", requiredOnCreate: true },
        { key: "productFeeTypeCode", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/risk-classifications",
    identifier: "product/static-data/risk-classifications",
    meta: {
      label: "Risk Classifications",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "riskClassificationCode",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "riskClassificationName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/sectors",
    identifier: "product/static-data/sectors",
    meta: {
      label: "Sectors",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "sectorCode", type: "text", requiredOnCreate: true },
        { key: "sectorName", type: "text", requiredOnCreate: true },
      ],
    },
  },
];

const productListResources: CustomResourceProps[] = [
  {
    name: "products/data",
    identifier: "product/data",
    meta: {
      label: "Products",
      icon: <ShoppingOutlined />,
      canList: true,
      canShow: true,
      showComponent: <ProductShow />,
      canCreate: true,
      createComponent: <ProductCreate />,
      // canEdit: true,
      // editComponent: <ProductEdit />,
      canApprove: true,
      approveComponent: <ProductApprove />,
      canDelete: true,
      // isFormData: true,
      childResource: "products/details",
      childForeignKey: "productId",
      statusResource: "products/data/approval",
      statusForeignKey: "productId",
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          label: "Approval Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "sid", label: "SID", span: 1 },
        { key: "hpfId", label: "HPF ID", span: 1 },
        { key: "siarCode", span: 1, label: "SIAR Code" },
        { key: "sinvestCode", span: 1, label: "SINVEST Code" },
        { key: "ifua", label: "IFUA", span: 1 },
        { key: "ifca", label: "IFCA", span: 1 },
        {},
        { key: "name", span: 1 },
        { key: "productType", span: 1, hideOnRecursor: true },
        { key: "description" },
        { key: "fundObjective" },
        { key: "investmentManager", hideOnRecursor: true },
        {},
        { key: "effectiveDate", span: 1 },
        { key: "effectiveNumber", span: 1 },
        { key: "launchDate" },
        { key: "currency" },
        { key: "periodicUpdate", span: 1 },
        { key: "maxDaily", span: 1 },
        { key: "productFees" },
        { key: "bankISINCode", label: "ISIN Code" },
        { key: "custodianBank", hideOnRecursor: true, span: 1 },
        { key: "correspondentBank", hideOnRecursor: true, span: 1 },
        { key: "riskClassification" },
        { key: "benchmark" },
        {},
        { key: "isShariaCompliant", span: 1 },
        { key: "isRDO", span: 1 },
        { key: "isSafeKeeping", label: "Safekeeping Product", span: 1 },
        { key: "isNav1000", label: "NAV/Unit from 1000", span: 1 },
        { key: "mutualFundType", span: 1 },
        { key: "dividendType", span: 1 },
        {},
        {
          key: "productInvestmentPolicy",
          resourceName: "join/product-investment-policies",
        },
        { key: "productHolding", resourceName: "join/product-holdings" },
        {
          key: "productPortfolioComposite",
          resourceName: "join/product-portfolio-composites",
        },
        { key: "productBenefit", resourceName: "join/product-benefits" },
        { key: "productRiskType", resourceName: "join/product-risk-types" },

        {},
        { key: "approvalStatus" },
        { key: "approvedAt", span: 1 },
        { key: "approvedBy", span: 1 },
      ],
      formColumns: [
        {
          key: "hpfId",
          label: "HPF ID",
          type: "number",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "siarCode",
          label: "SIAR Code",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "sinvestCode",
          label: "SINVEST Code",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "sid",
          label: "SID",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "ifua",
          label: "IFUA",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "ifca",
          label: "IFCA",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "name",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        { key: "logo", type: "image" },
        { key: "description", type: "text", requiredOnCreate: true },
        {
          key: "isShariaCompliant",
          type: "boolean",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "effectiveDate",
          type: "date",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "effectiveNumber",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "launchDate",
          type: "date",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        { key: "periodicUpdate", type: "text", requiredOnCreate: true },
        {
          key: "bankISINCode",
          label: "ISIN Code",
          type: "text",
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        { key: "fundObjective", type: "text", requiredOnCreate: true },
        { key: "maxDaily", type: "number", requiredOnCreate: true },
        {
          key: "productTypeId",
          type: "select",
          optionsMeta: { resourceName: "static/product-types" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "mutualFundTypeId",
          type: "select",
          optionsMeta: { resourceName: "static/mutual-fund-types" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "dividendTypeId",
          type: "select",
          optionsMeta: { resourceName: "static/dividend-types" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "benchmarkId",
          type: "select",
          optionsMeta: { resourceName: "static/benchmarks" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "currencyId",
          type: "select",
          optionsMeta: { resourceName: "static/currencies" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "custodianBankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "correspondentBankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "riskClassificationId",
          type: "select",
          optionsMeta: { resourceName: "static/risk-classifications" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "investmentManagerId",
          type: "select",
          optionsMeta: { resourceName: "static/investment-managers" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "isRDO",
          label: "Is RDO?",
          type: "boolean",
          requiredOnCreate: true,
        },
        {
          key: "isSafeKeeping",
          label: "Is Safe Keeping?",
          type: "boolean",
          requiredOnCreate: true,
        },
        {
          key: "isNav1000",
          label: "Is NAV Starts From 1000?",
          type: "boolean",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "products/details",
    identifier: "product/details",
    meta: {
      label: "Product Details",
      icon: <UnorderedListOutlined />,
      // canList: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      // isFormData: true,
      statusResource: "products/details/approval",
      statusForeignKey: "productDetailId",
      // initialSorters: [{ field: "id", order: "desc" }],
      // filters: [
      //   {
      //     key: "productId",
      //     label: "Product",
      //     type: "select",
      //     optionsMeta: { resourceName: "products/data" },
      //   },
      //   {
      //     key: "approvalStatusId",
      //     label: "Approval Status",
      //     type: "select",
      //     optionsMeta: { resourceName: "static/approval-statuses" },
      //   },
      //   {
      //     key: "status",
      //     label: "Status",
      //     type: "text",
      //   },
      // ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "product", resourceName: "products/data" },
        { key: "ffsFile", label: "FFS File", span: 1 },
        { key: "prospectusFile", span: 1 },
        {},
        { key: "subFee", span: 1 },
        { key: "isSubOnHold", span: 1 },
        { key: "timeToSettleSub", span: 1 },
        { key: "timeToExpireSub", span: 1 },
        { key: "minSub", span: 1, type: "currency" },
        { key: "initialSub", span: 1, type: "currency" },
        {},
        { key: "redFee", span: 1 },
        { key: "isRedOnHold", span: 1 },
        { key: "timeToSettleRed" },
        { key: "minRed", type: "currency" },
        {},
        { key: "switchFee", span: 1 },
        { key: "isSwitchOnHold", span: 1 },
        { key: "timeToSettleSwitch" },
        {},
        { key: "totalUnit", span: 1, type: "unit" },
        { key: "initialUnit", span: 1, type: "unit" },
        { key: "minRemaining", span: 1, type: "currency" },
        { key: "maxCustomer", span: 1 },
        { key: "minUnit", span: 1, type: "unit" },
        { key: "minUnitValue", span: 1 },
        { key: "managementFee", span: 1 },
        { key: "custodianFee", span: 1 },
        { key: "maxHoldDuration" },
        {},
        { key: "maturityDate" },
        { key: "status", span: 1 },
        { key: "approvalStatus", span: 1 },
        { key: "approvedAt", span: 1 },
        { key: "approvedBy", span: 1 },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        { key: "status", type: "text", requiredOnCreate: true },
        { key: "maturityDate", type: "date", requiredOnCreate: true },
        { key: "maxCustomer", type: "number", requiredOnCreate: true },
        { key: "initialUnit", type: "unit", requiredOnCreate: true },
        { key: "totalUnit", type: "unit", requiredOnCreate: true },
        { key: "initialSub", type: "currency", requiredOnCreate: true },
        { key: "minUnit", type: "unit", requiredOnCreate: true },
        { key: "minUnitValue", type: "number", requiredOnCreate: true },
        { key: "minSub", type: "currency", requiredOnCreate: true },
        { key: "minRed", type: "currency", requiredOnCreate: true },
        { key: "minRemaining", type: "currency", requiredOnCreate: true },
        { key: "timeToExpireSub", type: "number", requiredOnCreate: true },
        { key: "timeToSettleSub", type: "number", requiredOnCreate: true },
        { key: "timeToSettleRed", type: "number", requiredOnCreate: true },
        { key: "timeToSettleSwitch", type: "number", requiredOnCreate: true },
        { key: "isSubOnHold", type: "boolean", requiredOnCreate: true },
        { key: "isRedOnHold", type: "boolean", requiredOnCreate: true },
        { key: "isSwitchOnHold", type: "boolean", requiredOnCreate: true },
        { key: "maxHoldDuration", type: "number", requiredOnCreate: true },
        { key: "subFee", type: "percentage", requiredOnCreate: true },
        { key: "redFee", type: "percentage", requiredOnCreate: true },
        { key: "managementFee", type: "percentage", requiredOnCreate: true },
        { key: "switchFee", type: "percentage", requiredOnCreate: true },
        { key: "custodianFee", type: "percentage", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "products/details",
    identifier: "product/documents",
    meta: {
      label: "Documents",
      icon: <FileTextOutlined />,
      canList: true,
      // canShow: true,
      // canCreate: true,
      canEdit: true,
      editComponent: <DefaultEdit saveButtonProps={{ hidden: true }} />,
      // canApprove: true,
      // canDelete: true,
      // isFormData: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "product", hideOnRecursor: true },
        { key: "ffsFile", label: "FFS File" },
        { key: "prospectusFile" },
      ],
      formColumns: [
        {
          key: "ffsFile",
          label: "FFS File",
          type: "file",
          uploadResource: "products/files/ffs",
          uploadMethod: "put",
        },
        {
          key: "prospectusFile",
          type: "file",
          uploadResource: "products/files/prospectus",
          uploadMethod: "put",
        },
      ],
    },
  },
  {
    name: "products/fees",
    identifier: "product/fees",
    meta: {
      label: "Fees",
      icon: <DollarOutlined />,
      canList: true,
      // canSearch: true,
      canShow: true,
      canCreate: true,
      // canEdit: true,
      canApprove: true,
      canDelete: true,
      // isFormData: true,
      statusResource: "products/fees/approval",
      statusForeignKey: "productFeeId",
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "startDate",
          label: "Start Date",
          type: "date",
        },
        {
          key: "endDate",
          label: "End Date",
          type: "date",
        },
      ],
      displayColumns: [
        { key: "id", span: 1 },
        { key: "product", hideOnRecursor: true, span: 1 },
        { key: "productFeeType", span: 1 },
        { key: "fee", span: 1 },
        { key: "ppn", span: 1 },
        { key: "feeAndPpn", span: 1 },
        { key: "remark" },
        { key: "startDate", span: 1 },
        { key: "endDate", span: 1 },
        { key: "approvalStatus", span: 1 },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
          hideOnEdit: true,
        },
        {
          key: "productFeeTypeId",
          label: "Fee Type",
          type: "select",
          optionsMeta: { resourceName: "static/product-fee-types" },
          requiredOnCreate: true,
        },
        { key: "fee", type: "number", requiredOnCreate: true },
        { key: "ppn", type: "number", requiredOnCreate: true },
        { key: "feeAndPpn", type: "number", requiredOnCreate: true },
        { key: "remark", type: "text", requiredOnCreate: true },
        {
          key: "date",
          type: "daterange",
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["startDate", "endDate"],
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "products/performances",
    identifier: "product/performances",
    meta: {
      label: "Performances",
      icon: <FundOutlined />,
      canList: true,
      // canSearch: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      // isFormData: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "date",
          type: "date",
          placeholder: "Date",
          maxDate: dayjs().endOf("day"),
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "product", hideOnRecursor: true },
        { key: "productType" },
        { key: "date" },
        {
          key: "oneDay",
          type: "percentage",
          label: "1D",
          isProfitLossIndicator: true,
        },
        {
          key: "oneMonth",
          type: "percentage",
          label: "1M",
          isProfitLossIndicator: true,
        },
        {
          key: "threeMonth",
          type: "percentage",
          label: "3M",
          isProfitLossIndicator: true,
        },
        {
          key: "ytd",
          type: "percentage",
          label: "YTD",
          isProfitLossIndicator: true,
        },
        {
          key: "oneYear",
          type: "percentage",
          label: "1Y",
          isProfitLossIndicator: true,
        },
        {
          key: "threeYear",
          type: "percentage",
          label: "3Y",
          isProfitLossIndicator: true,
        },
        {
          key: "fiveYear",
          type: "percentage",
          label: "5Y",
          isProfitLossIndicator: true,
        },
        {
          key: "tenYear",
          type: "percentage",
          label: "10Y",
          isProfitLossIndicator: true,
        },
        { key: "lastNav", label: "Last NAV" },
        { key: "cagr", label: "CAGR" },
        { key: "aum", type: "currency", label: "AUM" },
        { key: "currency" },
      ],
    },
  },
  {
    name: "products/bank-accounts",
    meta: {
      label: "Bank Accounts",
      icon: <BankOutlined />,
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      canApprove: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
        },
        {
          key: "transactionTypeId",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "sellingAgentId",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            pagination: { mode: "off" },
          },
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      canDelete: true,
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "bankAccountName" },
        { key: "bankAccountNumber" },

        { key: "transactionType" },
        { key: "bank", hideOnRecursor: true },
        { key: "isDisbursementBank" },
        {
          key: "currency",
          childKey: "currencyCode",
          label: "Currency",
          hideOnRecursor: true,
        },
        { key: "product", hideOnRecursor: true },
        { key: "sellingAgent", hideOnRecursor: true },
        { key: "approvalStatus" },
        { key: "approvedAt" },
        { key: "approvedBy" },
      ],
      formColumns: [
        { key: "bankAccountNumber", type: "number", requiredOnCreate: true },
        { key: "bankAccountName", type: "text", requiredOnCreate: true },
        {
          key: "isDisbursementBank",
          type: "boolean",
          requiredOnCreate: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "bankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
          requiredOnCreate: true,
        },
        {
          key: "transactionTypeId",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
          requiredOnCreate: true,
        },
        {
          key: "currencyId",
          type: "select",
          optionsMeta: {
            resourceName: "static/currencies",
            disableGetOne: true,
          },
          requiredOnCreate: true,
        },
        {
          key: "sellingAgentId",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            pagination: { mode: "off" },
            disableGetOne: true,
          },
          requiredOnCreate: true,
        },
      ],
    },
  },
];

const productAmendmentResources: CustomResourceProps[] = [
  {
    name: "products/data/amendments",
    identifier: "product/amendment/data",
    meta: {
      icon: <ShoppingOutlined />,
      label: "Products",
      canList: true,
      canShow: true,
      rejectRemarkKey: "remark",
      canCreate: true,
      createButtonLabel: "Amend",
      createComponent: <AmendmentCreate />,
      showComponent: <AmendmentShow />,
      approveComponent: <AmendmentApprove />,
      // canEdit: true,
      canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      initialFilters: [{ field: "approvalStatusId", operator: "eq", value: 1 }],
      // downloadButtons: [{ resource: "export/kyc/retail/amendment" }],
      amendmentDropdownFormColumn: {
        key: "product",
        type: "select",
        optionsMeta: {
          resourceName: "products/data",
          disableGetOne: true,
          initialFilters: [
            { field: "approvalStatusId", operator: "eq", value: 3 },
          ],
        },
      },
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
          initialValue: 1,
        },
        {
          key: "approvedAt",
          placeholder: ["Approved At (Start)", "Approved At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      fixedColumns: 3,
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "version" },
        { key: "createdAt" },
        { key: "approvedAt" },
        { key: "name" },
        { key: "approvalStatus" },
        { key: "remark", hideIfEmpty: true },
        {},
        { key: "description" },
        { key: "fundObjective" },
        { key: "periodicUpdate" },
        { key: "isRDO", type: "boolean" },
        { key: "maxDaily" },
      ],
      formColumns: [
        { key: "logo", type: "image" },
        { key: "description", type: "textarea" },
        { key: "fundObjective", type: "textarea" },
        { key: "periodicUpdate", type: "text" },
        { key: "isRDO", type: "boolean" },
        { key: "maxDaily", type: "number" },
      ],
    },
  },
  {
    name: "products/details/amendments",
    identifier: "product/amendment/detail",
    meta: {
      icon: <UnorderedListOutlined />,
      label: "Product Details",
      canList: true,
      canShow: true,
      rejectRemarkKey: "remark",
      canCreate: true,
      createButtonLabel: "Amend",
      createComponent: <AmendmentCreate />,
      showComponent: <AmendmentShow />,
      approveComponent: <AmendmentApprove />,
      // canEdit: true,
      canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      initialFilters: [{ field: "approvalStatusId", operator: "eq", value: 1 }],
      // downloadButtons: [{ resource: "export/kyc/retail/amendment" }],
      amendmentDropdownFormColumn: {
        key: "productDetail",
        type: "select",
        optionsMeta: {
          resourceName: "products/details",
          disableGetOne: true,
          labelKey: "product",
          initialFilters: [
            { field: "approvalStatusId", operator: "eq", value: 3 },
          ],
        },
      },
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
          initialValue: 1,
        },
        {
          key: "approvedAt",
          placeholder: ["Approved At (Start)", "Approved At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      fixedColumns: 3,
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "version" },
        { key: "createdAt" },
        { key: "approvedAt" },
        { key: "approvalStatus" },
        { key: "remark", hideIfEmpty: true },
        {},
        { key: "productDetail", childKey: "product", label: "Product Name" },
        { key: "status" },
        { key: "maturityDate" },
        { key: "maxCustomer" },
        { key: "initialUnit", type: "unit" },
        { key: "totalUnit", type: "unit" },
        { key: "initialSub", type: "currency" },
        { key: "minUnit", type: "unit" },
        { key: "minUnitValue" },
        { key: "minSub", type: "currency" },
        { key: "minRed", type: "currency" },
        { key: "minRemaining", type: "currency" },
        { key: "timeToExpireSub" },
        { key: "timeToSettleSub" },
        { key: "timeToSettleRed" },
        { key: "timeToSettleSwitch" },
        { key: "isSubOnHold" },
        { key: "isRedOnHold" },
        { key: "isSwitchOnHold" },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
          requiredOnCreate: true,
          hideOnEdit: true,
          isDisabled: true,
        },
        { key: "status", type: "text" },
        { key: "maturityDate", type: "date" },
        { key: "maxCustomer", type: "number" },
        { key: "initialUnit", type: "unit" },
        { key: "totalUnit", type: "unit" },
        { key: "initialSub", label: "Initial Subscription", type: "currency" },
        { key: "minUnit", label: "Minimum Units", type: "unit" },
        { key: "minUnitValue", label: "Minimum Unit Value", type: "number" },
        { key: "minSub", label: "Minimum Subscription", type: "currency" },
        { key: "minRed", label: "Minimum Redemption", type: "currency" },
        {
          key: "minRemaining",
          label: "Minimum Remaining Amount",
          type: "currency",
        },
        { key: "timeToExpireSub", type: "number" },
        { key: "timeToSettleSub", type: "number" },
        { key: "timeToSettleRed", type: "number" },
        { key: "timeToSettleSwitch", type: "number" },
        { key: "isSubOnHold", label: "Subscription On Hold", type: "boolean" },
        { key: "isRedOnHold", label: "Redemption On Hold", type: "boolean" },
        { key: "isSwitchOnHold", label: "Switch On Hold", type: "boolean" },
      ],
    },
  },
];

const databaseRelationsResources: CustomResourceProps[] = [
  {
    name: "join/product-investment-policies",
    identifier: "product/join/product-investment-policies",
    meta: {
      label: "Product - Investment Policies",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "product",
          childKey: "sinvestCode",
          label: "Product Code",
          hideOnRecursor: true,
        },
        {
          key: "product",
          childKey: "name",
          label: "Product Name",
          hideOnRecursor: true,
        },
        {
          key: "investmentPolicy",
          childKey: "investmentPolicyCode",
          label: "Investment Policy Code",
        },
        {
          key: "investmentPolicy",
          childKey: "investmentPolicyName",
          label: "Investment Policy Name",
        },
        { key: "value", type: "percentage" },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "investmentPolicyId",
          type: "select",
          optionsMeta: { resourceName: "static/investment-policies" },
          requiredOnCreate: true,
        },
        { key: "value", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "join/product-benefits",
    identifier: "product/join/product-benefits",
    meta: {
      label: "Product - Benefits",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "product",
          childKey: "sinvestCode",
          label: "Product Code",
          hideOnRecursor: true,
        },
        {
          key: "product",
          childKey: "name",
          label: "Product Name",
          hideOnRecursor: true,
        },
        { key: "benefit" },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "benefitId",
          type: "select",
          optionsMeta: { resourceName: "static/benefits" },
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "join/product-holdings",
    identifier: "product/join/product-holdings",
    meta: {
      label: "Product - Holdings",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "product",
          childKey: "sinvestCode",
          label: "Product Code",
          hideOnRecursor: true,
        },
        {
          key: "product",
          childKey: "name",
          label: "Product Name",
          hideOnRecursor: true,
        },
        {
          key: "holding",
          childKey: "holdingCode",
          label: "Holding Code",
          hideOnRecursor: true,
        },
        {
          key: "holding",
          childKey: "holdingName",
          label: "Holding Name",
          hideOnRecursor: true,
        },
        { key: "value", type: "percentage" },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "holdingId",
          type: "select",
          optionsMeta: { resourceName: "static/holdings" },
          requiredOnCreate: true,
        },
        { key: "value", type: "number", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "join/product-portfolio-composites",
    identifier: "product/join/product-portfolio-composites",
    meta: {
      label: "Product - Portfolio Composites",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "product",
          childKey: "sinvestCode",
          label: "Product Code",
          hideOnRecursor: true,
        },
        {
          key: "product",
          childKey: "name",
          label: "Product Name",
          hideOnRecursor: true,
        },
        {
          key: "portfolioComposite",
          childKey: "portfolioCompositeCode",
          label: "Portfolio Composite Code",
          hideOnRecursor: true,
        },
        {
          key: "portfolioComposite",
          childKey: "portfolioCompositeName",
          label: "Portfolio Composite Name",
          hideOnRecursor: true,
        },
        { key: "value", type: "percentage" },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "portfolioCompositeId",
          type: "select",
          optionsMeta: { resourceName: "static/portfolio-composites" },
          requiredOnCreate: true,
        },
        { key: "value", type: "number", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "join/product-risk-types",
    identifier: "product/join/product-risk-types",
    meta: {
      label: "Product - Risk Types",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        {
          key: "product",
          childKey: "sinvestCode",
          label: "Product Code",
          hideOnRecursor: true,
        },
        {
          key: "product",
          childKey: "name",
          label: "Product Name",
          hideOnRecursor: true,
        },
        {
          key: "riskType",
          childKey: "riskTypeCode",
          label: "Risk Type Code",
          hideOnRecursor: true,
        },
        {
          key: "riskType",
          childKey: "riskTypeName",
          label: "Risk Type Name",
          hideOnRecursor: true,
        },
        { key: "value", type: "percentage" },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "riskTypeId",
          type: "select",
          optionsMeta: { resourceName: "static/risk-types" },
          requiredOnCreate: true,
        },
        { key: "value", type: "number", requiredOnCreate: true },
      ],
    },
  },
];

export const productResources: CustomResourceProps[] = [
  {
    name: "product-list",
    meta: {
      icon: <ShoppingOutlined />,
      label: "Products",
    },
  },
  ...productListResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "product-list",
    },
  })),

  {
    name: "product/amendment",
    meta: {
      icon: <EditOutlined />,
      label: "Amendment",
      parent: "product-list",
    },
  },
  ...productAmendmentResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "product/amendment",
    },
  })),

  {
    name: "product/static-data",
    meta: {
      icon: <DatabaseOutlined />,
      label: "Static Data",
      parent: "product-list",
    },
  },
  ...productStaticDataResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "product/static-data",
    },
  })),

  {
    name: "product/database-relations",
    meta: {
      label: "Database Relations",
      icon: <ApartmentOutlined />,
      parent: "product-list",
    },
  },
  ...databaseRelationsResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "product/database-relations",
    },
  })),
];
