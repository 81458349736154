import {
  AuditOutlined,
  DatabaseOutlined,
  TeamOutlined,
  UserOutlined,
  BankOutlined,
  EditOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { CustomResourceProps } from "interfaces";
import {
  BankAccountShow,
  CustomerShow,
  DefaultCreate,
  KYCApprove,
  AmendmentShow,
  AmendmentApprove,
  AmendmentCreate,
  KYCEdit,
} from "components";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import { RiskProfileCreate } from "components/pages/customer/riskProfile";

const kycColumns = [
  { key: "idCardPhoto", kycStep: 1 },
  { key: "selfiePhoto", kycStep: 1 },
  { key: "signPhoto", kycStep: 1 },

  { key: "firstName", kycStep: 2 },
  { key: "lastName", kycStep: 2 },
  { key: "placeOfBirth", kycStep: 2 },
  { key: "dateOfBirth", kycStep: 2 },
  { key: "gender", kycStep: 2 },
  { key: "maritalStatus", kycStep: 2 },
  { key: "religion", kycStep: 2 },

  { key: "address", kycStep: 3 },
  { key: "city", kycStep: 3 },
  { key: "postalCode", kycStep: 3 },
  { key: "domicileAddress", kycStep: 3 },
  { key: "domicileCity", kycStep: 3 },
  { key: "domicilePostalCode", kycStep: 3 },
  { key: "correspAddress", kycStep: 3 },
  { key: "educationalBackground", kycStep: 3 },
  { key: "occupation", kycStep: 3 },
  { key: "sourceOfFund", kycStep: 3 },
  { key: "incomeLevel", kycStep: 3 },
  { key: "investmentObjective", kycStep: 3 },

  { key: "bank", kycStep: 4 },
  { key: "bankAccountNumber", kycStep: 4 },
  {
    key: "riskProfile",
    label: "Investment Risk Profile",
    kycStep: 4,
  },
];

const retailCustomerDisplayColumns = [
  { key: "id", label: "ID" },
  { key: "kycVerifiedAt", label: "Verified At", span: 1 },
  { key: "idCardNo", label: "NIK" },
  { key: "sid", label: "SID" },
  { key: "ifua", label: "IFUA" },
  { key: "unitHolderId", label: "Unit Holder ID" },
  { span: 2 },

  { key: "firstName", span: 1 },
  { key: "lastName", span: 1 },
  {
    key: "customerSignup",
    resourceName: "customers/retail/signups",
    label: "Nickname",
    childKey: "nickname",
    span: 1,
  },
  { key: "gender", span: 1 },
  { key: "placeOfBirth", span: 1 },
  { key: "dateOfBirth", span: 1 },
  { key: "religion" },

  { key: "address" },
  { key: "city" },
  { key: "postalCode" },

  { key: "domicileAddress" },
  { key: "domicileCity" },
  { key: "domicilePostalCode" },

  { key: "correspAddress", label: "Correspondence Address" },
  { key: "correspCity", label: "Correspondence City" },
  { key: "correspPostalCode", label: "Correspondence Postal Code" },

  { key: "maritalStatus" },
  { key: "occupation" },
  {
    key: "customerSignup",
    label: "Phone Number",
    childKey: "phoneNumber",
    hideOnRecursor: true,
  },
  { key: "mothersMaidenName" },
  { span: 2 },

  { key: "educationalBackground", span: 1 },
  { key: "sourceOfFund", span: 1 },
  { key: "riskProfile", label: "Investment Risk Profile", span: 1 },
  { key: "incomeLevel", span: 1 },
  { key: "investmentObjective" },
  { span: 2 },

  { key: "agent", span: 1, resourceName: "agents" },
  { key: "sellingAgent", span: 1 },
  { span: 2 },

  { key: "isConventional", label: "Conventional Customer", span: 1 },
  { key: "isActive", label: "Active", span: 1 },
  { key: "kycStatus", label: "KYC Status", span: 1 },
  { key: "unapprovedText", label: "Reject Reason", hideIfEmpty: true },
  { span: 2 },

  { key: "bankAccounts", childKey: "bankAccountNumber" },
  { key: "revisionNotes", type: "json", hideIfEmpty: true, hideOnList: true },
];

const retailCustomersResources: CustomResourceProps[] = [
  {
    name: "customers/retail/details",
    identifier: "retail/customers/all",
    meta: {
      label: "All",
      canList: true,
      canShow: true,
      showComponent: <CustomerShow />,
      statusResource: "kyc/history",
      statusForeignKey: "customerDetailId",
      hideOnChildRecursor: ["province"],
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "agentId",
          type: "select",
          optionsMeta: {
            resourceName: "agents",
            initialFilters: [
              { field: "isActive", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },

  {
    name: "customers/retail/app",
    identifier: "retail/customers/app",
    meta: {
      label: "MyHero",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
    },
  },
  {
    name: "customers/retail/selling-agent",
    identifier: "retail/customers/aperd",
    meta: {
      label: "APERD",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
    },
  },
  {
    name: "customers/retail/conventional",
    identifier: "retail/customers/conventional",
    meta: {
      label: "Conventional",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
    },
  },
];

const retailKycResources: CustomResourceProps[] = [
  {
    name: "customers/retail/details/temporaries",
    identifier: "retail/kyc/aperd",
    meta: {
      label: "APERD",
      canList: true,
      canBulkApprove: true,
      approveResource: "kyc/co-2-temporary",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      // uploadFormats: ["csv"],
      // statusResource: "customers/retail/details/temporaries/approval",
      // statusForeignKey: "customerDetailId",
      initialSorters: [{ field: "id", order: "desc" }],
      initialFilters: [{ field: "approvalStatusId", operator: "eq", value: 1 }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
          initialValue: 1,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "firstName" },
        { key: "lastName" },
        { key: "sid", label: "SID" },
        { key: "ifua", label: "IFUA" },
        { key: "bankAccountNumber" },
        { key: "bankAccountName" },
        { key: "remark" },
        { key: "bank" },
        { key: "sellingAgent" },
        { key: "approvalStatus" },
        { key: "approvedAt" },
        { key: "approvedBy" },
        { key: "createdAt" },
        { key: "createdBy" },
      ],
    },
  },
  {
    name: "kyc-conventional",
    identifier: "retail/kyc/create",
    meta: {
      label: "Create (Conventional)",
      defaultAction: "create",
      canList: true,
      listComponent: <DefaultCreate />,
      formColumns: [
        {
          key: "idCardPhoto",
          label: "ID Card Photo",
          type: "image",
          requiredOnCreate: true,
          uploadResource: "outbound/ocr-ktp-check",
          remapOCR: true,
        },
        { key: "signPhoto", type: "image", requiredOnCreate: true },
        { key: "firstName", type: "text", requiredOnCreate: true },
        { key: "lastName", type: "text" },
        {
          key: "idCardNo",
          label: "NIK",
          type: "text",
          requiredOnCreate: true,
          length: 16,
        },
        { key: "npwpNo", label: "NPWP", type: "text", minLength: 15 },
        { key: "email", type: "email", requiredOnCreate: true },
        { key: "phoneNumber", type: "number", requiredOnCreate: true },
        { key: "placeOfBirth", type: "text", requiredOnCreate: true },
        {
          key: "dateOfBirth",
          type: "date",
          requiredOnCreate: true,
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "genderId",
          type: "select",
          optionsMeta: { resourceName: "static/genders" },
          requiredOnCreate: true,
        },
        {
          key: "maritalStatusId",
          type: "select",
          optionsMeta: { resourceName: "static/marital-statuses" },
          requiredOnCreate: true,
        },
        {
          key: "religionId",
          type: "select",
          optionsMeta: { resourceName: "static/religions" },
          requiredOnCreate: true,
        },
        { key: "address", type: "textarea", requiredOnCreate: true },
        {
          key: "cityId",
          type: "select",
          optionsMeta: { resourceName: "static/cities" },
          requiredOnCreate: true,
        },
        { key: "postalCode", type: "number", length: 5 },
        {
          key: "domicileAddress",
          type: "textarea",
          span: 2,
        },
        {
          key: "domicileCityId",
          type: "select",
          optionsMeta: { resourceName: "static/cities" },
          requiredOnCreate: true,
        },
        {
          key: "domicilePostalCode",
          type: "number",
          length: 5,
        },
        {
          key: "correspAddress",
          label: "Correspondence Address",
          type: "textarea",
          requiredOnCreate: true,
          span: 2,
        },
        {
          key: "correspCityId",
          label: "Correspondence City",
          type: "select",
          optionsMeta: { resourceName: "static/cities" },
          requiredOnCreate: true,
        },
        {
          key: "educationalBackgroundId",
          type: "select",
          optionsMeta: { resourceName: "static/educational-backgrounds" },
          requiredOnCreate: true,
        },
        {
          key: "occupationId",
          type: "select",
          optionsMeta: { resourceName: "static/occupations" },
          requiredOnCreate: true,
        },
        {
          key: "sourceOfFundId",
          type: "select",
          optionsMeta: { resourceName: "static/source-of-funds" },
          requiredOnCreate: true,
        },
        {
          key: "incomeLevelId",
          type: "select",
          optionsMeta: { resourceName: "static/income-levels" },
          requiredOnCreate: true,
        },
        {
          key: "investmentObjectiveId",
          type: "select",
          optionsMeta: { resourceName: "static/investment-objectives" },
          requiredOnCreate: true,
        },
        {
          key: "bankId",
          type: "select",
          optionsMeta: { resourceName: "static/banks" },
          requiredOnCreate: true,
        },
        { key: "bankAccountName", type: "text", requiredOnCreate: true },
        { key: "bankAccountNumber", type: "number", requiredOnCreate: true },
        {
          key: "riskProfileId",
          label: "Investment Risk Profile",
          type: "select",
          optionsMeta: { resourceName: "static/risk-profiles" },
          requiredOnCreate: true,
        },
        {
          key: "agentCode",
          type: "select",
          optionsMeta: {
            resourceName: "agents",
            valueKey: "agentCode",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "workAddress",
          type: "textarea",
          span: 2,
        },
        {
          key: "workPhoneNumber",
          type: "text",
        },
        {
          key: "mothersMaidenName",
          label: "Mother's Maiden Name",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "beneficiaryFullName",
          type: "text",
        },
        {
          key: "beneficiaryAliasName",
          type: "text",
        },
        {
          key: "beneficiaryIdCardNo",
          label: "Beneficiary NIK",
          type: "text",
          length: 16,
        },
        {
          key: "beneficiaryAddress",
          type: "textarea",
          span: 2,
        },
        {
          key: "beneficiaryDomicileAddress",
          type: "textarea",
          span: 2,
        },
        {
          key: "beneficiaryPlaceOfBirth",
          type: "text",
        },
        {
          key: "beneficiaryDateOfBirth",
          type: "date",
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "beneficiaryNationalityId",
          type: "select",
          optionsMeta: { resourceName: "static/countries" },
        },
        {
          key: "beneficiaryOccupationId",
          type: "select",
          optionsMeta: { resourceName: "static/occupations" },
        },
        {
          key: "beneficiaryWorkAddress",
          type: "textarea",
          span: 2,
        },
        {
          key: "beneficiaryWorkPhoneNumber",
          type: "text",
        },
        {
          key: "beneficiaryGenderId",
          type: "select",
          optionsMeta: { resourceName: "static/genders" },
        },
        {
          key: "beneficiaryMaritalStatusId",
          type: "select",
          optionsMeta: { resourceName: "static/marital-statuses" },
        },
        {
          key: "aliasName",
          label: "Alias Name",
          type: "text",
        },
      ],
    },
  },
  {
    name: "customers/retail/signups",
    identifier: "retail/kyc/signup",
    meta: {
      label: "Sign-Up",
      canList: true,
      canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "nickname" },
        { span: 2 },
        { key: "phoneNumber" },
        { key: "isOtpVerified", label: "OTP Verified", span: 1 },
        { key: "otpVerifiedAt", label: "OTP Verified At", span: 1 },
        { span: 2 },
        { key: "email" },
        { key: "isEmailVerified", span: 1 },
        { key: "emailVerifiedAt", span: 1 },
        { span: 2 },
        { key: "appVersion" },
        { span: 2 },
        { key: "createdAt", span: 1 },
        { key: "updatedAt", span: 1 },
      ],
    },
  },
  {
    name: "customers/retail/signups/temporaries",
    identifier: "retail/kyc/unverified-signup",
    meta: {
      label: "Unverified Sign-Up",
      canList: true,
      canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      displayColumns: [
        { key: "nickname" },
        { span: 2 },
        { key: "phoneNumber" },
        { key: "isOtpVerified", label: "OTP Verified", span: 1 },
        { key: "otpVerifiedAt", label: "OTP Verified At", span: 1 },
        { span: 2 },
        { key: "email" },
        { key: "isEmailVerified", span: 1 },
        { key: "emailVerifedAt", span: 1 },
        { span: 2 },
        { key: "appVersion" },
        { span: 2 },
        { key: "createdAt", span: 1 },
        { key: "updatedAt", span: 1 },
      ],
    },
  },

  {
    name: "customers/retail/progress",
    identifier: "retail/kyc/progress",
    meta: {
      label: "On-Progress",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "customers/retail/pending",
    identifier: "retail/kyc/pending",
    meta: {
      label: "Pending",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canDelete: true,
      canApprove: true,
      approveResource: "kyc/cs",
      approveComponent: <KYCApprove />,
      approvePermissionResource: "customers/retail/pending",
      approvePermissionAction: "list",
      formColumns: kycColumns,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "customers/retail/revision",
    identifier: "retail/kyc/revision",
    meta: {
      label: "Revision",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      canEdit: true,
      editMethod: "patch",
      editComponent: <KYCEdit />,
      typeToEdit: "conventional",
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      fixedColumns: 1,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      formColumns: [
        {
          key: "idCardPhoto",
          label: "ID Card Photo",
          type: "image",
          uploadResource: "outbound/ocr-ktp-check",
        },
        { key: "signPhoto", type: "image" },
        { key: "firstName", type: "text" },
        { key: "lastName", type: "text" },
        { key: "placeOfBirth", type: "text" },
        {
          key: "dateOfBirth",
          type: "date",
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "genderId",
          type: "select",
          optionsMeta: { resourceName: "static/genders" },
        },
        {
          key: "maritalStatusId",
          type: "select",
          optionsMeta: { resourceName: "static/marital-statuses" },
        },
        {
          key: "religionId",
          type: "select",
          optionsMeta: { resourceName: "static/religions" },
        },
        { key: "address", type: "textarea" },
        {
          key: "cityId",
          type: "select",
          optionsMeta: { resourceName: "static/cities" },
        },
        { key: "postalCode", type: "number", length: 5 },
        {
          key: "domicileAddress",
          type: "textarea",
          span: 2,
        },
        {
          key: "domicileCityId",
          type: "select",
          optionsMeta: { resourceName: "static/cities" },
        },
        {
          key: "domicilePostalCode",
          type: "number",
          length: 5,
        },
        {
          key: "correspAddress",
          label: "Correspondence Address",
          type: "textarea",
          span: 2,
        },
        {
          key: "educationalBackgroundId",
          type: "select",
          optionsMeta: { resourceName: "static/educational-backgrounds" },
        },
        {
          key: "occupationId",
          type: "select",
          optionsMeta: { resourceName: "static/occupations" },
        },
        {
          key: "sourceOfFundId",
          type: "select",
          optionsMeta: { resourceName: "static/source-of-funds" },
        },
        {
          key: "incomeLevelId",
          type: "select",
          optionsMeta: { resourceName: "static/income-levels" },
        },
        {
          key: "investmentObjectiveId",
          type: "select",
          optionsMeta: { resourceName: "static/investment-objectives" },
        },
        {
          key: "riskProfileId",
          label: "Investment Risk Profile",
          type: "select",
          optionsMeta: { resourceName: "static/risk-profiles" },
        },
      ],
    },
  },

  {
    name: "customers/retail/kyc-1",
    identifier: "retail/kyc/kyc-1",
    meta: {
      label: "KYC 1",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canDelete: true,
      canApprove: true,
      statusToApprove: "kyc 1",
      approveResource: "kyc/co-1",
      approveComponent: <KYCApprove />,
      approvePermissionResource: "customers/retail/kyc-1",
      approvePermissionAction: "list",
      formColumns: kycColumns,
      downloadButtons: [{ resource: "export/kyc/retail" }],
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "customers/retail/kyc-2",
    identifier: "retail/kyc/kyc-2",
    meta: {
      label: "KYC 2",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canDelete: true,
      canApprove: true,
      statusToApprove: "kyc 2",
      approveResource: "kyc/co-2",
      approveComponent: <KYCApprove />,
      approvePermissionResource: "customers/retail/kyc-2",
      approvePermissionAction: "list",
      formColumns: kycColumns,
      downloadButtons: [{ resource: "export/kyc/retail" }],
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "customers/retail/verified",
    identifier: "retail/kyc/verified",
    meta: {
      label: "Verified",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "kycVerifiedAt",
          placeholder: ["Verified At (Start)", "Verified At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "customers/retail/rejected",
    identifier: "retail/kyc/rejected",
    meta: {
      label: "Rejected",
      canList: true,
      canShow: true,
      showResource: "customers/retail/details",
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      displayColumns: retailCustomerDisplayColumns,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
];

const retailBankAccounts: CustomResourceProps = {
  name: "customers/retail/bank-accounts",
  identifier: "retail/bank-accounts",
  meta: {
    icon: <BankOutlined />,
    label: "Bank Accounts",
    canList: true,
    canShow: true,
    showComponent: <BankAccountShow />,
    canCreate: true,
    // canEdit: true,
    canApprove: true,
    // canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    downloadButtons: [{ resource: "export/kyc/retail/bank-account" }],
    statusResource: "customers/retail/bank-accounts/approval",
    statusForeignKey: "customerBankAccountId",
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
      {
        key: "customerDetailId",
        placeholder: "Customer",
        type: "select",
        optionsMeta: {
          resourceName: "customers/retail/details",
          labelKey: ["firstName", "lastName"],
          additionalLabelKeys: ["agent.agentName"],
          optionsWidth: 480,
        },
      },
      {
        key: "approvalStatusId",
        type: "select",
        optionsMeta: {
          resourceName: "static/approval-statuses",
          pagination: { mode: "off" },
          disableGetOne: true,
          disableSearch: true,
        },
      },
      {
        key: "isActive",
        label: "Active",
        type: "select",
        options: [
          { label: "Active", value: true },
          { label: "Inactive", value: false },
        ],
      },
    ],
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "bankAccountName" },
      { key: "bank", hideOnRecursor: true },
      { key: "bankAccountNumber" },
      { key: "currency" },
      { key: "isMainBankAccount" },
      { key: "isUploaded" },
      { key: "isActive" },
      { key: "approvalStatus" },
      {
        key: "customerDetail",
        resourceName: "customers/retail/details",
        hideOnParent: true,
      },
    ],
    formColumns: [
      { key: "bankAccountNumber", type: "number", requiredOnCreate: true },
      { key: "bankAccountName", type: "text", requiredOnCreate: true },
      {
        key: "customerDetailId",
        type: "select",
        optionsMeta: {
          resourceName: "customers/retail/details",
          labelKey: ["firstName", "lastName"],
          additionalLabelKeys: ["agent.agentName"],
        },
        requiredOnCreate: true,
      },
      {
        key: "currencyId",
        type: "select",
        optionsMeta: { resourceName: "static/currencies" },
        requiredOnCreate: true,
      },
      {
        key: "bankId",
        type: "select",
        optionsMeta: { resourceName: "static/banks" },
        requiredOnCreate: true,
      },
    ],
  },
};

const retailAmendment: CustomResourceProps = {
  name: "kyc-amendment",
  identifier: "retail/kyc-amendment",
  meta: {
    icon: <EditOutlined />,
    label: "Amendment",
    canList: true,
    canShow: true,
    rejectRemarkKey: "remark",
    canCreate: true,
    createButtonLabel: "Amend",
    createComponent: <AmendmentCreate />,
    showComponent: <AmendmentShow />,
    approveComponent: <AmendmentApprove />,
    // canEdit: true,
    canApprove: true,
    // canDelete: true,
    initialSorters: [{ field: "id", order: "desc" }],
    initialFilters: [{ field: "kycStatus", operator: "eq", value: 2 }],
    downloadButtons: [{ resource: "export/kyc/retail/amendment" }],
    amendmentDropdownFormColumn: {
      key: "customerDetail",
      type: "select",
      optionsMeta: {
        resourceName: "customers/retail/details",
        labelKey: ["firstName", "lastName"],
        additionalLabelKeys: ["agent.agentName"],
        disableGetOne: true,
        initialFilters: [{ field: "isVerified", operator: "eq", value: true }],
      },
    },
    filters: [
      {
        key: "search",
        type: "text",
        placeholder: "Search",
        prefix: <SearchOutlined />,
      },
      {
        key: "kycStatus",
        label: "Status",
        type: "select",
        options: [
          { label: "Pending", value: 2 },
          { label: "Verified", value: 5 },
          { label: "Rejected", value: 7 },
        ],
        initialValue: 2,
      },
      {
        key: "approvedAt",
        placeholder: ["Approved At (Start)", "Approved At (End)"],
        type: "daterange",
        maxDate: dayjs().endOf("day"),
      },
    ],
    fixedColumns: 3,
    displayColumns: [
      { key: "id", label: "ID" },
      { key: "createdAt" },
      { key: "updatedAt", label: "Latest Update At" },
      { key: "kycStatus", label: "Status" },
      { key: "remark", hideIfEmpty: true },
      {},
      {
        key: "unitHolderId",
        label: "Unit Holder ID",
        hideOnList: true,
        hideIfEmpty: true,
      },
      {
        key: "customerDetail",
        childKey: "unitHolderId",
        label: "Unit Holder ID",
        hideIfEmpty: true,
      },
      { key: "firstName", hideOnList: true, hideIfEmpty: true },
      {
        key: "customerDetail",
        childKey: "firstName",
        label: "First Name",
        hideIfEmpty: true,
      },
      { key: "lastName", hideOnList: true, hideIfEmpty: true },
      {
        key: "customerDetail",
        label: "Last Name",
        childKey: "lastName",
        hideIfEmpty: true,
      },
      { key: "religion" },
      { key: "maritalStatus" },
      { key: "occupation" },
      { key: "educationalBackground" },
      { key: "incomeLevel" },
      { key: "sourceOfFund" },
      { key: "nationality" },
      { key: "city" },
      { key: "address" },
      { key: "postalCode" },
      { key: "domicileCity" },
      { key: "domicileAddress" },
      { key: "domicilePostalCode" },
      { key: "correspCity", label: "Correspondence City" },
      { key: "correspAddress", label: "Correspondence Address" },
      { key: "correspPostalCode", label: "Correspondence Postal Code" },
    ],
    formColumns: [
      {
        key: "religionId",
        label: "Religion",
        type: "select",
        optionsMeta: { resourceName: "static/religions" },
      },
      {
        key: "maritalStatusId",
        label: "Marital Status",
        type: "select",
        optionsMeta: { resourceName: "static/marital-statuses" },
      },
      {
        key: "occupationId",
        label: "Occupation",
        type: "select",
        optionsMeta: { resourceName: "static/occupations" },
      },
      {
        key: "educationalBackgroundId",
        label: "Educational Background",
        type: "select",
        optionsMeta: { resourceName: "static/educational-backgrounds" },
      },
      {
        key: "incomeLevelId",
        label: "Income Level",
        type: "select",
        optionsMeta: { resourceName: "static/income-levels" },
      },
      {
        key: "sourceOfFundId",
        label: "Source Of Fund",
        type: "select",
        optionsMeta: { resourceName: "static/source-of-funds" },
      },
      {
        key: "nationalityId",
        label: "Nationality",
        type: "select",
        optionsMeta: { resourceName: "static/countries" },
        span: 2,
      },
      {
        key: "cityId",
        label: "City",
        type: "select",
        optionsMeta: { resourceName: "static/cities" },
        span: 2,
      },
      {
        key: "address",
        type: "textarea",
        span: 2,
      },
      {
        key: "postalCode",
        type: "number",
        span: 2,
      },
      {
        key: "domicileCityId",
        label: "Domicile City",
        type: "select",
        optionsMeta: { resourceName: "static/cities" },
        span: 2,
      },
      {
        key: "domicileAddress",
        type: "textarea",
        span: 2,
      },
      {
        key: "domicilePostalAddress",
        type: "textarea",
        span: 2,
      },
      {
        key: "correspCityId",
        label: "Correspondence City",
        type: "select",
        optionsMeta: { resourceName: "static/cities" },
        span: 2,
      },
      {
        key: "correspAddress",
        label: "Correspondence Address",
        type: "textarea",
        span: 2,
      },
      {
        key: "correspPostalAddress",
        label: "Correspondence Postal Address",
        type: "textarea",
        span: 2,
      },
    ],
  },
};

const retailStaticDataResources: CustomResourceProps[] = [
  {
    name: "static/educational-backgrounds",
    identifier: "retail/static-data/educational-backgrounds",
    meta: {
      label: "Educational Backgrounds",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "educationCode", type: "number", requiredOnCreate: true },
        { key: "educationName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/genders",
    identifier: "retail/static-data/genders",
    meta: {
      label: "Genders",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "genderCode", type: "number", requiredOnCreate: true },
        { key: "genderName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/income-levels",
    identifier: "retail/static-data/income-levels",
    meta: {
      label: "Income Levels",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "incomeCode", type: "text", requiredOnCreate: true },
        { key: "incomeName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/investment-objectives",
    identifier: "retail/static-data/investment-objectives",
    meta: {
      label: "Investment Objectives",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "investmentObjectiveCode",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "investmentObjectiveName",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "static/marital-statuses",
    identifier: "retail/static-data/marital-statuses",
    meta: {
      label: "Marital Statuses",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "maritalStatusCode", type: "text", requiredOnCreate: true },
        { key: "maritalStatusName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/occupations",
    identifier: "retail/static-data/occupations",
    meta: {
      label: "Occupations",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "occupationCode", type: "text", requiredOnCreate: true },
        { key: "occupationName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/religions",
    identifier: "retail/static-data/religions",
    meta: {
      label: "Religions",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "religionCode", type: "text", requiredOnCreate: true },
        { key: "religionName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/risk-profiles",
    identifier: "retail/static-data/risk-profiles",
    meta: {
      label: "Investment Risk Profiles",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "mark", type: "text", requiredOnCreate: true },
        { key: "description", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/risk-profile-questions",
    identifier: "retail/static-data/risk-profile-questions",
    meta: {
      label: "Risk Profile Questions",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "category",
          label: "Category",
          type: "text",
        },
      ],
      formColumns: [
        { key: "category", type: "text", requiredOnCreate: true },
        { key: "questionText", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/risk-profile-choices",
    identifier: "retail/static-data/risk-profile-choices",
    meta: {
      label: "Risk Profile Choice",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "asc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "questionId",
          label: "Question",
          type: "select",
          optionsMeta: {
            resourceName: "static/risk-profile-questions",
          },
        },
      ],
      formColumns: [
        {
          key: "questionId",
          label: "Question",
          type: "select",
          optionsMeta: {
            resourceName: "static/risk-profile-questions",
          },
          requiredOnCreate: true,
        },
        { key: "choiceText", type: "text", requiredOnCreate: true },
        { key: "score", type: "number", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/risk-profile-categories",
    identifier: "retail/static-data/risk-profile-categories",
    meta: {
      label: "Risk Profile Categories",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "asc" }],
      filters: [
        {
          key: "riskProfile",
          label: "Investment Risk Profile",
          type: "text",
        },
      ],
      displayColumns: [
        { key: "id", span: 1 },
        { key: "riskProfile", label: "Investment Risk Profile", span: 1 },
        { key: "description" },
        { key: "minScore", span: 1 },
        { key: "maxScore", span: 1 },
        { key: "minRiskTolerance", span: 1 },
        { key: "maxRiskTolerance", span: 1 },
      ],
      formColumns: [
        {
          key: "riskProfile",
          label: "Investment Risk Profile",
          type: "text",
          requiredOnCreate: true,
        },
        { key: "description", type: "text", requiredOnCreate: true },
        { key: "minScore", type: "number", requiredOnCreate: true },
        { key: "maxScore", type: "number", requiredOnCreate: true },
        { key: "minRiskTolerance", type: "number", requiredOnCreate: true },
        { key: "maxRiskTolerance", type: "number", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/risk-profile-compositions",
    identifier: "retail/static-data/risk-profile-compositions",
    meta: {
      label: "Risk Profile Composition",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "asc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productType",
          type: "text",
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "categoryId", label: "Category ID" },
        { key: "productType" },
        { key: "composition" },
        { key: "category", hideOnRecursor: true },
        {
          key: "category",
          label: "Investment Risk Profile",
          childKey: "riskProfile",
          hideOnRecursor: true,
        },
      ],
      formColumns: [
        {
          key: "categoryId",
          label: "Category ID",
          type: "number",
          requiredOnCreate: true,
        },
        { key: "productType", type: "text", requiredOnCreate: true },
        { key: "composition", type: "number", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/source-of-funds",
    identifier: "retail/static-data/source-of-funds",
    meta: {
      label: "Source of Funds",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "sourceOfFundCode", type: "number", requiredOnCreate: true },
        { key: "sourceOfFundName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/selling-agents",
    identifier: "retail/static-data/selling-agents",
    meta: {
      label: "Selling Agents",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "sellingAgentCode", type: "text", requiredOnCreate: true },
        {
          key: "sellingAgentSinvestCode",
          type: "text",
          requiredOnCreate: true,
        },
        { key: "sellingAgentName", type: "text", requiredOnCreate: true },
      ],
    },
  },
];

const retailCddResources: CustomResourceProps[] = [
  {
    name: "customers/retail/risk-profiles",
    identifier: "retail/risk-profiles/history",
    meta: {
      icon: <EditOutlined />,
      label: "Risk Profile",
      canList: true,
      canShow: true,
      canEdit: true,
      // canCreate: true,
      // canApprove: true,
      // canDelete: true,
      editMethod: "patch",
      initialSorters: [{ field: "id", order: "desc" }],
      initialFilters: [{ field: "isLatest", operator: "eq", value: true }],
      displayColumns: [
        {
          key: "customerDetail",
          childKey: "firstName",
          label: "First Name",
          span: 1,
        },
        {
          key: "customerDetail",
          childKey: "lastName",
          label: "Last Name",
          span: 1,
        },
        {
          key: "kycVerifiedAt",
          label: "KYC Verified At",
          span: 1,
        },
        { key: "date", label: "Calculated At", span: 1 },
        { key: "isPep", label: "PEP", type: "boolean", span: 1 },
        { key: "isLatest", label: "Latest", type: "boolean", span: 1 },
        {},
        {
          key: "cddOccupation",
          childKey: "occupationName",
          label: "Occupation",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddOccupation",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddProductType",
          childKey: "productTypeName",
          label: "Product Type",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddProductType",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddProvince",
          childKey: "provinceName",
          label: "Province",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddProvince",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddChannelDistribution",
          childKey: "channelDistributionName",
          label: "Channel Distribution",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddChannelDistribution",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddIncomeLevel",
          childKey: "incomeName",
          label: "Income Level",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddIncomeLevel",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddSourceOfFund",
          childKey: "sourceOfFundName",
          label: "Source Of Fund",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddSourceOfFund",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddCriminalAct",
          childKey: "criminalActName",
          label: "Criminal Act",
          span: 1,
          hideOnList: true,
        },
        {
          key: "cddCriminalAct",
          childKey: "score",
          label: "Score",
          span: 1,
          hideOnList: true,
        },
        {},
        { key: "score", label: "Final Score", span: 1 },
        { key: "classification", span: 1 },
        { key: "remark" },
      ],
      formColumns: [
        {
          key: "isPep",
          label: "PEP",
          type: "boolean",
        },
        {
          key: "remark",
          type: "text",
        }
      ],
      filters: [
        {
          key: "customerDetailId",
          placeholder: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/retail/details",
            labelKey: ["firstName", "lastName"],
            additionalLabelKeys: ["agent.agentName"],
            optionsWidth: 480,
          },
        },
        {
          key: "kycVerifiedAt",
          placeholder: ["KYC Verified At (Start)", "KYC Verified At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "classification",
          label: "Classification",
          type: "select",
          options: [
            { label: "HR", value: "HR" },
            { label: "MR", value: "MR" },
            { label: "LR", value: "LR" },
          ],
        },
        {
          key: "isLatest",
          label: "Latest",
          type: "select",
          initialValue: true,
          options: [
            { label: "Yes", value: true },
            { label: "No", value: false },
          ],
        },
      ],
    },
  },
  {
    name: "customers/retail/risk-profiles",
    identifier: "retail/risk-profiles/recalculate",
    meta: {
      icon: <RedoOutlined />,
      label: "Recalculate",
      canList: true,
      listComponent: <RiskProfileCreate />,
      formColumns: [
        {
          key: "date",
          type: "daterange",
          requiredOnCreate: true,
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["dateFrom", "dateTo"],
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
            disableGetOne: false,
          },
        },
      ],
    },
  },
];

export const retailResources: CustomResourceProps[] = [
  {
    name: "retail",
    meta: {
      icon: <UserOutlined />,
    },
  },

  {
    name: "retail/customers",
    meta: {
      icon: <TeamOutlined />,
      label: "Customers",
      parent: "retail",
    },
  },
  ...retailCustomersResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "retail/customers",
    },
  })),

  {
    name: "retail/kyc",
    meta: {
      icon: <AuditOutlined />,
      label: "KYC",
      parent: "retail",
    },
  },
  ...retailKycResources.map((resource) => ({
    ...resource,
    meta: { ...resource.meta, parent: resource?.meta?.parent || "retail/kyc" },
  })),
  {
    ...retailAmendment,
    meta: {
      ...retailAmendment.meta,
      parent: retailAmendment?.meta?.parent || "retail",
    },
  },

  {
    name: "retail/risk-profiles",
    meta: {
      icon: <TeamOutlined />,
      label: "Risk Profile",
      parent: "retail",
    },
  },
  ...retailCddResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "retail/risk-profiles",
    },
  })),

  {
    ...retailBankAccounts,
    meta: {
      ...retailBankAccounts.meta,
      parent: retailBankAccounts?.meta?.parent || "retail",
    },
  },

  {
    name: "retail/static-data",
    meta: {
      icon: <DatabaseOutlined />,
      label: "Static Data",
      parent: "retail",
    },
  },
  ...retailStaticDataResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "retail/static-data",
    },
  })),
];
