import { AuthBindings } from "@refinedev/core";
import { axiosInstance, mapAccessControlRules } from "utils";
import { setAuth } from "redux/slices/authSlice";
import { resetTemp, setTemp } from "redux/slices/tempSlice";
import { resetUser, setUser } from "redux/slices/userSlice";
import { store } from "redux/store";
import { notification } from "antd";
import { resetAccessControlCache } from "redux/slices/accessControlCacheSlice";

const API_URL =
  process.env.REACT_APP_BACKOFFICE_API_URL ||
  "http://localhost:4000/api/v1/backoffice";

const resetAllAuthData = () => {
  store?.dispatch(setAuth({ refreshToken: "" }));
  store?.dispatch(resetAccessControlCache());
  store?.dispatch(resetTemp());
  store?.dispatch(resetUser());
};

export const authProvider: AuthBindings = {
  login: async ({ username, email, password, remember }) => {
    try {
      const response = await axiosInstance.post(API_URL + "/auth/login", {
        email,
        password,
      });
      if (response?.data?.statusCode === 202) {
        return {
          success: false,
          error: {
            name: "Password Expired!",
            message: response?.data?.body?.details || "Unknown Error",
          },
        };
      }
      const userData = response?.data?.body?.user;
      const accessToken = response?.data?.body?.token?.access?.token;
      const refreshToken = response?.data?.body?.token?.refresh?.token;

      store?.dispatch(setUser(userData));
      store?.dispatch(setTemp({ accessToken }));
      store?.dispatch(
        setAuth({
          refreshToken,
          email: remember ? email : "",
          remember,
        })
      );

      const params = new URLSearchParams(window.location.search);
      const redirectTo = params.get("to") || "/";

      return { success: true, redirectTo };
    } catch (error: any) {
      return {
        success: false,
        error: {
          name: `Error code: ${error?.statusCode || "unknown"}`,
          message: error?.message || "Unknown Error",
        },
      };
    }
  },
  logout: async () => {
    try {
      const refreshToken = store?.getState()?.auth?.refreshToken;
      if (refreshToken && refreshToken !== "") {
        await axiosInstance.post(API_URL + "/auth/logout", { refreshToken });
      }

      return { success: true };
    } catch (error: any) {
      return {
        success: false,
        error: {
          name: `Error code: ${error?.statusCode || "unknown"}`,
          message: error?.message || "Unknown Error",
        },
      };
    } finally {
      resetAllAuthData();
      window.location.reload();
    }
  },
  check: async () => {
    try {
      const oldAccessToken = store?.getState()?.temp?.accessToken;
      const oldRefreshToken = store?.getState()?.auth?.refreshToken;
      var isAuthenticated = false;

      if (oldAccessToken && oldAccessToken !== "") {
        // Check if access token is valid
        const checkAccessToken = await axiosInstance.post(
          API_URL + "/auth/check-access-token",
          { accessToken: oldAccessToken }
        );

        isAuthenticated = checkAccessToken?.data?.body?.status;
      } else if (oldRefreshToken && oldRefreshToken !== "") {
        // Refresh access token
        const refreshAccessToken = await axiosInstance.post(
          API_URL + "/auth/refresh",
          { refreshToken: oldRefreshToken }
        );

        isAuthenticated = refreshAccessToken?.data?.success;
        const accessToken = refreshAccessToken?.data?.body?.access?.token;
        const refreshToken = refreshAccessToken?.data?.body?.refresh?.token;

        store?.dispatch(setTemp({ accessToken }));
        store?.dispatch(setAuth({ refreshToken }));
      }

      if (isAuthenticated) {
        // get user's data
        const getUser = await axiosInstance.get(API_URL + "/users/me");
        const user = getUser?.data?.body;

        // Get user's access control rules
        const getRoles = await axiosInstance.get(API_URL + `/admin/roles/me`);
        const roles = getRoles?.data?.body?.rows;
        const accessControlRules = mapAccessControlRules(roles);

        store?.dispatch(setUser(user));
        store?.dispatch(setTemp({ accessControlRules }));

        return { authenticated: true };
      } else {
        resetAllAuthData();
        return { authenticated: false, redirectTo: "/login" };
      }
    } catch (error) {
      resetAllAuthData();
      return { authenticated: false, redirectTo: "/login" };
    }
  },
  forgotPassword: async ({ email }) => {
    // send password reset link to the user's email address here
    try {
      const resp = await axiosInstance.post(
        API_URL + "/auth/reset-password/request-token",
        {
          email,
        }
      );

      notification.open({
        message: "Email has been sent!",
        description:
          resp?.data?.message ||
          "Please check your email to reset your password.",
      });

      return {
        success: true,
      };
    } catch (error: any) {
      return {
        success: false,
        error: {
          name: `Status Code : ${error?.statusCode}`,
          message: error?.message,
        },
      };
    }
  },
  updatePassword: async ({ password, confirmPassword, token }) => {
    // send password reset link to the user's email address here
    try {
      const resp = await axiosInstance.post(
        API_URL + `/auth/reset-password/${token || ""}`,
        {
          password,
          confirmPassword,
        }
      );

      notification.open({
        message: "Password has been Updated!",
        description: resp?.data?.message,
      });

      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (error: any) {
      return {
        success: false,
        error: {
          name: `Status Code : ${error?.statusCode}`,
          message: error?.message,
        },
      };
    }
  },
  getPermissions: async () => {
    const user = store?.getState()?.user;
    if (user?.id && user?.id > 0) return user?.role;
    return null;
  },
  getIdentity: async () => {
    const user = store?.getState()?.user;
    if (user?.id && user?.id > 0) return user;
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
