import React, { useState } from "react";
import {
  BaseRecord,
  HttpError,
  IResourceComponentsProps,
  UseFormProps,
  useNavigation,
  useResource,
} from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { CustomErrorComponent, FormCard } from "components/organisms";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { Divider, Typography } from "antd";
import { useChildRecords, setFormInitialValues } from "utils";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation();
  const { resource } = useResource();
  const [parentData, setParentData] = useState<BaseRecord>();

  let useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.editResource,
    meta: { method: resource?.meta?.editMethod || "put" },
  };

  if (resource?.meta?.childResource) {
    useFormProps = {
      ...useFormProps,
      redirect: false,
      onMutationSuccess: (data) => {
        setParentData(data?.data);
        childFormProps?.form?.setFieldsValue({
          [resource?.meta?.childForeignKey]: data?.data?.id,
        });
        childFormProps?.form?.submit();
      },
    };
  }

  const {
    formProps: parentFormProps,
    saveButtonProps: parentSaveButtonProps,
    queryResult: parentQueryResult,
    formLoading,
  } = useForm(useFormProps);
  const parentRecord = parentQueryResult?.data?.data;

  const childRecords = useChildRecords({
    resourceName: resource?.meta?.childResource,
    foreignKey: resource?.meta?.childForeignKey,
    parentId: parentRecord?.id,
  });

  const { resource: childResource } = useResource(
    resource?.meta?.childResource
  );

  // The following codes handle the child resource
  const {
    formProps: childFormProps,
    saveButtonProps: childSaveButtonProps,
    queryResult: childQueryResult,
  } = useForm({
    resource: childResource?.name,
    id: childRecords?.[0]?.id,
    redirect: false,
    onMutationSuccess: () =>
      list(resource?.identifier || resource?.name || "/"),
  });

  // set the form's initial values
  setFormInitialValues({
    formColumns: resource?.meta?.formColumns,
    formProps: parentFormProps,
  });
  setFormInitialValues({
    formColumns: childResource?.meta?.formColumns,
    formProps: childFormProps,
  });

  const parentError = parentQueryResult?.error as HttpError;
  if (parentError?.statusCode) {
    return <CustomErrorComponent error={parentError} />;
  }

  const childError = childQueryResult?.error as HttpError;
  if (childError?.statusCode) {
    return <CustomErrorComponent error={childError} />;
  }

  return (
    <Edit
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      isLoading={formLoading}
      headerProps={{ breadcrumb: undefined, extra: <CustomRefreshButton /> }}
      saveButtonProps={
        !parentData ? parentSaveButtonProps : childSaveButtonProps
      }
    >
      {/* PARENT FORM */}
      <FormCard formProps={parentFormProps} resource={resource} />

      {resource?.meta?.childResource && (
        <>
          <Divider />
          <Typography.Title level={3}>
            {`${pluralize.singular(
              childResource?.meta?.label || startCase(childResource?.name || "")
            )}`}
          </Typography.Title>

          {/* CHILD FORM */}
          <FormCard formProps={childFormProps} resource={childResource} />
        </>
      )}
    </Edit>
  );
};
