/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  useShow,
  IResourceComponentsProps,
  BaseRecord,
  useResource,
  useNavigation,
  useParsed,
  HttpError,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { Col } from "antd";
import { startCase } from "lodash";
import pluralize from "pluralize";
import { KycShowLayout } from "./components";
import { DownloadButton } from "components/atoms";
import { CustomErrorComponent } from "components/organisms";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

export const KYCApprove: React.FC<IResourceComponentsProps> = () => {
  const { list } = useNavigation();
  const { id, pathname } = useParsed();
  const { resource } = useResource(
    pathname?.split("/").filter(Boolean).slice(0, -2).join("/")
  );

  const { queryResult } = useShow({
    resource: resource?.meta?.showResource || resource?.name,
    id,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const [mappedData, setMappedData] = useState<BaseRecord | undefined>(record);

  // MAP THE MAIN BANK ACCOUNT TO THE DATA
  useEffect(() => {
    const bankAccount = record?.bankAccounts?.find(
      (account: any) => account?.isMainBankAccount
    );

    if (bankAccount) {
      setMappedData({
        ...record,
        bank: bankAccount?.bank,
        bankAccountNumber: bankAccount?.bankAccountNumber,
      });
    } else {
      setMappedData(record);
    }
  }, [record]);

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Col>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          onBack: () => list(resource?.identifier || resource?.name || ""),
          breadcrumb: undefined,
          extra: (
            <>
              {resource?.meta?.downloadButtons?.map((button: any) => (
                <DownloadButton
                  key={`${button?.resource}/${id || record?.id || ""}`}
                  resource={button?.resource}
                  id={(id || record?.id || "") as string}
                  label={button?.label}
                />
              ))}

              <CustomRefreshButton />
            </>
          ),
        }}
      >
        <KycShowLayout objectData={mappedData} />
      </Show>
    </Col>
  );
};
