import { Text } from "components/atoms";
import { Card, Col, Row } from "antd";
import { ReconTotalSummary } from "../models/data";
import { SummaryCardItem } from "./summaryCardItem";
import { useMemo } from "react";
import { useTranslate } from "@refinedev/core";

type SummaryCardProps = {
  title: string;
  dataSInvest: ReconTotalSummary;
  dataGoodfund: ReconTotalSummary;
  isDataAvailable: boolean;
  loading: boolean;
};
export const SummaryCard = ({
  title,
  dataGoodfund,
  dataSInvest,
  isDataAvailable,
  loading,
}: SummaryCardProps) => {
  const translate = useTranslate();
  const status = useMemo(() => {
    if (isDataAvailable) {
      return "available";
    }

    if (loading) {
      return "loading";
    }

    return "initial";
  }, [isDataAvailable, loading]);

  return (
    <Card
      headStyle={{
        padding: "8px 16px",
        backgroundColor: "#0a78bd",
      }}
      bodyStyle={{
        padding: "0 0",
        justifyContent: "center",
        height: "100%",
        minHeight: "200px",
      }}
      style={{
        height: "100%",
        minHeight: "200px",
        backgroundColor: "rgba(0,0,0,0)",
      }}
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Text size="sm" strong style={{ marginLeft: ".7rem", color: "#fff" }}>
            {title}
          </Text>
        </div>
      }
    >
      <Row gutter={[16, 32]} style={{ marginTop: "16px" }}>
        <Col xl={6} sm={12} xs={24}>
          <SummaryCardItem
            loading={loading}
            goodfundValue={dataGoodfund.red}
            SInvestValue={dataSInvest.red}
            title={translate("page.recon.summary.redemption", "Redemption")}
            status={status}
            isDifferent={dataGoodfund.red !== dataSInvest.red}
          />
        </Col>
        <Col xl={6} sm={12} xs={24}>
          <SummaryCardItem
            loading={loading}
            goodfundValue={dataGoodfund.sub}
            SInvestValue={dataSInvest.sub}
            title={translate("page.recon.summary.subscription", "Subscription")}
            status={status}
            isDifferent={dataGoodfund.sub !== dataSInvest.sub}
          />
        </Col>
        <Col xl={6} sm={12} xs={24}>
          <SummaryCardItem
            loading={loading}
            goodfundValue={dataGoodfund.switchIn}
            SInvestValue={dataSInvest.switchIn}
            title={translate("page.recon.summary.switchIn", "Switch In")}
            status={status}
            isDifferent={dataGoodfund.switchIn !== dataSInvest.switchIn}
          />
        </Col>
        <Col xl={6} sm={12} xs={24}>
          <SummaryCardItem
            loading={loading}
            goodfundValue={dataGoodfund.switchOut}
            SInvestValue={dataSInvest.switchOut}
            title={translate("page.recon.summary.switchOut", "Switch Out")}
            status={status}
            isDifferent={dataGoodfund.switchOut !== dataSInvest.switchOut}
          />
        </Col>
      </Row>
    </Card>
  );
};
