import { ClockCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import { CustomResourceProps } from "interfaces";

export const periodicResources: CustomResourceProps[] = [
  {
    name: "periodic-data",
    meta: {
      icon: <ClockCircleOutlined />,
    },
  },
  {
    name: "periodic/benchmark-histories",
    identifier: "periodic-data/benchmark-histories",
    meta: {
      label: "Benchmark Histories",
      parent: "periodic-data",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      uploadResource: "periodic/benchmark-histories/bulk-import",
      uploadFormats: ["csv"],
      uploadExampleResource: "periodic/benchmark-histories/example",
      initialSorters: [{ field: "date", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "benchmark" },
        { key: "value", label: "Value", type: "currency" },
        { key: "date" },
      ],
      formColumns: [
        {
          key: "benchmarkId",
          type: "select",
          optionsMeta: { resourceName: "static/benchmarks" },
          requiredOnCreate: true,
        },
        { key: "value", type: "number", requiredOnCreate: true },
        {
          key: "date",
          type: "date",
          requiredOnCreate: true,
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "periodic/navs",
    identifier: "periodic-data/navs",
    meta: {
      label: "NAV",
      parent: "periodic-data",
      canList: true,
      canShow: true,
      statusResource: "periodic/navs/approval",
      statusForeignKey: "navId",
      canCreate: true,
      // canEdit: true,
      canApprove: true,
      canBulkApprove: true,
      uploadResource: "periodic/navs/bulk-import",
      uploadFormats: ["csv"],
      uploadExampleResource: "periodic/navs/example",
      // canDelete: true,
      initialSorters: [{ field: "date", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "date",
          placeholder: ["Date (Start)", "Date (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "product", span: 1, hideOnRecursor: true },
        { key: "productCode", span: 1 },
        { key: "nav", label: "NAV/Unit", type: "currency", span: 1 },
        { key: "aum", label: "AUM", type: "currency", span: 1 },
        { key: "date" },
        { key: "approvalStatus" },
        { key: "approvedBy", span: 1 },
        { key: "approvedAt", span: 1 },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
        {
          key: "date",
          type: "date",
          requiredOnCreate: true,
          maxDate: dayjs().subtract(1, "day").endOf("day"),
        },
        {
          key: "nav",
          label: "NAV",
          type: "number",
          requiredOnCreate: true,
        },
        {
          key: "aum",
          label: "AUM",
          type: "number",
          requiredOnCreate: true,
        },
      ],
    },
  },
];
