import React, { useState, useEffect } from "react";
import {
  IResourceComponentsProps,
  useResource,
  useInvalidate,
  useTranslate,
  useList,
} from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { DynamicSelect } from "components/atoms";
import { Form, Col, Divider, Transfer } from "antd";
import { DefaultList } from "../default";
import { mapTransfer } from "utils";
import { IResourceTransfer } from "interfaces";

export const AgentMovement: React.FC<IResourceComponentsProps> = () => {
  const { resource } = useResource();
  const { resource: customerResource } = useResource(
    "customers/retail/agent-movement"
  );

  const [sourceParentId, setSourceParentId] = useState<number | undefined>();
  const [targetParentId, setTargetParentId] = useState<number | undefined>();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [transferData, setTransferData] = useState<IResourceTransfer[]>([]);
  const invalidate = useInvalidate();
  const translate = useTranslate();

  const resetStates = () => {
    setSourceParentId(undefined);
    setTargetParentId(undefined);
    setTargetKeys([]);
    setTransferData([]);
    invalidate({
      resource: resource?.identifier || resource?.name,
      invalidates: ["all"],
    });
    invalidate({ resource: customerResource?.name, invalidates: ["all"] });
    formProps?.form?.resetFields();
  };

  // hooks for submitting the form
  const { formProps, saveButtonProps } = useForm({
    resource: `${resource?.name}/${sourceParentId}`,
    onMutationSuccess: resetStates,
  });

  // get the list of customers for the selected agents
  let sourceCustomerData = useList({
    resource: customerResource?.name || "",
    pagination: { mode: "server", pageSize: 1000 },
    filters: [
      { field: "agentId", operator: "eq", value: sourceParentId },
      { field: "isAvailable", operator: "eq", value: true }
    ],
    queryOptions: { enabled: sourceParentId !== undefined },
  });

  let targetCustomerData = useList({
    resource: customerResource?.name || "",
    pagination: { mode: "server", pageSize: 1000 },
    filters: [{ field: "agentId", operator: "eq", value: targetParentId }],
    queryOptions: { enabled: targetParentId !== undefined },
  });

  // map the data to be used in the transfer component
  useEffect(() => {
    formProps?.form?.setFieldsValue({ customerDetailId: [] });
    const mappedData: IResourceTransfer[] = [];

    // if the source agent is selected, map the source data
    if (sourceParentId) {
      const mappedSourceData = mapTransfer({
        data: sourceCustomerData?.data?.data,
        disabled: false,
      });
      mappedData.push(...mappedSourceData);
    }

    // if the target agent is selected, map the target data
    if (targetParentId) {
      const mappedTargetData = mapTransfer({
        data: targetCustomerData?.data?.data,
        disabled: true,
      });
      mappedData.push(...mappedTargetData);
      setTargetKeys(mappedTargetData.map((data) => data?.key || ""));
    }

    setTransferData(mappedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sourceParentId,
    targetParentId,
    sourceCustomerData?.data?.data,
    targetCustomerData?.data?.data,
  ]);

  return (
    <Col>
      <Create
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        headerProps={{ breadcrumb: undefined }}
        saveButtonProps={{
          ...saveButtonProps,
          disabled:
            saveButtonProps?.disabled ||
            !sourceParentId ||
            !targetParentId ||
            targetKeys?.length === targetCustomerData?.data?.data?.length,
        }}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            label={translate("page.agent.sourceAgent", "Source Agent")}
            rules={[{ required: true }]}
          >
            <DynamicSelect
              style={{ width: "100%" }}
              formColumn={{
                optionsMeta: {
                  resourceName: "agents",
                  initialFilters: [
                    { field: "approvalStatusId", operator: "eq", value: 3 },
                    { field: "isActive", operator: "eq", value: true },
                  ],
                },
              }}
              value={sourceParentId}
              onChange={setSourceParentId}
            />
          </Form.Item>
          <Form.Item
            name="newAgentId"
            label={translate("page.agent.targetAgent", "Target Agent")}
            rules={[{ required: true }]}
          >
            <DynamicSelect
              style={{ width: "100%" }}
              formColumn={{
                optionsMeta: {
                  resourceName: "agents",
                  initialFilters: [
                    { field: "approvalStatusId", operator: "eq", value: 3 },
                    { field: "isActive", operator: "eq", value: true },
                  ],
                },
              }}
              value={targetParentId}
              onChange={setTargetParentId}
            />
          </Form.Item>
          <Form.Item
            name="customerDetailId"
            getValueFromEvent={(value) => {
              // only return the source customer ids
              return value?.filter((id: string) =>
                sourceCustomerData?.data?.data
                  ?.map((data) => data?.id?.toString())
                  ?.includes(id)
              );
            }}
          >
            <Transfer
              dataSource={transferData}
              titles={["Source", "Target"]}
              targetKeys={targetKeys}
              onChange={setTargetKeys}
              render={(item) => item.title || ""}
              oneWay
              style={{ marginTop: 16 }}
              listStyle={{ width: "100%", minHeight: "300px" }}
              showSearch
            />
          </Form.Item>
        </Form>
      </Create>
      <Divider />

      <DefaultList
        title={translate("page.agent.movementApproval", "Movement Approval")}
      />
    </Col>
  );
};
