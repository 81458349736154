import { CustomResourceProps } from "interfaces";
import { FieldTimeOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { RollingBalanceCreate, RollingBalanceList } from "components";

export const rollingBalanceResources: CustomResourceProps[] = [
  {
    name: "rolling-balance-data",
    meta: {
      icon: <FieldTimeOutlined />,
      label: "Rolling Balance",
    },
  },
  {
    name: "rolling-balance/start",
    identifier: "rolling-balance/run",
    meta: {
      label: "Run",
      parent: "rolling-balance-data",
      canList: true,
      listComponent: <RollingBalanceCreate />,
      formColumns: [
        {
          key: "date",
          type: "daterange",
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["dateFrom", "dateTo"],
          maxDate: dayjs().endOf("day"),
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
            disableGetOne: false,
          },
        },
      ],
    },
  },
  {
    name: "rolling-balance/histories",
    meta: {
      label: "History",
      parent: "rolling-balance-data",
      canList: true,
      listComponent: <RollingBalanceList />,
      initialSorters: [{ field: "date", order: "desc" }],
      pagination: { pageSize: 500 },
      initialFilters: [
        { field: "date", operator: "gte", value: dayjs().startOf("month") },
        { field: "date", operator: "lte", value: dayjs().endOf("day") },
        { field: "unitHolderId", operator: "eq", value: "0" },
        { field: "productId", operator: "eq", value: 0 },
      ],
      filters: [
        {
          key: "date",
          placeholder: ["Date (Start)", "Date (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
          required: true,
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
          },
          required: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
          required: true,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID", isSortable: false },
        { key: "date", label: "Date" },
        { key: "unitHolderId", isSortable: false },
        { key: "name", label: "Product", isSortable: false },
        { key: "nav", label: "NAV", isSortable: false },
        { key: "beginningUnit", isSortable: false, type: "unit" },
        { key: "subscriptionUnit", isSortable: false, type: "unit" },
        { key: "redeemUnit", isSortable: false, type: "unit" },
        { key: "endingUnit", isSortable: false, type: "unit" },
      ],
    },
  },
];
