import React, { lazy, useState } from "react";
import { useTitle } from "@refinedev/core";
import { Title as DefaultTitle } from "@refinedev/antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Layout, Button, theme } from "antd";
import type { RefineLayoutSiderProps } from "@refinedev/antd";

const NavbarMenu = lazy(() =>
  import("components/molecules").then((module) => ({
    default: module.NavbarMenu,
  }))
);

export const VerticalNavbar: React.FC<RefineLayoutSiderProps> = ({
  Title: TitleFromProps,
  render,
  meta,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const TitleFromContext = useTitle();
  const { token } = theme.useToken();
  const RenderTitle = TitleFromProps ?? TitleFromContext ?? DefaultTitle;

  return (
    <Layout.Sider
      style={{ backgroundColor: token.colorBgContainer }}
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      width={250}
      breakpoint="lg"
      trigger={
        <Button
          type="text"
          style={{
            borderRadius: 0,
            height: "100%",
            width: "100%",
            backgroundColor: token.colorBgElevated,
          }}
        >
          {collapsed ? <RightOutlined /> : <LeftOutlined />}
        </Button>
      }
    >
      <RenderTitle collapsed={collapsed} />
      <NavbarMenu mode="inline" meta={meta} />
    </Layout.Sider>
  );
};
