import { Text } from "components/atoms";
import { ReconTableCellValue } from "../models/table";
import { formatThousands } from "utils/number";

export const ReconTableValueCell = ({
  value,
  isDifferent,
}: ReconTableCellValue) => {
  return (
    <Text size="sm" type={isDifferent ? "danger" : undefined}>
      {formatThousands(value)}
    </Text>
  );
};
