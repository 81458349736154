import React from "react";
import {
  IResourceComponentsProps,
  useInvalidate,
  useResource,
} from "@refinedev/core";
import { Create, UseFormProps, useForm } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import { FormCard } from "components/organisms";
import { ButtonProps } from "antd";

interface DefaultCreateProps extends IResourceComponentsProps {
  saveButtonProps?: ButtonProps;
}

export const DefaultCreate: React.FC<DefaultCreateProps> = ({
  saveButtonProps,
}) => {
  const { resource } = useResource();
  const invalidate = useInvalidate();
  var useFormProps: UseFormProps | undefined = {
    resource: resource?.meta?.createResource,
  };

  if (resource?.meta?.defaultAction === "create") {
    useFormProps = {
      ...useFormProps,
      onMutationSuccess: () => {
        formProps?.form?.resetFields();
        invalidate({
          resource: resource?.identifier || resource?.name || "",
          invalidates: ["all"],
        });
      },
    };
  }

  const {
    formProps,
    saveButtonProps: defaultSaveButtonProps,
    formLoading,
  } = useForm(useFormProps);

  return (
    <Create
      key={resource?.identifier || resource?.name}
      title={`${pluralize.singular(
        resource?.meta?.label ||
          startCase(resource?.identifier || resource?.name || "")
      )}`}
      headerProps={{ breadcrumb: undefined }}
      saveButtonProps={{
        ...defaultSaveButtonProps,
        loading: formLoading,
        ...saveButtonProps,
      }}
    >
      <FormCard
        key={resource?.identifier || resource?.name}
        formProps={formProps}
        resource={resource}
      />
    </Create>
  );
};
