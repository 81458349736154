import React from "react";
import { useResource } from "@refinedev/core";
import { Col, Form, FormProps, Row } from "antd";
import { CustomResourceProps, IResourceFormColumn } from "interfaces";

import {
  isObject,
  getKeysByColumnType,
  imagesToBase64,
  jsonToFormData,
} from "utils";
import { FormImageItem } from "components/atoms";
import { FormDynamicItem } from "components/molecules";

export interface FormCardProps {
  formProps: FormProps;
  resource?: CustomResourceProps;
}

export const FormCard: React.FC<FormCardProps> = ({ formProps, resource }) => {
  const { resource: defaultResource, action } = useResource();

  const filteredFormColumns: IResourceFormColumn[] = (
    resource || defaultResource
  )?.meta?.formColumns?.filter((formColumn: IResourceFormColumn) => {
    if (action === "create" && formColumn?.hideOnCreate) return false;
    if (action === "edit" && formColumn?.hideOnEdit) return false;
    return formColumn?.key;
  });

  const formColumns = filteredFormColumns?.map(
    (formColumn: IResourceFormColumn) => {
      const isDisabledOnEdit = action === "edit" && formColumn?.disabledOnEdit;
      return {
        ...formColumn,
        isDisabled: isDisabledOnEdit || formColumn?.isDisabled,
      };
    }
  );

  const newInitialValueList = Object.entries(
    formProps?.initialValues || {}
  ).map(([key, value]) => {
    if (isObject(value)) return { [`${key}Id`]: value?.id };
    else return { [key]: value };
  });

  const initialValues = Object.assign({}, ...newInitialValueList);

  const reformatAndSubmit = async (values: any) => {
    if (formProps?.onFinish) {
      resource?.meta?.submitCallback?.(values);

      // find all formColumns with property "ignoreOnSubmit" set to true
      const ignoreOnSubmitKeys =
        formColumns
          ?.filter(
            (formColumn) =>
              formColumn?.ignoreOnSubmit ||
              (formColumn?.ignoreOnEdit && action === "edit")
          )
          ?.map((formColumn) => formColumn?.key) || [];
      // remove the values of the formColumns with property "ignoreOnSubmit" set to true
      ignoreOnSubmitKeys?.forEach((key) => {
        delete values[key || ""];
      });

      // find the value which formColumn's type is "image"
      const imageKeys = getKeysByColumnType(values, formColumns, "image");
      // if the value is string, set the initial values of image fields to undefined
      imageKeys?.forEach((key) => {
        if (typeof values?.[key] === "string") {
          values[key] = undefined;
        }
      });

      // find the value which formColumn's type is "file"
      const fileKeys = getKeysByColumnType(values, formColumns, "file");
      // if the value is string, set the initial values of image fields to undefined
      fileKeys?.forEach((key) => {
        if (typeof values?.[key] === "string") {
          values[key] = undefined;
        }
      });

      // Change daterange format to date
      const dateRangeKeys = getKeysByColumnType(
        values,
        formColumns,
        "daterange"
      );
      dateRangeKeys?.forEach((key) => {
        const formColumn = formColumns?.find(
          (formColumn) => formColumn?.key === key
        );

        values[key]?.forEach((date: any, index: number) => {
          const defaultKey = index === 0 ? "dateFrom" : "dateTo";

          Object.assign(values, {
            [formColumn?.rangeKeys?.[index] || defaultKey]:
              date?.format("YYYY-MM-DD"),
          });
          delete values[key];
        });
      });

      // find the values inside values that are null or undefined, and remove them
      Object?.entries(values)?.forEach(([key, value]) => {
        if (value === null || value === undefined || value === "")
          delete values[key];
      });

      if ((resource || defaultResource)?.meta?.isFormData) {
        formProps?.onFinish(jsonToFormData(values));
      } else {
        const newValuesPromise = await imagesToBase64(values);
        const newValues = Object.fromEntries(newValuesPromise);
        formProps?.onFinish(newValues);
      }
    }
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={initialValues || formProps?.initialValues}
      onFinish={reformatAndSubmit}
    >
      <Row gutter={32}>
        <Col xs={24} xl={6}>
          <Row justify="center" style={{ position: "sticky", top: "100px" }}>
            {formColumns
              ?.filter((formColumn) => formColumn?.type === "image")
              ?.map((formColumn) => (
                <Col xs={24} key={formColumn?.key}>
                  <FormImageItem
                    formColumn={formColumn}
                    required={
                      action === "edit"
                        ? formColumn?.requiredOnEdit
                        : formColumn?.requiredOnCreate
                    }
                    formProps={formProps}
                  />
                </Col>
              ))}
          </Row>
        </Col>

        <Col
          xs={24}
          xl={
            formColumns?.find((formColumn) => formColumn?.type === "image")
              ? 18
              : 24
          }
        >
          <Row gutter={32}>
            {formColumns
              ?.filter((formColumn) => !(formColumn?.type === "image"))
              ?.map((formColumn) => (
                <FormDynamicItem
                  key={`${formColumn?.key} ${
                    resource?.identifier || resource?.name || ""
                  }`}
                  formColumn={formColumn}
                  formProps={formProps}
                  required={
                    action === "edit"
                      ? formColumn?.requiredOnEdit
                      : formColumn?.requiredOnCreate
                  }
                />
              ))}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};
