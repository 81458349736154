import { Layout, theme, Row, Col } from "antd";
import { lazy } from "react";
import { EnvBadge, Title } from "components/atoms";

const HeaderToolbar = lazy(() =>
  import("components/molecules").then((module) => ({
    default: module.HeaderToolbar,
  }))
);
const NavbarMenu = lazy(() =>
  import("components/molecules").then((module) => ({
    default: module.NavbarMenu,
  }))
);

interface HeaderProps {
  showNavbar?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ showNavbar = false }) => {
  const { token } = theme.useToken();

  if (!showNavbar) {
    return (
      <Layout.Header
        style={{
          alignItems: "center",
          padding: "0px 24px",
          height: "64px",
          backgroundColor: token.colorBgContainer,
          position: "sticky",
          top: 0,
          zIndex: 3,
        }}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            gap: "100px",
            maxWidth: "1300px",
            marginInline: "auto",
          }}
        >
          <HeaderToolbar
            style={{ width: "100%", justifyContent: "flex-end" }}
          />
        </div>

        <EnvBadge />
      </Layout.Header>
    );
  }

  return (
    <Layout.Header
      style={{
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: token.colorBgContainer,
        position: "sticky",
        top: 0,
        zIndex: 3,
      }}
    >
      <Row
        wrap={false}
        style={{
          width: "100%",
          justifyContent: "space-between",
          gap: "100px",
          maxWidth: "1300px",
          marginInline: "auto",
        }}
      >
        <Col flex="none">
          <Title collapsed={false} />
        </Col>

        <Col flex="auto" style={{ width: "100%" }}>
          <Layout.Header
            style={{
              backgroundColor: token.colorBgContainer,
              margin: 0,
              padding: 0,
            }}
          >
            <NavbarMenu mode="horizontal" />
          </Layout.Header>
        </Col>

        <Col flex="none">
          <HeaderToolbar />
        </Col>
      </Row>

      <EnvBadge />
    </Layout.Header>
  );
};
