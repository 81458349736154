import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAccessControlRule } from "interfaces";

export interface ITempState {
  accessToken?: string;
  accessControlRules?: IAccessControlRule[];
  [key: string]: any;
}

const initialState: ITempState = {
  accessToken: "",
  accessControlRules: [],
};

export const tempSlice = createSlice({
  name: "temp",
  initialState,
  reducers: {
    setTemp: (state, action: PayloadAction<ITempState>) => {
      Object.entries(action?.payload)?.forEach(([key, value]) => {
        if (value !== undefined) state[key as keyof ITempState] = value;
      });
    },
    resetTemp: (state) => Object.assign(state, initialState),
  },
});

// Action creators are generated for each case reducer function
export const { setTemp, resetTemp } = tempSlice.actions;

export default tempSlice.reducer;
