import React, { useState, useEffect } from "react";

import {
  BaseRecord,
  useNotification,
  useApiUrl,
  useTranslate,
} from "@refinedev/core";

import { RiseOutlined, FallOutlined } from "@ant-design/icons";
import { Card, Empty, Table, Tooltip } from "antd";
import { axiosInstance } from "utils";
import { Text } from "../../../atoms/text";
import type { ColumnsType } from "antd/es/table";

interface TopGainerLoserProps {
  isTop?: boolean;
}

const columns: ColumnsType<BaseRecord> = [
  {
    title: "Name",
    dataIndex: "productName",
    key: "productName",
    render(value, record, index) {
      return (
        <Text strong size="md">
          {value}
        </Text>
      );
    },
  },
  {
    title: "Number",
    dataIndex: "oneDay",
    key: "oneDay",
    align: "right",
    fixed: "right",
    render(value, record, index) {
      if (value > 0)
        return (
          <Tooltip title={`${value}%`}>
            <Text strong size="md" style={{ color: "#119761" }}>
              +{value.toFixed(2)}%
            </Text>
          </Tooltip>
        );
      else if (value < 0) {
        return (
          <Tooltip title={`${value}%`}>
            <Text strong size="md" style={{ color: "#D80027" }}>
              {value.toFixed(2)}%
            </Text>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={`${value}%`}>
            <Text strong size="md">
              {value.toFixed(2)}%
            </Text>
          </Tooltip>
        );
      }
    },
  },
];

export const TopGainerLoser: React.FC<TopGainerLoserProps> = ({ isTop }) => {
  const translate = useTranslate();
  const { open } = useNotification();

  const [tableData, setTableData] = useState<BaseRecord[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const apiUrl = useApiUrl();
  const url = `${apiUrl}/dashboard/top-gainer-loser-product`;

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(url)
      .then((res) => {
        const data = res?.data?.body;
        if (isTop) {
          setTableData(data?.topGainer);
        } else {
          setTableData(data?.topLoser);
        }
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      })
      .finally(() => setIsLoading(false));
  }, [isTop, open, translate, url]);

  return (
    <Card
      headStyle={{
        padding: "8px 16px",
        backgroundColor: isTop ? "#119761" : "#D80027",
      }}
      bodyStyle={{ padding: "0 1rem", height: "100%", minHeight: "200px" }}
      style={{ height: "100%", minHeight: "200px" }}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "white",
          }}
        >
          {isTop ? <RiseOutlined /> : <FallOutlined />}
          <Text
            size="sm"
            strong
            style={{ marginLeft: ".7rem", color: "white" }}
          >
            {isTop
              ? translate("page.dashboard.topGainer", `Top Gainer`)
              : translate("page.dashboard.topLoser", `Top Loser`)}
          </Text>
        </div>
      }
    >
      {!isLoading && !tableData?.length ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{
            margin: 0,
            padding: 0,
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        />
      ) : (
        <Table
          rowKey="id"
          style={{ margin: "10px 0" }}
          columns={columns}
          dataSource={tableData}
          showHeader={false}
          scroll={{ x: "max-content" }}
          loading={isLoading}
          pagination={{ position: ["none"] }}
        />
      )}
    </Card>
  );
};
