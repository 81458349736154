import { Button, ButtonProps, Popconfirm, Typography, Input } from "antd";
import { useTranslate } from "@refinedev/core";
import { DynamicSelect } from "components/atoms";
import { IResourceFormColumn } from "interfaces";
import React, { useState } from "react";

interface ButtonWithConfirmationProps extends ButtonProps {
  title?: string;
  remark?: string;
  remarkResource?: IResourceFormColumn;
  onChangeRemark?: (remark: string) => void;
}

export const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
  title = "Are you sure?",
  remark,
  remarkResource,
  onClick = () => {},
  onChangeRemark,
  ...props
}) => {
  const translate = useTranslate();
  const [initialRemarks, setInitialRemarks] = useState<string>();

  return (
    <Popconfirm
      placement="topLeft"
      title={translate("buttonConfirmation.title", title)}
      description={
        !!onChangeRemark && (
          <div>
            <Typography.Text>
              {translate("buttonConfirmation.remark", "Remarks / Reason") + ":"}
            </Typography.Text>

            {remarkResource && (
              <DynamicSelect
                style={{ width: "100%", position: "relative" }}
                formColumn={remarkResource}
                onChange={(value, option, record) => {
                  setInitialRemarks(record?.remark);
                  onChangeRemark?.(record?.remark);
                }}
              />
            )}

            <Input.TextArea
              style={{ marginTop: 8 }}
              onChange={(e) => {
                onChangeRemark?.(e.target.value);
                setInitialRemarks(e.target.value);
              }}
              autoSize={{ minRows: 3, maxRows: 6 }}
              value={initialRemarks}
            />
          </div>
        )
      }
      okText={translate("buttons.yes")}
      cancelText={translate("buttons.no")}
      onConfirm={
        onClick as (
          e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
        ) => void
      }
      okButtonProps={{ danger: props.danger }}
      disabled={props?.disabled}
    >
      <Button {...props} style={{ ...props?.style }} />
    </Popconfirm>
  );
};
