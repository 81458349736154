import {
  useList,
  BaseRecord,
  CrudFilters,
  HttpError,
  BaseKey,
} from "@refinedev/core";
import {
  IFilterVariables,
  IResourceDisplayColumn,
  CustomResourceProps,
} from "interfaces";
import { useEffect, useState } from "react";
import { useTable } from "@refinedev/antd";
import { isObject, startCase } from "lodash";

const useChildRecords = ({
  resourceName,
  parentId,
  foreignKey,
  approvalStatus,
}: {
  resourceName?: string;
  parentId?: BaseKey;
  foreignKey?: string;
  approvalStatus?: BaseRecord;
}) => {
  const listFilters: CrudFilters = [
    { field: foreignKey || "", operator: "eq", value: parentId },
  ];

  if (approvalStatus?.id) {
    listFilters.push({
      field: "approvalStatusId",
      operator: "eq",
      value: approvalStatus?.id,
    });
  }

  const getChildRecords = useList({
    resource: resourceName,
    queryOptions: { enabled: !!parentId && !!resourceName && !!foreignKey },
    filters: listFilters,
    pagination: { pageSize: 1000 },
  });

  if (!!parentId && !!resourceName && !!foreignKey) {
    return getChildRecords?.data?.data;
  }
};

const useChildTable = ({
  parentIdKey,
  parentIdValue,
  childResource,
  approvalStatus,
}: {
  parentIdKey?: string;
  parentIdValue?: string;
  childResource?: CustomResourceProps;
  approvalStatus?: BaseRecord;
}) => {
  const { tableProps, sorters } = useTable<
    BaseRecord,
    HttpError,
    IFilterVariables
  >({
    resource: childResource?.name,
    initialFilter: [
      ...(childResource?.meta?.initialFilters || []),
      {
        field: parentIdKey,
        operator: "eq",
        value: parentIdValue,
      },
      {
        field: "approvalStatusId",
        operator: "eq",
        value: approvalStatus?.id,
      },
    ],
    pagination: childResource?.meta?.pagination,
    sorters: { initial: childResource?.meta?.initialSorters },
  });

  const [displayColumns, setDisplayColumns] =
    useState<IResourceDisplayColumn[]>();
  useEffect(() => {
    if (tableProps?.dataSource && tableProps?.dataSource?.length > 0) {
      const blacklist = [
        "Password",
        "Created At",
        "Updated At",
        "Deleted At",
        "Created By",
        "Updated By",
        "Deleted By",
        "Avatar",
        "Image",
        "Img",
        "Photo",
        "Logo",
      ];

      const keys = Object.entries(tableProps?.dataSource[0])
        .filter(([key, value]) => !blacklist.includes(startCase(key)))
        ?.map(([key, value]) => {
          if (isObject(value)) return { key, type: "select" }; // displayColumn with type "select" means the data is an object
          return { key };
        });

      setDisplayColumns(keys);
    }
  }, [tableProps?.dataSource]);

  return { tableProps, sorters, displayColumns };
};

export { useChildRecords, useChildTable };
