import {
  ForwardOutlined,
  PercentageOutlined,
  SettingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { CustomResourceProps } from "interfaces";
const promotionResources: CustomResourceProps[] = [
  {
    name: "promotions/promotion-details",
    identifier: "myhero/promotions/promotion-details",
    meta: {
      label: "Details",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "promoCode", type: "text", requiredOnCreate: true },
        { key: "promoName", type: "text", requiredOnCreate: true },
        { key: "promoItem", type: "text", requiredOnCreate: true },
        { key: "promoValue", type: "number", requiredOnCreate: true },
        { key: "minimumSub", type: "number", requiredOnCreate: true },
        { key: "deliveryPeriod", type: "number", requiredOnCreate: true },
        {
          key: "description",
          type: "textarea",
          requiredOnCreate: true,
          span: 2,
        },
      ],
    },
  },

  {
    name: "promotions/promo-recipient-groups",
    identifier: "myhero/promotions/promo-recipient-groups",
    meta: {
      label: "Recipient Groups",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      displayColumns: [
        { key: "id", label: "ID" },
        { key: "recipientGroupCode" },
        { key: "recipientGroupName" },
        {
          key: "customerDetail",
          childKey: "firstName",
          label: "First Name",
          hideOnRecursor: true,
        },
        {
          key: "customerDetail",
          childKey: "lastName",
          label: "Last Name",
          hideOnRecursor: true,
        },
      ],
      formColumns: [
        { key: "recipientGroupCode", type: "number", requiredOnCreate: true },
        { key: "recipientGroupName", type: "text", requiredOnCreate: true },
        {
          key: "customerDetailId",
          label: "Customer",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "customers/retail/verified",
            disableGetOne: true,
            labelKey: ["firstName", "lastName"],
          },
        },
      ],
    },
  },

  {
    name: "promotions/promotion-availabilities",
    identifier: "myhero/promotions/promotion-availabilities",
    meta: {
      label: "Availabilities",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "isActive", type: "boolean", requiredOnCreate: true },
        {
          key: "date",
          type: "daterange",
          placeholder: ["Date (Start)", "Date (End)"],
          rangeKeys: ["startDate", "endDate"],
          maxDate: dayjs().endOf("day"),
          requiredOnCreate: true,
        },
        { key: "totalStock", type: "number", requiredOnCreate: true },
        { key: "remainingStock", type: "number", requiredOnCreate: true },
        {
          key: "promotionDetailId",
          type: "select",
          optionsMeta: { resourceName: "promotions/promotion-details" },
          requiredOnCreate: true,
        },
        {
          key: "promoRecipientGroupId",
          type: "select",
          optionsMeta: { resourceName: "promotions/promo-recipient-groups" },
          requiredOnCreate: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: { resourceName: "products/data" },
          requiredOnCreate: true,
        },
      ],
    },
  },
];

const settingsResources: CustomResourceProps[] = [
  {
    name: "settings/apk-version",
    identifier: "myhero/settings/apk-version",
    meta: {
      label: "APK Version",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "version", type: "text", requiredOnCreate: true },
        { key: "buildNo", type: "number", requiredOnCreate: true },
        {
          key: "type",
          type: "select",
          requiredOnCreate: true,
          options: [
            { label: "ANDROID", value: "ANDROID" },
            { label: "IOS", value: "IOS" },
          ],
        },
        { key: "releaseDate", type: "date", requiredOnCreate: true },
        {
          key: "isUpdateRequired",
          label: "Update Required",
          type: "boolean",
          requiredOnCreate: true,
        },
        { key: "url", type: "text", requiredOnCreate: true },
        {
          key: "isMaintenance",
          label: "Maintenance",
          type: "boolean",
          requiredOnCreate: true,
        },
        { key: "flavor", type: "text" },
      ],
    },
  },
  {
    name: "settings/faq",
    identifier: "myhero/settings/faq",
    meta: {
      label: "FAQ",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "title", type: "text", requiredOnCreate: true },
        { key: "content", type: "text", requiredOnCreate: true },
        {
          key: "type",
          type: "select",
          requiredOnCreate: true,
          options: [
            { label: "faq", value: "faq" },
            { label: "ensiklovestasi", value: "ensiklovestasi" },
          ],
        },
        { key: "subType", type: "text", requiredOnCreate: true },
        { key: "order", type: "number", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "settings/pay-guide",
    identifier: "myhero/settings/pay-guide",
    meta: {
      label: "Pay Guide",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "title", type: "text", requiredOnCreate: true },
        { key: "content", type: "text", requiredOnCreate: true },
        {
          key: "methodCode",
          type: "text",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "settings/slide",
    identifier: "myhero/settings/slide",
    meta: {
      label: "Slide",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      editMethod: "patch",
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "title", type: "text", requiredOnCreate: true },
        { key: "link", type: "text", requiredOnCreate: true },
        {
          key: "type",
          type: "text",
          requiredOnCreate: true,
        },
        {
          key: "isActive",
          type: "boolean",
          requiredOnCreate: true,
        },
        {
          key: "file",
          type: "image",
          requiredOnCreate: true,
          span: 2,
        },
      ],
    },
  },
  {
    name: "settings/banners",
    identifier: "myhero/settings/banners",
    meta: {
      label: "Banners",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      editMethod: "patch",
      canDelete: true,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "title", type: "text", requiredOnCreate: true },
        {
          key: "type",
          type: "text",
          requiredOnCreate: true,
        },
        { key: "link", type: "text", requiredOnCreate: true },
        {
          key: "isActive",
          type: "boolean",
          requiredOnCreate: true,
        },
        { key: "description", type: "text", requiredOnCreate: true },
        {
          key: "file",
          type: "file",
          requiredOnCreate: true,
          span: 2,
        },
      ],
    },
  },
];

export const myheroResources: CustomResourceProps[] = [
  {
    name: "myhero",
    meta: {
      label: "MyHero",
      icon: <ForwardOutlined />,
    },
  },

  {
    name: "promotions",
    meta: {
      icon: <PercentageOutlined />,
      parent: "myhero",
    },
  },

  ...promotionResources.map((resource) => ({
    ...resource,
    meta: { ...resource.meta, parent: resource?.meta?.parent || "promotions" },
  })),

  {
    name: "settings",
    meta: {
      icon: <SettingOutlined />,
      parent: "myhero",
    },
  },
  ...settingsResources.map((resource) => ({
    ...resource,
    meta: { ...resource.meta, parent: resource?.meta?.parent || "settings" },
  })),
];
