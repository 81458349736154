import { useNotification, useTranslate } from "@refinedev/core";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib";
import mime from "mime";
import { useState } from "react";
import { axiosInstance } from "utils";
import { AxiosResponse } from "axios";

type UseUploaderProps<T> = {
  accept: string[];
  url: string;
  onSuccess?: (res?: AxiosResponse<T, any>) => void;
};
export const useUploader = <T>({
  accept,
  onSuccess,
  url,
}: UseUploaderProps<T>) => {
  const [file, setFile] = useState<UploadFile>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any | null>(null);

  const { open } = useNotification();

  const translate = useTranslate();

  const handleUpload = () => {
    if (!file) {
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("file", file.originFileObj || (file as unknown as Blob));

    axiosInstance
      .post<T>(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
        onSuccess?.();
        open?.({
          type: "success",
          message: "Import successful",
        });
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      });
  };

  const handleBeforeUpload = (file: RcFile) => {
    const mimeTypes = accept?.map((format) => mime.getType(format));
    const isFormatAccepted = mimeTypes?.includes(file?.type);

    if (!isFormatAccepted) {
      open?.({
        type: "error",
        message: translate("notifications.err"),
        description: translate("notifications.uploadFormatError", {
          format: accept?.join(", "),
        }),
      });

      return false;
    } else {
      setFile(file);
    }
  };

  const handleRemove = () => {
    setFile(undefined);
  };

  return {
    handleUpload,
    handleRemove,
    handleBeforeUpload,
    file,
    data,
    error,
    loading,
  };
};
