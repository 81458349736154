import {
  AccountBookOutlined,
  DollarOutlined,
  EditOutlined,
  HourglassOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  DatabaseOutlined,
  FileSearchOutlined,
  SyncOutlined,
  CheckOutlined,
  LikeOutlined,
  DislikeOutlined,
  ClockCircleOutlined,
  PlusOutlined,
  UserOutlined,
  BankOutlined,
} from "@ant-design/icons";
import {
  TransactionApprove,
  TransactionCreate,
  FeeCalculatorCreate,
  TransactionEdit,
  TransactionDraftApprove,
  DefaultCreate,
} from "components";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import { IResourceDisplayColumn, CustomResourceProps } from "interfaces";
import { DividendDistributionEdit } from "components/pages/transaction/editDividendDistribution";
import { unitAmountToggleHandler } from "./helpers/transaction";
import { TRANSACTION_TYPE, TRANSFER_TYPE_NAME } from "constants/transaction";
import { TransactionCreateBackdate } from "components/pages/transaction/createBackdate";
import { DisplayRemainingPortofolio } from "components/pages/transaction/molecules/displayRemainingPortofolio";

// #region TRX LIST COLUMNS
const transactionListDisplayColumns: IResourceDisplayColumn[] = [
  { key: "transactionCode", span: 1 },
  { key: "switchingCode", span: 1 },
  { key: "transactionDate", span: 1 },
  { key: "transactionStatus", label: "Status", span: 1 },
  {
    key: "pairTransaction",
    resourceName: "transactions",
    hideOnParent: true,
  },
  {},
  { key: "fullName", span: 1 },
  { key: "unitHolderId", label: "Unit Holder ID", span: 1 },
  { key: "transactionType", label: "Type" },
  { key: "product", hideOnRecursor: true },
  { key: "nominal", span: 1, type: "currency" },
  { key: "netAmount", span: 1, type: "currency" },
  { key: "unit", span: 1, type: "unit" },
  { key: "fee", label: "Fee (%)", span: 1 },
  { key: "feeNominal", label: "Fee (Nominal)", span: 1 },
  { key: "charges", type: "currency" },
  { key: "isAllUnits", label: "All Units", span: 1 },
  { key: "nav", label: "NAV" },
  {},
  { key: "settledAt", label: "Settle Date", span: 1 },
  { key: "transactionExpired", span: 1 },
  { key: "remark" },
  {},
  { key: "promoCode", span: 1 },
  { key: "referralCode", span: 1 },
  { key: "agent", span: 1, hideOnRecursor: true },
  { key: "sellingAgent", span: 1, hideOnRecursor: true },
  {
    key: "agent",
    label: "Agent Branch",
    childKey: "branch",
    hideOnRecursor: true,
  },
  {},
  { key: "customerBankAccountNumber", span: 1 },
  {
    key: "productBankAccount",
    label: "Product Bank Account Number",
    childKey: "bankAccountNumber",
    hideOnRecursor: true,
    span: 1,
  },
  {
    key: "customerBankAccount",
    label: "Customer Bank Account Name",
    childKey: "bankAccountName",
    hideOnRecursor: true,
    span: 1,
  },
  {
    key: "productBankAccount",
    label: "Product Bank Account Name",
    childKey: "bankAccountName",
    hideOnRecursor: true,
    span: 1,
  },
  {
    key: "customerBankAccount",
    label: "Customer's Bank",
    childKey: "bankName",
    hideOnRecursor: true,
    span: 1,
  },
  {
    key: "productBankAccount",
    label: "Product's Bank",
    childKey: "bankName",
    hideOnRecursor: true,
  },
  { key: "paymentMethod" },
  { key: "transferType" },
  { key: "paymentProofFile", hideIfEmpty: true, hideOnList: true },
  { key: "supportingDocumentFile", hideIfEmpty: true, hideOnList: true },
  {},
  { key: "vaExternalId", hideIfEmpty: true },
  { key: "vaCallbackPaymentId", hideIfEmpty: true },
  { key: "vaNumber", span: 1, hideIfEmpty: true },
  { key: "vaAmount", type: "currency", span: 1, hideIfEmpty: true },
  { key: "vaStatus", span: 1, hideIfEmpty: true },
  { key: "vaPaymentDate", span: 1, hideIfEmpty: true },
  {},
  { key: "disbursementExternalId", hideIfEmpty: true },
  { key: "disbursementAmount", type: "currency", hideIfEmpty: true },
  { key: "disbursementStatus", span: 1, hideIfEmpty: true },
  { key: "disbursementDate", span: 1, hideIfEmpty: true },
  {},
  { key: "createdAt", span: 1 },
  { key: "createdBy", span: 1 },
  { key: "goodfundAt", span: 1 },
  { key: "goodfundBy", span: 1 },
  { key: "approvedAt", span: 1 },
  { key: "approvedBy", span: 1 },
  { key: "rejectedAt", span: 1 },
  { key: "rejectedBy", span: 1 },
];
// #endregion

// #region BACKDATE COLUMNS
const backdateReasonsDisplayColumns: IResourceDisplayColumn[] = [
  { key: "id", label: "ID" },
  { key: "reason", wrap: true },
  { key: "approvalStatus" },
  { key: "transaction", label: "Transaction ID", childKey: "id" },
  {
    key: "transaction",
    label: "Transaction Code",
    childKey: "transactionCode",
  },
  {
    key: "transaction",
    label: "Transaction Date",
    childKey: "transactionDate",
  },
  { key: "transaction", label: "Customer Full Name", childKey: "fullName" },
  { key: "transaction", label: "Unit Holder ID", childKey: "unitHolderId" },
  {
    key: "transaction",
    label: "Product Name",
    childKey: "product",
  },
  {
    key: "transaction",
    label: "Transaction Type Id",
    childKey: "transactionTypeId",
  },
  { key: "transaction", label: "Nominal", childKey: "nominal" },
  { key: "transaction", label: "Unit", childKey: "unit" },
  { key: "supportingDocumentUploadedAt" },
  { key: "supportingDocumentUploadedBy" },
  { key: "createdAt", span: 1 },
  { key: "createdBy", span: 1 },
  { key: "approvedAt", span: 1 },
  { key: "approvedBy", span: 1 },
];
// #endregion

// #region AUTODEBIT COLUMNS
const autodebitsListDisplayColumns: IResourceDisplayColumn[] = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "customerDetail",
    childKey: "firstName",
    label: "First Name",
    hideOnRecursor: true,
  },
  {
    key: "customerDetail",
    childKey: "lastName",
    label: "Last Name",
    hideOnRecursor: true,
  },
  {
    key: "customerDetail",
    childKey: "isConventional",
    label: "Is Conventional",
    hideOnRecursor: true,
  },
  {
    key: "customerDetail",
    childKey: "unitHolderId",
    label: "Unit Holder ID",
    hideOnRecursor: true,
  },
  {
    key: "product",
    childKey: "name",
    label: "Product Name",
    hideOnRecursor: true,
  },
  {
    key: "customerBankAccount",
    childKey: "bankAccountName",
    label: "Customer Bank Account Name",
    hideOnRecursor: true,
  },
  {
    key: "customerBankAccount",
    childKey: "bankAccountNumber",
    label: "Customer Bank Account Number",
    hideOnRecursor: true,
  },
  {
    key: "productBankAccount",
    childKey: "bankAccountName",
    label: "Product Bank Account Name",
    hideOnRecursor: true,
  },
  {
    key: "productBankAccount",
    childKey: "bankAccountNumber",
    label: "Product Bank Account Number",
    hideOnRecursor: true,
  },
  { key: "amount", type: "currency" },
  {
    key: "productBankAccount",
    childKey: "bankAccountNumber",
    label: "Bank Account Number",
    hideOnRecursor: true,
  },
  { key: "startDate", type: "date" },
  { key: "approvalStatus" },
  { key: "isActive", label: "Active" },
  { key: "recurringDate" },
  { key: "remark", span: 4 },
];
// #endregion

// #region TRX AUTODEBIT COLUMNS
const transactionAutodebitsListDisplayColumns: IResourceDisplayColumn[] = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "customerDetail",
    childKey: "firstName",
    label: "First Name",
    hideOnRecursor: true,
  },
  {
    key: "customerDetail",
    childKey: "lastName",
    label: "Last Name",
    hideOnRecursor: true,
  },
  {
    key: "customerDetail",
    childKey: "isConventional",
    label: "Is Conventional",
    hideOnRecursor: true,
  },
  {
    key: "customerDetail",
    childKey: "unitHolderId",
    label: "Unit Holder ID",
    hideOnRecursor: true,
  },
  {
    key: "product",
    childKey: "name",
    label: "Product Name",
    hideOnRecursor: true,
  },
  {
    key: "customerBankAccount",
    childKey: "bankAccountName",
    label: "Customer Bank Account Name",
    hideOnRecursor: true,
  },
  {
    key: "customerBankAccount",
    childKey: "bankAccountNumber",
    label: "Customer Bank Account Number",
    hideOnRecursor: true,
  },
  {
    key: "productBankAccount",
    childKey: "bankAccountName",
    label: "Product Bank Account Name",
    hideOnRecursor: true,
  },
  {
    key: "productBankAccount",
    childKey: "bankAccountNumber",
    label: "Product Bank Account Number",
    hideOnRecursor: true,
  },
  { key: "amount", type: "currency" },
  { key: "date", type: "date" },
  { key: "approvalStatus" },
  { key: "remark", span: 4 },
];
// #endregion

// #region DIVIDEND COLUMNS
const dividendDistributionDisplayColumns: IResourceDisplayColumn[] = [
  {
    key: "id",
  },
  {
    key: "customerName",
    label: "Customer Name",
  },
  { key: "unitHolderId", label: "Unit Holder ID" },
  { key: "ifua", label: "IFUA" },
  { key: "product", childKey: "name", label: "Product Name" },
  { key: "date", label: "Date", type: "date" },
  { key: "amount", type: "currency" },
  { key: "unit", type: "unit" },
  { key: "bankAccountNumber" },
  { key: "bankAccountName" },
  { key: "dividendType" },
  { key: "approvalStatus" },
  { key: "approvedAt" },
  { key: "approvedBy" },
  { key: "createdAt" },
  { key: "createdBy" },
  { key: "updatedBy" },
  { key: "updatedAt" },
];
// #endregion

// #region CREATE RETAIL
const createRetailTransactionResources: CustomResourceProps[] = [
  {
    name: "transactions/retail/conventional/buy/draft",
    identifier: "transactions/create/retail/subscription",
    meta: {
      label: "Subscription",
      defaultAction: "create",
      canList: true,
      listComponent: (
        <TransactionCreate
          saveButtonProps={{ children: "Save as Draft" }}
          transactionType={1}
        />
      ),
      formColumns: [
        {
          key: "userId",
          label: "Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/retail/verified",
            initialFilters: [
              { field: "isConventional", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "productId",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            childResourceName: "products/fees",
            childForeignKey: "productId",
            childApprovalStatus: "approved",
            displayColumns: [
              {
                key: "productDetail",
                childKey: "minSub",
                label: "Min. Subscription",
                type: "currency",
              },
              {
                key: "productDetail",
                childKey: "subFee",
                label: "Fee",
                type: "percentage",
              },
            ],
          },
        },
        {
          key: "amount",
          type: "rupiah",
          requiredOnCreate: true,
          span: 2,
        },
        {
          key: "fee",
          type: "number",
          requiredOnCreate: true,
          span: 2,
          label: "Fee (%)",
        },
        {
          key: "settlementDate",
          type: "date",
          span: 2,
          requiredOnCreate: true,
          isDisabled: true,
        },
        {
          key: "customerBankAccountNumber",
          label: "Customer Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "customers/retail/bank-accounts",
            valueKey: "bankAccountNumber",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            filterFromOtherColumn: {
              formKey: "userId",
              filterKey: "customerDetailId",
              required: true,
            },
          },
        },
        {
          key: "productBankAccountId",
          label: "Product Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "transactionTypeId", operator: "eq", value: 1 },
              { field: "sellingAgentId", operator: "eq", value: 1 },
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
          },
        },
      ],
    },
  },

  {
    name: "transactions/retail/conventional/sell/draft",
    identifier: "transactions/create/retail/redemption",
    meta: {
      label: "Redemption",
      defaultAction: "create",
      canList: true,
      submitCallback: unitAmountToggleHandler("valueType"),
      listComponent: (
        <TransactionCreate
          saveButtonProps={{ children: "Save as Draft" }}
          transactionType={2}
        />
      ),
      formColumns: [
        {
          key: "userId",
          label: "Choose a Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/retail/verified",
            initialFilters: [
              { field: "isConventional", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "productId",
          label: "Choose a Product",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            disableFilter: true,
            resourceName: "customers/user-portfolios",
            valueKey: "product.id",
            filterFromRedux: {
              formKey: "userId.unitHolderId",
              filterKey: "search",
              required: true,
            },
          },
        },
        {
          key: "display-remaining",
          type: "custom",
          CustomComponent: DisplayRemainingPortofolio,
          customValueKeys: {
            valueType: "valueType",
            value: "value",
            customerKey: "userId",
          },
          optionsMeta: {
            filterFromOtherColumn: {
              formKey: "productId",
            },
          },
        },
        {
          key: "valueType",
          span: 2,
          type: "boolean",
          labelTrue: "Unit",
          labelFalse: "Amount",
          ignoreOnSubmit: true,
          requiredOnCreate: true,
          initialValue: true,
        },
        {
          key: "value",
          span: 2,
          min: 0,
          requiredOnCreate: true,
          ignoreOnSubmit: true,
          type: "formatted-number",
        },
        {
          key: "fee",
          type: "number",
          requiredOnCreate: true,
          span: 2,
          label: "Fee (%)",
        },
        {
          key: "settlementDate",
          type: "date",
          span: 2,
          requiredOnCreate: true,
        },
        {
          key: "productBankAccountId",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
            initialFilters: [
              { field: "transactionTypeId", operator: "eq", value: 2 },
              { field: "sellingAgentId", operator: "eq", value: 1 },
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isCustodianBankAccount", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "customerBankAccountNumber",
          label: "Customer Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "customers/retail/bank-accounts",
            valueKey: "bankAccountNumber",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            filterFromOtherColumn: {
              formKey: "userId",
              filterKey: "customerDetailId",
              required: true,
            },
          },
        },
      ],
    },
  },

  {
    name: "transactions/retail/conventional/switch/draft",
    identifier: "transactions/create/retail/switching",
    meta: {
      label: "Switching",
      defaultAction: "create",
      canList: true,
      submitCallback: unitAmountToggleHandler("valueType"),
      listComponent: (
        <TransactionCreate
          saveButtonProps={{ children: "Save as Draft" }}
          transactionType={3}
        />
      ),
      formColumns: [
        {
          key: "userId",
          label: "Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/retail/verified",
            initialFilters: [
              { field: "isConventional", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "productFromId",
          label: "Redemption From",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            disableFilter: true,
            resourceName: "customers/user-portfolios",
            valueKey: "product.id",
            filterFromRedux: {
              formKey: "userId.unitHolderId",
              filterKey: "search",
              required: true,
            },
          },
        },
        {
          key: "display-remaining",
          type: "custom",
          CustomComponent: DisplayRemainingPortofolio,
          customValueKeys: {
            valueType: "valueType",
            value: "value",
            customerKey: "userId",
          },
          optionsMeta: {
            filterFromOtherColumn: {
              formKey: "productFromId",
            },
          },
        },
        {
          key: "productToId",
          label: "Subscription To",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            childResourceName: "products/fees",
            childForeignKey: "productId",
            childApprovalStatus: "approved",
            displayColumns: [
              {
                key: "productDetail",
                childKey: "minSub",
                label: "Min. Subscription",
                type: "currency",
              },
              {
                key: "productDetail",
                childKey: "subFee",
                label: "Fee",
                type: "percentage",
              },
            ],
          },
        },
        {
          key: "valueType",
          span: 2,
          type: "boolean",
          labelTrue: "Unit",
          labelFalse: "Amount",
          ignoreOnSubmit: true,
          requiredOnCreate: true,
          initialValue: true,
        },
        {
          key: "value",
          span: 2,
          min: 0,
          requiredOnCreate: true,
          ignoreOnSubmit: true,
          type: "formatted-number",
        },
        {
          key: "feeType",
          span: 2,
          type: "boolean",
          labelTrue: "Switch Out",
          valueTrue: 1,
          labelFalse: "Switch In",
          valueFalse: 2,
          requiredOnCreate: true,
          initialValue: true,
        },
        {
          key: "fee",
          type: "number",
          requiredOnCreate: true,
          span: 2,
          label: "Fee (%)",
        },
        {
          key: "settlementDate",
          type: "date",
          span: 2,
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "transactions/dividend-distributions",
    identifier: "transactions/create/retail/dividend-distributions",
    meta: {
      label: "Dividend Distribution",
      defaultAction: "create",
      listComponent: <DefaultCreate saveButtonProps={{ children: "Create" }} />,
      canList: true,
      filters: [
        {
          key: "unitHolderId",
          type: "text",
          placeholder: "Search Unit Holder ID",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
          },
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      formColumns: [
        {
          key: "customerDetailId",
          label: "Choose a Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          ignoreOnSubmit: true,
          optionsMeta: {
            fillOtherColumns: {
              customerName: "customerSignup.nickname",
              ifua: "ifua",
              unitHolderId: "unitHolderId",
            },
            resourceName: "customers/retail/verified",
            labelKey: ["firstName", "lastName"],
          },
        },
        {
          key: "date",
          type: "date",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
        },
        {
          key: "productId",
          label: "Choose a Product",
          type: "select",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "dividendTypeId",
          optionsMeta: {
            resourceName: "static/dividend-types",
          },
          requiredOnCreate: true,
          type: "select",
        },
        {
          key: "amount",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          type: "rupiah",
        },
        {
          key: "unit",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          type: "number",
        },
        {
          key: "bankAccountNumber",
          label: "Customer Bank Account",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "customers/retail/bank-accounts",
            valueKey: "bankAccountNumber",
            additionalLabelKeys: ["bankAccountNumber"],
            disableGetOne: true,
            labelKey: "bank",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            fillOtherColumns: {
              bankId: "bank.id",
            },
            filterFromOtherColumn: {
              formKey: "customerDetailId",
              filterKey: "customerDetailId",
              required: true,
            },
          },
        },
        // hidden fields, set from unitHolderId select-table
        {
          key: "unitHolderId",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "customerName",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "ifua",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "bankId",
          type: "text",
          isDisabled: true,
          hidden: true,
        },
      ],
    },
  },
  {
    name: "transactions/backdate-reasons",
    identifier: "transactions/create/retail/backdate-reasons",
    meta: {
      label: "Backdate",
      defaultAction: "create",
      listComponent: (
        <TransactionCreateBackdate saveButtonProps={{ children: "Create" }} />
      ),
      canList: true,
      formColumns: [
        {
          key: "customerDetailId",
          label: "Choose a Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          ignoreOnSubmit: true,
          optionsMeta: {
            fillOtherColumns: { unitHolderId: "unitHolderId" },
            resourceName: "customers/retail/verified",
            labelKey: ["firstName", "lastName"],
          },
        },
        {
          key: "customerBankAccountNumber",
          label: "Customer Bank Account",
          type: "select-details",
          span: 2,
          requiredFromColumn: {
            value: [1, 2],
            key: "transactionTypeId",
          },
          optionsMeta: {
            resourceName: "customers/retail/bank-accounts",
            valueKey: "bankAccountNumber",
            disableGetOne: true,
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            filterFromOtherColumn: {
              formKey: "customerDetailId",
              filterKey: "customerDetailId",
              required: true,
            },
          },
        },
        {
          key: "productId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "transactionTypeId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "switchingCode",
          type: "text",
          isDisabled: true,
          requiredFromColumn: {
            key: "transactionTypeId",
            //switch
            value: 3,
          },
        },
        {
          key: "productBankAccountId",
          type: "select",
          requiredOnCreate: true,
          requiredFromColumn: {
            key: "transactionTypeId",
            value: [TRANSACTION_TYPE.SUB, TRANSACTION_TYPE.RED],
          },
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "transactionDate",
          type: "date",
          requiredOnCreate: true,
        },
        {
          key: "settlementDate",
          type: "date",
          requiredOnCreate: true,
        },
        {
          key: "transferType",
          type: "select",
          options: Object.entries(TRANSFER_TYPE_NAME).map(([id, label]) => ({
            label: label,
            value: id,
          })),
          requiredOnCreate: true,
        },
        { key: "unit", type: "number", requiredOnCreate: true },
        { key: "isAllUnits", type: "boolean", requiredOnCreate: true },
        { key: "amount", type: "rupiah", requiredOnCreate: true },
        {
          key: "fee",
          type: "number",
          label: "Fee (%)",
        },
        { key: "feeNominal", type: "rupiah" },
        { key: "netAmount", type: "rupiah", requiredOnCreate: true },
        { key: "charges", type: "rupiah" },
        {
          key: "paymentMethodId",
          type: "select",
          optionsMeta: { resourceName: "static/payment-methods" },
          requiredOnCreate: true,
        },
        {
          key: "sellingAgentId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
          },
        },
        {
          key: "agentId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "agents",
            initialFilters: [
              { field: "isActive", operator: "eq", value: true },
            ],
          },
        },

        {
          key: "reason",
          type: "textarea",
          requiredOnCreate: true,
          span: 2,
        },
        // hidden
        {
          key: "unitHolderId",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
      ],
    },
  },
];
// #endregion

// #region CREATE INSTITUTION
const createInstitutionTransactionResources: CustomResourceProps[] = [
  {
    name: "transactions/institution/buy/draft",
    identifier: "transactions/create/institution/subscription",
    meta: {
      label: "Subscription",
      defaultAction: "create",
      canList: true,
      listComponent: (
        <TransactionCreate
          saveButtonProps={{ children: "Save as Draft" }}
          transactionType={1}
        />
      ),
      formColumns: [
        {
          key: "userId",
          label: "Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/institution",
            initialFilters: [
              {
                field: "kycStatus",
                operator: "eq",
                value: 5,
              },
            ],
          },
        },
        {
          key: "productId",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            childResourceName: "products/fees",
            childForeignKey: "productId",
            childApprovalStatus: "approved",
            displayColumns: [
              {
                key: "productDetail",
                childKey: "minSub",
                label: "Min. Subscription",
                type: "currency",
              },
              {
                key: "productDetail",
                childKey: "subFee",
                label: "Fee",
                type: "percentage",
              },
            ],
          },
        },
        {
          key: "amount",
          type: "rupiah",
          requiredOnCreate: true,
          span: 2,
        },
        {
          key: "fee",
          type: "number",
          requiredOnCreate: true,
          span: 2,
          label: "Fee (%)",
        },
        {
          key: "settlementDate",
          type: "date",
          span: 2,
          requiredOnCreate: true,
          isDisabled: true,
        },
        {
          key: "productBankAccountId",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "transactionTypeId", operator: "eq", value: 1 },
              { field: "sellingAgentId", operator: "eq", value: 1 },
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
          },
        },
        {
          key: "customerBankAccountNumber",
          label: "Customer Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "customers/institution/bank-accounts",
            valueKey: "bankAccountNumber",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            filterFromOtherColumn: {
              formKey: "userId",
              filterKey: "customerInstitutionId",
              required: true,
            },
          },
        },
      ],
    },
  },

  {
    name: "transactions/institution/sell/draft",
    identifier: "transactions/create/institution/redemption",
    meta: {
      label: "Redemption",
      defaultAction: "create",
      canList: true,
      submitCallback: unitAmountToggleHandler("valueType"),
      listComponent: (
        <TransactionCreate
          saveButtonProps={{ children: "Save as Draft" }}
          transactionType={2}
        />
      ),
      formColumns: [
        {
          key: "userId",
          label: "Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/institution",
            initialFilters: [
              {
                field: "kycStatus",
                operator: "eq",
                value: 5,
              },
            ],
          },
        },
        {
          key: "productId",
          label: "Choose a Product",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            disableFilter: true,
            resourceName: "customers/user-portfolios",
            valueKey: "product.id",
            filterFromRedux: {
              formKey: "userId.unitHolderId",
              filterKey: "search",
              required: true,
            },
          },
        },
        {
          key: "display-remaining",
          type: "custom",
          CustomComponent: DisplayRemainingPortofolio,
          customValueKeys: {
            valueType: "valueType",
            value: "value",
            customerKey: "userId",
          },
          optionsMeta: {
            filterFromOtherColumn: {
              formKey: "productId",
            },
          },
        },
        {
          key: "valueType",
          span: 2,
          type: "boolean",
          labelTrue: "Unit",
          labelFalse: "Amount",
          ignoreOnSubmit: true,
          requiredOnCreate: true,
          initialValue: true,
        },
        {
          key: "value",
          span: 2,
          min: 0,
          requiredOnCreate: true,
          ignoreOnSubmit: true,
          type: "formatted-number",
        },
        {
          key: "fee",
          type: "number",
          requiredOnCreate: true,
          span: 2,
          label: "Fee (%)",
        },
        {
          key: "settlementDate",
          type: "date",
          span: 2,
          requiredOnCreate: true,
        },
        {
          key: "productBankAccountId",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "transactionTypeId", operator: "eq", value: 2 },
              { field: "sellingAgentId", operator: "eq", value: 1 },
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isCustodianBankAccount", operator: "eq", value: true },
            ],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
          },
        },
        {
          key: "customerBankAccountNumber",
          label: "Customer Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "customers/institution/bank-accounts",
            valueKey: "bankAccountNumber",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            filterFromOtherColumn: {
              formKey: "userId",
              filterKey: "customerInstitutionId",
              required: true,
            },
          },
        },
      ],
    },
  },

  {
    name: "transactions/institution/switch/draft",
    identifier: "transactions/create/institution/switching",
    meta: {
      label: "Switching",
      defaultAction: "create",
      canList: true,
      submitCallback: unitAmountToggleHandler("valueType"),
      listComponent: (
        <TransactionCreate
          saveButtonProps={{ children: "Save as Draft" }}
          transactionType={3}
        />
      ),
      formColumns: [
        {
          key: "userId",
          label: "Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/institution",
            initialFilters: [
              {
                field: "kycStatus",
                operator: "eq",
                value: 5,
              },
            ],
          },
        },
        {
          key: "productFromId",
          label: "Redemption From",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            disableFilter: true,
            resourceName: "customers/user-portfolios",
            valueKey: "product.id",
            filterFromRedux: {
              formKey: "userId.unitHolderId",
              filterKey: "search",
              required: true,
            },
          },
        },
        {
          key: "display-remaining",
          type: "custom",
          CustomComponent: DisplayRemainingPortofolio,
          customValueKeys: {
            valueType: "valueType",
            value: "value",
            customerKey: "userId",
          },
          optionsMeta: {
            filterFromOtherColumn: {
              formKey: "productFromId",
            },
          },
        },
        {
          key: "productToId",
          label: "Subscription To",
          type: "select-details",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          direction: "horizontal",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            childResourceName: "products/fees",
            childForeignKey: "productId",
            childApprovalStatus: "approved",
            displayColumns: [
              {
                key: "productDetail",
                childKey: "minSub",
                label: "Min. Subscription",
                type: "currency",
              },
              {
                key: "productDetail",
                childKey: "subFee",
                label: "Fee",
                type: "percentage",
              },
            ],
          },
        },
        {
          key: "valueType",
          span: 2,
          type: "boolean",
          labelTrue: "Unit",
          labelFalse: "Amount",
          ignoreOnSubmit: true,
          requiredOnCreate: true,
          initialValue: true,
        },
        {
          key: "value",
          span: 2,
          min: 0,
          requiredOnCreate: true,
          ignoreOnSubmit: true,
          type: "formatted-number",
        },
        {
          key: "feeType",
          span: 2,
          type: "boolean",
          labelTrue: "Switch Out",
          valueTrue: 1,
          labelFalse: "Switch In",
          valueFalse: 2,
          requiredOnCreate: true,
          initialValue: true,
        },
        {
          key: "fee",
          type: "number",
          requiredOnCreate: true,
          span: 2,
          label: "Fee (%)",
        },
        {
          key: "settlementDate",
          type: "date",
          span: 2,
          requiredOnCreate: true,
        },
      ],
    },
  },

  {
    name: "transactions/dividend-distributions",
    identifier: "transactions/create/institution/dividend-distribution",
    meta: {
      label: "Dividend Distribution",
      defaultAction: "create",
      listComponent: <DefaultCreate saveButtonProps={{ children: "Create" }} />,
      canList: true,
      filters: [
        {
          key: "unitHolderId",
          type: "text",
          placeholder: "Search Unit Holder ID",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
          },
        },
        {
          key: "approvalStatusId",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      formColumns: [
        {
          key: "customerDetailId",
          label: "Choose a Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          ignoreOnSubmit: true,
          optionsMeta: {
            fillOtherColumns: {
              customerName: "companyName",
              ifua: "ifua",
              unitHolderId: "unitHolderId",
            },
            resourceName: "customers/institution",
            labelKey: ["firstName", "lastName"],
            initialFilters: [
              {
                field: "kycStatus",
                operator: "eq",
                value: 5,
              },
            ],
          },
        },
        {
          key: "date",
          type: "date",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
        },
        {
          key: "productId",
          label: "Choose a Product",
          type: "select",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "dividendTypeId",
          optionsMeta: {
            resourceName: "static/dividend-types",
          },
          requiredOnCreate: true,
          type: "select",
        },
        {
          key: "amount",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          type: "rupiah",
        },
        {
          key: "unit",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          type: "number",
        },
        {
          key: "bankAccountNumber",
          label: "Customer Bank Account",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "customers/institution/bank-accounts",
            valueKey: "bankAccountNumber",
            additionalLabelKeys: ["bankAccountNumber"],
            disableGetOne: true,
            labelKey: "bank",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            fillOtherColumns: {
              bankId: "bank.id",
            },
            filterFromOtherColumn: {
              formKey: "customerDetailId",
              filterKey: "customerInstitutionId",
              required: true,
            },
          },
        },
        // hidden fields, set from unitHolderId select-table
        {
          key: "unitHolderId",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "customerName",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "ifua",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "bankId",
          type: "text",
          isDisabled: true,
          hidden: true,
        },
      ],
    },
  },
  {
    name: "transactions/backdate-reasons",
    identifier: "transactions/create/institution/backdate-reasons",
    meta: {
      label: "Backdate",
      defaultAction: "create",
      listComponent: (
        <TransactionCreateBackdate saveButtonProps={{ children: "Create" }} />
      ),
      canList: true,
      formColumns: [
        {
          key: "customerDetailId",
          label: "Choose a Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          ignoreOnSubmit: true,
          optionsMeta: {
            fillOtherColumns: { unitHolderId: "unitHolderId" },
            resourceName: "customers/institution",
            labelKey: ["firstName", "lastName"],
            initialFilters: [
              {
                field: "kycStatus",
                operator: "eq",
                value: 5,
              },
            ],
          },
        },
        {
          key: "customerBankAccountNumber",
          label: "Customer Bank Account",
          type: "select-details",
          span: 2,
          requiredFromColumn: {
            value: [1, 2],
            key: "transactionTypeId",
          },
          optionsMeta: {
            resourceName: "customers/institution/bank-accounts",
            valueKey: "bankAccountNumber",
            disableGetOne: true,
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            displayColumns: [
              { key: "bankAccountNumber" },
              { key: "bankAccountName" },
              { key: "bank" },
            ],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
              { field: "isActive", operator: "eq", value: true },
            ],
            filterFromOtherColumn: {
              formKey: "customerDetailId",
              filterKey: "customerInstitutionId",
              required: true,
            },
          },
        },
        {
          key: "productId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "transactionTypeId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "switchingCode",
          type: "text",
          isDisabled: true,
          requiredFromColumn: {
            key: "transactionTypeId",
            //switch
            value: 3,
          },
        },
        {
          key: "productBankAccountId",
          type: "select",
          requiredFromColumn: {
            value: [1, 2],
            key: "transactionTypeId",
          },
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "transactionDate",
          type: "date",
          requiredOnCreate: true,
        },
        {
          key: "settlementDate",
          type: "date",
          requiredOnCreate: true,
        },
        {
          key: "transferType",
          type: "select",
          options: Object.entries(TRANSFER_TYPE_NAME).map(([id, label]) => ({
            label: label,
            value: id,
          })),
          requiredOnCreate: true,
        },
        { key: "unit", type: "number", requiredOnCreate: true },
        { key: "isAllUnits", type: "boolean", requiredOnCreate: true },
        { key: "amount", type: "rupiah", requiredOnCreate: true },
        {
          key: "fee",
          type: "number",
          label: "Fee (%)",
        },
        { key: "feeNominal", type: "rupiah" },
        { key: "netAmount", type: "rupiah", requiredOnCreate: true },
        { key: "charges", type: "rupiah" },
        {
          key: "paymentMethodId",
          type: "select",
          optionsMeta: { resourceName: "static/payment-methods" },
          requiredOnCreate: true,
        },
        {
          key: "sellingAgentId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
          },
        },
        {
          key: "agentId",
          type: "select",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "agents",
            initialFilters: [
              { field: "isActive", operator: "eq", value: true },
            ],
          },
        },

        {
          key: "reason",
          type: "textarea",
          requiredOnCreate: true,
          span: 2,
        },
        // hidden
        {
          key: "unitHolderId",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
      ],
    },
  },
];
// #endregion

// #region TRANSACTION
const transactionListResources: CustomResourceProps[] = [
  {
    name: "transactions",
    identifier: "transactions/all",
    meta: {
      label: "All",
      icon: <FileSearchOutlined />,
      canList: true,
      canShow: true,
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      // canCreate: true,
      // createComponent: <ProductCreate />,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 3,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "paymentMethodId",
          type: "select",
          optionsMeta: {
            resourceName: "static/payment-methods",
            disableGetOne: true,
          },
        },
        {
          key: "sellingAgentId",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/draft",
    meta: {
      label: "Draft",
      icon: <EditOutlined />,
      canList: true,
      // canShow: true,
      // canCreate: true,
      canEdit: true,
      editComponent: <TransactionEdit />,
      editMethod: "patch",
      canApprove: true,
      approveComponent: <TransactionDraftApprove />,
      approvePermissionAction: "list",
      approvePermissionResource: "transactions",
      rejectRemarkKey: "remark",
      statusToApprove: "draft",
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 2,
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 6 },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
      // formColumns: [
      //   { key: "fee", type: "number" },
      //   { key: "charges", type: "number" },
      // ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/pending",
    meta: {
      label: "Pending",
      icon: <SyncOutlined />,
      canList: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      // editMethod: "patch",
      canApprove: true,
      approveComponent: <TransactionApprove />,
      approveResource: "transactions/approval/pending",
      rejectRemarkKey: "remark",
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 2,
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 1 },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "paymentMethodId",
          label: "Payment Method",
          type: "select",
          optionsMeta: {
            resourceName: "static/payment-methods",
            disableGetOne: true,
          },
        },
        {
          key: "sellingAgentId",
          label: "Selling Agent",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/pending-aperd",
    meta: {
      label: "Pending (APERD)",
      icon: <HourglassOutlined />,
      canList: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      // editMethod: "patch",
      canApprove: true,
      canBulkApprove: true,
      approveComponent: <TransactionApprove />,
      approveResource: "transactions/approval/pending",
      rejectRemarkKey: "remark",
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 2,
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 1 },
        { field: "isAperd", operator: "eq", value: 1 },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "sellingAgentId",
          label: "Selling Agent",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/goodfund",
    meta: {
      label: "Goodfund",
      icon: <CheckOutlined />,
      canList: true,
      // canShow: true,
      // canCreate: true,
      // canEdit: true,
      canApprove: true,
      statusToApprove: "goodfund",
      rejectRemarkKey: "remark",
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      approveComponent: <TransactionApprove />,
      approveResource: "transactions/approval/goodfund",
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 3,
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 2 },
      ],
      downloadButtons: [
        {
          label: "Download (SUB & RED)",
          resource: "export/transactions/sub-red",
        },
        {
          label: "Download (SWT)",
          resource: "export/transactions/switch",
        },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "paymentMethodId",
          label: "Payment Method",
          type: "select",
          optionsMeta: {
            resourceName: "static/payment-methods",
            disableGetOne: true,
          },
        },
        {
          key: "sellingAgentId",
          label: "Selling Agent",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "goodfundAt",
          placeholder: ["Goodfund At (Start)", "Goodfund At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/approved",
    meta: {
      label: "Approved",
      icon: <LikeOutlined />,
      canList: true,
      canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 3,
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 3 },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "paymentMethodId",
          label: "Payment Method",
          type: "select",
          optionsMeta: {
            resourceName: "static/payment-methods",
            disableGetOne: true,
          },
        },
        {
          key: "sellingAgentId",
          label: "Selling Agent",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "approvedAt",
          placeholder: ["Approved At (Start)", "Approved At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/rejected",
    meta: {
      label: "Rejected",
      icon: <DislikeOutlined />,
      canList: true,
      canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 1,
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 4 },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "paymentMethodId",
          label: "Payment Method",
          type: "select",
          optionsMeta: {
            resourceName: "static/payment-methods",
            disableGetOne: true,
          },
        },
        {
          key: "sellingAgentId",
          label: "Selling Agent",
          type: "select",
          optionsMeta: {
            resourceName: "static/selling-agents",
            disableGetOne: true,
            optionsWidth: 480,
          },
        },
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "rejectedAt",
          placeholder: ["Rejected At (Start)", "Rejected At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions",
    identifier: "transactions/expired",
    meta: {
      label: "Expired",
      icon: <ClockCircleOutlined />,
      canList: true,
      canShow: true,
      // canCreate: true,
      // canEdit: true,
      // canApprove: true,
      // canDelete: true,
      displayColumns: transactionListDisplayColumns,
      fixedColumns: 1,
      statusResource: "transactions/status",
      statusForeignKey: "transactionId",
      initialSorters: [{ field: "id", order: "desc" }],
      permanentFilters: [
        { field: "transactionStatusId", operator: "eq", value: 5 },
      ],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "paymentMethodId",
          label: "Payment Method",
          type: "select",
          optionsMeta: {
            resourceName: "static/payment-methods",
            disableGetOne: true,
          },
        },
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
        {
          key: "transactionExpired",
          placeholder: ["Expired At (Start)", "Expired At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },
  {
    name: "transactions/dividend-distributions",
    identifier: "transactions/dividend-distributions",
    meta: {
      label: "Dividend Distribution",
      icon: <PieChartOutlined />,
      canEdit: true,
      canList: true,
      statusResource: "transactions/dividend-distributions/approval",
      statusForeignKey: "dividendDistributionId",
      statusToEdit: "pending",
      editComponent: <DividendDistributionEdit />,
      canShow: true,
      canApprove: true,
      editMethod: "patch",
      rejectRemarkKey: "remark",
      approveResource: "transactions/dividend-distributions/approval",
      displayColumns: dividendDistributionDisplayColumns,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "dividendTypeId",
          label: "Dividend Type",
          optionsMeta: {
            resourceName: "static/dividend-types",
          },
          type: "select",
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
          },
          required: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            optionsWidth: 480,
          },
        },
        {
          key: "approvalStatusId",
          label: "Approval Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      formColumns: [
        {
          key: "customerName",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
        },
        {
          key: "date",
          label: "Date",
          type: "date",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
        },
        {
          key: "productId",
          label: "Choose a Product",
          type: "select",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "dividendTypeId",
          label: "Dividend Type",
          optionsMeta: {
            resourceName: "static/dividend-types",
          },
          requiredOnCreate: true,
          type: "select",
        },
        {
          key: "amount",
          label: "Amount",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          type: "rupiah",
        },
        {
          key: "unit",
          label: "Unit",
          disabledOnEdit: true,
          ignoreOnEdit: true,
          requiredOnCreate: true,
          type: "number",
        },
        // handled by custom component
        {
          key: "bankAccountNumber",
          label: "Customer Bank Account",
        },
        // hidden fields, set from unitHolderId select-table
        {
          key: "customerDetailId",
          disabledOnEdit: true,
          label: "Customer ID",
          hideOnCreate: true,
          ignoreOnEdit: true,
          hidden: true,
        },
        {
          key: "customerInstitutionId",
          label: "Customer ID",
          disabledOnEdit: false,
          hidden: true,
          ignoreOnEdit: true,
          type: "text",
        },
        {
          key: "unitHolderId",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "ifua",
          ignoreOnEdit: true,
          type: "text",
          isDisabled: true,
          hidden: true,
        },
        {
          key: "bankId",
          type: "text",
          isDisabled: true,
          hidden: true,
        },
      ],
    },
  },
  {
    name: "transactions/backdate-reasons",
    identifier: "transactions/backdate-reasons",
    meta: {
      label: "Backdate",
      icon: <QuestionCircleOutlined />,
      canList: true,
      canApprove: true,
      canEdit: true,
      editMethod: "patch",
      rejectRemarkKey: "remark",
      statusForeignKey: "backdateTransactionReasonId",
      statusResource: "transactions/backdate-reasons/approval",
      displayColumns: backdateReasonsDisplayColumns,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "unitHolderId",
          label: "Customer",
          type: "select",
          optionsMeta: {
            resourceName: "customers/all",
            valueKey: "unitHolderId",
          },
          required: true,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            optionsWidth: 480,
          },
        },
        {
          key: "approvalStatusId",
          label: "Approval Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      formColumns: [
        {
          key: "customerName",
          label: "Customer Name",
          optionsMeta: {
            objectName: "transaction",
            valueKey: "fullName",
          },
          type: "text",
          ignoreOnSubmit: true,
          isDisabled: true,
        },
        {
          key: "customerBankAccountNumber",
          optionsMeta: {
            objectName: "transaction",
            valueKey: "customerBankAccountNumber",
          },
          type: "text",
          ignoreOnSubmit: true,
          isDisabled: true,
        },
        {
          key: "transactionCode",
          label: "Transaction Code",
          optionsMeta: {
            objectName: "transaction",
            valueKey: "transactionCode",
          },
          type: "text",
          ignoreOnSubmit: true,
          isDisabled: true,
        },
        {
          key: "transactionDate",
          type: "text",
          ignoreOnSubmit: true,
          optionsMeta: {
            objectName: "transaction",
            valueKey: "transactionDate",
          },
          isDisabled: true,
        },
        {
          key: "settlementDate",
          type: "text",
          ignoreOnSubmit: true,
          optionsMeta: {
            objectName: "transaction",
            valueKey: "settledAt",
          },
          isDisabled: true,
        },
        {
          key: "amount",
          type: "rupiah",
          ignoreOnSubmit: true,
          optionsMeta: {
            objectName: "transaction",
            valueKey: "nominal",
          },
          isDisabled: true,
        },
        {
          key: "reason",
          type: "textarea",
          span: 2,
          requiredOnEdit: true,
        },
      ],
    },
  },
];
// #endregion

// #region TRX AUTODEBIT
const transactionAutodebitResources: CustomResourceProps[] = [
  {
    name: "transactions/autodebits",
    identifier: "transactions/autodebits",
    meta: {
      label: "Autodebit",
      icon: <ScheduleOutlined />,
      statusResource: "transactions/autodebits/approval",
      statusForeignKey: "autodebitId",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      canApprove: true,
      fixedColumns: 1,
      editMethod: "patch",
      approveResource: "transactions/autodebits/approval",
      rejectRemarkKey: "remark",
      displayColumns: autodebitsListDisplayColumns,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "recurringDate",
          type: "number",
        },
        {
          key: "approvalStatusId",
          label: "Approval Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
      formColumns: [
        {
          key: "customerDetailId",
          label: "Choose a Customer",
          type: "select-table",
          requiredOnCreate: true,
          span: 2,
          hideOnEdit: true,
          optionsMeta: {
            resourceName: "customers/retail/details",
            labelKey: ["firstName", "lastName"],
            initialFilters: [
              { field: "isConventional", operator: "eq", value: true },
              { field: "isVerified", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "customerDetailId",
          label: "Customer Name",
          type: "select",
          hideOnCreate: true,
          disabledOnEdit: true,
          ignoreOnSubmit: true,
          optionsMeta: {
            resourceName: "customers/retail/details",
            labelKey: ["firstName", "lastName"],
            initialFilters: [
              { field: "isConventional", operator: "eq", value: true },
              { field: "isVerified", operator: "eq", value: true },
            ],
          },
        },
        {
          key: "customerBankAccountId",
          label: "Customer Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "customers/retail/bank-accounts",
            valueKey: "id",
            disableGetOne: true,
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            filterFromOtherColumn: {
              formKey: "customerDetailId",
              filterKey: "customerDetailId",
              required: true,
            },
          },
        },
        {
          key: "productId",
          type: "select-details",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
            childApprovalStatus: "approved",
          },
        },
        {
          key: "productBankAccountId",
          label: "Product Bank Account",
          type: "select-details",
          requiredOnCreate: true,
          optionsMeta: {
            resourceName: "products/bank-accounts",
            labelKey: "bank",
            additionalLabelKeys: ["bankAccountNumber"],
            initialFilters: [
              { field: "transactionTypeId", operator: "eq", value: 1 },
            ],
            filterFromOtherColumn: {
              formKey: "productId",
              filterKey: "productId",
              required: true,
            },
          },
        },
        {
          key: "amount",
          type: "rupiah",
          requiredOnCreate: true,
        },
        {
          key: "startDate",
          type: "date",
          requiredOnCreate: true,
        },
        {
          key: "recurringDate",
          type: "number",
          requiredOnCreate: true,
        },
      ],
    },
  },
  {
    name: "transactions/autodebit-transactions",
    identifier: "transactions/autodebit-transactions",
    meta: {
      label: "Autodebit Transactions",
      icon: <AccountBookOutlined />,
      statusResource: "transactions/autodebit-transactions/approval",
      statusForeignKey: "autodebitTransactionId",
      approveResource: "transactions/autodebit-transactions/approval",
      canList: true,
      canApprove: true,
      uploadResource: "transactions/autodebit-transactions/import",
      uploadFormats: [".txt"],
      uploadParams: [
        {
          key: "type",
          label: "Select Bank",
          type: "select",
          options: [
            { label: "BRI", value: "bri" },
            { label: "BCA", value: "bca" },
          ],
        },
        {
          key: "recurringDate",
          label: "Enter Recurring Date",
          type: "select",
        },
      ],
      canShow: true,
      fixedColumns: 1,
      displayColumns: transactionAutodebitsListDisplayColumns,
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "approvalStatusId",
          label: "Approval Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/approval-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
        },
      ],
    },
  },
];
// #endregion

// #region TRX STATIC DATA
const transactionStaticDataResources: CustomResourceProps[] = [
  {
    name: "static/transaction-times",
    identifier: "transactions/static/transaction-times",
    meta: {
      label: "Transaction Times",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "transactionTimeCode", type: "text", requiredOnCreate: true },
        { key: "transactionStartTime", type: "time", requiredOnCreate: true },
        { key: "transactionEndTime", type: "time", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/transaction-status-details",
    identifier: "transactions/static/transaction-status-details",
    meta: {
      label: "Transaction Status Details",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "transactionTypeId",
          label: "Transaction Type",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-types",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
          requiredOnCreate: true,
        },
        {
          key: "transactionStatusId",
          label: "Transaction Status",
          type: "select",
          optionsMeta: {
            resourceName: "static/transaction-statuses",
            pagination: { mode: "off" },
            disableGetOne: true,
            disableSearch: true,
          },
          requiredOnCreate: true,
        },
        { key: "description", type: "text", requiredOnCreate: true },
        { key: "note", type: "text", requiredOnCreate: true },
        { key: "color", type: "color", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/transaction-terms",
    identifier: "transactions/static/transaction-terms",
    meta: {
      label: "Transaction Terms",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
          requiredOnCreate: true,
        },
        { key: "title", type: "text", requiredOnCreate: true },
        {
          key: "content ",
          type: "textarea",
          requiredOnCreate: true,
          span: 2,
        },
        { key: "isNumberedList  ", type: "boolean", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/payment-methods",
    identifier: "transactions/static/payment-methods",
    meta: {
      label: "Payment Methods",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "paymentMethodCode", type: "text", requiredOnCreate: true },
        { key: "paymentMethodName", type: "text", requiredOnCreate: true },
      ],
    },
  },
  {
    name: "static/reject-reasons",
    identifier: "transactions/static/reject-reasons",
    meta: {
      label: "Reject Reasons",
      canList: true,
      canShow: true,
      canCreate: true,
      canEdit: true,
      // canApprove: true,
      canDelete: true,
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
      ],
      formColumns: [
        { key: "reason", type: "text", requiredOnCreate: true },
        { key: "remark", type: "text", requiredOnCreate: true },
      ],
    },
  },
];

const feeCalculatorResource: CustomResourceProps = {
  name: "transactions/fee-calculation",
  identifier: "transactions/create/fee-calculator",
  meta: {
    label: "Fee Calculator",
    defaultAction: "create",
    canList: true,
    listComponent: <FeeCalculatorCreate />,
    formColumns: [
      {
        key: "unitHolderId",
        label: "Choose a Customer",
        type: "select-table",
        requiredOnCreate: true,
        span: 2,
        optionsMeta: {
          resourceName: "customers/retail/verified",
          valueKey: "unitHolderId",
        },
      },
      {
        key: "productId",
        label: "Choose a Product",
        type: "select-table",
        requiredOnCreate: true,
        span: 2,
        optionsMeta: {
          disableFilter: true,
          resourceName: "customers/user-portfolios",
          valueKey: "product.id",
          filterFromRedux: {
            formKey: "unitHolderId.unitHolderId",
            filterKey: "search",
            required: true,
          },
        },
      },
      {
        key: "unit",
        type: "number-with-converter",
        optionsMeta: {
          resourceName: "periodic/navs",
          valueKey: "nav",
          initialSorters: [{ field: "date", order: "desc" }],
          filterFromOtherColumn: {
            formKey: "productId",
            filterKey: "productId",
            required: true,
          },
        },
        requiredOnCreate: true,
        span: 2,
      },
      {
        key: "fee",
        type: "number",
        requiredOnCreate: true,
        span: 2,
        label: "Redeem Fee (%)",
        initialValue: 2,
      },
      {
        key: "minHoldingDays",
        type: "number",
        requiredOnCreate: true,
        span: 2,
        label: "Minimum Hold Duration (Days)",
        initialValue: 90,
      },
    ],
  },
};
// #endregion

// #region TRANSACTION
export const transactionResources: CustomResourceProps[] = [
  {
    name: "transaction-list",
    meta: {
      icon: <DollarOutlined />,
      label: "Transactions",
    },
  },

  {
    name: "create-transaction",
    meta: {
      icon: <PlusOutlined />,
      label: "Create",
      parent: "transaction-list",
    },
  },

  {
    name: "transactions/temporaries",
    identifier: "transactions/create/aperd",
    meta: {
      label: "APERD",
      icon: <UploadOutlined />,
      parent: "create-transaction",
      canList: true,
      uploadResource: "transactions/retail/aperd/import",
      uploadSuccessMessageKey: "details",
      showUploadErrorDetail: true,
      uploadFormats: ["xls"],
      initialSorters: [{ field: "id", order: "desc" }],
      filters: [
        {
          key: "search",
          type: "text",
          placeholder: "Search",
          prefix: <SearchOutlined />,
        },
        {
          key: "productId",
          type: "select",
          optionsMeta: {
            resourceName: "products/data",
            initialFilters: [
              { field: "approvalStatusId", operator: "eq", value: 3 },
            ],
          },
        },
        {
          key: "createdAt",
          placeholder: ["Created At (Start)", "Created At (End)"],
          type: "daterange",
          maxDate: dayjs().endOf("day"),
        },
      ],
    },
  },

  {
    name: "transactions/create/retail",
    meta: {
      label: "Retail",
      icon: <UserOutlined />,
      parent: "create-transaction",
    },
  },
  ...createRetailTransactionResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "transactions/create/retail",
    },
  })),

  {
    name: "transactions/create/institution",
    meta: {
      label: "Institution",
      icon: <BankOutlined />,
      parent: "create-transaction",
    },
  },
  ...createInstitutionTransactionResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "transactions/create/institution",
    },
  })),

  {
    ...feeCalculatorResource,
    meta: {
      ...feeCalculatorResource.meta,
      parent: "create-transaction",
    },
  },

  ...transactionListResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "transaction-list",
    },
  })),
  {
    name: "transactions/autodebit",
    meta: {
      icon: <ScheduleOutlined />,
      label: "Autodebit",
      parent: "transaction-list",
    },
  },
  ...transactionAutodebitResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "transactions/autodebit",
    },
  })),

  {
    name: "transactions/static-data",
    meta: {
      icon: <DatabaseOutlined />,
      label: "Static Data",
      parent: "transaction-list",
    },
  },
  ...transactionStaticDataResources.map((resource) => ({
    ...resource,
    meta: {
      ...resource.meta,
      parent: resource?.meta?.parent || "transactions/static-data",
    },
  })),
];
// #endregion
