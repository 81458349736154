import React from "react";
import { FileField, ImageField } from "@refinedev/antd";
import {
  isColorPalette,
  isObject,
  isDate,
  isBoolean,
  isFile,
  isPercentage,
  isCurrency,
  isUnit,
  isText,
  isArrayOfStrings,
  isImage,
  getNameKey,
  isArrayOfObjects,
  isDateTime,
} from "utils";
import { Row, Space, Tag, Tooltip } from "antd";
import { IResourceDisplayColumn } from "interfaces";
import { ArrowRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export interface DynamicDisplayItemProps {
  value: any;
  displayColumn: IResourceDisplayColumn;
  displayType?: "list" | "show";
}

export const DynamicDisplayItem: React.FC<DynamicDisplayItemProps> = ({
  value,
  displayColumn,
  displayType = "list",
}) => {
  if (value === undefined || value === null) {
    if (displayColumn?.isProfitLossIndicator) {
      return <span style={{ color: "gray" }}>{"N/A"}</span>;
    }
    return value;
  }

  if (displayColumn?.type === "json") {
    Object.keys(value || {})?.forEach((key1) => {
      let objectTemp = {};
      Object.keys(value?.[key1] || {})?.forEach((key2) => {
        if (value?.[key1]?.[key2] !== "ok") {
          Object.assign(objectTemp, { [key2]: value?.[key1]?.[key2] });
        }
      });
      if (Object.keys(objectTemp).length > 0) {
        Object.assign(value, { [key1]: objectTemp });
      } else {
        delete value[key1];
      }
    });

    return <pre>{JSON.stringify(value, null, 1)}</pre>;
  }

  if (isImage(value, displayColumn) && displayType === "list") {
    return (
      <Row justify="center">
        <ImageField value={value} style={{ maxHeight: "24px" }} />
      </Row>
    );
  }

  if (isImage(value, displayColumn) && displayType === "show") {
    return (
      <ImageField
        value={value}
        style={{ marginBottom: "20px", width: "100%", maxHeight: "200px" }}
      />
    );
  }

  if (isFile(displayColumn)) {
    return (
      <FileField
        src={value}
        title={
          value
            ?.substring(value?.lastIndexOf("/") + 1, value?.length)
            ?.split("?")[0]
        }
      />
    );
  }

  if (isDate(value)) return dayjs(value).format("D MMM YYYY");

  if (isDateTime(value)) return dayjs(value).format("D MMM YYYY, HH:mm:ss");

  if (isObject(value)) {
    if (value?.oldValue !== undefined && value?.newValue !== undefined) {
      let oldValue;
      let newValue;
      if (isObject(value?.oldValue) || isObject(value?.newValue)) {
        const nameKey = getNameKey(value?.oldValue || value?.newValue);
        oldValue =
          value?.oldValue?.[displayColumn?.childKey || nameKey || "id"];
        newValue =
          value?.newValue?.[displayColumn?.childKey || nameKey || "id"];
      } else {
        oldValue = value?.oldValue;
        newValue = value?.newValue;
      }
      return (
        <div>
          <Tag color="red">{oldValue}</Tag>
          <ArrowRightOutlined style={{ marginRight: "8px" }} />
          <Tag color="green">{newValue}</Tag>
        </div>
      );
    }
    const nameKey = getNameKey(value);
    const nameValue = value?.[displayColumn?.childKey || nameKey || "id"];

    if (value?.colorCode !== undefined) {
      return (
        <Tag
          color={
            value?.colorCode === "Gray" ? "" : value?.colorCode.toLowerCase()
          }
        >
          {DynamicDisplayItem({
            value: nameValue,
            displayColumn: {
              key: displayColumn?.childKey || nameKey,
            },
          })}
        </Tag>
      );
    }
    return DynamicDisplayItem({
      value: nameValue,
      displayColumn: {
        key: displayColumn?.childKey || nameKey,
      },
    });
  }

  if (isArrayOfStrings(value, displayColumn)) {
    return (
      <Space wrap>
        {value?.map((item: string) => (
          <Tag key={item}>{item}</Tag>
        ))}
      </Space>
    );
  }

  if (isArrayOfObjects(value)) {
    const list = value?.map((item: any) => {
      const nameKey = getNameKey(item);
      const nameValue =
        item?.[displayColumn?.childKey || nameKey || ""] || item?.id || item;

      return nameValue;
    });

    return (
      <Space wrap>
        {list?.map((item: string) => (
          <Tag key={item}>{item}</Tag>
        ))}
      </Space>
    );
  }

  if (isBoolean(value, displayColumn)) {
    return value ? (
      <Tag style={{ borderRadius: "4px", marginTop: "2px" }} color={"#119761"}>
        Yes
      </Tag>
    ) : (
      <Tag style={{ borderRadius: "4px", marginTop: "2px" }} color={"#D80027"}>
        No
      </Tag>
    );
  }

  if (isColorPalette(value)) return <Tag color={value}>{value}</Tag>;

  const profitLossColorChanger = (
    val: string,
    checker: number,
    postfix?: string
  ) => {
    return (
      <Tooltip title={`${checker}${postfix || ""}`}>
        <span
          style={{
            color: checker > 0 ? "limegreen" : checker < 0 ? "#D80027" : "gray",
          }}
        >
          {checker >= 0 && "+"}
          {val}
        </span>
      </Tooltip>
    );
  };

  if (isPercentage(displayColumn)) {
    const percentageValue = parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return displayColumn?.isProfitLossIndicator
      ? profitLossColorChanger(`${percentageValue}%`, parseFloat(value), "%")
      : `${percentageValue}%`;
  }
  

  if (isCurrency(displayColumn)) {
    const currencyValue = parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });
  
    const formattedCurrencyValue = `Rp. ${currencyValue}`;
  
    return displayColumn?.isProfitLossIndicator
      ? profitLossColorChanger(formattedCurrencyValue, value)
      : formattedCurrencyValue;
  }
  

  if (isUnit(displayColumn)) {
    const unitValue = parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
      useGrouping: true,
    });
  
    return unitValue;
  }

  if (isText(value, displayColumn)) {
    if (typeof value === "number" && value % 1 !== 0) {
      value = value.toFixed(4);
    }

    if (displayColumn.wrap) {
      return (
        <p style={{ textWrap: "wrap" }}>
          {displayColumn?.isProfitLossIndicator
            ? profitLossColorChanger(value, value)
            : value}
        </p>
      );
    }

    return displayColumn?.isProfitLossIndicator
      ? profitLossColorChanger(value, value)
      : value;
  }

  return null;
};
