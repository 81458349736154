import { startCase } from "lodash";
import dayjs from "dayjs";
import { IResourceDisplayColumn } from "interfaces";

const escapeRegExpMatch = function (s: string) {
  // eslint-disable-next-line no-useless-escape
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const isExactMatch = (str: string, match: string) => {
  return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(str);
};

// Key-Based Validation

const isImage = (value: any, displayColumn: IResourceDisplayColumn) => {
  const imgFormats = [".jpg", ".jpeg", ".png", ".gif", ".svg", ".webp"];
  const colNames = ["Avatar", "Image", "Img", "Photo", "Logo"];

  return (
    displayColumn?.type === "avatar" ||
    displayColumn?.type === "image" ||
    imgFormats?.some((word) => value?.toString()?.includes(word)) ||
    colNames?.some((word) => isExactMatch(startCase(displayColumn?.key), word))
  );
};

const isBoolean = (value: any, displayColumn: IResourceDisplayColumn) =>
  displayColumn?.type === "boolean" ||
  typeof value === "boolean" ||
  isExactMatch(startCase(displayColumn?.key), "Is ");

const isText = (value: any, displayColumn: IResourceDisplayColumn) =>
  displayColumn?.type === "text" ||
  ["number", "string", "text"].includes(typeof value);

const isArrayOfStrings = (value: any, displayColumn: IResourceDisplayColumn) =>
  displayColumn?.type === "array" ||
  (Array.isArray(value) && typeof value[0] === "string");

const isArrayOfObjects = (value: any) =>
  Array.isArray(value) && isObject(value[0]);

// Type-Based Validation
const isDate = (value: any) =>
  typeof value === "string" && dayjs(value, "YYYY-MM-DD", true).isValid();

const isDateTime = (value: any) =>
  typeof value === "string" &&
  dayjs(value, "YYYY-MM-DD HH:mm:ss", true).isValid();

const isFile = (displayColumn: IResourceDisplayColumn) =>
  isExactMatch(startCase(displayColumn?.key), "File");

const isCurrency = (displayColumn: IResourceDisplayColumn) =>
  displayColumn?.type === "currency" ||
  displayColumn?.key?.toLowerCase()?.endsWith("price") ||
  displayColumn?.key?.toLowerCase()?.endsWith("cost") ||
  displayColumn?.key?.toLowerCase()?.endsWith("nominal");

const isUnit = (displayColumn: IResourceDisplayColumn) =>
  displayColumn?.type === "unit" ||
  displayColumn?.key?.toLowerCase()?.endsWith("unit");

const isPercentage = (displayColumn: IResourceDisplayColumn) =>
  displayColumn?.type === "percentage" ||
  displayColumn?.key?.toLowerCase()?.endsWith("fee") ||
  displayColumn?.key?.toLowerCase()?.endsWith("ppn") ||
  displayColumn?.key?.toLowerCase()?.endsWith("percent") ||
  displayColumn?.key?.toLowerCase()?.endsWith("percentage");

const isObject = (obj: unknown) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

const isColorPalette = (str: string) => {
  const regex = "^#(?:[0-9a-fA-F]{3}){1,2}$";
  return new RegExp(regex).test(str);
};

export {
  isImage,
  isBoolean,
  isDate,
  isDateTime,
  isFile,
  isCurrency,
  isUnit,
  isPercentage,
  isText,
  isArrayOfStrings,
  isArrayOfObjects,
  isObject,
  isColorPalette,
  isExactMatch,
};
