import React, { useEffect, useState } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useResource,
  HttpError,
} from "@refinedev/core";
import { EditButton, Show } from "@refinedev/antd";
import pluralize from "pluralize";
import { startCase } from "lodash";
import {
  CustomErrorComponent,
  ShowItemRecursor,
  ShowLayout,
} from "components/organisms";
import { Space, Divider, Typography, TimelineItemProps } from "antd";
import { mapStatus, useChildRecords } from "utils";
import { CustomRefreshButton } from "components/atoms/customRefreshButton";

interface ProductShowProps extends IResourceComponentsProps {
  id?: string | number;
}

export const ProductShow: React.FC<ProductShowProps> = ({ id }) => {
  const { resource } = useResource();
  const { queryResult } = useShow({
    resource: resource?.meta?.showResource,
    id,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // handle the population of the data's status
  const statusData = useChildRecords({
    resourceName: resource?.meta?.statusResource,
    foreignKey: resource?.meta?.statusForeignKey,
    parentId: record?.id,
  });

  const [timelineItemProps, setTimelineItemProps] =
    useState<TimelineItemProps[]>();

  useEffect(() => {
    if (statusData && statusData.length > 0)
      setTimelineItemProps(mapStatus({ data: statusData }));
    else setTimelineItemProps(undefined);
  }, [resource, statusData, record]);

  // useEffect hook that checks for childResource in the resource meta
  const { resource: childResource } = useResource(
    resource?.meta?.childResource
  );

  // handle the population of the data's child resource
  const childRecords = useChildRecords({
    resourceName: resource?.meta?.childResource,
    foreignKey: resource?.meta?.childForeignKey,
    parentId: record?.id,
  });

  // handle the population of the child data's status
  const childStatusData = useChildRecords({
    resourceName: childResource?.meta?.statusResource,
    foreignKey: childResource?.meta?.statusForeignKey,
    parentId: childRecords?.[0]?.id,
  });

  const [childTimelineItemProps, setChildTimelineItemProps] =
    useState<TimelineItemProps[]>();

  useEffect(() => {
    if (childStatusData && childStatusData.length > 0)
      setChildTimelineItemProps(mapStatus({ data: childStatusData }));
    else setChildTimelineItemProps(undefined);
  }, [childResource, childStatusData, childRecords]);

  const error = queryResult?.error as HttpError;
  if (error?.statusCode) return <CustomErrorComponent error={error} />;

  return (
    <Space size="large" direction="vertical" style={{ width: "100%" }}>
      <Show
        title={`${pluralize.singular(
          resource?.meta?.label ||
            startCase(resource?.identifier || resource?.name || "")
        )}`}
        isLoading={isLoading}
        headerProps={{
          breadcrumb: undefined,
          extra: (
            <>
              {resource?.meta?.canEdit && <EditButton />}

              <CustomRefreshButton />
            </>
          ),
        }}
      >
        <ShowLayout
          objectName={resource?.identifier || resource?.name}
          objectData={record}
          timelineItemProps={timelineItemProps}
        />

        {childRecords?.map((childRecord, index) => (
          <div key={index}>
            <Divider style={{ marginTop: "48px", marginBottom: "48px" }} />

            <Typography.Title level={3}>
              {`${pluralize.singular(
                childResource?.meta?.label ||
                  startCase(childResource?.name || "")
              )}`}
            </Typography.Title>

            <ShowLayout
              objectName={childResource?.name}
              objectData={childRecord}
              timelineItemProps={childTimelineItemProps}
            />
          </div>
        ))}
      </Show>

      <ShowItemRecursor
        parentName={resource?.identifier || resource?.name}
        parentData={record}
      />
    </Space>
  );
};
