import {
  useNotification,
  useResource,
  useInvalidate,
  useCreate,
  useTranslate,
  useApiUrl,
} from "@refinedev/core";
import {
  Button,
  ButtonProps,
  UploadProps,
  Upload,
  UploadFile,
  Select,
  Table,
  Row,
  Col,
} from "antd";
import { RcFile } from "antd/es/upload";
import React, { useEffect, useState } from "react";
import { InboxOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { axiosInstance, jsonToFormData } from "utils";
import mime from "mime";
import { List } from "@refinedev/antd";
import { startCase } from "lodash";

interface UploadDraggerProps extends ButtonProps {
  resource?: string;
  formats?: string[];
}

export const UploadDragger: React.FC<UploadDraggerProps> = ({
  resource,
  formats = ["csv"],
  // ...rest
}) => {
  const { resource: defaultResource } = useResource();
  const [errors, setErrors] = useState<string[]>([]);
  const [file, setFile] = useState<UploadFile>();
  const [selectedParam, setSelectedParam] = useState<{
    [key: string]: string | number;
  }>();
  const [dateOptions, setDateOptions] =
    useState<{ label: string; value: number }[]>();
  const [uploading, setUploading] = useState(false);
  const { open } = useNotification();
  const invalidate = useInvalidate();
  const { mutate: mutateCreate } = useCreate();
  const translate = useTranslate();
  const apiUrl = useApiUrl();
  const [isExampleLoading, setIsExampleLoading] = useState(false);

  const handleUpload = () => {
    setUploading(true);
    mutateCreate(
      {
        resource: resource || defaultResource?.name || "",
        values: jsonToFormData({ file }),
        meta: {
          headers: {
            contentType: "multipart/form-data",
          },
          query: {
            ...(defaultResource?.meta?.uploadParams &&
              defaultResource.meta.uploadParams.reduce(
                (accumulator: any, param: any) => {
                  return {
                    ...accumulator,
                    [param.key]: selectedParam && selectedParam[param?.key],
                  };
                },
                {}
              )),
          },
        },
        successNotification:
          defaultResource?.meta?.uploadSuccessMessageKey &&
          ((data) => {
            return {
              type: "success",
              message:
                (data?.data &&
                  data.data[defaultResource?.meta?.uploadSuccessMessageKey]) ||
                `Successfully created ${defaultResource?.meta?.uploadResource}`,
            };
          }),
      },
      {
        onSettled: (data) => {
          setErrors(
            data?.data?.[
              defaultResource?.meta?.uploadErrorDetailKey || "errContainer"
            ] || []
          );
          setUploading(false);
          setFile(undefined);
          invalidate({
            resource: defaultResource?.name || "",
            invalidates: ["all"],
          });
        },
      }
    );
  };

  const handleChangeParam = (key: string, value: string | number) => {
    setSelectedParam((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Set date options based on selected type param (bca or bri)
  useEffect(() => {
    const date = {
      bca: [
        { label: "5", value: 5 },
        { label: "29", value: 29 },
      ],
      bri: [
        { label: "2", value: 2 },
        { label: "26", value: 26 },
      ],
    };

    if (selectedParam?.type === "bca") {
      setDateOptions(date.bca);
    } else if (selectedParam?.type === "bri") {
      setDateOptions(date.bri);
    }
  }, [selectedParam]);

  const handleDownloadExample = async () => {
    setIsExampleLoading(true);
    const endpoint = defaultResource?.meta?.uploadExampleResource;
    const url = `${apiUrl}/${endpoint}`;

    try {
      const s3Response = await axiosInstance.get(url);
      const s3SignedUrl = s3Response?.data?.body;

      // open the url in a new tab
      window.open(s3SignedUrl, "_blank");
    } catch (err: any) {
      const error = err?.response?.data;
      open?.({
        type: "error",
        message: translate(
          "notifications.error",
          { statusCode: error?.statusCode },
          `Error code: ${error?.statusCode}`
        ),
        description: error?.message || "Unknown error",
      });
    } finally {
      setIsExampleLoading(false);
    }
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file: RcFile) => {
      const mimeTypes = formats?.map((format) => mime.getType(format));
      const isCorrectFormat = mimeTypes?.includes(file?.type);

      if (!isCorrectFormat) {
        open?.({
          type: "error",
          message: translate("notifications.err"),
          description: translate("notifications.uploadFormatError", {
            format: formats?.join(", "),
          }),
        });
      } else {
        setFile(file);
      }
      return false;
    },
    fileList: file ? [file] : [],
  };

  return (
    <List
      title={`${
        defaultResource?.meta?.label ||
        startCase(defaultResource?.identifier || defaultResource?.name || "")
      } - ${translate("uploadDragger.title", "Upload File")}`}
      headerProps={{
        breadcrumb: undefined,
        extra: (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {defaultResource?.meta?.uploadExampleResource && (
              <Button
                loading={isExampleLoading}
                onClick={handleDownloadExample}
                icon={<QuestionCircleOutlined />}
              >
                {translate("buttons.exampleFile", "Example File")}
              </Button>
            )}
          </div>
        ),
      }}
    >
      <Row gutter={[16, 16]}>
        {defaultResource?.meta?.uploadParams &&
          defaultResource?.meta?.uploadParams.map(
            (param: {
              key: string;
              label?: string;
              type?: string;
              options?: { label: string; value: string | number }[];
            }) =>
              param.type === "select" ? (
                <Col flex={1}>
                  <Select
                    key={param.key}
                    onChange={(value) => {
                      handleChangeParam(param?.key, value);
                    }}
                    placeholder={param.label || `Select ${param.key}`}
                    options={param.options || dateOptions}
                    style={{ width: "100%", marginBottom: "16px" }}
                  />
                </Col>
              ) : null
          )}
      </Row>
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon" style={{ height: "75px" }}></p>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {translate(
            "uploadDragger.instructions",
            "Click or drag file to this area"
          )}
        </p>
        <p className="ant-upload-hint" style={{ height: "75px" }}>
          {translate("uploadDragger.hint", { format: formats?.join("/") })}
        </p>
      </Upload.Dragger>

      <Button
        type="primary"
        onClick={handleUpload}
        disabled={
          file === undefined ||
          (defaultResource?.meta?.uploadParams && selectedParam === undefined)
        }
        loading={uploading}
        style={{ margin: "16px 0", width: "100%" }}
      >
        {translate("buttons.upload", "Upload")}
      </Button>
      {defaultResource?.meta?.showUploadErrorDetail && errors.length !== 0 && (
        <Table
          columns={[
            {
              title: () => <h4 style={{ color: "red" }}>Errors</h4>,
              dataIndex: "value",
              render: (val) => (
                <p style={{ color: "red", marginBottom: 0 }}>{val}</p>
              ),
            },
          ]}
          scroll={{ y: 350 }}
          pagination={false}
          dataSource={errors.map((item, idx) => ({
            key: `err-${idx}`,
            value: item,
          }))}
        />
      )}
    </List>
  );
};
