import { useApiUrl, useNotification, useTranslate } from "@refinedev/core";
import { Space, Typography, theme } from "antd";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "utils";

export const Footer: React.FC = () => {
  const { token } = theme.useToken();
  // get app version from package.json
  const appVersion = require("../../../package.json").version;
  const [apiVersion, setApiVersion] = useState<string>("-");

  const translate = useTranslate();
  const apiUrl = useApiUrl();
  const { open } = useNotification();
  const url = `${apiUrl}/dashboard/api-version`;

  useEffect(() => {
    axiosInstance
      .get(url)
      .then((res) => {
        const data = res?.data?.body?.apiVersion;
        setApiVersion(data);
      })
      .catch((err) => {
        const error = err?.response?.data;
        open?.({
          type: "error",
          message: translate(
            "notifications.error",
            { statusCode: error?.statusCode },
            `Error code: ${error?.statusCode}`
          ),
          description: error?.message || "Unknown error",
        });
      });
  }, [translate, open, url]);

  return (
    <div
      style={{
        backgroundColor: token.colorBgContainer,
        minHeight: "48px",
        display: "flex",
        justifyContent: "center",
        marginTop: "48px",
      }}
    >
      <Space
        size="large"
        style={{
          width: "100%",
          maxWidth: "1300px",
          justifyContent: "space-between",
          marginInline: "24px",
        }}
      >
        <Typography.Text>
          © 2022-2024 Henan Asset Management. All rights reserved.
        </Typography.Text>
        <Typography.Text>
          {`Client ${appVersion || "-"} - Server ${apiVersion || "-"}`}
        </Typography.Text>
      </Space>
    </div>
  );
};
