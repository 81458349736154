import React, { useState } from "react";
import { useResource, useShow } from "@refinedev/core";
import { Create, useForm, Show } from "@refinedev/antd";
import { Col, Row, Form } from "antd";
import { FormCard, ShowLayout } from "components/organisms";
import { DynamicSelect } from "components/atoms";

export const AmendmentCreate: React.FC = () => {
  const { resource } = useResource();
  const [selectedId, setSelectedId] = useState();

  const { formProps, saveButtonProps } = useForm();

  const { queryResult } = useShow({
    resource:
      resource?.meta?.amendmentDropdownFormColumn?.optionsMeta?.resourceName,
    id: selectedId,
  });
  const { data } = queryResult;
  const records = data?.data;

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Show
          title={"Old Data"}
          headerProps={{ breadcrumb: undefined }}
          headerButtons={[]}
        >
          <Form form={formProps?.form} layout="vertical">
            <Form.Item
              label={"Old Data"}
              name={resource?.meta?.amendmentDropdownFormColumn?.key + "Id"}
              required
            >
              <DynamicSelect
                formColumn={resource?.meta?.amendmentDropdownFormColumn}
                onChange={(value, option, selectedData) => {
                  setSelectedId(value);
                  if (selectedData?.productId) {
                    formProps?.form?.setFieldsValue({
                      productId: selectedData?.productId,
                    });
                  }
                }}
              />
            </Form.Item>
          </Form>

          {records && (
            <>
              <div style={{ height: "16px" }} />

              <ShowLayout
                objectName={resource?.identifier || resource?.name}
                objectData={records}
              />
            </>
          )}
        </Show>
      </Col>

      <Col span={12}>
        <Create
          title="New Data"
          headerProps={{ breadcrumb: undefined, backIcon: false }}
          saveButtonProps={saveButtonProps}
        >
          <FormCard
            key={resource?.identifier || resource?.name}
            formProps={formProps}
            resource={resource}
          />
        </Create>
      </Col>
    </Row>
  );
};
