import { lazy } from "react";
import { Authenticated, CanAccess } from "@refinedev/core";
import "@refinedev/antd/dist/reset.css";
import {
  CatchAllNavigate,
  NavigateToResource,
} from "@refinedev/react-router-v6";
import { Outlet, Route, Routes } from "react-router-dom";
import { ResourceProps } from "@refinedev/core/dist/interfaces";
import {
  Title,
  DefaultList,
  DefaultShow,
  DefaultCreate,
  DefaultEdit,
  DefaultApprove,
  CustomErrorComponent,
} from "components";

const AuthPage = lazy(() =>
  import("components").then((module) => ({ default: module.AuthPage }))
);
const CustomLayout = lazy(() =>
  import("components").then((module) => ({ default: module.CustomLayout }))
);

const EXCLUDED_ROUTES = [
  "/general/users/show/me",
  "/general/users/edit/me",
  "/general/users/approve/me",

  "general/access-controls/permissions/show/me",
  "general/access-controls/permissions/edit/me",
  "general/access-controls/permissions/approve/me",
];

interface Props {
  resources: ResourceProps[] | undefined;
}

export const RouteProvider: React.FC<Props> = ({ resources }) => {
  const params = new URLSearchParams(window.location.search);
  let redirectTo = params.get("to") || "dashboard";
  if (redirectTo.charAt(0) === "/") redirectTo = redirectTo.slice(1);

  return (
    <Routes>
      <Route
        element={
          <Authenticated
            key="authenticated-inner"
            fallback={<CatchAllNavigate to="/login" />}
          >
            <CustomLayout>
              <Outlet />
            </CustomLayout>
          </Authenticated>
        }
      >
        <Route index element={<NavigateToResource resource="dashboard" />} />

        <Route path="*" element={<CustomErrorComponent />} />

        {EXCLUDED_ROUTES.map((route) => (
          <Route key={route} path={route} element={<CustomErrorComponent />} />
        ))}

        {resources?.map((resource) => {
          return (
            <Route key={resource.name} path={resource?.list as string}>
              {resource?.list && (
                <Route
                  index
                  element={
                    <CanAccess
                      resource={
                        resource?.meta?.listPermissionResource ??
                        resource?.meta?.listResource ??
                        resource?.name
                      }
                      action={
                        resource?.meta?.listPermissionAction ??
                        resource?.meta?.defaultAction ??
                        "list"
                      }
                      fallback={<CustomErrorComponent />}
                    >
                      {resource?.meta?.listComponent || (
                        <DefaultList key={resource?.show as string} />
                      )}
                    </CanAccess>
                  }
                />
              )}

              {resource?.show && (
                <Route
                  path="show/:id"
                  element={
                    <CanAccess
                      resource={
                        resource?.meta?.showPermissionResource ??
                        resource?.meta?.showResource ??
                        resource?.name
                      }
                      action={resource?.meta?.showPermissionAction ?? "show"}
                      fallback={<CustomErrorComponent />}
                    >
                      {resource?.meta?.showComponent || (
                        <DefaultShow key={resource?.show as string} />
                      )}
                    </CanAccess>
                  }
                />
              )}

              {resource?.create && (
                <Route
                  path="create"
                  element={
                    <CanAccess
                      resource={
                        resource?.meta?.createPermissionResource ??
                        resource?.meta?.createResource ??
                        resource?.name
                      }
                      action={
                        resource?.meta?.createPermissionAction ?? "create"
                      }
                      fallback={<CustomErrorComponent />}
                    >
                      {resource?.meta?.createComponent || (
                        <DefaultCreate key={resource?.create as string} />
                      )}
                    </CanAccess>
                  }
                />
              )}

              {resource?.edit && (
                <Route
                  path="edit/:id"
                  element={
                    <CanAccess
                      resource={
                        resource?.meta?.editPermissionResource ??
                        resource?.meta?.editResource ??
                        resource?.name
                      }
                      action={resource?.meta?.editPermissionAction ?? "edit"}
                      fallback={<CustomErrorComponent />}
                    >
                      {resource?.meta?.editComponent || (
                        <DefaultEdit key={resource?.edit as string} />
                      )}
                    </CanAccess>
                  }
                />
              )}

              {resource?.meta?.canApprove && (
                <Route
                  path="approve/:id"
                  element={
                    <CanAccess
                      resource={
                        resource?.meta?.approvePermissionResource ??
                        resource?.meta?.approveResource ??
                        `${resource?.name}/approval`
                      }
                      action={resource?.meta?.approvePermissionAction ?? "edit"}
                      fallback={<CustomErrorComponent />}
                    >
                      {resource?.meta?.approveComponent || (
                        <DefaultApprove
                          key={`${resource?.name}/approve/:id` as string}
                        />
                      )}
                    </CanAccess>
                  }
                />
              )}
            </Route>
          );
        })}
      </Route>

      <Route
        element={
          <Authenticated key="authenticated-outer" fallback={<Outlet />}>
            <NavigateToResource resource={redirectTo} />
          </Authenticated>
        }
      >
        <Route
          path="/login"
          element={
            <AuthPage
              type="login"
              registerLink={false}
              title={<Title collapsed={false} size="large" />}
            />
          }
        />

        <Route
          path="/forgot-password"
          element={
            <AuthPage
              type="forgotPassword"
              title={<Title collapsed={false} size="large" />}
            />
          }
        />

        <Route
          path="/update-password"
          element={
            <AuthPage
              type="updatePassword"
              title={<Title collapsed={false} size="large" />}
            />
          }
        />
      </Route>

      <Route
        element={
          <Authenticated key="authenticated-outer" fallback={<Outlet />}>
            <NavigateToResource resource={redirectTo} />
          </Authenticated>
        }
      >
        <Route path="*" element={<CustomErrorComponent />} />
      </Route>
    </Routes>
  );
};
